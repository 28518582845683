import React, { useState, useEffect } from "react";

import "../App.css";
import FileSizeCategory from "./Production/FileSizeCategory";
import OtherFileSizeCategory from "./Production/OtherFileSizeCategory";

//////////
// Calculate aspect ratio: Width / Height, might have to limit to 2 decimal places //
// REGEX for finding hex colour => ^#?(?:[0-9a-fA-F]{3}){1,2}$ //
//////////

const Production = ({ client, firebase, theme }) => {
  const [lifeFileSizes, setLifeFileSizes] = useState(client.fileSizes.life);
  const [productFileSizes, setProductFileSizes] = useState(
    client.fileSizes.product
  );
  const [videoFileSizes, setVideoFileSizes] = useState(client.fileSizes.video);
  const [otherFileSize1, setOtherFileSize1] = useState(client.fileSizes.other1);
  const [otherFileSize2, setOtherFileSize2] = useState(client.fileSizes.other2);
  const [otherFileSize3, setOtherFileSize3] = useState(client.fileSizes.other3);
  const [otherFileSize4, setOtherFileSize4] = useState(client.fileSizes.other4);

  useEffect(() => {
    setLifeFileSizes(client.fileSizes.life);
    setProductFileSizes(client.fileSizes.product);
    setVideoFileSizes(client.fileSizes.video);
    setOtherFileSize1(client.fileSizes.other1);
    setOtherFileSize2(client.fileSizes.other2);
    setOtherFileSize3(client.fileSizes.other3);
    setOtherFileSize4(client.fileSizes.other4);
  }, [client]);

  const style = {
    overflow: "auto",
    maxHeight: "calc(100vh - 220px)",
    width:800
  };

  return (
    <div style={style} id="FileSizeCategoryContainer">
      <FileSizeCategory
        key="FS-CAT-LIFE"
        kind="Life"
        fileSizes={lifeFileSizes}
        firebase={firebase}
        client={client}
        topMargin={false}
        theme={theme}
      />
      <FileSizeCategory
        key="FS-CAT-PRODUCT"
        kind="Product"
        fileSizes={productFileSizes}
        firebase={firebase}
        client={client}
        topMargin={true}
        theme={theme}
      />
      <FileSizeCategory
        key="FS-CAT-VIDEO"
        kind="Video"
        fileSizes={videoFileSizes}
        firebase={firebase}
        client={client}
        topMargin={true}
        theme={theme}
      />
      <div
        style={{
          paddingTop: "20px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <OtherFileSizeCategory
          key="FS-CAT-OTHER1"
          kind="Other Size 1"
          firebase={firebase}
          fileSize={otherFileSize1}
          setFileSize={setOtherFileSize1}
          client={client}
          theme={theme}
        />
        <OtherFileSizeCategory
          key="FS-CAT-OTHER2"
          kind="Other Size 2"
          firebase={firebase}
          fileSize={otherFileSize2}
          setFileSize={setOtherFileSize2}
          client={client}
          theme={theme}
        />
        <OtherFileSizeCategory
          key="FS-CAT-OTHER3"
          kind="Other Size 3"
          firebase={firebase}
          fileSize={otherFileSize3}
          setFileSize={setOtherFileSize3}
          client={client}
          theme={theme}
        />
        <OtherFileSizeCategory
          key="FS-CAT-OTHER4"
          kind="Other Size 4"
          firebase={firebase}
          fileSize={otherFileSize4}
          setFileSize={setOtherFileSize4}
          client={client}
          theme={theme}
        />
      </div>
    </div>
  );
};

export default Production;

import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import Autocomplete from "@mui/material/Autocomplete";

import { useAtom } from "jotai";
import {
  isMobileBrowserAtom,
  // isMobileAtom
} from "../../../-Atoms";

import { debounce } from "lodash";

const OldInvoicing = ({
  jobData,
  staffData,
  findStaff,
  clientData,
  firebase,
  isDarkMode,
}) => {
  // const [isMobile] = useAtom(isMobileAtom);
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);

  let deleteTimeout;

  const cardStyle = {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    minWidth: 220,
    // maxWidth: isMobileBrowser ? "100%" : isMobile ? 270 : 270,
    maxWidth: isMobileBrowser ? "100%" : 270,
    width: "100%",
  };
  const cardStyleFW = {
    borderRadius: "10px",
    padding: "10px",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%",
  };

  const updateFS = debounce((firebase, id, field, value) => {
    firebase
      .firestore()
      .collection("JobData")
      .doc(id)
      .update({ [field]: value }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
  }, 250);

  const [showDelete, setShowDelete] = useState(false);

  const [, setInvoicingSpecialRequirements] = useState(
    jobData.invoicingSpecialRequirements
  );
  const [imagesProcessedNumbers, setImagesProcessedNumbers] = useState(
    jobData.imagesProcessedNumbers
  );
  const [imagesProcessed, setImagesProcessed] = useState(
    jobData.imagesProcessed
  );
  const [imagesSupplied, setImagesSupplied] = useState(jobData.imagesSupplied);
  const [backgroundRolls, setBackgroundRolls] = useState(
    jobData.backgroundRolls
  );
  const [cStrings, setCStrings] = useState(jobData.cStrings);
  const [, setNippleCovers] = useState(jobData.nippleCovers);
  const [, setPrintedPages] = useState(jobData.printedPages);

  const [operator, setOperator] = useState(jobData.operator);
  const [additionalOperators, setAdditionalOperators] = useState(
    jobData.additionalOperators
  );
  // console.log(additionalOperators);
  const [retoucher, setRetoucher] = useState("");
  const [retouchingTime, setRetouchingTime] = useState(jobData.retouchingTime);
  const [bookedStart, setBookedStart] = useState(jobData.bookedStart);
  const [bookedFinish, setBookedFinish] = useState(jobData.bookedFinish);
  const [startTime, setStartTime] = useState(jobData.startTime);
  const [finishTime, setFinishTime] = useState(jobData.finishTime);
  const [overtime, setOvertime] = useState(jobData.overtime);
  const [catering, setCatering] = useState(jobData.catering);
  const [dayType, setDayType] = useState(jobData.dayType);
  const [creativeDayType, setCreativeDayType] = useState(
    jobData.creativeDayType
  );
  const [jobTypeFull, setJobTypeFull] = useState(jobData.jobTypeFull);

  // eslint-disable-next-line
  const [imagesOverAllocation, setImagesOverAllocation] = useState(
    jobData.imagesOverAllocation
  );

  const [imagesProcessedNumbersError, setImagesProcessedNumbersError] =
    useState(true);
  const imagesProcessedError = false;
  const [imagesSuppliedError, setImagesSuppliedError] = useState(true);
  const [retoucherError, setRetoucherError] = useState(true);
  const [retouchingTimeError, setRetouchingTimeError] = useState(
    jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
      ? true
      : false
  );

  const [, setInvoicingNotes] = useState(clientData.invoicingNotes);
  const [fullDayRate, setFullDayRate] = useState(clientData.fullDayRate);
  const [halfDayRate, setHalfDayRate] = useState(clientData.halfDayRate);
  const [contentFullDayRate, setContentFullDayRate] = useState(
    clientData.contentFullDayRate
  );
  const [contentHalfDayRate, setContentHalfDayRate] = useState(
    clientData.contentHalfDayRate
  );
  const [, setClientReview] = useState(clientData.clientReview);

  useEffect(() => {
    imagesProcessedNumbers
      ? setImagesProcessedNumbersError(false)
      : setImagesProcessedNumbersError(true);
  }, [imagesProcessedNumbers]);
  useEffect(() => {
    imagesSupplied
      ? setImagesSuppliedError(false)
      : setImagesSuppliedError(true);
  }, [imagesSupplied]);
  useEffect(() => {
    retoucher ? setRetoucherError(false) : setRetoucherError(true);
  }, [retoucher]);
  useEffect(() => {
    if (jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")) {
      retouchingTime
        ? setRetouchingTimeError(false)
        : setRetouchingTimeError(true);
    }
  }, [retouchingTime, jobTypeFull]);
  useEffect(() => {
    jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
      ? setRetouchingTimeError(true)
      : setRetouchingTimeError(false);
  }, [jobTypeFull]);

  useEffect(() => {
    setInvoicingSpecialRequirements(jobData.invoicingSpecialRequirements);
    setImagesProcessed(jobData.imagesProcessed);
    setImagesSupplied(jobData.imagesSupplied);
    setBackgroundRolls(jobData.backgroundRolls);
    setCStrings(jobData.cStrings);
    setNippleCovers(jobData.nippleCovers);
    setPrintedPages(jobData.printedPages);
    setOperator(jobData.operator);
    setAdditionalOperators(jobData.additionalOperators);
    setRetoucher(jobData.retoucher);
    setRetouchingTime(jobData.retouchingTime);
    setBookedStart(jobData.bookedStart);
    setBookedFinish(jobData.bookedFinish);
    setStartTime(jobData.startTime);
    setFinishTime(jobData.finishTime);
    setOvertime(jobData.overtime);
    setCatering(jobData.catering);
    setDayType(jobData.dayType);
    setCreativeDayType(jobData.creativeDayType);
    setJobTypeFull(jobData.jobTypeFull);
    setImagesOverAllocation(jobData.imagesOverAllocation);
    setImagesProcessedNumbers(jobData.imagesProcessedNumbers);
  }, [jobData]);

  useEffect(() => {
    setInvoicingNotes(clientData.invoicingNotes);
    setFullDayRate(clientData.fullDayRate);
    setHalfDayRate(clientData.halfDayRate);
    setContentFullDayRate(clientData.contentFullDayRate);
    setContentHalfDayRate(clientData.contentHalfDayRate);
    setClientReview(clientData.clientReview);
  }, [clientData]);

  const handleValueChange = (e, setValue, kind) => {
    setValue(e.target.value);
    jobData[kind] = e.target.value;
    updateFS(firebase, jobData.id, kind, e.target.value);
  };

  // const handleClientReviewClick = () => {
  //   let value;
  //   if (clientReview) {
  //     value = false;
  //   } else {
  //     value = true;
  //   }
  //   setClientReview(value);
  //   console.log(clientData.id);
  //   firebase
  //     .firestore()
  //     .collection("ClientDatabase")
  //     .doc(clientData.id)
  //     .set({ clientReview: value }, { merge: true });
  // };
  // const codStyle = {
  //   // background: "#00000005",
  //   borderRadius: "10px",
  //   padding: "10px",
  //   margin: "10px",
  //   display: "flex",
  //   position: "relative",
  //   justifyContent: "space-evenly",
  //   fontSize: "1.4em",
  //   boxSizing: "border-box",
  //   color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
  // };
  // const codStyleRed = {
  //   // background: "#00000005",
  //   borderRadius: "10px",
  //   padding: "10px",
  //   margin: "10px",
  //   display: "flex",
  //   position: "relative",
  //   justifyContent: "space-evenly",
  //   fontSize: "1.4em",
  //   border: "1px #f44336 solid",
  //   boxSizing: "border-box",
  //   color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
  // };
  const textFieldStyle = {
    margin: "6px",
  };

  const handleNewCateringItem = () => {
    setShowDelete(false);
    if (catering.length === 0) {
      catering.push("");
    }
    catering.push("");
    updateJobDataNOW(firebase, jobData.id, "catering", catering);
  };
  const handleShowDelete = () => {
    if (deleteTimeout) {
      clearTimeout(deleteTimeout);
      deleteTimeout = null;
    } else {
      deleteTimeout = setTimeout(() => {
        setShowDelete(false);
        deleteTimeout = null;
      }, 10000);
    }
    setShowDelete(!showDelete);
  };
  const handleDeleteCateringItem = (index) => {
    catering.splice(index, 1);
    setCatering(catering);
    updateJobDataNOW(firebase, jobData.id, "catering", catering);
  };
  const cateringButtons = [
    <Button key={"NewItemButton"} onClick={handleNewCateringItem}>
      New Item
    </Button>,
    <Button key={"DeleteItemButton"} onClick={handleShowDelete}>
      Delete Item
    </Button>,
  ];

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: 20,
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            firebase
              .firestore()
              .collection("JobData")
              .doc(jobData.id)
              .update({ imageBreakdown: [] }, { merge: true })
              .catch((error) => {
                console.log(error);
              });
          }}
        >
          Switch to new invoicing (no data carries over)
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <div style={{ ...cardStyle, marginTop: isMobileBrowser ? 30 : 10 }}>
          <Tooltip title="Images Processed" enterDelay={500}>
            <TextField
              variant="outlined"
              multiline={true}
              label={
                jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
                  ? "Videos Shot *"
                  : "Images Processed *"
              }
              error={imagesProcessedNumbersError}
              value={imagesProcessedNumbers || ""}
              onChange={async (e) => {
                if (!isNaN(e.target.value)) {
                  setImagesProcessedNumbers(e.target.value);
                  const parsed = parseImagesOverAllocation(
                    e.target.value,
                    jobData,
                    clientData
                  );
                  setImagesOverAllocation(parsed);
                  updateImagesProcessedNumbers(
                    firebase,
                    jobData.id,
                    "imagesProcessedNumbers",
                    e.target.value,
                    parsed
                  );
                  console.log(e.target.value);
                } else setImagesProcessedNumbersError(true);
              }}
              style={textFieldStyle}
            />
          </Tooltip>
          <Tooltip title="Image Breakdown" enterDelay={500}>
            <TextField
              variant="outlined"
              multiline={true}
              label={
                jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
                  ? "Video Breakdown"
                  : "Image Breakdown"
              }
              error={imagesProcessedError}
              value={imagesProcessed || ""}
              onChange={(e) => {
                handleValueChange(e, setImagesProcessed, "imagesProcessed");
              }}
              style={textFieldStyle}
            />
          </Tooltip>
          <Tooltip title="Images Supplied" enterDelay={500}>
            <TextField
              variant="outlined"
              multiline={true}
              error={imagesSuppliedError}
              label={
                jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
                  ? "Videos Supplied *"
                  : "Images Supplied *"
              }
              value={imagesSupplied || ""}
              onChange={(e) => {
                handleValueChange(e, setImagesSupplied, "imagesSupplied");
              }}
              style={textFieldStyle}
            />
          </Tooltip>
          <TextField
            variant="outlined"
            multiline={true}
            label="Background Rolls"
            value={backgroundRolls || ""}
            onChange={(e) => {
              handleValueChange(e, setBackgroundRolls, "backgroundRolls");
            }}
            style={textFieldStyle}
          />
          <TextField
            variant="outlined"
            multiline={true}
            label="C-Strings"
            value={cStrings || ""}
            onChange={(e) => {
              handleValueChange(e, setCStrings, "cStrings");
            }}
            style={textFieldStyle}
          />
        </div>

        <div style={{ ...cardStyle, marginTop: isMobileBrowser ? 30 : 10 }}>
          <Tooltip title="Operator" enterDelay={500}>
            <Autocomplete
              disablePortal
              sx={{ marginTop: 2 }}
              style={textFieldStyle}
              options={staffData}
              getOptionLabel={(option) => findStaff(option, staffData)}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Operator" />
              )}
              value={operator || ""}
              onChange={(e, value) => {
                setOperator(value.id || "");
                firebase
                  .firestore()
                  .collection("JobData")
                  .doc(jobData.id)
                  .update({ operator: value.id || "" }, { merge: true })
                  .catch((e) => console.log(e));
              }}
            />
          </Tooltip>
          {additionalOperators &&
            additionalOperators.map((ao) => (
              <Tooltip title="Additional Operator" enterDelay={500}>
                <TextField
                  variant="outlined"
                  label="Additional Operator"
                  value={findStaff(ao, staffData) || ""}
                  style={textFieldStyle}
                />
              </Tooltip>
            ))}
          <Tooltip title="Retoucher" enterDelay={500}>
            <Autocomplete
              disablePortal
              sx={{ marginTop: 2 }}
              style={textFieldStyle}
              options={staffData}
              getOptionLabel={(option) => findStaff(option, staffData)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={retoucherError}
                  variant="outlined"
                  label={
                    jobTypeFull?.includes("(-V)") ||
                    jobTypeFull?.includes("(-E)")
                      ? "Editor *"
                      : "Retoucher *"
                  }
                />
              )}
              value={retoucher || ""}
              onChange={(e, value) => {
                let val = "";
                if (value?.id) val = value.id;
                setRetoucher(val || "");
                firebase
                  .firestore()
                  .collection("JobData")
                  .doc(jobData.id)
                  .update({ retoucher: val || "" }, { merge: true })
                  .catch((e) => console.log(e));
              }}
            />
          </Tooltip>
          <Tooltip title="Retouching Time" enterDelay={500}>
            <TextField
              variant="outlined"
              multiline={true}
              error={retouchingTimeError}
              label={
                jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
                  ? "Editing Time *"
                  : "Retouching Time"
              }
              value={retouchingTime || ""}
              onChange={(e) => {
                handleValueChange(e, setRetouchingTime, "retouchingTime");
              }}
              style={textFieldStyle}
            />
          </Tooltip>
        </div>
      </div>

      {/* start finish catering */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          marginTop: 20,
        }}
      >
        <div style={{ ...cardStyle, marginTop: isMobileBrowser ? 30 : 10 }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <TimePicker
              label="Booked Start"
              value={bookedStart || null}
              onChange={(d) => {}}
              readOnly={true}
              renderInput={(params) => (
                <TextField {...params} style={{ margin: 5 }} />
              )}
            />
            <TimePicker
              label="Booked Finish"
              value={bookedFinish || null}
              onChange={(d) => {}}
              readOnly={true}
              renderInput={(params) => (
                <TextField {...params} style={{ margin: 5 }} />
              )}
            />
            <TimePicker
              label="Start Time"
              value={startTime || null}
              onChange={(d) => {
                if (!d) return;
                if (!d.isValid()) return;
                setStartTime(d.toISOString());
                updateFS(firebase, jobData.id, "startTime", d.toISOString());
              }}
              onInput={(e) => {
                e.preventDefault();
              }}
              renderInput={(params) => (
                <TextField {...params} style={{ margin: 5 }} />
              )}
            />
            <TimePicker
              label="Finish Time"
              value={finishTime || null}
              onChange={(d) => {
                if (!d) return;
                if (!d.isValid()) return;
                setFinishTime(d.toISOString());
                updateFS(firebase, jobData.id, "finishTime", d.toISOString());
              }}
              onInput={(e) => {
                console.log(e);
                e.preventDefault();
              }}
              renderInput={(params) => (
                <TextField {...params} style={{ margin: 5 }} />
              )}
            />
          </LocalizationProvider>
          <Tooltip title="Overtime (In Hours)" enterDelay={500}>
            <TextField
              variant="outlined"
              multiline={true}
              label="Overtime (In Hours)"
              value={overtime || ""}
              onChange={(e) => {
                if (!isNaN(e.target.value)) {
                  handleValueChange(e, setOvertime, "overtime");
                }
              }}
              style={textFieldStyle}
            />
          </Tooltip>
        </div>
        <div style={{ ...cardStyle, marginTop: isMobileBrowser ? 30 : 15 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <ButtonGroup size="small" style={{ paddingBottom: "10px" }}>
              {cateringButtons}
            </ButtonGroup>
            <label
              style={{
                color: isDarkMode ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
              }}
            >
              Total: {parseCateringTotal(catering)}
            </label>
          </div>
          <List style={{ width: "100%", maxHeight: "260px", overflow: "auto" }}>
            {catering && catering.length > 0 ? (
              catering.map((item, index) => (
                <div
                  key={`catering-${index}-container`}
                  style={{ display: "flex" }}
                >
                  {showDelete ? (
                    <IconButton
                      onClick={() => {
                        handleDeleteCateringItem(index);
                      }}
                    >
                      <DeleteIcon
                        style={{
                          color: "#ff0033",
                          width: 30,
                          height: 30,
                        }}
                      />
                    </IconButton>
                  ) : null}
                  <CateringItem
                    key={`catering-${index}`}
                    item={item}
                    index={index}
                    catering={catering}
                    firebase={firebase}
                    id={jobData.id}
                  />
                </div>
              ))
            ) : (
              <div key={`catering-0-container`}>
                <CateringItem
                  key={`catering-0`}
                  catering={catering}
                  firebase={firebase}
                  id={jobData.id}
                />
              </div>
            )}
          </List>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {dayType === "Full Day" ? (
          <div style={cardStyleFW}>
            <span style={{ padding: "5px" }}>Job Specifications</span>
            <Tooltip title="Full Day - Hours" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Full Day Hours"
                value={fullDayRate?.time || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
            <Tooltip title="Full Day - Lunch" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Full Day Lunch"
                value={fullDayRate?.lunch || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
            <Tooltip title="Full Day - Images" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Full Day Images"
                value={fullDayRate?.images || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
          </div>
        ) : dayType === "Half Day" ? (
          <div style={cardStyleFW}>
            <span style={{ padding: "5px" }}>Job Specifications</span>
            <Tooltip title="Half Day - Hours" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Half Day Hours"
                value={halfDayRate?.time || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
            <Tooltip title="Half Day - Images" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Half Day Images"
                value={halfDayRate?.images || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
          </div>
        ) : null}
        {creativeDayType === "Full Day" ? (
          <div style={cardStyleFW}>
            <span style={{ padding: "5px" }}>Job Specifications</span>
            <Tooltip title="Content Full Day - Hours" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Content Full Day Hours"
                value={contentFullDayRate?.time || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
            <Tooltip title="Content Full Day - Lunch" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Content Full Day Lunch"
                value={contentFullDayRate?.lunch || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
            <Tooltip title="Content Full Day - Images" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Content Full Day Images"
                value={contentFullDayRate?.images || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
          </div>
        ) : creativeDayType === "Half Day" ? (
          <div style={cardStyleFW}>
            <span style={{ padding: "5px" }}>Job Specifications</span>
            <Tooltip title="Content Half Day - Hours" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Content Half Day Hours"
                value={contentHalfDayRate?.time || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
            <Tooltip title="Content Half Day - Images" enterDelay={500}>
              <TextField
                variant="outlined"
                label="Content Half Day Images"
                value={contentHalfDayRate?.images || ""}
                onChange={(e) => {}}
                style={{ marginTop: "10px", width: "100%" }}
                inputProps={{ readOnly: true }}
              />
            </Tooltip>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default OldInvoicing;

const parseImagesOverAllocation = (images, jobData, clientData) => {
  let imageAllocation;
  if (jobData.dayType === "Full Day") {
    if (!isNaN(clientData?.fullDayRate?.images)) {
      imageAllocation = clientData?.fullDayRate?.images;
    } else imageAllocation = 240;
  } else if (jobData.dayType === "Half Day") {
    if (!isNaN(clientData?.halfDayRate?.images)) {
      imageAllocation = clientData?.halfDayRate?.images;
    } else imageAllocation = 120;
  } else if (jobData.creativeDayType === "Full Day") {
    if (!isNaN(clientData?.contentFullDayRate?.images)) {
      imageAllocation = clientData?.contentFullDayRate?.images;
    } else imageAllocation = 240;
  } else if (jobData.creativeDayType === "Half Day") {
    if (!isNaN(clientData?.contentHalfDayRate?.images)) {
      imageAllocation = clientData?.contentHalfDayRate?.images;
    } else imageAllocation = 120;
  } else {
    imageAllocation = 240;
  }
  if (images > parseInt(imageAllocation)) {
    return true;
  } else return false;
};

const CateringItem = ({ index, item, catering, firebase, id }) => {
  const [itemContent, setItemContent] = useState(item);

  const updateFS = debounce((firebase, id, field, value) => {
    firebase
      .firestore()
      .collection("JobData")
      .doc(id)
      .update({ [field]: value }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
  }, 250);

  useEffect(() => {
    setItemContent(item);
  }, [item]);
  const handleChange = (e) => {
    setItemContent(e.target.value);
    catering[index || 0] = e.target.value;
    updateFS(firebase, id, "catering", catering);
  };
  return (
    <ListItem>
      <TextField
        size="small"
        variant="outlined"
        label={`Catering ${(index || 0) + 1}`}
        style={{ width: "100%" }}
        inputProps={{ style: { fontSize: 15 } }}
        value={itemContent || ""}
        onChange={handleChange}
      />
    </ListItem>
  );
};

const parseCateringTotal = (catering) => {
  if (!catering) {
    return `$0`;
  }
  let total = 0;
  catering.forEach((c) => {
    if (!c) {
      c = 0;
    }
    c = `${c}`;
    c = c.replace(/\$/g, "");
    c = parseFloat(c);
    total = total + c;
  });
  total = total.toFixed(2);
  return `$${total}`;
};

// const updateJobData = oldbounce((firebase, id, field, value) => {
//   firebase
//     .firestore()
//     .collection("JobData")
//     .doc(id)
//     .update({ [field]: value }, { merge: true })
//     .catch((e) => console.log(e));
// }, 300);

const updateImagesProcessedNumbers = oldbounce(
  (firebase, id, field, value, parseImagesOverAllocationValue) => {
    firebase
      .firestore()
      .collection("JobData")
      .doc(id)
      .update({ [field]: value }, { merge: true })
      .catch((e) => console.log(e));

    firebase
      .firestore()
      .collection("JobData")
      .doc(id)
      .update({
        imagesOverAllocation: parseImagesOverAllocationValue,
      })
      .catch((e) => console.log(e));
  },
  400
);

function oldbounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const updateJobDataNOW = oldbounce((firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("JobData")
    .doc(id)
    .update({ [field]: value }, { merge: true })
    .catch((e) => console.log(e));
}, 0);

import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const KanbanDueDateExpressContainer = ({
  newPostJobDueDate,
  setNewPostJobDueDate,
  newPostJobExpress,
  setNewPostJobExpress,
  newPostJobExpressDueDate,
  setNewPostJobExpressDueDate,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        marginTop: 0,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Due Date"
          inputFormat="dd/MM/yyyy"
          value={newPostJobDueDate || null}
          onChange={(e) => {
            try {
              e.setHours(17);
              e.setMinutes(30);
              setNewPostJobDueDate(e.toISOString());
            } catch (e) {
              setNewPostJobDueDate(null);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={newPostJobDueDate ? false : true}
              sx={{ marginTop: 2, width: "56%" }}
            />
          )}
        />
      </LocalizationProvider>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormGroup
          style={{
            width: "80%",
            display: "flex",
            alignItems: "center",
          }}
          sx={{ marginTop: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={newPostJobExpress || false}
                onChange={(e) => {
                  setNewPostJobExpress(e.target.checked);
                }}
              />
            }
            label="Express"
          />
        </FormGroup>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Express Due Date"
            value={newPostJobExpressDueDate || null}
            inputFormat="dd/MM/yyyy hh:mm"
            onChange={(e) => {
              setNewPostJobExpressDueDate(e.toISOString() || null);
            }}
            renderInput={(params) => (
              <TextField {...params} fullWidth sx={{ marginTop: 2 }} />
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default KanbanDueDateExpressContainer;

import React from "react";

import { useAtom } from "jotai";
import { themeAtom, labelColoursAtom } from "../../../-Atoms";
import EditIcon from "@mui/icons-material/Edit";
import Checkbox from "@mui/material/Checkbox";
import { IconButton } from "@mui/material";

const LabelItem = ({
  index,
  jobData,
  toggleCheck,
  label,
  setLabelMenuEditMode,
  setLabelToEdit,
}) => {
  const [theme] = useAtom(themeAtom);
  const [labelColours] = useAtom(labelColoursAtom);

  return (
    <div
      key={`labelMenu Key - ${index}`}
      style={{
        width: "100%",
        maxWidth: 290,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Checkbox
        size="small"
        checked={jobData?.idLabels?.includes(label.id) || false}
        onClick={toggleCheck}
      />
      <div
        style={{
          fontSize: 13,
          fontWeight: "bold",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          cursor: "pointer",
          // width: 200,
          borderRadius: 4,
          width: "100%",
          backgroundColor: label.color
            ? labelColours[label.color][theme]
            : "rgba(0, 0, 0, 0.1)",
          margin: 5,
          padding: 5,
        }}
        onClick={toggleCheck}
      >
        {label.name}
      </div>
      <IconButton
        size="small"
        onClick={() => {
          setLabelMenuEditMode(true);
          setLabelToEdit(label);
        }}
      >
        <EditIcon fontSize="10px" />
      </IconButton>
    </div>
  );
};

export default LabelItem;

import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

// import { DateRangePicker } from "mui-daterange-picker";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { DatePickerWithRange } from "../GlobalComponents/DatePickerWithRange";

const ExportDatesModal = ({
  firebase,
  exportDatesModalOpen,
  setExportDatesModalOpen,
  jobTypes,
  studios,
  clientData,
  staffData,
  theme,
}) => {
  const [clientNames, setClientNames] = useState([]);

  useEffect(() => {
    const arr = [];
    clientData.forEach((c) => {
      if (c.brand) {
        if (c.brand !== "Images That Sell") arr.push(c.brand);
      }
      setClientNames([...arr]);
    });
  }, [clientData, setClientNames]);

  const [dateRange, setDateRange] = useState({});

  const [selectedClients, setSelectedClients] = useState([]);

  const [selectedStaff, setSelectedStaff] = useState("");

  const [selectedJobTypes, setSelectedJobTypes] = useState([]);

  const [showResults, setShowResults] = useState(false);

  const [dates, setDates] = useState({});

  const [clientSelect, setClientSelect] = useState(true);

  const toggleClientSelect = () => {
    setClientSelect(!clientSelect);
  };

  const exportDates = async () => {
    if (!dateRange.from) return;
    if (!dateRange.from) return;
    if (!selectedJobTypes) return;
    if (clientSelect) {
      if (!selectedClients) return;
    } else {
      if (!selectedStaff) return;
    }

    const endDate = new Date(dateRange.to.toISOString());
    endDate.setDate(endDate.getDate() + 1);

    if (clientSelect) {
      const tmp = {};

      for (let i = 0; i < selectedClients.length; i++) {
        const clientName = selectedClients[i];
        const arr = [];
        const result = await firebase
          .firestore()
          .collection("JobData")
          .where("client", "==", clientName)
          .where("bookedStart", ">", dateRange.from.toISOString())
          .where("bookedStart", "<", endDate.toISOString())
          .get();
        result.docs.forEach((t) => {
          arr.push(t.data());
        });
        tmp[clientName] = arr;
      }

      setDates(tmp);

      setShowResults(true);
    } else {
      const tmp = {};
      const arr = [];
      const pResult = await firebase
        .firestore()
        .collection("JobData")
        .where("photographer", "==", selectedStaff)
        .where("bookedStart", ">", dateRange.from.toISOString())
        .where("bookedStart", "<", endDate.toISOString())
        .get();
      pResult.docs.forEach((t) => {
        arr.push(t.data());
      });
      const oResult = await firebase
        .firestore()
        .collection("JobData")
        .where("operator", "==", selectedStaff)
        .where("bookedStart", ">", dateRange.from.toISOString())
        .where("bookedStart", "<", endDate.toISOString())
        .get();
      oResult.docs.forEach((t) => {
        arr.push(t.data());
      });
      const sResult = await firebase
        .firestore()
        .collection("JobData")
        .where("stylist", "==", selectedStaff)
        .where("bookedStart", ">", dateRange.from.toISOString())
        .where("bookedStart", "<", endDate.toISOString())
        .get();
      sResult.docs.forEach((t) => {
        arr.push(t.data());
      });
      const apResult = await firebase
        .firestore()
        .collection("JobData")
        .where("additionalPhotographers", "array-contains", selectedStaff)
        .where("bookedStart", ">", dateRange.from.toISOString())
        .where("bookedStart", "<", endDate.toISOString())
        .get();
      apResult.docs.forEach((t) => {
        arr.push(t.data());
      });
      const aoResult = await firebase
        .firestore()
        .collection("JobData")
        .where("additionalOperators", "array-contains", selectedStaff)
        .where("bookedStart", ">", dateRange.from.toISOString())
        .where("bookedStart", "<", endDate.toISOString())
        .get();
      aoResult.docs.forEach((t) => {
        arr.push(t.data());
      });
      const asResult = await firebase
        .firestore()
        .collection("JobData")
        .where("additionalStylists", "array-contains", selectedStaff)
        .where("bookedStart", ">", dateRange.from.toISOString())
        .where("bookedStart", "<", endDate.toISOString())
        .get();
      asResult.docs.forEach((t) => {
        arr.push(t.data());
      });

      let sn;
      staffData.forEach((s) => {
        if (s.id === selectedStaff) {
          sn = `${s.firstName} ${s.lastName}`;
        }
      });

      tmp[sn] = arr;

      setDates(tmp);
      setShowResults(true);
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 720,
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
    border: "none",
    outline: "none",
    padding: 20,
    borderRadius: 10,
    display: showResults ? "none" : "flex",
    flexDirection: "column",
  };
  const modalStyle2 = {
    ...modalStyle,
    display: showResults ? "flex" : "none",
    overflow: "auto",
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Modal
      open={exportDatesModalOpen}
      onClose={() => {
        setExportDatesModalOpen(false);
      }}
    >
      <>
        <div style={modalStyle}>
          <div style={{ display: "flex", justifyContent: "center", margin: 5 }}>
            <IconButton
              sx={{ position: "absolute", top: 10, left: 10, zIndex: 9999 }}
              onClick={() => {
                setExportDatesModalOpen(false);
              }}
            >
              <CloseIcon />
            </IconButton>
            <Button
              style={{ zIndex: 10000 }}
              variant="outlined"
              onClick={toggleClientSelect}
            >
              Toggle Client / Staff Select
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 10000,
            }}
          >
            {clientSelect ? (
              <MultipleSelectCheckmarks
                label={"Clients"}
                options={clientNames}
                selectAll={false}
                selectedOptions={selectedClients}
                setSelectedOptions={setSelectedClients}
              />
            ) : (
              <FormControl sx={{ m: 1, width: 700 }}>
                <InputLabel id="Staff-Select-label">Staff</InputLabel>
                <Select
                  labelId="Staff-Select-label"
                  id="Staff-Select"
                  value={selectedStaff}
                  label="Staff"
                  onChange={(e) => {
                    setSelectedStaff(e.target.value);
                  }}
                  MenuProps={MenuProps}
                >
                  {staffData.map((s) => (
                    <MenuItem value={s.id} key={s.id}>{`${
                      s.preferredName ? `(${s.preferredName}) ` : ""
                    }${s.firstName} ${s.lastName}`}</MenuItem>
                  ))}
                  {/* <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            )}
            <MultipleSelectCheckmarks
              label={"Job Types"}
              options={jobTypes}
              selectAll={true}
              selectedOptions={selectedJobTypes}
              setSelectedOptions={setSelectedJobTypes}
            />
          </div>
          <div style={{ margin: 8, zIndex: 1 }}>
            <DatePickerWithRange setDateRange={setDateRange} />
            {/* <DateRangePicker
              open={true}
              closeOnClickOutside={false}
              onChange={(range) => setDateRange(range)}
              wrapperClassName="DateRangePicker"
            /> */}
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", margin: 10 }}
          >
            <Button
              style={{ zIndex: 10000, width: 300 }}
              variant="outlined"
              onClick={exportDates}
            >
              Export Dates
            </Button>
          </div>
        </div>

        <div id="dates" style={modalStyle2}>
          <div style={{ marginTop: 50 }}></div>
          {Object.keys(dates).map((key) => (
            <div>
              <pre
                style={{
                  fontFamily:
                    '"HelveticaNeue-Light", "Helvetica Neue Light","Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
                  margin: 0,
                }}
              >
                <p>{key}:</p>
              </pre>
              {dates[key].map((date) => (
                <>
                  <pre
                    style={{
                      fontFamily:
                        '"HelveticaNeue-Light", "Helvetica Neue Light","Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif',
                      margin: 0,
                    }}
                  >
                    <span>&nbsp;&nbsp;&nbsp;- {parseDate(date)}</span>
                  </pre>
                  <br />
                </>
              ))}
              <br />
            </div>
          ))}
          <div
            style={{
              position: "absolute",
              top: 20,
              left: "50%",
              transform: "translate(-50%)",
            }}
          >
            <ButtonGroup
              variant="contained"
              aria-label="outlined primary button group"
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setShowResults(false);
                  setSelectedClients([]);
                  //   setDateRange({});
                }}
              >
                Reset
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  let copyText = document.getElementById("dates").innerText;
                  copyText = copyText.replace("\n\nRESET", "");
                  copyText = copyText.replace("\n\nCOPY", "");
                  navigator.clipboard.writeText(copyText);
                }}
              >
                Copy
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default ExportDatesModal;

const MultipleSelectCheckmarks = ({
  label,
  options,
  selectAll,
  selectedOptions,
  setSelectedOptions,
}) => {
  //   const [selectedOptions, setSelectedOptions] = useState(
  //     selectAll ? [...options] : []
  //   );

  useEffect(() => {
    setSelectedOptions(selectAll ? [...options] : []);
  }, [selectAll, setSelectedOptions, options]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(typeof value === "string" ? value.split(",") : value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <div>
      <FormControl sx={{ m: 1, width: 700 }}>
        <InputLabel id="MultipleSelectCheckmarks-label">{label}</InputLabel>
        <Select
          labelId="MultipleSelectCheckmarks-label"
          id="MultipleSelectCheckmarks-checkbox"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedOptions.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

function parseDate(jobData) {
  const date = new Date(jobData.bookedStart);
  const weekDay = parseWeekDay(date.getDay());
  const day = parseDay(date.getDate());
  const month = parseMonth(date.getMonth());
  const jobType = jobData.jobTypeFull.replace(/ \(.*\)/, "").toUpperCase();
  const dayType = jobData.dayType.toUpperCase();

  const lifeRes = `${
    jobData.jobNumber ? `${jobData.jobNumber} - ` : ""
  }${weekDay} ${day} ${month} ${jobType} ${
    jobData.multipleDays
  } ${dayType} ${formatAMPM(date)} - ${formatAMPM(
    new Date(jobData.bookedFinish)
  )}`;
  //   const ghostRes = `${weekDay} ${day} ${month} ${jobType}${
  //     jobData.multipleDays ? ` ${jobData.multipleDays}` : ""
  //   } x${jobData.imagesScheduledToBeShot}`;
  const ghostRes = `${
    jobData.jobNumber ? `${jobData.jobNumber} - ` : ""
  }${weekDay} ${day} ${month} ${jobType}${
    jobData.multipleDays ? ` ${jobData.multipleDays}` : ""
  }`;
  let res;
  if (jobData.jobTypeFull === "Creative Life (-LC)") res = lifeRes;
  else if (jobData.jobTypeFull === "Life (-L)") res = lifeRes;
  else if (jobData.jobTypeFull === "Video (-V)") res = lifeRes;
  else res = ghostRes;

  return res;

  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ampm;
    return strTime;
  }

  function parseMonth(month) {
    if (month === 0) return "JANUARY";
    if (month === 1) return "FEBRUARY";
    if (month === 2) return "MARCH";
    if (month === 3) return "APRIL";
    if (month === 4) return "MAY";
    if (month === 5) return "JUNE";
    if (month === 6) return "JULY";
    if (month === 7) return "AUGUST";
    if (month === 8) return "SEPTEMBER";
    if (month === 9) return "OCTOBER";
    if (month === 10) return "NOVEMBER";
    if (month === 11) return "DECEMBER";
  }
  function parseDay(i) {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "ST";
    }
    if (j === 2 && k !== 12) {
      return i + "ND";
    }
    if (j === 3 && k !== 13) {
      return i + "RD";
    }
    return i + "TH";
  }
  function parseWeekDay(day) {
    if (day === 0) return "SUNDAY";
    if (day === 1) return "MONDAY";
    if (day === 2) return "TUESDAY";
    if (day === 3) return "WEDNESDAY";
    if (day === 4) return "THURSDAY";
    if (day === 5) return "FRIDAY";
    if (day === 6) return "SATURDAY";
  }
}

import React from "react";
import TextField from "@mui/material/TextField";

const JobSpecifications = ({ titleStyle, jobData, clientData }) => {
  return (
    <>
      <div style={{ ...titleStyle, paddingBottom: 20 }}>
        <span>Job Specifications</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: 15,
          paddingLeft: 35,
          paddingRight: 35,
          paddingBottom: 20,
        }}
      >
        <>
          <TextField
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            label="Payment Terms"
            disabled={true}
            variant="filled"
            value={clientData.paymentTerms}
            size="small"
            sx={{ width: "47%" }}
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            label="Production Fee"
            disabled={true}
            variant="filled"
            value={`${clientData.PSF}%`}
            size="small"
            sx={{ width: "47%" }}
            InputProps={{ disableUnderline: true }}
          />
          <TextField
            variant="filled"
            label="Full Day Images"
            value={clientData.fullDayRate?.images || ""}
            disabled={true}
            size="small"
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            InputProps={{ disableUnderline: true }}
            sx={{ width: "47%" }}
          />
          <TextField
            variant="filled"
            label="Half Day Images"
            value={clientData.halfDayRate?.images || ""}
            disabled={true}
            size="small"
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            InputProps={{ disableUnderline: true }}
            sx={{ width: "47%" }}
          />
          <TextField
            variant="filled"
            label="Content Full Day Images"
            value={clientData.contentFullDayRate?.images || ""}
            disabled={true}
            size="small"
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            InputProps={{ disableUnderline: true }}
            sx={{ width: "47%" }}
          />
          <TextField
            variant="filled"
            label="Content Half Day Images"
            value={clientData.contentHalfDayRate?.images || ""}
            disabled={true}
            size="small"
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            InputProps={{ disableUnderline: true }}
            sx={{ width: "47%" }}
          />
        </>
      </div>
    </>
  );
};

export default JobSpecifications;

// {jobData.dayType === "Full Day" ? (
//   <>
//     <TextField
//       variant="filled"
//       label="Full Day Hours"
//       value={clientData.fullDayRate?.time || ""}
//       disabled={true}
//       size="small"
//       inputProps={{ style: { fontSize: 15 } }}
//       InputLabelProps={{ style: { fontSize: 15 } }}
//       InputProps={{ disableUnderline: true }}
//       sx={{ width: "47%" }}
//     />
//     <TextField
//       variant="filled"
//       label="Full Day Lunch"
//       value={clientData.fullDayRate?.lunch || ""}
//       disabled={true}
//       size="small"
//       inputProps={{ style: { fontSize: 15 } }}
//       InputLabelProps={{ style: { fontSize: 15 } }}
//       InputProps={{ disableUnderline: true }}
//       sx={{ width: "47%" }}
//     />
//     <TextField
//       variant="filled"
//       label="Full Day Images"
//       value={clientData.fullDayRate?.images || ""}
//       disabled={true}
//       size="small"
//       inputProps={{ style: { fontSize: 15 } }}
//       InputLabelProps={{ style: { fontSize: 15 } }}
//       InputProps={{ disableUnderline: true }}
//       sx={{ width: "47%" }}
//     />
//   </>
// ) : jobData.dayType === "Half Day" ? (
//   <>
//     <TextField
//       variant="filled"
//       label="Half Day Hours"
//       value={clientData.halfDayRate?.time || ""}
//       disabled={true}
//       size="small"
//       inputProps={{ style: { fontSize: 15 } }}
//       InputLabelProps={{ style: { fontSize: 15 } }}
//       InputProps={{ disableUnderline: true }}
//       sx={{ width: "47%" }}
//     />
//     <TextField
//       variant="filled"
//       label="Half Day Images"
//       value={clientData.halfDayRate?.images || ""}
//       disabled={true}
//       size="small"
//       inputProps={{ style: { fontSize: 15 } }}
//       InputLabelProps={{ style: { fontSize: 15 } }}
//       InputProps={{ disableUnderline: true }}
//       sx={{ width: "47%" }}
//     />
//   </>
// ) : null}

/* <div
  style={{
    display: "flex",
    justifyContent: "space-between",
    gap: 15,
    paddingLeft: 35,
    paddingRight: 35,
    paddingBottom: 20,
  }}
>
  {jobData.creativeDayType === "Full Day" ? (
    <>
      <TextField
        variant="filled"
        label="Content Full Day Hours"
        value={clientData.contentFullDayRate?.time || ""}
        disabled={true}
        size="small"
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        InputProps={{ disableUnderline: true }}
        sx={{ width: "47%" }}
      />
      <TextField
        variant="filled"
        label="Content Full Day Lunch"
        value={clientData.contentFullDayRate?.lunch || ""}
        disabled={true}
        size="small"
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        InputProps={{ disableUnderline: true }}
        sx={{ width: "47%" }}
      />
      <TextField
        variant="filled"
        label="Content Full Day Images"
        value={clientData.contentFullDayRate?.images || ""}
        disabled={true}
        size="small"
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        InputProps={{ disableUnderline: true }}
        sx={{ width: "47%" }}
      />
    </>
  ) : jobData.creativeDayType === "Half Day" ? (
    <>
      <TextField
        variant="filled"
        label="Content Half Day Hours"
        value={clientData.contentHalfDayRate?.time || ""}
        disabled={true}
        size="small"
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        InputProps={{ disableUnderline: true }}
        sx={{ width: "47%" }}
      />
      <TextField
        variant="filled"
        label="Content Half Day Images"
        value={clientData.contentHalfDayRate?.images || ""}
        disabled={true}
        size="small"
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        InputProps={{ disableUnderline: true }}
        sx={{ width: "47%" }}
      />
    </>
  ) : null}
</div>; */

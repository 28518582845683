import React, { useState, useEffect } from "react";
import ImageIcon from "@mui/icons-material/Image";
import Chip from "@mui/material/Chip";

import { useAtom } from "jotai";
import { themeAtom, labelColoursAtom } from "../-Atoms";

const ImagesChip = ({ jobData, clientData, cardBackgroundColour }) => {
  const [labelColours] = useAtom(labelColoursAtom);
  const [theme] = useAtom(themeAtom);

  const [returnNull, setReturnNull] = useState(false);
  const [imagesOverAllocation, setImagesOverAllocation] = useState(false);
  const [client, setClient] = useState({});

  const [imagesProcessedNumbers, setImagesProcessedNumbers] = useState(
    jobData.imagesProcessedNumbers || 0
  );
  useEffect(() => {
    setImagesProcessedNumbers(jobData.imagesProcessedNumbers || 0);
  }, [jobData]);

  useEffect(() => {
    const main = () => {
      if (!clientData) return;
      if (clientData.length > 0) {
        clientData.forEach((c) => {
          if (c.brand === jobData.client) {
            setClient(c);
          }
        });
      }
    };
    main();
  }, [clientData, setClient, jobData]);

  useEffect(() => {
    const main = () => {
      if (!imagesProcessedNumbers) {
        setReturnNull(true);
      } else {
        setReturnNull(false);
      }
      setImagesOverAllocation(
        parseImagesOverAllocation(imagesProcessedNumbers, jobData, client)
      );
    };
    main();
  }, [
    jobData,
    client,
    setReturnNull,
    setImagesOverAllocation,
    imagesProcessedNumbers,
  ]);

  return (
    <>
      {returnNull ? null : (
        <Chip
          icon={<ImageIcon />}
          label={
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: 11,
              }}
            >
              {imagesProcessedNumbers || 0}
            </span>
          }
          sx={{
            marginTop: 0.6,
            backgroundColor: imagesOverAllocation
              ? labelColours["purple"][theme]
              : cardBackgroundColour
              ? cardBackgroundColour
              : theme === "dark"
              ? "#272727"
              : "#d6d6d6",
            padding: 0,
            borderRadius: 0.8,
          }}
          size="small"
        />
      )}
    </>
  );
};

export default ImagesChip;

const parseImagesOverAllocation = (images, jobData, clientData) => {
  let imageAllocation;
  if (jobData.dayType === "Full Day") {
    if (!isNaN(clientData?.fullDayRate?.images)) {
      imageAllocation = clientData?.fullDayRate?.images;
    } else imageAllocation = 240;
  } else if (jobData.dayType === "Half Day") {
    if (!isNaN(clientData?.halfDayRate?.images)) {
      imageAllocation = clientData?.halfDayRate?.images;
    } else imageAllocation = 120;
  } else if (jobData.creativeDayType === "Full Day") {
    if (!isNaN(clientData?.contentFullDayRate?.images)) {
      imageAllocation = clientData?.contentFullDayRate?.images;
    } else imageAllocation = 240;
  } else if (jobData.creativeDayType === "Half Day") {
    if (!isNaN(clientData?.contentHalfDayRate?.images)) {
      imageAllocation = clientData?.contentHalfDayRate?.images;
    } else imageAllocation = 120;
  } else {
    imageAllocation = 240;
  }
  if (images > parseInt(imageAllocation)) {
    return true;
  } else return false;
};

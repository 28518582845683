const TO_RADIANS = Math.PI / 180;

export async function canvasPreview(
    image,
    canvas,
    crop,
    scale = 1,
    rotate = 0,
) {
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw new Error('No 2d context');
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const pixelRatio = window.devicePixelRatio;

    // Adjust canvas dimensions to 800px x 800px
    canvas.width = 800 * pixelRatio;
    canvas.height = 800 * pixelRatio;

    ctx.scale(pixelRatio, pixelRatio);
    ctx.imageSmoothingQuality = 'high';

    const cropX = crop.x * scaleX;
    const cropY = crop.y * scaleY;
    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;

    const rotateRads = rotate * TO_RADIANS;

    // Calculate the scale to fit the cropped image within the 800px x 800px canvas
    const scaleFactor = Math.min(800 / cropWidth, 800 / cropHeight);

    ctx.save();

    // Apply transformations
    ctx.translate(400, 400); // Translate to the center of the canvas
    ctx.rotate(rotateRads); // Rotate around the center
    ctx.scale(scaleFactor * scale, scaleFactor * scale); // Scale around the center
    ctx.translate(-cropWidth / 2, -cropHeight / 2); // Translate to draw the image from top-left corner

    // Draw the cropped image
    ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
    );

    ctx.restore();
}

import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const Files = ({
  client,
  firebase,
  updateFS,
  attachments,
  setAttachments,
  dbx,
  suppliedLinks,
  theme,
}) => {
  // eslint-disable-next-line
  const [filesUploading, setFilesUploading] = useState(false);

  const deleteAttachment = (item, index) => {
    const splice = [...attachments];
    splice.splice(index, 1);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ attachments: splice || "" }, { merge: true });
    setAttachments([...splice]);
  };

  const onDrop = async (e) => {
    // console.log(e[0]);
    // if (!canEdit) return;
    // setStopModalClose(true);
    setFilesUploading(true);
    let newAttachments = [];
    for (let i = 0; i < e.length; i++) {
      const file = e[i];
      if (file.size > 150000000) {
        alert("file must be under 150MB");
      } else {
        let url;
        const res = await dbx
          .filesUpload({
            path: `/-Client Attachments/${client.id}/${file.name}`,
            autorename: false,
            mode: "add",
            mute: false,
            contents: file,
          })
          .catch((e) => {
            // setStopModalClose(false);
            setFilesUploading(false);
            console.log(e);
          });

        await dbx
          .sharingListSharedLinks({
            path: res.result.path_display,
            direct_only: true,
          })
          .then((data) => {
            url = data.result.links[0].url;
          })
          .catch(async (e) => {
            console.log(e);
            await dbx
              .sharingCreateSharedLinkWithSettings({
                path: res.result.path_display,
                settings: {
                  requested_visibility: "public",
                  audience: "public",
                  access: "viewer",
                },
              })
              .then((data) => {
                url = data.result.url;
              })
              .catch((e) => {
                // setStopModalClose(false);
                setFilesUploading(false);
                console.log(e);
              });
          });
        if (url) newAttachments.push({ fileName: file.name, url: url });
      }
    }
    // console.log(newAttachments);
    if (newAttachments) {
      newAttachments.forEach((a) => {
        attachments.push(a);
      });
      firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(client.id)
        .update({ attachments: attachments || "" }, { merge: true });
      setAttachments([...attachments, ...newAttachments]);
    }
    setTimeout(() => {
      // setStopModalClose(false);
      setFilesUploading(false);
    }, 500);
  };

  const { getRootProps } = useDropzone({ onDrop });

  return (
    <>
      <div
        style={{
          marginLeft: 5,
          marginBottom: 10,
          color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          fontWeight: 400,
          fontSize: "1.2rem",
          lineHeight: "1.4375em",
          letterSpacing: "0.00938em",
        }}
      >
        Supply Links
      </div>
      <div
        style={{
          marginLeft: 2,
          width: "40vw",
          height: 300,
          background: "rgba(0,0,0,0.01)",
          border:
          theme === "dark"
            ? "1px solid rgba(230,230,230,0.3)"
            : "1px solid rgba(25,25,25,0.3)",
      borderRadius: 5,
          overflowY: "auto",
          position: "relative",
        }}
      >
        <div
          style={{
            background: "rgba(0, 0, 0, 0.05)",
            width: "100%",
            height: "100%",
            display: filesUploading ? "block" : "none",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </div>
        </div>
        {suppliedLinks &&
          suppliedLinks.map((s, index) => (
            <div
              style={{
                fontSize: "0.9rem",
              }}
              key={`suppliedLinks-${s.jobNumber}-${index}`}
            >
              <p>
                {s.jobNumber} -{" "}
                <a href={s.url} target="_blank" rel="noreferrer">
                  Link
                </a>
              </p>
            </div>
          ))}
      </div>
      <div style={{ marginTop: 40 }} {...getRootProps()}>
        <div
          style={{
            marginLeft: 5,
            marginBottom: 10,
            color:
              theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: 400,
            fontSize: "1.2rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
          }}
        >
          Attachments
        </div>
        <div
          style={{
            marginLeft: 2,
            width: "40vw",
            height: 300,
            background: "rgba(0,0,0,0.01)",
            border:
              theme === "dark"
                ? "1px solid rgba(230,230,230,0.3)"
                : "1px solid rgba(25,25,25,0.3)",
            borderRadius: 5,
            overflowY: "auto",
            position: "relative",
          }}
        >
          <div
            style={{
              background: "rgba(0, 0, 0, 0.05)",
              width: "100%",
              height: "100%",
              display: filesUploading ? "block" : "none",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </div>
          </div>
          {attachments &&
            attachments.map((a, index) => (
              <Attachment
                key={`${a.fileName}-${a.url}-${index}`}
                index={index}
                attachment={a}
                deleteAttachment={deleteAttachment}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default Files;

const Attachment = ({ attachment, index, deleteAttachment }) => {
  return (
    <div
      className="attachmentItem"
      style={{
        margin: 5,
        padding: 4,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 5,
        borderBottom: "1px solid rgba(25,25,25,0.3)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <a href={attachment.url} rel="noreferrer" target="_blank">
        {attachment.fileName}
      </a>
      <IconButton
        onClick={() => {
          deleteAttachment(attachment, index);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { DateTime } from "luxon";

const ProgressCircle = ({ loginToken, loginTokenCreated, theme }) => {
  const [progress, setProgress] = useState(0);
  const [color, setColor] = useState(theme === "dark" ? "white" : "black");
  const [flashing, setFlashing] = useState(false);
  const [flashingSpeed, setFlashingSpeed] = useState(2);

  useEffect(() => {
    if (!loginTokenCreated) return;

    // Calculate the creation and end times
    const createdTime = DateTime.fromISO(loginTokenCreated);
    const endTime = createdTime.plus({ minutes: 20 }); // Updated to 20 minutes
    const totalDuration = endTime.diff(
      createdTime,
      "milliseconds"
    ).milliseconds;

    const updateProgress = () => {
      const now = DateTime.now();
      const elapsed = now.diff(createdTime, "milliseconds").milliseconds;
      const remaining = totalDuration - elapsed;
      const newProgress = Math.min((elapsed / totalDuration) * 100, 100);

      // Update the progress
      setProgress(newProgress);

      // Adjust the time blocks for 20 minutes
      if (remaining <= 300000 && remaining > 225000) {
        // 5 minutes remaining (300000ms to 225000ms)
        setFlashing(true);
        setFlashingSpeed(2.5);
        setColor("red");
      } else if (remaining <= 225000 && remaining > 150000) {
        // 3.75 to 2.5 minutes remaining (225000ms to 150000ms)
        setFlashing(true);
        setFlashingSpeed(2);
        setColor("red");
      } else if (remaining <= 150000 && remaining > 125000) {
        // 2.5 to 2.08 minutes remaining (150000ms to 125000ms)
        setFlashing(true);
        setFlashingSpeed(1.5);
        setColor("red");
      } else if (remaining <= 125000 && remaining > 100000) {
        // 2.08 to 1.66 minutes remaining (125000ms to 100000ms)
        setFlashing(true);
        setFlashingSpeed(1);
        setColor("red");
      } else if (remaining <= 100000) {
        // 1.66 minutes remaining (100000ms)
        setFlashing(true);
        setFlashingSpeed(0.5);
        setColor("red");
      } else if (remaining <= 300000) {
        // 5 minutes remaining
        const percentageElapsed = elapsed / totalDuration;
        const red = Math.min(255, Math.floor(percentageElapsed * 510));
        const blackToRed = `rgb(${red}, 0, 0)`;
        setColor(blackToRed);
        setFlashing(false);
      } else {
        setFlashing(false);
        setColor(theme === "dark" ? "white" : "black");
      }
    };

    // Set interval to update progress
    const intervalId = setInterval(updateProgress, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [loginTokenCreated, theme]);

  useEffect(() => {
    setProgress(0);
    setFlashing(false);
    setFlashingSpeed(2);
    setColor(theme === "dark" ? "white" : "black");
  }, [loginToken]);

  return (
    <>
      <Box position="relative" display="inline-flex" pr={0.5}>
        <CircularProgress
          variant="determinate"
          value={100}
          size={18}
          thickness={10}
          sx={{
            color: "grey.600",
            position: "absolute",
          }}
        />
        <CircularProgress
          variant="determinate"
          value={progress}
          size={18}
          thickness={10}
          sx={{
            color: "primary.main",
          }}
        />
      </Box>
      <span
        style={{
          fontWeight: "bold",
          letterSpacing: 4,
          color: flashing ? "red" : color,
          animation: flashing ? `flash ${flashingSpeed}s infinite` : "none",
        }}
      >
        {loginToken}
      </span>
      <style>{`
        @keyframes flash {
          0% { opacity: 1; }
          50% { opacity: 0; }
          100% { opacity: 1; }
        }
      `}</style>
    </>
  );
};

export default ProgressCircle;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { debounce } from "lodash";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import MDEditor from "@uiw/react-md-editor";
// import ChecklistIcon from "@mui/icons-material/Checklist";

import IconButton from "@mui/material/IconButton";
import ImageIcon from "@mui/icons-material/Image";
import DownloadIcon from "@mui/icons-material/Download";

import getJobName from "./GlobalFunctions/getJobName";

import { useAtom } from "jotai";
import { isIdleAtom, themeAtom } from "./-Atoms";

const OutsourcerJob = ({ firebase }) => {
  const [isIdle] = useAtom(isIdleAtom);
  const [theme] = useAtom(themeAtom);
  const { uid } = useParams();

  const [jobData, setJobData] = useState({});
  const [retouchingNotes, setRetouchingNotes] = useState({});

  useEffect(() => {
    if (uid) {
      let unsub = firebase
        .firestore()
        .collection("JobData")
        .doc(uid)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          let tmp;
          data.checklists.forEach((ch) => {
            if (ch.name === "Retouching Notes") tmp = ch;
          });
          setRetouchingNotes(tmp);
          setJobData(data);
        });
      // if (isIdle) {
      //   unsub();
      // }
      return () => {
        unsub();
      };
    }
  }, [firebase, isIdle, uid]);

  return (
    <div
      style={{
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        padding: 10,
      }}
    >
      <Checklist
        firebase={firebase}
        jobData={jobData}
        retouchingNotes={retouchingNotes}
        isDarkMode={theme === "dark" ? true : false}
      />
    </div>
  );
};

export default OutsourcerJob;

const Checklist = ({ firebase, jobData, retouchingNotes, isDarkMode }) => {
  return (
    <div
      style={{
        width: 650,
        padding: 10,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        borderRadius: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span
            style={{
              fontSize: "1.8rem",
              fontWeight: 800,
              color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
              marginBottom: 10,
            }}
          >
            {getJobName(jobData)}
          </span>
          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ChecklistIcon
              fontSize="large"
              sx={{
                color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
                marginLeft: "17px",
                marginRight: "12px",
              }}
            />
            <div
              style={{
                fontSize: "1.6rem",
                fontWeight: 800,
                color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
                marginBottom: 10,
              }}
            >
              {retouchingNotes.name}
            </div>
          </div> */}
        </div>
      </div>
      <div>
        <List sx={{ width: "100%", paddingTop: "3px", paddingBottom: "3px" }}>
          {retouchingNotes.checklistItems &&
            retouchingNotes.checklistItems.map((ci, index) => {
              if (!ci.excluded) {
                return (
                  <ChecklistItem
                    key={`${ci.title}_${index}_CheckListItem`}
                    firebase={firebase}
                    jobData={jobData}
                    checklist={retouchingNotes}
                    isDarkMode={isDarkMode}
                    index={index}
                    ci={ci}
                  />
                );
              } else return null;
            })}
        </List>
      </div>
      <div style={{ marginTop: 40 }}> </div>
    </div>
  );
};

const ChecklistItem = ({
  isDarkMode,
  firebase,
  jobData,
  checklist,
  index,
  ci,
}) => {
  const updateOutsourcerJobChecklists = firebase
    .functions()
    .httpsCallable("updateOutsourcerJobChecklists");

  const updateChecklists = debounce(async (jobData) => {
    updateOutsourcerJobChecklists(jobData);
  }, 1000);

  const ListItemTextID = `${ci.title}_${index}_CheckListItem_Title`;
  const CheckboxID = `${ci.title}_${index}_CheckListItem_Checkbox`;

  const [title, setTitle] = useState(ci.title);
  const [checked, setChecked] = useState(ci.checked);

  const toggleCheck = async (e) => {
    const newChecked = !checked;
    setChecked(newChecked);
    ci.checked = newChecked;
    ci.outsourcer = newChecked;

    jobData.checklists.forEach((ch) => {
      if (ch.name === "Retouching Notes") {
        ch = checklist;
      }
    });
    // updateOutsourcerJobChecklists(jobData);

    updateChecklists(jobData);
  };

  useEffect(() => {
    setTitle(ci.title);
    setChecked(ci.checked);
  }, [ci]);

  return (
    <>
      <ListItem
        disablePadding
        className="checklistItem"
        style={{
          WebkitTouchCallout: "none",
          WebkitUserSelect: "none",
          khtmlUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginLeft: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked}
                onClick={toggleCheck}
                disableRipple
                id={CheckboxID}
                size="small"
              />
            </ListItemIcon>
            <div
              onClick={() => {
                toggleCheck();
              }}
              id={ListItemTextID}
              style={{
                width: "90%",
                marginLeft: -25,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                cursor: "pointer",
              }}
            >
              <MDEditor.Markdown
                source={`${ci.checked ? "~~" : ""}${title}${
                  ci.checked ? "~~" : ""
                }`}
                style={{
                  background: "transparent",
                  width: "90%",
                  paddingTop: 5,
                  paddingBottom: 5,
                  color: ci.checked ? "#808080" : "",
                }}
              />
              {ci.image ? (
                <IconButton
                  edge="end"
                  sx={{ marginRight: 1 }}
                  onClick={() => {
                    window.open(ci.image, "_blank");
                  }}
                >
                  <ImageIcon fontSize="small" />
                </IconButton>
              ) : null}
              {ci.image ? (
                <IconButton
                  edge="end"
                  sx={{ marginRight: 2 }}
                  onClick={() => {
                    window.open(ci.image.replace("&dl=0", "&dl=1"), "_blank");
                  }}
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              ) : null}
            </div>
          </div>
        </div>
      </ListItem>
    </>
  );
};

import React from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";

import AdditionalStaffContainer from "../../AdditionalStaffContainer";

const { DateTime } = require("luxon");

const StaffContainer = ({
  firebase,
  jobData,
  checkIfStaffAssigned,
  event,
  events,
  findStaffAvatar,
  photographer,
  setPhotographer,
  additionalPhotographers,
  setAdditionalPhotographers,
  stylist,
  setStylist,
  additionalStylists,
  setAdditionalStylists,
  operator,
  setOperator,
  additionalOperators,
  setAdditionalOperators,
  addAdditionalStaff,
  canEdit,
  staffData,
  jobType,
  isEventTomorrow,
  client,
  sendAddedNotification,
  sendNotificationToUID,
  findStaff,
  sendRemovedNotification,
  additionalDates,
  theme,
  index,
}) => {
  const employedStaff = staffData.filter((staff) => staff.currentlyEmployed);

  const titleContainerStyle = {
    marginTop: 20,
    marginLeft: 5,
    marginBottom: 5,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const handlePhotographerChange = (e, value) => {
    if (!canEdit) return;
    const startDate = event.start;
    sendRemovedNotification(
      new Date(startDate).toISOString(),
      photographer,
      client,
      jobType,
      isEventTomorrow,
      sendNotificationToUID
    );

    let val = "";
    if (value?.id) val = value.id;

    if (index === undefined) {
      setPhotographer(val || "");
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ photographer: val || "" }, { merge: true })
        .catch((error) => {
          console.log(error);
        });
    } else {
      additionalDates[index].photographer = val;
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ additionalDates: additionalDates })
        .catch((error) => {
          console.log(error);
        });
    }

    sendAddedNotification(
      startDate,
      val,
      client,
      jobType,
      isEventTomorrow,
      sendNotificationToUID
    );
  };

  const handleOperatorChange = (e, value) => {
    if (!canEdit) return;
    const startDate = event.start;
    sendRemovedNotification(
      new Date(startDate).toISOString(),
      operator,
      client,
      jobType,
      isEventTomorrow,
      sendNotificationToUID
    );

    let val = "";
    if (value?.id) val = value.id;
    if (value === "Not Required") val = "Not Required";
    if (value === "External") val = "External";

    if (index === undefined) {
      setOperator(val || "");
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ operator: val || "" }, { merge: true })
        .catch((error) => {
          console.log(error);
        });
    } else {
      additionalDates[index].operator = val;
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ additionalDates: additionalDates })
        .catch((error) => {
          console.log(error);
        });
    }

    sendAddedNotification(
      startDate,
      val,
      client,
      jobType,
      isEventTomorrow,
      sendNotificationToUID
    );
  };
  const handleStylistChange = (e, value) => {
    if (!canEdit) return;
    const startDate = event.start;
    sendRemovedNotification(
      new Date(startDate).toISOString(),
      stylist,
      client,
      jobType,
      isEventTomorrow,
      sendNotificationToUID
    );

    let val = "";
    if (value?.id) val = value.id;
    if (value === "Not Required") val = "Not Required";
    if (value === "External") val = "External";

    if (index === undefined) {
      setStylist(val || "");
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ stylist: val || "" }, { merge: true })
        .catch((error) => {
          console.log(error);
        });
      console.log(val);
    } else {
      additionalDates[index].stylist = val;
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ additionalDates: additionalDates })
        .catch((error) => {
          console.log(error);
        });
    }

    sendAddedNotification(
      startDate,
      val,
      client,
      jobType,
      isEventTomorrow,
      sendNotificationToUID
    );
  };
  return (
    <div
      style={{
        marginTop: 20,
      }}
    >
      {additionalDates && additionalDates.length > 0 ? (
        <div style={titleContainerStyle}>
          {DateTime.fromISO(
            index !== undefined
              ? additionalDates[index].start
              : jobData.bookedStart
          )
            .setZone("Australia/Sydney")
            .toFormat("cccc dd LLL yy hh:mm a")}
        </div>
      ) : null}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Autocomplete
          disablePortal
          fullWidth
          sx={{ marginTop: 2 }}
          options={employedStaff}
          getOptionLabel={(option) => findStaff(option, employedStaff)}
          renderOption={(props, option) => {
            let exists = true;
            let inDate = true;

            if (!option?.wwccCheckNumber) exists = false;
            if (!option?.wwccExpiry) exists = false;

            if (!option?.wwccExpiry) {
              inDate = false;
            } else {
              if (
                DateTime.fromISO(option?.wwccExpiry)
                  .setZone("Australia/Sydney")
                  .startOf("day") <=
                DateTime.now().setZone("Australia/Sydney").startOf("day")
              ) {
                inDate = false;
              } else {
                inDate = true;
              }
            }

            console.log(exists, inDate);
            return (
              <li {...props}>
                <span
                  style={{
                    color: checkIfStaffAssigned(option, events, event, jobData)
                      ? "#d13c36"
                      : "",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    style={{ marginRight: 10 }}
                    sx={{ height: 28, width: 28 }}
                    alt={findStaff(option, employedStaff)}
                    src={findStaffAvatar(option, employedStaff)}
                  />
                  {findStaff(option, employedStaff)}
                </span>
                {exists ? (
                  <span
                    style={{
                      marginLeft: 10,
                      marginTop: 0,
                      padding: "3px 8px 3px 8px",
                      backgroundColor: inDate ? "#4DAAE9" : "#d94141",
                      borderRadius: 4,
                      // width: 60,
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "0.55rem",
                      fontWeight: "bold",
                    }}
                  >
                    {!inDate ? "!!" : ""} WWCC {!inDate ? "!!" : ""}
                  </span>
                ) : null}
              </li>
            );
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="filled"
                label={
                  jobData?.jobTypeFull?.includes("-V") ||
                  jobData?.jobTypeFull?.includes("-E")
                    ? "Videographer"
                    : "Photographer"
                }
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                  startAdornment: (
                    <Avatar
                      style={{ marginRight: 10 }}
                      sx={{ height: 28, width: 28 }}
                      alt={photographer}
                      src={findStaffAvatar(photographer, employedStaff)}
                    />
                  ),
                }}
              />
            );
          }}
          value={photographer || ""}
          onChange={handlePhotographerChange}
        />
        <IconButton
          onClick={() => {
            if (!canEdit) return;
            if (index === undefined) {
              addAdditionalStaff(
                additionalPhotographers,
                setAdditionalPhotographers
              );
            } else {
              addAdditionalStaff(
                index,
                additionalDates,
                "additionalPhotographers"
              );
            }
          }}
          style={{
            marginRight: -8,
            marginLeft: 20,
            marginTop: 17,
          }}
        >
          <AddIcon />
        </IconButton>
      </div>

      <div style={{ display: photographer ? "block" : "none" }}>
        <AdditionalStaffContainer
          kind="photographer"
          additionalStaff={additionalPhotographers}
          setAdditionalStaff={setAdditionalPhotographers}
          staffData={employedStaff}
          firebase={firebase}
          jobData={jobData}
          checkIfStaffAssigned={checkIfStaffAssigned}
          events={events}
          event={event}
          findStaffAvatar={findStaffAvatar}
          additionalIndex={index}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Autocomplete
          disablePortal
          sx={{ marginTop: 2 }}
          fullWidth
          options={[...employedStaff, "Not Required", "External"]}
          getOptionLabel={(option) => findStaff(option, employedStaff)}
          renderOption={(props, option) => (
            <li {...props}>
              <span
                style={{
                  color: checkIfStaffAssigned(option, events, event, jobData)
                    ? "#d13c36"
                    : "",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Avatar
                  style={{ marginRight: 10 }}
                  sx={{ height: 28, width: 28 }}
                  alt={findStaff(option, employedStaff)}
                  src={findStaffAvatar(option, employedStaff)}
                />

                {findStaff(option, employedStaff)}
              </span>
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Operator"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                startAdornment: (
                  <Avatar
                    sx={{ height: 28, width: 28 }}
                    style={{ marginRight: 10 }}
                    alt={operator}
                    src={findStaffAvatar(operator, employedStaff)}
                  />
                ),
              }}
            />
          )}
          value={operator || ""}
          onChange={handleOperatorChange}
        />
        <IconButton
          onClick={() => {
            if (!canEdit) return;
            if (index === undefined) {
              addAdditionalStaff(additionalOperators, setAdditionalOperators);
            } else {
              addAdditionalStaff(index, additionalDates, "additionalOperators");
            }
          }}
          style={{
            marginRight: -8,
            marginLeft: 20,
            marginTop: 17,
          }}
        >
          <AddIcon />
        </IconButton>
      </div>

      <div style={{ display: operator ? "block" : "none" }}>
        <AdditionalStaffContainer
          kind="operator"
          additionalStaff={additionalOperators}
          setAdditionalStaff={setAdditionalOperators}
          staffData={employedStaff}
          firebase={firebase}
          jobData={jobData}
          checkIfStaffAssigned={checkIfStaffAssigned}
          events={events}
          event={event}
          findStaffAvatar={findStaffAvatar}
          additionalIndex={index}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Autocomplete
          disablePortal
          sx={{ marginTop: 2 }}
          fullWidth
          options={[...employedStaff, "Not Required", "External"]}
          getOptionLabel={(option) => findStaff(option, employedStaff)}
          renderOption={(props, option) => (
            <>
              <li {...props}>
                <span
                  style={{
                    color: checkIfStaffAssigned(option, events, event, jobData)
                      ? "#d13c36"
                      : "",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Avatar
                    style={{ marginRight: 10 }}
                    alt={findStaff(option, employedStaff)}
                    src={findStaffAvatar(option, employedStaff)}
                  />
                  {findStaff(option, employedStaff)}
                </span>
              </li>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Stylist"
              variant="filled"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                startAdornment: (
                  <Avatar
                    sx={{ height: 28, width: 28 }}
                    style={{ marginRight: 10 }}
                    alt={stylist}
                    src={findStaffAvatar(stylist, employedStaff)}
                  />
                ),
              }}
            />
          )}
          value={stylist || ""}
          onChange={handleStylistChange}
        />
        <IconButton
          onClick={() => {
            if (!canEdit) return;
            if (index === undefined) {
              addAdditionalStaff(additionalStylists, setAdditionalStylists);
            } else {
              addAdditionalStaff(index, additionalDates, "additionalStylists");
            }
          }}
          style={{
            marginRight: -8,
            marginLeft: 20,
            marginTop: 17,
          }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <div style={{ display: stylist ? "block" : "none" }}>
        <AdditionalStaffContainer
          kind="stylist"
          additionalStaff={additionalStylists}
          setAdditionalStaff={setAdditionalStylists}
          staffData={employedStaff}
          firebase={firebase}
          jobData={jobData}
          checkIfStaffAssigned={checkIfStaffAssigned}
          events={events}
          event={event}
          findStaffAvatar={findStaffAvatar}
          additionalIndex={index}
        />
      </div>
    </div>
  );
};

export default StaffContainer;

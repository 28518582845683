import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { v4 as uuidv4 } from "uuid";
import CateringItem from "./CateringItem";
import { useAtom } from "jotai";
import { isDebouncingAtom } from "../../../../-Atoms";

const Catering = ({
  titleStyle,
  catering,
  setCatering,
  firebase,
  jobData,
  updateFS,
}) => {
  const [isDebouncing] = useAtom(isDebouncingAtom);
  const [cateringTotal, setCateringTotal] = useState(0.0);

  useEffect(() => {
    let total = 0;
    catering.forEach((ci) => {
      if (ci.amount) {
        total = parseFloat(total) + parseFloat(ci.amount);
      }
    });
    setCateringTotal(total.toFixed(2));
  }, [catering]);

  const addCateringItem = () => {
    const newitemObj = {
      id: uuidv4(),
      amount: "",
      store: "",
    };
    catering.push(newitemObj);
    setCatering([...catering]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ catering: catering }, { merge: true })
      .catch((e) => console.log(e));
  };

  const [showRemoveCateringItem, setShowRemoveCateringItem] = useState(false);
  const toggleShowRemoveCateringItem = () => {
    setShowRemoveCateringItem(true);
    setTimeout(() => {
      setShowRemoveCateringItem(false);
    }, 6000);
  };

  return (
    <>
      <div style={titleStyle}>
        <span>Catering - ${cateringTotal}</span>
        <div>
          <IconButton
            onClick={toggleShowRemoveCateringItem}
            disabled={isDebouncing}
          >
            <RemoveIcon />
          </IconButton>
          <IconButton onClick={addCateringItem} disabled={isDebouncing}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          gap: 15,
          paddingBottom: 25,
          paddingTop: 20,
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        {catering &&
          catering.map((ca, index) => (
            <CateringItem
              key={ca.id}
              item={ca}
              items={catering}
              setItems={setCatering}
              index={index}
              showRemoveCateringItem={showRemoveCateringItem}
              firebase={firebase}
              jobData={jobData}
              updateFS={updateFS}
            />
          ))}
      </div>
    </>
  );
};

export default Catering;

import React from 'react'

import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

const OtherFileSizeCategory = ({ kind, firebase, fileSize, setFileSize, client }) => {
    const handleChange = (e) => {
        let realKind = kind.replace(/ /g, '')
        realKind = realKind.replace('Size', '')
        realKind = realKind.replace('Other', 'other')
        setFileSize(e.target.value);
        client.fileSizes[realKind] = e.target.value
        updateFSOBJ(firebase, client.id, 'fileSizes', client.fileSizes)
    }
    return (
        <Tooltip key={`${kind}`} title={kind} enterDelay={500}>
            <TextField
                variant='outlined'
                label={kind}
                value={fileSize}
                onChange={handleChange}
                style={{
                    margin: '10px',
                    width: '90%',
                }}
            />
        </Tooltip>
    )
}

export default OtherFileSizeCategory


const updateFSOBJ = debounce(async (firebase, id, field, value) => {
    firebase.firestore().collection('ClientDatabase').doc(id).set({ [field]: value }, { merge: true });
}, 600);
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};
// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
// import CheckIcon from "@mui/icons-material/Check";
// import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";

// import Chip from "@mui/material/Chip";

const { DateTime } = require("luxon");

const StaffListItem = ({
  staff,
  selectedStaff,
  setSelectedStaff,
  setOpen,
  user,
  theme,
}) => {
  // eslint-disable-next-line
  const [detailsFilledCorrectly, setDetailsFilledCorrectly] = useState(false);
  const [wwccExists, setwWccExists] = useState(false);
  const [wwccInDate, setWwccInDate] = useState(false);
  // eslint-disable-next-line
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (user?.staffData?.isSuperAdmin) {
      setIsAdmin(true);
      return;
    }
    // if (user.staffData.isAdmin) {
    //   setIsAdmin(true);
    //   return;
    // }
    setIsAdmin(false);
  }, [user]);

  useEffect(() => {
    let value = true;
    if (!staff?.firstName) value = false;
    if (!staff?.lastName) value = false;
    if (!staff?.phoneNumber) value = false;
    if (!staff?.dateOfBirth) value = false;
    if (!staff?.address.streetNumber) value = false;
    if (!staff?.address.streetName) value = false;
    if (!staff?.address.postcode) value = false;
    if (!staff?.address.suburb) value = false;
    if (!staff?.employmentBasis) value = false;
    if (!staff?.nextOfKin1Name) value = false;
    if (!staff?.nextOfKin1Phone) value = false;
    if (!staff?.taxFileNumber) value = false;
    // if (!staff?.salaryHourlyRate) value = false;
    if (!staff?.bankName) value = false;
    if (!staff?.BSB) value = false;
    if (!staff?.superannuationProvider) value = false;
    if (!staff?.superannuationMemberNumber) value = false;
    if (!staff?.superannuationFundUSI) value = false;
    setDetailsFilledCorrectly(value);
  }, [staff]);

  useEffect(() => {
    let exists = true;
    if (!staff?.wwccCheckNumber) exists = false;
    if (!staff?.wwccExpiry) exists = false;
    setwWccExists(exists);

    if (!staff?.wwccExpiry) {
      setWwccInDate(false);
    } else {
      if (
        DateTime.fromISO(staff?.wwccExpiry)
          .setZone("Australia/Sydney")
          .startOf("day") <=
        DateTime.now().setZone("Australia/Sydney").startOf("day")
      ) {
        setWwccInDate(false);
      } else {
        setWwccInDate(true);
      }
    }
  }, [staff]);

  const handleClick = () => {
    setSelectedStaff(staff.id);
    setOpen(false);
  };
  const secondaryText = (
    <span
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        flexDirection: "column",
      }}
    >
      <span style={{ fontSize: 15 }}>{staff.phoneNumber.replace(/ /g, "")}</span>
      {wwccExists ? (
        <span
          style={{
            marginTop: 4,
            padding: "3px 5px 3px 5px",
            backgroundColor: wwccInDate ? "#4DAAE9" : "#d94141",
            borderRadius: 4,
            width: 85,
            display: "flex",
            justifyContent: "center",
            fontSize: "0.7rem",
            fontWeight: "bold",
          }}
        >
          {!wwccInDate ? "!!" : ""} WWCC {!wwccInDate ? "!!" : ""}
        </span>
      ) : null}
    </span>
  );
  return (
    <>
      <ListItem
        button={true}
        onClick={handleClick}
        style={{
          borderRadius: "5px",
          background: parseBackground(staff, selectedStaff, theme),
          borderBottom: "1px solid rgb(180, 180, 180)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Avatar
            sx={{ width: 60, height: 60, marginRight: 2 }}
            src={staff.avatar}
          />
          <ListItemText
            className="listItemSecondaryStaff"
            primary={`${staff.firstName} ${staff.lastName}`}
            secondary={secondaryText}
          />
        </div>
      </ListItem>
    </>
  );
};

export default StaffListItem;

const parseBackground = (staff, selectedStaff, theme) => {
  if (staff.notEmployed) {
    return "rgb(160, 160, 160)";
  }
  if (selectedStaff === staff.id) {
    return theme === "dark" ? "#383838" : "rgb(230 230 230)";
  }
  return "";
};

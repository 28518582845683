import React, { useState, useCallback, useEffect } from "react";
// import TextField from "@mui/material/TextField";
// import { useAtom } from "jotai";
// import { isDebouncingAtom } from "../../../../-Atoms";
import debounce from "lodash.debounce";

import AdvancedTextField from "../../../../GlobalComponents/AdvancedTextField";

const Extras = ({
  titleStyle,
  handleValueChange,
  cStrings,
  setCStrings,
  nippleCovers,
  setNippleCovers,
  printedPages,
  setPrintedPages,
  firebase,
  jobData,
}) => {
  // const [isDebouncing] = useAtom(isDebouncingAtom);
  // const [cStringFocus, setCStringFocus] = useState(false);
  // const [nippleCoversFocus, setNippleCoversFocus] = useState(false);
  // const [printedPagesFocus, setPrintedPagesFocus] = useState(false);

  const [queuedUpdates, setQueuedUpdates] = useState({});

  const debouncedUpdateFS = useCallback(
    debounce((updates) => {
      const batch = firebase.firestore().batch();
      const docRef = firebase.firestore().collection("JobData").doc(jobData.id);
      batch.update(docRef, updates);
      batch.commit().catch((e) => console.log(e));
    }, 500),
    []
  );

  useEffect(() => {
    if (Object.keys(queuedUpdates).length > 0) {
      debouncedUpdateFS(queuedUpdates);
      setQueuedUpdates({});
    }
  }, [queuedUpdates, debouncedUpdateFS]);

  // const handleNVChange = (e, field, setVal) => {
  //   const numericValue = e.target.value.replace(/[^0-9]/g, "");
  //   setVal(numericValue);

  //   setQueuedUpdates((prevUpdates) => ({
  //     ...prevUpdates,
  //     [field]: numericValue,
  //   }));
  // };

  return (
    <>
      <div style={titleStyle}>
        <span>Extras</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: -10,
          gap: 15,
          padding: 30,
        }}
      >
        <AdvancedTextField
          firebase={firebase}
          field="cStrings"
          collectionName="JobData"
          docId={jobData.id}
          label="C-Strings"
          variant="filled"
          size="small"
          value={cStrings || ""}
          setValue={setCStrings}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          sx={{ width: "47%" }}
          numbersOnly={true}
        />
        <AdvancedTextField
          firebase={firebase}
          field="nippleCovers"
          collectionName="JobData"
          docId={jobData.id}
          label="Nipple Covers"
          variant="filled"
          size="small"
          value={nippleCovers || ""}
          setValue={setNippleCovers}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          sx={{ width: "47%" }}
          numbersOnly={true}
        />
        <AdvancedTextField
          firebase={firebase}
          field="printedPages"
          collectionName="JobData"
          docId={jobData.id}
          label="Printed Pages"
          variant="filled"
          size="small"
          value={printedPages || ""}
          setValue={setPrintedPages}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          sx={{ width: "47%" }}
          numbersOnly={true}
        />
      </div>
    </>
  );
};

export default Extras;

import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { Alert as MUIAlert } from "@mui/material";

import { UpdateMetaThemeColour } from "./Utils/UpdateMetaThemeColour";

import LoadingFullScreen from "./GlobalComponents/LoadingFullScreen";

import Logo from "./Login/Logo";
import StaffIcons from "./Login/StaffIcons";

// const darkMode = "#1f1f1f";
const darkMode = "#1f1f1f";
const lightMode = "#f4f5f7";

const LoginOld = ({ auth, user, firebase }) => {
  UpdateMetaThemeColour({ light: lightMode, dark: darkMode });

  const [isDarkMode, setIsDarkMode] = useState(true);
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        setIsDarkMode(e.matches ? true : false)
      );
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  function Alert(props) {
    return <MUIAlert elevation={6} variant="filled" {...props} />;
  }

  let loginError = localStorage.getItem("error");
  if (loginError === null) {
    loginError = false;
  } else {
    loginError = JSON.parse(loginError);
  }
  if (loginError) {
    setOpen(true);
    localStorage.setItem("error", false);
  }

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 800);
  }, []);

  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    const init = async () => {
      const res = await fetch("https://api.ipify.org");
      if (res) {
        try {
          const ip = await res.text();
          if (ip) {
            setIpAddress(ip);
          }
        } catch (e) {}
      }
    };
    init();
  }, [setIpAddress]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100%",
          background: isDarkMode ? darkMode : lightMode,
        }}
      >
        <LoadingFullScreen
          loadingVisible={isLoading}
          isDarkMode={true}
          darkMode={"RGB(18,18,18)"}
          lightMode={"#ffffff"}
        />
        <Logo isDarkMode={isDarkMode} />
        <div style={{ height: "", overflow: "auto" }}>
          <StaffIcons
            firebase={firebase}
            isDarkMode={isDarkMode}
            auth={auth}
            darkMode={darkMode}
            lightMode={lightMode}
            setIsLoading={setIsLoading}
            ipAddress={ipAddress}
          />
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          Only Images That Sell accounts are valid.
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoginOld;

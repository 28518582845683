import { useIdleTimer } from "react-idle-timer";

import { useAtom } from "jotai";
import { isElectronAtom } from "../-Atoms";

export function useElectronIdleTimer(auth) {
  const [isElectron] = useAtom(isElectronAtom);
  const { DateTime } = require("luxon");
  let timeout;

  const onIdle = () => {
    if (isElectron) auth.signOut();
  };
  const onActive = () => {};
  const onAction = () => {
    timeout = setTimeout(() => {
      if (isElectron && auth.currentUser) {
        localStorage.setItem(
          "lastActivity",
          DateTime.now().setZone("Australia/Sydney").toUTC().toISO()
        );
      }
    }, 500);

    const startDateTime = DateTime.fromISO(
      localStorage.getItem("lastActivity")
    );
    if (startDateTime) {
      const endDateTime = DateTime.now().setZone("Australia/Sydney").toUTC();

      const diffInHours = endDateTime.diff(startDateTime, "hours").hours;
      // console.log(diffInHours);
      if (diffInHours > 3 && isElectron) {
        auth.signOut();
        localStorage.setItem("lastActivity", null);
        try {
          clearTimeout(timeout);
        } catch (e) {}
      }
    }
  };
  // 1800000
  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    // timeout: 10800000,
    timeout: 7200000,
    throttle: 0,
  });
}

import React, { useState, useEffect } from "react";
import Timeline, { TodayMarker } from "react-calendar-timeline";
import "./OutsourcerTimeline.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import moment from "moment";

if (!firebase.apps.length) {
  firebase.initializeApp({
    apiKey: "AIzaSyBvy9wwbHmVfNXy_XiaengbrcC0AeSHWp0",
    authDomain: "its-connect-main.firebaseapp.com",
    projectId: "its-connect-main",
    storageBucket: "its-connect-main.appspot.com",
    messagingSenderId: "1001127722110",
    appId: "1:1001127722110:web:7db56f4369dbc7a8a96c2f",
    measurementId: "G-KB3ZEJVTPK",
  });
} else {
  firebase.app();
}
const firestore = firebase.firestore();

const OutsourcerTimeline = () => {
  const [jobData, setJobData] = useState([]);
  const [outsourcerData, setOutsourcerData] = useState([]);
  const [clients, setClients] = useState([]);

  const [, setScrollRef] = useState();

  const [groupKind, setGroupKind] = useState(true);
  const [hideCompleted, setHideCompleted] = useState(true);
  const [showDueToday, setShowDueToday] = useState(false);

  useEffect(() => {
    groupKind ? setHideCompleted(true) : setHideCompleted(false);
  }, [groupKind]);

  useEffect(() => {
    let reference = firestore
      .collection("JobData")
      .where("year", "==", new Date().getFullYear())
      .orderBy("jobNumber", "desc")
      .limit(1200);

    const unsub = reference.onSnapshot((collection) => {
      const initialData = collection.docs.map((d) => {
        const data = d.data();
        data.id = d.id;
        return data;
      });
      setJobData([...initialData]);
    });
    return () => {
      unsub();
    };
  }, [setJobData]);

  useEffect(() => {
    let arr = [];

    jobData.forEach((job) => {
      for (let i = 0; i < job.outsourcers.length; i++) {
        let shouldPush = true;
        if (job.outsourcers[i].outsourcer) {
          if (job.outsourcers[i].sentDate) {
            if (job.outsourcers[i].dueDate) {
              if (hideCompleted) {
                if (
                  zeroOutTime(new Date(job.outsourcers[i].dueDate), "end") <
                  new Date()
                ) {
                  shouldPush = false;
                }
              }
              if (showDueToday) {
                if (
                  zeroOutTime(new Date(job.outsourcers[i].dueDate), "end") >
                  getToday()
                ) {
                  shouldPush = true;
                }
              }
              // .set({ hour: 1, minute: 0, second: 0, millisecond: 0 })
              const name = `${job.outsourcers[i].outsourcer} - ${
                job.jobName
              } - ${numToLetter(i + 1)}`;
              if (arr.length < 80) {
                if (shouldPush) {
                  // console.log('test')
                  arr.push({
                    id: name,
                    group: groupKind
                      ? job.outsourcers[i].outsourcer
                      : `${job.jobName} - ${numToLetter(i + 1)}`,
                    title: groupKind
                      ? `${job.jobName} - ${numToLetter(i + 1)}`
                      : `${job.outsourcers[i].outsourcer} - ${
                          job.jobName
                        } - ${numToLetter(i + 1)}`,
                    start_time: zeroOutTime(
                      new Date(job.outsourcers[i].sentDate),
                      "start"
                    ),
                    end_time: zeroOutTime(
                      new Date(job.outsourcers[i].dueDate),
                      "end"
                    ),
                    canMove: false,
                    canResize: false,
                    canChangeGroup: false,
                    itemProps: {
                      onDoubleClick: () => {
                        console.log("You clicked double!");
                      },
                      style: {
                        background: parseOutsourcerColour(
                          job.outsourcers[i].outsourcer
                        ),
                        borderRadius: 5,
                        border: "none",
                      },
                    },
                  });
                } else {
                  // console.log('no')
                }
              }
            }
          }
        }
      }
    });
    // console.log(arr)
    arr.sort(function (a, b) {
      return b.start_time - a.start_time;
    });
    setOutsourcerData([...arr]);
  }, [jobData, setOutsourcerData, groupKind, hideCompleted, showDueToday]);
  function getToday() {
    let today = new Date();
    today.setDate(today.getDate() - 1);
    today.setHours(8, 30, 0);
    return today.getTime();
  }
  useEffect(() => {
    let groups = [];
    for (let i = 0; i < outsourcerData.length; i++) {
      let contains = false;
      for (let g = 0; g < groups.length; g++) {
        if (groups.length !== 0) {
          if (groups[g].title === outsourcerData[i].group) {
            contains = true;
          }
        }
      }
      if (!contains) {
        groups.push({
          title: outsourcerData[i].group,
          id: outsourcerData[i].group,
          stackItems: true,
        });
      }
    }
    setClients([...groups]);
  }, [outsourcerData, setClients]);

  const [visibleTimeStart, setVisibleTimeStart] = useState(
    moment().startOf("day").subtract(5, "day").valueOf()
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment().startOf("day").add(5, "day").valueOf()
  );

  useEffect(() => {
    setTimeout(() => {
      setVisibleTimeStart(visibleTimeStart + 48000000);
      setVisibleTimeEnd(visibleTimeEnd + 48000000);
    }, 1000);
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div style={{ marginLeft: 20 }}>
        <FormGroup>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>Job Name</Typography>
            <Switch
              checked={groupKind}
              onChange={(e) => {
                setGroupKind(e.target.checked);
              }}
            />
            <Typography style={{ marginRight: 30 }}>Outsourcer</Typography>
            <FormControlLabel
              style={{ marginRight: 30 }}
              control={
                <Checkbox
                  checked={hideCompleted}
                  onChange={(e) => {
                    setHideCompleted(e.target.checked);
                  }}
                />
              }
              label="Hide Completed"
            />
            <FormControlLabel
              style={{ display: hideCompleted ? "block" : "none" }}
              control={
                <Checkbox
                  checked={showDueToday}
                  onChange={(e) => {
                    setShowDueToday(e.target.checked);
                  }}
                />
              }
              label="Show Due Today"
            />
          </Stack>
        </FormGroup>
      </div>
      <div style={{ width: "100vw", overflowX: "hidden" }}>
        <Timeline
          groups={clients}
          items={outsourcerData}
          scrollRef={(el) => {
            setScrollRef(el);
          }}
          fullUpdate={true}
          visibleTimeStart={visibleTimeStart}
          visibleTimeEnd={visibleTimeEnd}
          traditionalZoom={true}
          sidebarWidth={280}
          minZoom={518400000}
          maxZoom={518400000}
          onTimeChange={(_start, _end) => {
            setVisibleTimeStart(_start);
            setVisibleTimeEnd(_end);
          }}
          lineHeight={45}
        >
          <TodayMarker interval={2000} />
        </Timeline>
      </div>
    </>
  );
};

export default OutsourcerTimeline;

const zeroOutTime = (d, kind) => {
  if (kind === "start") {
    return d.setHours(1, 0, 0);
  } else if (kind === "end") {
    return d.setHours(8, 0, 0);
  }
};

const parseOutsourcerColour = (outsourcer) => {
  if (outsourcer === "OMMS") {
    return "#857270";
  } else if (outsourcer === "Advanced Innovations") {
    return "#A35D4D";
  } else if (outsourcer === "Offshore Clipping Path") {
    return "#6FA9B3";
  } else if (outsourcer === "Image Clipping Camp") {
    return "#BA8E83";
  } else if (outsourcer === "PixoFix") {
    return "#BA8286";
  } else if (outsourcer === "The Digital Retouch") {
    return "#A34D54";
  } else {
    return "#A34D54";
  }
};

function numToLetter(num) {
  let s = "",
    t;
  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
}

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import InputAdornment from "@mui/material/InputAdornment";

// import Checkbox from "@mui/material/Checkbox";
// import Autocomplete from "@mui/material/Autocomplete";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useAtom } from "jotai";
import { themeAtom, isMobileAtom, jobTypesAtom } from "../-Atoms";

import { useDynamicDebounceCallback } from "react-dynamic-debounce";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ClientTabBreakdownItem = ({
  item,
  items,
  setItems,
  index,
  showRemoveBreakdownItem,
  firebase,
  clientData,
}) => {
  // console.log(clientData);
  const [isMobile] = useAtom(isMobileAtom);
  const [theme] = useAtom(themeAtom);
  const [jobTypes] = useAtom(jobTypesAtom);

  const filterButtonStyles = {
    fontSize: 10,
    fontWeight: "bold",
    padding: 0.8,
    border: `1px solid ${theme === "dark" ? "#3A3A3A" : "#DCDCDC"} !important`,
    borderRadius: "4px !important",
  };

  const updateFSNow = (firebase, id, field, value) => {
    // console.log(firebase, id, field, value);
    // return;
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .update({ [field]: value }, { merge: true })
      .catch((error) => {
        console.log(error);
      });
  };

  const [updateFS] = useDynamicDebounceCallback(updateFSNow, {
    defaultDelay: 1000,
    maxDelay: 6000,
    minSamples: 6,
    maxSamples: 4,
    delayFunction: (averageGap) => Math.floor(averageGap + 300),
  });

  const [description, setDescription] = useState("");
  const [jobTypesFilter, setJobTypesFilter] = useState([]);
  const [expectedPerHour, setExpectedPerHour] = useState("");
  const [costPerFile, setCostPerFile] = useState("");
  const [existingItem, setExistingItem] = useState("");
  const [dontAddToTotalSupplied, setDontAddToTotalSupplied] = useState(false);

  useEffect(() => {
    setDescription(item.description);
    setJobTypesFilter(item.jobTypesFilter || []);
    setExpectedPerHour(item.expectedPerHour);
    setCostPerFile(item.costPerFile);
    setExistingItem(item.existingItem);
    setDontAddToTotalSupplied(item.dontAddToTotalSupplied);
  }, [item]);

  const removeBreakdownItem = () => {
    items.splice(index, 1);
    setItems([...items]);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(clientData.id)
      .update({ imageBreakdown: items }, { merge: true })
      .catch((e) => console.log(e));
  };

  const handleJobTypeFilterChange = (event, newFilter) => {
    setJobTypesFilter(newFilter);
    item.jobTypesFilter = newFilter;
    updateFS(firebase, clientData.id, "imageBreakdown", [...items]);
  };

  const handleDontAddToTotalSupplied = (event, checked) => {
    setDontAddToTotalSupplied(checked);
    item.dontAddToTotalSupplied = checked;
    updateFS(firebase, clientData.id, "imageBreakdown", [...items]);
  };
  return (
    <div
      style={{
        display: "block",
        borderRadius: 5,
        padding: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          gap: 15,
          marginBottom: 4,
        }}
      >
        <TextField
          inputProps={{
            style: {
              fontSize: 15,
              fontWeight: existingItem ? "bold" : "",
              color:
                theme === "dark"
                  ? "rgba(255, 255, 255, 0.6)"
                  : "rgba(0, 0, 0, 0.6)",
            },
          }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          placeholder="Replace Me - e.g Ghost / Life / Jackets / T-Shirts etc..."
          variant="standard"
          size="small"
          multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            item.description = e.target.value;
            // console.log(clientData.id)
            updateFS(firebase, clientData.id, "imageBreakdown", [...items]);
          }}
          sx={{
            width: "100%",
            opacity: 1,
            "& .MuiInputBase-input.Mui-disabled": {
              WebkitTextFillColor:
                theme === "dark"
                  ? "rgba(255, 255, 255, 0.6)"
                  : "rgba(0, 0, 0, 0.6)",
            },
          }}
          InputProps={{
            endAdornment: showRemoveBreakdownItem ? (
              <InputAdornment position="end">
                <IconButton onClick={removeBreakdownItem}>
                  <RemoveIcon />
                </IconButton>
              </InputAdornment>
            ) : null,
            disableUnderline: true,
          }}
        />
      </div>
      {/* <Autocomplete
        multiple
        disablePortal
        // sx={{ marginRight: 2, width: 400 }}
        size="small"
        disableCloseOnSelect
        disableClearable
        options={jobTypes}
        getOptionLabel={(option) => findJobType(option, jobTypes)}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={jobTypesFilter.some((obj) => obj === option.id)}
              />
              {option.name} -({option.nameShort})
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, style: { fontSize: 15 } }}
            InputLabelProps={{
              ...params.InputLabelProps,
              style: { fontSize: 15 },
            }}
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            variant="filled"
            label="Job Types"
          />
        )}
        value={jobTypesFilter || []}
        onChange={(e, value) => {
          let tmp = [];
          value.forEach((v) => {
            if (!v.id) {
              tmp.push(v);
            } else {
              tmp.push(v.id);
            }
          });
          console.log(tmp);
          setJobTypesFilter([...tmp]);
        }}
      /> */}
      <ToggleButtonGroup
        value={jobTypesFilter}
        onChange={handleJobTypeFilterChange}
        sx={{
          flexWrap: "wrap",
          justifyContent: "flex-start",
          gap: 0.8,
          marginTop: 1.5,
          marginBottom: 3,
        }}
      >
        {jobTypes &&
          jobTypes.map((jt) => {
            const combined = `${jt.name} (-${jt.nameShort})`;
            return (
              <ToggleButton
                key={jt.id}
                value={jt.id}
                size="small"
                sx={filterButtonStyles}
              >
                {combined}
              </ToggleButton>
            );
          })}
      </ToggleButtonGroup>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 18,
          marginBottom: 5,
          flexWrap: isMobile ? "wrap" : "nowrap",
        }}
      >
        <TextField
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          variant="filled"
          label={"Cost Per File"}
          size="small"
          sx={{
            width: isMobile ? "47%" : "100%",
            opacity: 1,
          }}
          value={costPerFile}
          InputProps={{ disableUnderline: true }}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setCostPerFile(numericValue);
            item.costPerFile = e.target.value;
            updateFS(firebase, clientData.id, "imageBreakdown", [...items]);
          }}
        />
        <TextField
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          variant="filled"
          label={"Expected Per Hour"}
          size="small"
          sx={{
            width: isMobile ? "47%" : "100%",
            opacity: 1,
          }}
          value={expectedPerHour}
          InputProps={{ disableUnderline: true }}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setExpectedPerHour(numericValue);
            item.expectedPerHour = e.target.value;
            updateFS(firebase, clientData.id, "imageBreakdown", [...items]);
          }}
        />
      </div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={dontAddToTotalSupplied}
              onChange={handleDontAddToTotalSupplied}
            />
          }
          label="Don't Add To Total Supplied"
        />
      </FormGroup>
    </div>
  );
};

export default ClientTabBreakdownItem;

// const findJobType = (option, jobTypes) => {
//   if (option.name) return `${option.name} (-${option.nameShort})`;
//   const jt = jobTypes.find((obj) => obj.id === option);
//   return `${jt.name} (-${jt.nameShort})`;
// };

import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import ButtonGroup from "@mui/material/ButtonGroup";
import MarkdownNotesEditorClient from "./MarkdownNotesEditorClient";

import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import {
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";

import NotesContainer from "../GlobalComponents/NotesContainer";
import MDEditor, { commands } from "@uiw/react-md-editor";
import { v4 as uuidv4 } from "uuid";

const { DateTime } = require("luxon");

const Notes = ({ client, firebase, theme }) => {
  const [invoicingNotes, setInvoicingNotes] = useState(client.invoicingNotes);
  const [spreadsheetURL, setSpreadsheetURL] = useState(client.spreadsheetURL);
  const [createSpreadsheetButtonText, setCreateSpreadsheetButtonText] =
    useState("Create Client Spreadsheet");
  const [shareSpreadsheetWithClientsText, setShareSpreadsheetWithClientsText] =
    useState("Share With Clients");
  const [noClick, setNoClick] = useState(false);

  useEffect(() => {
    setInvoicingNotes(client.invoicingNotes);
    setSpreadsheetURL(client.spreadsheetURL);
  }, [client]);

  const style = {
    width: "70%",
    maxWidth: "800px",
    marginBottom: "20px",
    marginTop: "5px",
    borderColor: "#f7f7f7 !important",
  };

  const handleSpreadhseetChange = (e) => {
    setSpreadsheetURL(e.target.value);
    updateFS(firebase, client.id, "spreadsheetURL", e.target.value);
  };

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "calc(100vh - 220px)",
        }}
      >
        <div
          style={{
            marginBottom: 40,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "calc(100vw - 340px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30,
              marginTop: 10,
            }}
          >
            <Button
              variant="outlined"
              style={{
                marginBottom: 20,
                width: 260,
                height: 40,
                display: spreadsheetURL ? "none" : "block",
              }}
              onClick={async () => {
                if (!noClick) {
                  setNoClick(true);
                  setCreateSpreadsheetButtonText(
                    <CircularProgress style={{ width: 28, height: 28 }} />
                  );
                  const res = await fetch(
                    "https://us-central1-its-connect-main.cloudfunctions.net/createClientSpreadsheet",
                    {
                      method: "POST",
                      headers: {
                        "content-type": "application/json",
                      },
                      body: JSON.stringify({
                        brand: client.brand,
                        id: client.id,
                      }),
                    }
                  )
                    .then((res) => {
                      return res.json();
                    })
                    .catch(() => {
                      alert("an error occurred");
                    });
                  setSpreadsheetURL(res.url);
                  updateFS(firebase, client.id, "spreadsheetURL", res.url);
                  setCreateSpreadsheetButtonText("Create Client Spreadsheet");
                  setNoClick(false);
                }
              }}
            >
              {createSpreadsheetButtonText}
            </Button>
            <ButtonGroup
              variant="outlined"
              style={{
                marginBottom: 20,
                display: spreadsheetURL ? "block" : "none",
              }}
            >
              <Button
                style={{ width: 200, height: 40 }}
                onClick={async () => {
                  if (!noClick) {
                    setNoClick(true);
                    setShareSpreadsheetWithClientsText(
                      <CircularProgress style={{ width: 28, height: 28 }} />
                    );
                    const spreadsheetId =
                      spreadsheetURL.match(/\/d\/(.+)\//)[1];
                    const clientEmails = [];
                    client.contacts.forEach((c) => {
                      if (c.email) {
                        clientEmails.push(c.email);
                      }
                    });
                    await fetch(
                      "https://us-central1-its-connect-main.cloudfunctions.net/shareSpreadsheetToClients",
                      {
                        method: "POST",
                        headers: {
                          "content-type": "application/json",
                        },
                        body: JSON.stringify({
                          emails: clientEmails,
                          id: spreadsheetId,
                        }),
                      }
                    )
                      .then((res) => {
                        return res.json();
                      })
                      .catch(() => {
                        alert("an error occurred");
                      });
                    // setTimeout(() => {
                    setShareSpreadsheetWithClientsText("Share With Clients");
                    setNoClick(false);
                    // }, 3000);
                  }
                }}
              >
                {shareSpreadsheetWithClientsText}
              </Button>
              <Button
                style={{ width: 200, height: 40 }}
                onClick={() => {
                  window.open(spreadsheetURL, "_blank");
                }}
              >
                Shootlist
              </Button>
            </ButtonGroup>
            <TextField
              label={"Spreadsheet URL"}
              style={style}
              onChange={handleSpreadhseetChange}
              value={spreadsheetURL}
            />
          </div>

          <div
            style={{
              width: "90%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <NotesContainerContainer
              jobData={{}}
              clientData={client}
              firebase={firebase}
              theme={theme}
              clientTab={true}
            />
            <MarkdownNotesEditorClient
              label="Invoicing Notes"
              clientValue={invoicingNotes}
              setClientValue={(e) => {
                setInvoicingNotes(e);
                updateFS(firebase, client.id, "invoicingNotes", e);
              }}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notes;

const NotesContainerContainer = ({
  jobData,
  clientData,
  firebase,
  isMobile,
  theme,
  clientTab,
}) => {
  const titleContainerStyle = {
    marginTop: 20,
    marginLeft: 5,
    marginBottom: 5,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const [sortedNotes, setSortedNotes] = useState([]);

  useEffect(() => {
    let tmp = [];

    jobData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    clientData?.notes?.forEach((note) => {
      tmp.push(note);
    });
    setSortedNotes(sortNotes(tmp));
    // setSortedNotes(sortNotes([...jobData.notes, ...clientData.notes]));
  }, [jobData, clientData]);

  const [newNoteModalOpen, setNewNoteModalOpen] = useState(false);

  const [newNote, setNewNote] = useState(true);

  const [newSelectedNotesFilter, setNewSelectedNotesFilter] = useState([]);
  const [newNoteKind, setNewNoteKind] = useState("");

  const [newNotesText, setNewNotesText] = useState("");
  const [newNotesID, setNewNotesID] = useState(null);

  const toggleSelectedNotesFilterItem = (item) => {
    if (newSelectedNotesFilter.indexOf(item) !== -1) {
      newSelectedNotesFilter.splice(newSelectedNotesFilter.indexOf(item), 1);
      setNewSelectedNotesFilter([...newSelectedNotesFilter]);
    } else {
      newSelectedNotesFilter.push(item);
      setNewSelectedNotesFilter([...newSelectedNotesFilter]);
    }
  };

  const [newNoteErrorDialogOpen, setNewNoteErrorDialogOpen] = useState(false);
  const handlenewNoteErrorDialogClose = () => setNewNoteErrorDialogOpen(false);

  const updateNotes = async () => {
    let err = false;
    if (newSelectedNotesFilter.length === 0) err = true;
    if (!newNoteKind) err = true;
    if (!newNotesText) err = true;

    if (err) {
      setNewNoteErrorDialogOpen(true);
      return;
    }
    const obj = {
      id: uuidv4(),
      kind: newNoteKind,
      tags: newSelectedNotesFilter,
      text: newNotesText,
      timestamp: DateTime.now().setZone("Australia/Sydney").toUTC().toISO(),
    };

    if (newNoteKind === "Job") {
      if (!jobData.notes) jobData.notes = [];
      if (newNote) {
        jobData.notes.push(obj);
      } else {
        jobData.notes.forEach((note, index) => {
          if (note.id === newNotesID) {
            jobData.notes[index] = obj;
          }
        });
      }
      await firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ notes: jobData.notes });
    } else if (newNoteKind === "Permanent") {
      if (!clientData.notes) clientData.notes = [];
      if (newNote) {
        clientData.notes.push(obj);
      } else {
        clientData.notes.forEach((note, index) => {
          if (note.id === newNotesID) {
            clientData.notes[index] = obj;
          }
        });
      }
      await firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(clientData.id)
        .update({ notes: clientData.notes });
    }

    setNewNotesText("");
    setNewNoteKind("");
    setNewSelectedNotesFilter([]);
    setNewNoteModalOpen(false);
    setNewNote(true);
    setNewNotesID(null);
  };
  return (
    <div>
      <Dialog
        open={newNoteErrorDialogOpen}
        onClose={handlenewNoteErrorDialogClose}
      >
        <DialogContent>
          <DialogContentText>
            Please ensure there is atleast one tag selected
          </DialogContentText>
          <DialogContentText>
            Please ensure you select Permanent or Job
          </DialogContentText>
          <DialogContentText>Please ensure write a note</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlenewNoteErrorDialogClose} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
      <Slide direction="right" in={newNoteModalOpen} mountOnEnter unmountOnExit>
        <div
          style={{
            background: theme === "dark" ? "#1f1f1f" : "#ffffff",
            marginTop: 20,
          }}
        >
          <span style={{ marginTop: 20 }}>
            {newNote ? "New Note" : "Edit Note"}
          </span>
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginBottom: 1, marginTop: 2 }}
          >
            <Chip
              label="Pre-Production"
              sx={{ fontSize: 12 }}
              variant={
                newSelectedNotesFilter.includes("Pre-Production")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                toggleSelectedNotesFilterItem("Pre-Production");
              }}
            />
            <Chip
              label="Photographer"
              sx={{ fontSize: 12 }}
              variant={
                newSelectedNotesFilter.includes("Photographer")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                toggleSelectedNotesFilterItem("Photographer");
              }}
            />
            <Chip
              label="Videographer"
              sx={{ fontSize: 12 }}
              variant={
                newSelectedNotesFilter.includes("Videographer")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                toggleSelectedNotesFilterItem("Videographer");
              }}
            />
            <Chip
              label="Operator"
              sx={{ fontSize: 12 }}
              variant={
                newSelectedNotesFilter.includes("Operator")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                toggleSelectedNotesFilterItem("Operator");
              }}
            />
            <Chip
              label="Stylist"
              sx={{ fontSize: 12 }}
              variant={
                newSelectedNotesFilter.includes("Stylist")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                toggleSelectedNotesFilterItem("Stylist");
              }}
            />
            <Chip
              label="Retoucher"
              sx={{ fontSize: 12 }}
              variant={
                newSelectedNotesFilter.includes("Retoucher")
                  ? "contained"
                  : "outlined"
              }
              onClick={() => {
                toggleSelectedNotesFilterItem("Retoucher");
              }}
            />
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            sx={{ marginBottom: 2, marginTop: 2 }}
          >
            <Chip
              label="Permanent"
              sx={{
                fontSize: 12,
                backgroundColor:
                  newNoteKind === "Permanent"
                    ? theme === "dark"
                      ? "rgb(130, 165, 200)"
                      : "rgb(148, 195, 245)"
                    : "",
              }}
              variant={newNoteKind === "Permanent" ? "contained" : "outlined"}
              onClick={() => {
                if (clientTab) return;
                if (newNote) {
                  setNewNoteKind("Permanent");
                }
              }}
            />
            {!clientTab ? (
              <Chip
                label="Job"
                sx={{
                  fontSize: 12,
                  backgroundColor:
                    newNoteKind === "Job"
                      ? theme === "dark"
                        ? "rgb(130, 200, 165)"
                        : "rgb(148, 245, 195)"
                      : "",
                }}
                variant={newNoteKind === "Job" ? "contained" : "outlined"}
                onClick={() => {
                  if (newNote) {
                    setNewNoteKind("Job");
                  }
                }}
              />
            ) : null}
          </Stack>
          <MDEditor
            value={newNotesText}
            onChange={(e) => {
              setNewNotesText(e);
            }}
            commands={[
              commands.bold,
              commands.italic,
              commands.strikethrough,
              commands.hr,
              commands.title4,
              commands.divider,
              commands.link,
              commands.quote,
              commands.code,
              commands.codeBlock,
              commands.divider,
              commands.unorderedListCommand,
              commands.orderedListCommand,
            ]}
            extraCommands={[]}
          />
          <Button
            onClick={updateNotes}
            variant="outlined"
            sx={{ marginTop: 2 }}
          >
            {newNote ? "Add New Note" : "Save Note"}
          </Button>
        </div>
      </Slide>
      <div style={titleContainerStyle}>
        <span>Notes</span>
        <IconButton
          onClick={() => {
            setNewNoteModalOpen(!newNoteModalOpen);
            setNewNotesText("");
            setNewNoteKind(clientTab ? "Permanent" : "");
            setNewSelectedNotesFilter([]);
            setNewNote(true);
            setNewNotesID(null);
          }}
          style={{ marginRight: -8 }}
        >
          {!newNoteModalOpen ? <AddIcon /> : <RemoveIcon />}
        </IconButton>
      </div>
      <NotesContainer
        firebase={firebase}
        jobData={jobData}
        clientData={clientData}
        sortedNotes={sortedNotes}
        selectedNotesFilter={[]}
        isMobile={isMobile}
        modalWidth={"100%"}
        theme={theme}
        newNoteModalOpen={newNoteModalOpen}
        setNewNoteModalOpen={setNewNoteModalOpen}
        setNewSelectedNotesFilter={setNewSelectedNotesFilter}
        setNewNoteKind={setNewNoteKind}
        setNewNotesText={setNewNotesText}
        setNewNotesID={setNewNotesID}
        setNewNote={setNewNote}
        jobTab={true}
        clientTab={clientTab}
        shootTab={false}
      />
    </div>
  );
};

function sortNotes(notes) {
  let newNotes = [...notes];
  newNotes.sort((a, b) => {
    var keyA = new Date(a.timestamp),
      keyB = new Date(b.timestamp);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });

  let tmp = [];

  newNotes.forEach((note, index) => {
    if (note.tags.includes("Pre-Production")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Photographer")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Operator")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Stylist")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });
  newNotes.forEach((note, index) => {
    if (note.tags.includes("Retoucher")) {
      tmp.push(note);
      newNotes[index] = { tags: [] };
    }
  });

  return tmp;
}

const updateFS = debounce(
  async (firebase, id, field, value, setBorderState) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .update({ [field]: value }, { merge: true });
  },
  1500
);

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

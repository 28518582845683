import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

import { motion, AnimatePresence } from "framer-motion";

import Logo from "../Login/Logo";

const countryCodes = require("../countryCodes.json");

const NewStaffForm = ({ firebase }) => {
  const sendEmail = firebase.functions().httpsCallable("sendEmail");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [preferredName, setPreferredName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phone, setPhone] = useState("");
  // const [phone, setPhone] = useState("487677654");
  const [employmentBasis, setEmploymentBasis] = useState("");
  const [nextOfKin1Name, setNextOfKin1Name] = useState("");
  const [nextOfKin1Phone, setNextOfKin1Phone] = useState("");
  const [nextOfKin2Name, setNextOfKin2Name] = useState("");
  const [nextOfKin2Phone, setNextOfKin2Phone] = useState("");
  const [taxFileNumber, setTaxFileNumber] = useState("");
  const [salaryHourlyRate, setSalaryHourlyRate] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [BSB, setBSB] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [superannuationProvider, setSuperannuationProvider] = useState("");
  const [superannuationMemberNumber, setSuperannuationMemberNumber] =
    useState("");
  const [superannuationFundUSI, setSuperannuationFundUSI] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [suburb, setSuburb] = useState("");

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    position: "relative",
  };
  const innerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90vw",
    maxWidth: 650,
    height: "70vh",
    maxHeight: 600,
    position: "relative",
    // border: "2px solid red",
  };

  const submitForm = async () => {
    const userObject = {
      firstName,
      lastName,
      dateOfBirth,
      email,
      phoneNumber: phone,
      employmentBasis,
      nextOfKin1Name,
      nextOfKin1Phone,
      nextOfKin2Name,
      nextOfKin2Phone,
      preferredName,
      nickname: preferredName,
      jobTitle,
      taxFileNumber,
      salaryHourlyRate,
      bankName,
      bankAccountName,
      BSB,
      bankAccountNumber,
      superannuationProvider,
      superannuationMemberNumber,
      superannuationFundUSI,
      address: {
        streetNumber,
        streetName,
        postcode,
        suburb,
      },
      notificationTomorrowsJob: true,
      notificationNextDayChangesMe: true,
      notificationSubscriptions: [],
      wwccExpiry: "",
      wwccCheckNumber: "",
      userTheme: "automatic",
      currentlyEmployed: true,
      isAdmin: false,
      isSuperAdmin: false,
      uid: firebase.auth().currentUser.uid,
    };
    console.log(userObject);
    await firebase.firestore().collection("StaffData").doc().set(userObject);
    // await updateUserPassword({ uid, password });
    // return;

    await sendEmail({
      emailBody: `<style>
			body {
				font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
					Helvetica, Arial, "Lucida Grande", sans-serif;
			}
		</style>

		<body>
			<p>Hello Jodie,</p>
			<p>${firstName} ${lastName} has completed the Employee Details Form</p>
			<span>Thanks!</span>
			<br>
			<span>ITS Team</span>
		</body>`,
      fromEmail: "production@imagesthatsell.com.au",
      toEmails: "jodie@imagesthatsell.com.au",
      ccEmails: [],
      bccEmails: [],
      replyToEmails: [],
      subject: `${firstName} ${lastName} has completed the Employee Details Form`,
      attachments: [],
    }).then((res) => {
      console.log(res);
    });
    // setTimeout(() => {
    //   window.location.href = "https://staff.imagesthatsell.com.au";
    // }, 5000);
  };
  const innerInnerContainerStyle = {
    // display: "flex",
    // justifyContent: "center",
    // width: "100%",

    display: "flex",
    justifyContent: "flex-start",
    width: "90vw",
    maxWidth: 650,
    alignItems: "flex-start",
    // flexWrap: "wrap",
  };

  const [introVisible, setIntroVisible] = useState(true);
  const [basicInfoVisible, setBasicInfoVisible] = useState(false);
  const [phoneInfoVisible, setPhoneInfoVisible] = useState(false);
  const [addressInfoVisible, setAddressInfoVisible] = useState(false);
  const [nextOfKinInfoVisible, setNextOfKinInfoVisible] = useState(false);
  const [employmentInfoVisible, setEmploymentInfo] = useState(false);
  const [bankInfo, setBankInfo] = useState(false);
  const [superannuationInfo, setSuperannuationInfo] = useState(false);

  return (
    <div style={containerStyle}>
      <div style={innerContainerStyle}>
        <Logo isDarkMode={true} />
        <div style={innerInnerContainerStyle}>
          <AnimatePresence>
            {introVisible && (
              <Intro
                key="Intro"
                introVisible={introVisible}
                setIntroVisible={setIntroVisible}
                setBasicInfoVisible={setBasicInfoVisible}
              />
            )}
            {basicInfoVisible && (
              <BasicInfo
                key="BasicInfo"
                basicInfoVisible={basicInfoVisible}
                setBasicInfoVisible={setBasicInfoVisible}
                setPhoneInfoVisible={setPhoneInfoVisible}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                jobTitle={jobTitle}
                setJobTitle={setJobTitle}
                dateOfBirth={dateOfBirth}
                setDateOfBirth={setDateOfBirth}
                email={email}
                setEmail={setEmail}
                preferredName={preferredName}
                setPreferredName={setPreferredName}
              />
            )}
            {phoneInfoVisible && (
              <PhoneInfo
                key="PhoneInfo"
                firebase={firebase}
                setPhoneInfoVisible={setPhoneInfoVisible}
                setAddressInfoVisible={setAddressInfoVisible}
                phone={phone}
                setPhone={setPhone}
                email={email}
                firstName={firstName}
                lastName={lastName}
              />
            )}
            {addressInfoVisible && (
              <AddressInfo
                key="AddressInfo"
                setAddressInfoVisible={setAddressInfoVisible}
                setNextOfKinInfoVisible={setNextOfKinInfoVisible}
                streetNumber={streetNumber}
                setStreetNumber={setStreetNumber}
                streetName={streetName}
                setStreetName={setStreetName}
                postcode={postcode}
                setPostcode={setPostcode}
                suburb={suburb}
                setSuburb={setSuburb}
              />
            )}
            {nextOfKinInfoVisible && (
              <NextOfKinInfo
                key="NextOfKinInfo"
                setNextOfKinInfoVisible={setNextOfKinInfoVisible}
                setEmploymentInfo={setEmploymentInfo}
                nextOfKin1Name={nextOfKin1Name}
                setNextOfKin1Name={setNextOfKin1Name}
                nextOfKin1Phone={nextOfKin1Phone}
                setNextOfKin1Phone={setNextOfKin1Phone}
                nextOfKin2Name={nextOfKin2Name}
                setNextOfKin2Name={setNextOfKin2Name}
                nextOfKin2Phone={nextOfKin2Phone}
                setNextOfKin2Phone={setNextOfKin2Phone}
              />
            )}
            {employmentInfoVisible && (
              <EmploymentInfo
                key="EmploymentInfo"
                setEmploymentInfo={setEmploymentInfo}
                setBankInfo={setBankInfo}
                taxFileNumber={taxFileNumber}
                setTaxFileNumber={setTaxFileNumber}
                salaryHourlyRate={salaryHourlyRate}
                setSalaryHourlyRate={setSalaryHourlyRate}
                employmentBasis={employmentBasis}
                setEmploymentBasis={setEmploymentBasis}
              />
            )}
            {bankInfo && (
              <BankInfo
                key="BankInfo"
                setBankInfo={setBankInfo}
                setSuperannuationInfo={setSuperannuationInfo}
                bankName={bankName}
                setBankName={setBankName}
                bankAccountName={bankAccountName}
                setBankAccountName={setBankAccountName}
                BSB={BSB}
                setBSB={setBSB}
                bankAccountNumber={bankAccountNumber}
                setBankAccountNumber={setBankAccountNumber}
              />
            )}
            {superannuationInfo && (
              <SuperannuationInfo
                key="SuperannuationInfo"
                setSuperannuationInfo={setSuperannuationInfo}
                superannuationProvider={superannuationProvider}
                setSuperannuationProvider={setSuperannuationProvider}
                superannuationMemberNumber={superannuationMemberNumber}
                setSuperannuationMemberNumber={setSuperannuationMemberNumber}
                superannuationFundUSI={superannuationFundUSI}
                setSuperannuationFundUSI={setSuperannuationFundUSI}
                submitForm={submitForm}
              />
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

const SuperannuationInfo = ({
  setSuperannuationInfo,
  superannuationProvider,
  setSuperannuationProvider,
  superannuationMemberNumber,
  setSuperannuationMemberNumber,
  superannuationFundUSI,
  setSuperannuationFundUSI,
  submitForm,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    if (
      superannuationProvider &&
      superannuationMemberNumber &&
      superannuationFundUSI
    ) {
      setButtonDisabled(false);
    } else {
      //CHANGE TO TRUE
      setButtonDisabled(true);
    }
  }, [
    superannuationProvider,
    superannuationMemberNumber,
    superannuationFundUSI,
  ]);
  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        width: "90vw",
        maxWidth: 650,

        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
      }}
    >
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          width: "100%",
          marginBottom: 10,
        }}
      >
        Superannuation Details
      </span>
      <TextField
        variant="filled"
        label="Superannuation Provider"
        value={superannuationProvider || ""}
        onChange={(e) => {
          setSuperannuationProvider(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Member Number"
        value={superannuationMemberNumber || ""}
        onChange={(e) => {
          setSuperannuationMemberNumber(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Fund USI"
        value={superannuationFundUSI || ""}
        onChange={(e) => {
          setSuperannuationFundUSI(e.target.value);
        }}
        fullWidth
        // sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={buttonDisabled}
        onClick={async () => {
          // setSuperannuationInfo(false);
          setTimeout(() => {
            // setPhoneInfoVisible(true);
          }, 500);
          await submitForm();
          window.location.href = "https://staff.imagesthatsell.com.au";
        }}
      >
        Next
      </Button>
    </motion.div>
  );
};

const BankInfo = ({
  setBankInfo,
  setSuperannuationInfo,
  bankName,
  setBankName,
  bankAccountName,
  setBankAccountName,
  BSB,
  setBSB,
  bankAccountNumber,
  setBankAccountNumber,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    if (bankName && bankAccountName && BSB && bankAccountNumber) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [bankName, bankAccountName, BSB, bankAccountNumber]);
  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        width: "90vw",
        maxWidth: 650,
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
      }}
    >
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          width: "100%",
          marginBottom: 10,
        }}
      >
        Bank Details
      </span>
      <TextField
        variant="filled"
        label="Bank Name"
        value={bankName || ""}
        onChange={(e) => {
          setBankName(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Account Name"
        value={bankAccountName || ""}
        onChange={(e) => {
          setBankAccountName(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="BSB"
        value={BSB || ""}
        onChange={(e) => {
          setBSB(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Account Number"
        value={bankAccountNumber || ""}
        onChange={(e) => {
          setBankAccountNumber(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={buttonDisabled}
        onClick={() => {
          setBankInfo(false);
          setTimeout(() => {
            setSuperannuationInfo(true);
          }, 500);
        }}
      >
        Next
      </Button>
    </motion.div>
  );
};

const EmploymentInfo = ({
  setEmploymentInfo,
  setBankInfo,
  taxFileNumber,
  setTaxFileNumber,
  salaryHourlyRate,
  setSalaryHourlyRate,
  employmentBasis,
  setEmploymentBasis,
}) => {
  const employmentBasisStyle = {
    display: "flex",
    justifyContent: "center",
  };
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    if (taxFileNumber && salaryHourlyRate && employmentBasis) {
      setButtonDisabled(false);
    } else {
      //CHANGE TO TRUE
      setButtonDisabled(true);
    }
  }, [taxFileNumber, salaryHourlyRate, employmentBasis]);

  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        width: "90vw",
        maxWidth: 650,

        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
      }}
    >
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          width: "100%",
          marginBottom: 10,
        }}
      >
        Employment Details
      </span>
      <TextField
        variant="filled"
        label="Tax File Number"
        value={taxFileNumber || ""}
        onChange={(e) => {
          setTaxFileNumber(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Salary / Hourly Rate"
        value={salaryHourlyRate || ""}
        onChange={(e) => {
          setSalaryHourlyRate(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <div style={employmentBasisStyle}>
        <FormControl>
          <FormLabel>Employment Basis</FormLabel>
          <RadioGroup
            row
            value={employmentBasis || ""}
            onChange={(e) => {
              setEmploymentBasis(e.target.value);
            }}
          >
            <FormControlLabel
              value="permanent"
              control={<Radio />}
              label="Permanent"
            />
            <FormControlLabel
              value="part-time"
              control={<Radio />}
              label="Part-Time"
            />
            <FormControlLabel
              value="casual"
              control={<Radio />}
              label="Casual"
            />
            <FormControlLabel
              value="freelancer"
              control={<Radio />}
              label="Freelancer"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <Button
        variant="contained"
        fullWidth
        disabled={buttonDisabled}
        onClick={() => {
          setEmploymentInfo(false);
          setTimeout(() => {
            setBankInfo(true);
          }, 500);
        }}
      >
        Next
      </Button>
    </motion.div>
  );
};

const NextOfKinInfo = ({
  setNextOfKinInfoVisible,
  setEmploymentInfo,
  nextOfKin1Name,
  setNextOfKin1Name,
  nextOfKin1Phone,
  setNextOfKin1Phone,
  nextOfKin2Name,
  setNextOfKin2Name,
  nextOfKin2Phone,
  setNextOfKin2Phone,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    if (nextOfKin1Name && nextOfKin1Phone) {
      setButtonDisabled(false);
    } else {
      //CHANGE TO TRUE
      setButtonDisabled(true);
    }
  }, [nextOfKin1Name, nextOfKin1Phone]);

  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        width: "90vw",
        maxWidth: 650,

        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
      }}
    >
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          width: "100%",
          marginBottom: 10,
        }}
      >
        Next Of Kin Details
      </span>
      <TextField
        variant="filled"
        label="Next of Kin 1 Name"
        value={nextOfKin1Name || ""}
        onChange={(e) => {
          setNextOfKin1Name(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Next of Kin 1 Phone"
        value={nextOfKin1Phone || ""}
        onChange={(e) => {
          setNextOfKin1Phone(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Next of Kin 2 Name"
        value={nextOfKin2Name || ""}
        onChange={(e) => {
          setNextOfKin2Name(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        label="Next of Kin 2 Phone"
        value={nextOfKin2Phone || ""}
        onChange={(e) => {
          setNextOfKin2Phone(e.target.value);
        }}
        sx={{ width: "47%" }}
        InputProps={{ disableUnderline: true }}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={buttonDisabled}
        onClick={() => {
          setNextOfKinInfoVisible(false);
          setTimeout(() => {
            setEmploymentInfo(true);
          }, 500);
        }}
      >
        Next
      </Button>
    </motion.div>
  );
};

const AddressInfo = ({
  setAddressInfoVisible,
  setNextOfKinInfoVisible,
  streetNumber,
  setStreetNumber,
  streetName,
  setStreetName,
  postcode,
  setPostcode,
  suburb,
  setSuburb,
}) => {
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    if (streetNumber && streetName && postcode && suburb) {
      setButtonDisabled(false);
    } else {
      //CHANGE TO TRUE
      setButtonDisabled(true);
    }
  }, [streetNumber, streetName, postcode, suburb]);

  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        width: "90vw",
        maxWidth: 650,

        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
      }}
    >
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          width: "100%",
          marginBottom: 10,
        }}
      >
        Address
      </span>
      <TextField
        variant="filled"
        size="small"
        label="Street Number"
        value={streetNumber || ""}
        fullWidth
        onChange={(e) => {
          setStreetNumber(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        size="small"
        label="Street Name"
        value={streetName || ""}
        fullWidth
        onChange={(e) => {
          setStreetName(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        size="small"
        label="Suburb"
        value={suburb || ""}
        fullWidth
        onChange={(e) => {
          setSuburb(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        variant="filled"
        size="small"
        label="Postcode"
        value={postcode || ""}
        fullWidth
        onChange={(e) => {
          setPostcode(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <Button
        variant="contained"
        fullWidth
        disabled={buttonDisabled}
        onClick={() => {
          setAddressInfoVisible(false);
          setTimeout(() => {
            setNextOfKinInfoVisible(true);
          }, 500);
        }}
      >
        Next
      </Button>
    </motion.div>
  );
};

const PhoneInfo = ({
  firebase,
  setPhoneInfoVisible,
  setAddressInfoVisible,
  phone,
  setPhone,
  email,
  firstName,
  lastName,
}) => {
  const [selectedCountryCode, setSelectedCountryCode] = useState("AU");
  const [confirmationResult, setConfirmationResult] = useState("");
  const [verifyingNumber, setVerifyingNumber] = useState(false);
  const [verifyingButtonText, setVerifyingButtonText] = useState("Verify");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeButtonText, setVerificationCodeButtonText] =
    useState("Next");

  const [numberVerified, setNumberVerified] = useState(false);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "main-app-container",
      {
        size: "invisible",
        callback: (response) => {},
      }
    );
  }, []);
  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        width: "90vw",
        maxWidth: 650,

        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
      }}
    >
      <span
        style={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          width: "100%",
          marginBottom: 10,
        }}
      >
        Phone
      </span>
      {!numberVerified ? (
        <>
          <span>
            Your phone number is going to be your username to log in to the
            calendar
          </span>
          <span>
            Once you click verify, a password will be sent to your phone via SMS
          </span>
          <div style={{ display: "flex", gap: 15, width: "100%" }}>
            <FormControl
              disabled={verifyingNumber}
              variant="filled"
              sx={{ width: 300 }}
              size="small"
            >
              <InputLabel id="countryCodeSelectorLabel">
                Country Code
              </InputLabel>
              <Select
                labelId="countryCodeSelectorLabel"
                value={selectedCountryCode}
                label="Country Code"
                onChange={(e) => {
                  setSelectedCountryCode(e.target.value);
                }}
                disableUnderline
              >
                {countryCodes &&
                  countryCodes.map((cc, index) => (
                    <MenuItem
                      key={`${cc.code}${index}`}
                      value={cc.code}
                    >{`${cc.emoji} ${cc.name} (${cc.dialCode})`}</MenuItem>
                  ))}
              </Select>
            </FormControl>

            <TextField
              variant="filled"
              disabled={verifyingNumber}
              size="small"
              label="Phone Number"
              value={phone || ""}
              fullWidth
              onChange={(e) => {
                const numericValue = e.target.value.replace(/[^0-9]/g, "");
                setPhone(numericValue);
              }}
              InputProps={{ disableUnderline: true }}
            />
          </div>
          {/* Mkx5DXLjTBPgby6yo0CB3WUdYwl2 */}
          {/* Q2shLKo0IYgMwRUITJcTZZQf5lB2 */}
          <Button
            variant="contained"
            fullWidth
            disabled={!phone}
            onClick={() => {
              if (verifyingNumber) return;
              setVerifyingNumber(true);
              setVerifyingButtonText(
                <CircularProgress size={24} sx={{ color: "black" }} />
              );

              const cc = countryCodes.find(
                (obj) => obj.code === selectedCountryCode
              );
              firebase
                .auth()
                .signInWithPhoneNumber(
                  `${cc.dialCode}${phone}`,
                  window.recaptchaVerifier
                )
                .then((confirmationResult) => {
                  setConfirmationResult(confirmationResult);
                  setNumberVerified(true);
                  phone.replace(/^04/, "4");
                  setPhone(`${cc.dialCode}${phone}`);

                  console.log(confirmationResult);
                })
                .catch((error) => {
                  window.recaptchaVerifier.render().then(function (widgetId) {
                    window.grecaptcha.reset(widgetId);
                  });
                  alert(
                    "Invalid Phone Number, please ensure the number is correct"
                  );
                  setPhone("");
                  setVerifyingNumber(false);
                  setVerifyingButtonText("Verify");

                  console.log(error);
                });
            }}
          >
            {verifyingButtonText}
          </Button>
        </>
      ) : (
        <>
          <TextField
            variant="filled"
            size="small"
            label="Verification Code"
            value={verificationCode || ""}
            fullWidth
            onChange={(e) => {
              setVerificationCode(e.target.value);
            }}
            InputProps={{ disableUnderline: true }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={async () => {
              setVerificationCodeButtonText(
                <CircularProgress size={24} sx={{ color: "black" }} />
              );

              await confirmationResult
                .confirm(verificationCode)
                .then(async (result) => {
                  const user = result.user;
                  await firebase
                    .auth()
                    .currentUser.updateProfile({
                      displayName: `${firstName} ${lastName}`,
                      firstName: firstName,
                      lastName: lastName,
                    })
                    .then(() => {
                      console.log("profile updated");
                      console.log(user.auth);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  await firebase
                    .auth()
                    .currentUser.updateEmail(email)
                    .then(() => {
                      console.log("email updated");
                      console.log(user.auth);
                    })
                    .catch((error) => {
                      console.log(error);
                    });

                  setPhoneInfoVisible(false);
                  setTimeout(() => {
                    setAddressInfoVisible(true);
                  }, 500);
                })
                .catch((error) => {
                  console.log(error);
                  alert(
                    "Incorrect Verification Code, double check it is correct and try again"
                  );
                  setVerificationCode("");

                  // User couldn't sign in (bad verification code?)
                  // ...
                });
            }}
          >
            {verificationCodeButtonText}
          </Button>
        </>
      )}
    </motion.div>
  );
};

const BasicInfo = ({
  basicInfoVisible,
  setBasicInfoVisible,
  setPhoneInfoVisible,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  jobTitle,
  setJobTitle,
  dateOfBirth,
  setDateOfBirth,
  email,
  setEmail,
  preferredName,
  setPreferredName,
}) => {
  const [showNickName, setShowNickName] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    if (firstName && lastName && jobTitle && dateOfBirth && email) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [firstName, lastName, jobTitle, dateOfBirth, email]);

  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 15,
        width: "90vw",
        maxWidth: 650,
      }}
    >
      <TextField
        size="small"
        variant="filled"
        label="First Name"
        sx={{ width: "47%" }}
        value={firstName || ""}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        size="small"
        variant="filled"
        label="Last Name"
        sx={{ width: "47%" }}
        value={lastName || ""}
        onChange={(e) => {
          setLastName(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <TextField
        size="small"
        variant="filled"
        label="Job Title"
        sx={{ width: "47%" }}
        value={jobTitle || ""}
        onChange={(e) => {
          setJobTitle(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Date of Birth"
          inputFormat="dd/MM/yyyy"
          value={dateOfBirth || null}
          onChange={(e) => {
            if (!e) return;
            if (new Date(e)) {
              setDateOfBirth(new Date(e).toISOString());
            }
          }}
          onInput={() => {}}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              variant="filled"
              sx={{ width: "47%" }}
              InputProps={{ ...params.InputProps, disableUnderline: true }}
            />
          )}
        />
      </LocalizationProvider>
      <TextField
        size="small"
        variant="filled"
        label="Email"
        fullWidth
        value={email || ""}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        InputProps={{ disableUnderline: true }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90vw",
          maxWidth: 650,

          gap: 15,
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showNickName}
                onChange={(e, checked) => {
                  setShowNickName(checked);
                  if (!checked) {
                    setPreferredName("");
                  }
                }}
              />
            }
            sx={{
              webkitTouchCallout: "none",
              webkitUserSelect: "none",
              khtmlUserSelect: "none",
              MozUserSelect: "none",
              msUserSelect: "none",
              userSelect: "none",
            }}
            label="I have a preferred name / nickname I want to be referred to as"
          />
        </FormGroup>
        <TextField
          size="small"
          sx={{ display: showNickName ? "block" : "none" }}
          variant="filled"
          fullWidth
          label="Preferred Name / Nickname"
          value={preferredName || ""}
          onChange={(e) => {
            setPreferredName(e.target.value);
          }}
          InputProps={{ disableUnderline: true }}
        />
      </div>
      <Button
        variant="contained"
        fullWidth
        disabled={buttonDisabled}
        onClick={() => {
          setBasicInfoVisible(false);
          setTimeout(() => {
            setPhoneInfoVisible(true);
          }, 500);
        }}
      >
        Next
      </Button>
    </motion.div>
  );
};

const Intro = ({ introVisible, setIntroVisible, setBasicInfoVisible }) => {
  return (
    <motion.div
      initial={{ x: window.innerWidth * 2 }}
      animate={{ x: 0 }}
      exit={{
        x: -window.innerWidth * 2,
        duration: 0.1,
        transitionEnd: {
          display: "none",
        },
      }}
      style={{
        display: introVisible ? "flex" : "none",
        width: "90vw",
        maxWidth: 650,

        flexDirection: "column",
        alignItems: "center",
        gap: 40,
      }}
    >
      <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
        Welcome to Images That Sell!
      </span>
      <span>Please fill out the following form</span>
      <Button
        variant="contained"
        fullWidth
        // sx={{ position: "absolute", bottom: 0 }}
        onClick={() => {
          setIntroVisible(false);
          setTimeout(() => {
            setBasicInfoVisible(true);
          }, 500);
        }}
      >
        Start
      </Button>
    </motion.div>
  );
};

export default NewStaffForm;

import React, { useState } from "react";

import Button from "@mui/material/Button";
import MDEditor, { commands } from "@uiw/react-md-editor";

import "./style.css";

import { v4 as uuidv4 } from "uuid";
const { DateTime } = require("luxon");

const MarkdownNotesEditorCalendar = ({
  label,
  jobValue,
  setJobValue,
  clientValue,
  setClientValue,
  canEdit,
  isMobile,
  showShootDriveNote,
  jobClientData,
  theme,
  firebase,
  jobData,
}) => {
  const [toggleEdit, settoggleEdit] = useState("preview");
  const [maxHeight, setMaxHeight] = useState(250);
  return (
    <>
      {label !== "Invoicing Notes" ? (
        <button
          style={{
            marginTop: 25,
            cursor: "pointer",
          }}
          onClick={async () => {
            if (jobValue) {
              let tags = [];
              if (label === "Pre-Production Notes") {
                tags.push("Pre-Production");
              } else if (label === "Photographer Notes") {
                tags.push("Photographer");
              } else if (label === "Videographer Notes") {
                tags.push("Videographer");
              } else if (label === "Operator Notes") {
                tags.push("Operator");
              } else if (label === "Video Assistant Notes") {
                tags.push("Operator");
              } else if (label === "Stylist Notes") {
                tags.push("Stylist");
              } else if (label === "Retoucher Notes") {
                tags.push("Retoucher");
              }
              const jobValueObj = {
                id: uuidv4(),
                kind: "Job",
                tags: tags,
                text: jobValue,
                timestamp: DateTime.now()
                  .setZone("Australia/Sydney")
                  .toUTC()
                  .toISO(),
              };
              if (!jobData.notes) jobData.notes = [];
              jobData.notes.push(jobValueObj);
              let updateObj = { notes: jobData.notes };

              if (label === "Pre-Production Notes") {
                updateObj.preProductionNotes = "";
              } else if (label === "Photographer Notes") {
                updateObj.photographerNotes = "";
              } else if (label === "Operator Notes") {
                updateObj.operatorNotes = "";
              } else if (label === "Video Assistant Notes") {
                updateObj.operatorNotes = "";
              } else if (label === "Stylist Notes") {
                updateObj.stylistNotes = "";
              } else if (label === "Retoucher Notes") {
                updateObj.retoucherNotes = "";
              }

              await firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update(updateObj);
            }
          }}
        >
          Transfer {label}
        </button>
      ) : null}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          cursor: "text",
          // marginTop: 25,
          marginBottom: 20,
          minHeight: 80,
          maxHeight: maxHeight,
          overflow: "hidden",
        }}
        onClick={(e) => {
          if (!canEdit) return;
          if (toggleEdit === "edit") return;
          if (toggleEdit === "preview") {
            settoggleEdit("edit");
            setMaxHeight(800);
          }
        }}
      >
        <fieldset
          data-color-mode={theme}
          style={{
            border: "1px solid #bababa",
            borderRadius: 4,
            width: "100%",
            position: "relative",
            padding: 10,
            overflow: "auto",
          }}
        >
          <legend>
            <span
              style={{
                marginLeft: 4,
                marginRight: 4,
                fontWeight: 400,
                color:
                  theme === "dark"
                    ? "rgba(255, 255, 255, 0.6)"
                    : "rgba(0, 0, 0, 0.6)",
                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                fontSize: "0.9rem",
                letterSpacing: "0.00938em",
              }}
            >
              {label}
            </span>
          </legend>
          <div
            style={{
              // width: isMobile ? "82vw" : "34vw",
              width: "100%",
            }}
          >
            {toggleEdit === "edit" ? (
              <div>
                <p style={{ marginLeft: 15, marginRight: 15, marginTop: 4 }}>
                  Job Notes (Shows on this job)
                </p>
                <MDEditor
                  onFocus={(e) => {
                    e.target.selectionStart = e.target.value.length + 1;
                    e.target.selectionEnd = e.target.value.length + 1;
                  }}
                  value={jobValue}
                  onChange={setJobValue}
                  preview={toggleEdit}
                  commands={[
                    commands.bold,
                    commands.italic,
                    commands.strikethrough,
                    commands.hr,
                    commands.title4,
                    commands.divider,
                    commands.link,
                    commands.quote,
                    commands.code,
                    commands.codeBlock,
                    commands.divider,
                    commands.unorderedListCommand,
                    commands.orderedListCommand,
                  ]}
                  extraCommands={[]}
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                  }}
                  height={200}
                />
                <br />
                <p style={{ marginLeft: 15, marginRight: 15, marginTop: 4 }}>
                  Client Notes (Shows on every job)
                </p>
                <MDEditor
                  onFocus={(e) => {
                    e.target.selectionStart = e.target.value.length + 1;
                    e.target.selectionEnd = e.target.value.length + 1;
                  }}
                  value={clientValue}
                  onChange={setClientValue}
                  preview={toggleEdit}
                  commands={[
                    commands.bold,
                    commands.italic,
                    commands.strikethrough,
                    commands.hr,
                    commands.title4,
                    commands.divider,
                    commands.link,
                    commands.quote,
                    commands.code,
                    commands.codeBlock,
                    commands.divider,
                    commands.unorderedListCommand,
                    commands.orderedListCommand,
                  ]}
                  extraCommands={[]}
                  style={{
                    marginLeft: 15,
                    marginRight: 15,
                  }}
                  height={200}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{ width: "80%", height: 24, margin: 5 }}
                    onClick={() => {
                      settoggleEdit("preview");
                      setMaxHeight(200);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            ) : (
              <>
                {showShootDriveNote ? (
                  <>
                    <MDEditor.Markdown
                      source={`- [${
                        jobClientData?.shootDrivePhoto ? "x" : " "
                      }] Photo Shoot Drive 
                    
- [${jobClientData?.shootDriveVideo ? "x" : " "}] Video Shoot Drive`}
                      style={{
                        padding: 4,
                        background: "transparent",
                        // marginBottom: 10,
                      }}
                      height={200}
                    />
                  </>
                ) : null}

                {jobValue ? (
                  <MDEditor.Markdown
                    source={jobValue}
                    style={{
                      padding: 4,
                      background: "transparent",
                    }}
                    height={200}
                  />
                ) : null}
                {/* {clientValue ? ( */}
                <div style={{ marginBottom: 10 }}>
                  <MDEditor.Markdown
                    source={clientValue}
                    style={{
                      padding: 4,
                      background: "transparent",
                      marginBottom: 5,
                    }}
                    height={200}
                  />
                </div>
                {/* ) : null} */}
              </>
            )}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export default MarkdownNotesEditorCalendar;

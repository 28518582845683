import React from "react";
import { TextField, Box, Button, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "99vw",
  height: "99vh",
  bgcolor: "background.paper",
  boxShadow: 10,
  p: 1.2,
  borderRadius: 2,
  border: "1px solid grey",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "center",
  outline: "none",
};

const URLModal = ({ open, handleClose, url }) => {
  const copyURL = () => {
    navigator.clipboard.writeText(url);
  };
  const openURL = () => {
    window.open(url, "_blank");
    handleClose();
  };

  const urlIsImage = (str) => {
    if (str.includes(".jpg")) return true;
    if (str.includes(".png")) return true;
    if (str.includes(".heic")) return true;
    if (str.includes(".webp")) return true;
    if (str.includes(".jpeg")) return true;
    return false;
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <div
          style={{
            width: "100%",
            height: 40,
            display: "flex",
            justifyContent: "center",
            gap: 10,
            marginBottom: 10,
          }}
        >
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
          <TextField value={url} fullWidth size="small" sx={{ height: 40 }} />
          <Button variant="contained" onClick={copyURL}>
            Copy
          </Button>
          <Button variant="contained" onClick={openURL}>
            Open
          </Button>
        </div>
        {urlIsImage(url.replace("dl=0", "raw=1").toLowerCase()) ? (
          <img
            src={url.replace("dl=0", "raw=1")}
            style={{
              maxWidth: "100%",
              maxHeight: "calc(100% - 50px)",
              width: "auto",
              height: "auto",
            }}
            alt="url"
          />
        ) : (
          <iframe
            id="myiframe"
            title="url"
            style={{
              width: "100%",
              height: "calc(100% - 50px)",
              border: "none",
            }}
            src={url.replace("dl=0", "raw=1")}
          />
        )}
      </Box>
    </Modal>
  );
};

export default URLModal;

import React from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const JobNumber = ({
  jobNumber,
  setJobNumber,
  isMobile,
  handleJobNumberChange,
  assignJobNumberDisabled,
  assignJobNumberText,
  setAssignJobNumberText,
  jobType,
  client,
  multipleDays,
  firebase,
  jobData,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginTop: 15,
      }}
    >
      <TextField
        fullWidth
        label="Job Number"
        value={jobNumber || ""}
        error={jobNumber ? false : true}
        onChange={handleJobNumberChange}
        variant="filled"
        InputProps={{ disableUnderline: true }}
        sx={{ marginTop: 2, marginRight: 2 }}
      />
      <Button
        variant="outlined"
        style={{ height: 50, width: "100%" }}
        sx={{
          marginTop: 2,
          marginLeft: 2,
          padding: 0,
          fontSize: isMobile ? "0.7em" : "0.9em",
        }}
        disabled={assignJobNumberDisabled}
        onClick={async () => {
          const jobYear = new Date(jobData.bookedStart).getFullYear();
          setAssignJobNumberText(<CircularProgress size={35} />);
          const res = await fetch(
            "https://us-central1-its-connect-main.cloudfunctions.net/getLatestJobNumber",
            {
              method: "POST",
              mode: "cors",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ year: jobYear }),
            }
          ).then(function (res) {
            return res.json();
          });
          setJobNumber(parseInt(res.jobNumber));
          const jtsRaw = jobType.match(/^.*\((-.*)\)/);
          let jts = "";
          if (jtsRaw) {
            jts = jtsRaw[1];
          }
          const jn = `${parseInt(res.jobNumber)}-${client}${
            multipleDays ? `-${multipleDays}` : ""
          }${jts}`;
          firebase
            .firestore()
            .collection("JobData")
            .doc(jobData.id)
            .update({ jobName: jn }, { merge: true });
          firebase
            .firestore()
            .collection("JobData")
            .doc(jobData.id)
            .update({ jobNumber: parseInt(res.jobNumber) }, { merge: true });
          setAssignJobNumberText("Assign Job Number");
        }}
      >
        {assignJobNumberText}
      </Button>
    </div>
  );
};

export default JobNumber;

import React, { useState, useEffect } from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AdvancedTextField from "./GlobalComponents/AdvancedTextField";

// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import getJobName from "./GlobalFunctions/getJobName";

import { useAtom } from "jotai";
import { themeAtom, labelsAtom, labelColoursAtom } from "./-Atoms";

const { DateTime } = require("luxon");

const VideoSchedule = ({ firebase }) => {
  const [videoJobs, setVideoJobs] = useState([]);
  useEffect(() => {
    const today = DateTime.now()
      .setZone("Australia/Sydney")
      .startOf("day")
      .plus({ days: 15 })
      .toUTC()
      .toISO();
    let unsub = firebase
      .firestore()
      .collection("JobData")
      .where("jobTypeFull", "==", "Video (-V)")
      .where("bookedStart", "<=", today)
      .orderBy("bookedStart", "desc")
      .limit(40)
      .onSnapshot((snapshot) => {
        let docs = [];
        snapshot.docs.forEach((doc) => {
          try {
            const data = doc.data();
            data.id = doc.id;
            docs.push(data);
          } catch (e) {
            console.log(e);
          }
        });
        setVideoJobs([...docs]);
      });

    return () => {
      unsub();
    };
  }, [firebase]);

  return (
    <TableContainer
      className="tableFixHeader"
      sx={{ height: "100vh", overflow: "scroll" }}
    >
      <Table stickyHeader className="videoScheduleListTable">
        <TableHead>
          <TableRow>
            <TableCell>Job Name</TableCell>
            <TableCell>Completed</TableCell>
            <TableCell>Shoot Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Deliverables</TableCell>
            <TableCell>Time Estimate</TableCell>
            <TableCell>Notes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {videoJobs &&
            videoJobs.map((job, index) => {
              console.log(job.idLabels);
              return (
                <VideoTableRow job={job} index={index} firebase={firebase} />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const VideoTableRow = ({ job, index, firebase }) => {
  const [theme] = useAtom(themeAtom);
  const [labels] = useAtom(labelsAtom);
  const [labelColours] = useAtom(labelColoursAtom);

  const [deliverables, setDeliverables] = useState(job.deliverables || "");
  const [videoTimeEstimate, setVideoTimeEstimate] = useState(
    job.videoTimeEstimate || ""
  );
  const [videoNotes, setVideoNotes] = useState(job.videoNotes || "");

  useEffect(() => {
    setVideoNotes(job.videoNotes);
    setVideoTimeEstimate(job.videoTimeEstimate);
  }, [job]);

  return (
    <TableRow
      key={`${index}_${getJobName(job)}`}
      sx={{
        opacity:
          job.idLabels.includes("5e6881d6af988c41f2bf6e12") ||
          job.idLabelsPermanent.includes("5e6881d6af988c41f2bf6e12") ||
          !job.jobNumber
            ? 0.8
            : 1,
        background: parseBGColour(job, theme),
      }}
    >
      <TableCell>{getJobName(job)}</TableCell>
      <TableCell>
        <Checkbox
          checked={
            job.idLabels.includes("5e6881d6af988c41f2bf6e12") ||
            job.idLabelsPermanent.includes("5e6881d6af988c41f2bf6e12") ||
            false
          }
          onChange={(e, checked) => {}}
        />
      </TableCell>

      <TableCell>
        {DateTime.fromISO(job.bookedStart).toFormat("dd-MM-yyyy")}
      </TableCell>

      <TableCell>
        <div style={{ width: 300 }}>
          {job.idLabels.map((labelID) => (
            <LabelDiv
              labelID={labelID}
              labels={labels}
              labelColours={labelColours}
              theme={theme}
            />
          ))}
        </div>
      </TableCell>

      <TableCell>
        <AdvancedTextField
          firebase={firebase}
          field="deliverables"
          collectionName="JobData"
          docId={job.id}
          label="Deliverables"
          variant="filled"
          size="small"
          value={deliverables}
          setValue={setDeliverables}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          sx={{ width: 350 }}
          multiline
        />
      </TableCell>

      <TableCell>
        <AdvancedTextField
          firebase={firebase}
          field="videoTimeEstimate"
          collectionName="JobData"
          docId={job.id}
          label="Time Estimate"
          variant="filled"
          size="small"
          value={videoTimeEstimate}
          setValue={setVideoTimeEstimate}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          sx={{ width: 160 }}
          multiline
        />
      </TableCell>
      <TableCell>
        <AdvancedTextField
          firebase={firebase}
          field="videoNotes"
          collectionName="JobData"
          docId={job.id}
          label="Notes"
          variant="filled"
          size="small"
          value={videoNotes}
          setValue={setVideoNotes}
          InputProps={{ disableUnderline: true }}
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          sx={{ width: 350 }}
          multiline
        />
      </TableCell>
    </TableRow>
  );
};

const LabelDiv = ({ labelID, labels, labelColours, theme }) => {
  const theLabel = labels.find((obj) => obj.id === labelID);
  return (
    <>
      {theLabel.name.includes("VIDEO") ? (
        <div
          style={{
            height: 25,
            backgroundColor: labelColours[theLabel.color][theme],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: 5,
            borderRadius: 5,
            fontWeight: "bold",
            fontSize: "0.8rem",
          }}
        >
          {theLabel.name}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default VideoSchedule;

const parseBGColour = (job, theme) => {
  if (!job.jobNumber)
    return theme === "dark"
      ? "rgba(180, 10, 50, 0.1)"
      : "rgba(180, 10, 50, 0.15)";
  if (
    job.idLabels.includes("5e6881d6af988c41f2bf6e12") ||
    job.idLabelsPermanent.includes("5e6881d6af988c41f2bf6e12")
  )
    // if (job.videoComplete || job.idLabels.includes("5e6881d6af988c41f2bf6e12"))
    return theme === "dark"
      ? "rgba(10, 180, 50, 0.1)"
      : "rgba(10, 180, 50, 0.15)";
  return "";
};

import React from "react";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Box from "@mui/material/Box";

const SuccessFullScreen = ({ successVisible, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: successVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <CheckCircleOutlineIcon color="success" style={loading.progress} />

        <span style={{ marginTop: 50 }}>Email Sent!</span>
      </Box>
    </div>
  );
};

export default SuccessFullScreen;

import React from "react";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";

const KanbanJobTypeSelector = ({
  newPostJobJobType,
  setNewPostJobJobType,
  newPostJobMultipleDays,
  setNewPostJobMultipleDays,
}) => {
  const jobTypes = [
    "Retouching (-R)",
    "Video Editing (-E)",
    "Design (-D)",
    "Misc (-M)",
  ];
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: 0,
      }}
    >
      <FormControl fullWidth sx={{ marginTop: 2, marginRight: 2, width: 268 }}>
        <InputLabel id="job-type">Job Type</InputLabel>
        <Select
          labelId="job-type-select"
          value={newPostJobJobType || ""}
          label="Job Type"
          error={newPostJobJobType ? false : true}
          onChange={(e) => {
            setNewPostJobJobType(e.target.value);
          }}
        >
          {jobTypes.map((s) => (
            <MenuItem key={s} value={s}>
              {s}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        label="Multiple Days"
        value={newPostJobMultipleDays || ""}
        onChange={(e) => {
          setNewPostJobMultipleDays(e.target.value);
        }}
        variant="outlined"
        sx={{ marginTop: 2, marginLeft: 2, width: 268 }}
      />
    </div>
  );
};

export default KanbanJobTypeSelector;

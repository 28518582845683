import { publish } from "./customEvent";
import { isDockHiddenAtom } from "../-Atoms";
import { useAtom } from "jotai";

function DockFunctions() {
  const [isDockHidden] = useAtom(isDockHiddenAtom);
  const showDock = () => {
    if (!isDockHidden) {
      publish("showDock");
    }
  };
  const hideDock = () => {
    publish("hideDock");
  };
  return { showDock, hideDock };
}

export default DockFunctions;

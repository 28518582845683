import React from "react";

import Button from "@mui/material/Button";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
const ErrorFullScreen = ({ errorVisible, errorMessage, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: errorVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <ErrorOutlineIcon color="error" style={loading.progress} />

        <span style={{ marginTop: 40 }}>{errorMessage}</span>
        <Button
          onClick={() => window.location.reload()}
          style={{ marginTop: 30 }}
          variant="outlined"
        >
          Retry
        </Button>
      </Box>
    </div>
  );
};

export default ErrorFullScreen;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Button from "@mui/material/Button";
import { InputAdornment, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";
// import Typography from "@mui/material/Typography";

import Logo from "../Login/Logo";

const NewStaffFormOld = ({ firebase }) => {
  const [loading, setLoading] = useState(true);
  const { uid } = useParams();
  const getUserDetails = firebase.functions().httpsCallable("getUserDetails");
  const updateUserPassword = firebase
    .functions()
    .httpsCallable("updateUserPassword");
  const sendEmail = firebase.functions().httpsCallable("sendEmail");

  const [userData, setUserData] = useState({});
  const windowSize = useWindowSize();

  const [submitted, setSubmitted] = useState(false);

  const [submitButtonText, setSubmitButtonText] = useState("SUBMIT");

  const [password, setPassword] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [preferredName, setPreferredName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [employmentBasis, setEmploymentBasis] = useState("");
  const [nextOfKin1Name, setNextOfKin1Name] = useState("");
  const [nextOfKin1Phone, setNextOfKin1Phone] = useState("");
  const [nextOfKin2Name, setNextOfKin2Name] = useState("");
  const [nextOfKin2Phone, setNextOfKin2Phone] = useState("");
  const [taxFileNumber, setTaxFileNumber] = useState("");
  const [salaryHourlyRate, setSalaryHourlyRate] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankAccountName, setBankAccountName] = useState("");
  const [BSB, setBSB] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [superannuationProvider, setSuperannuationProvider] = useState("");
  const [superannuationMemberNumber, setSuperannuationMemberNumber] =
    useState("");
  const [superannuationFundUSI, setSuperannuationFundUSI] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [postcode, setPostcode] = useState("");
  const [suburb, setSuburb] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    const init = async () => {
      const data = await getUserDetails({ uid: uid });
      if (data) {
        setUserData(data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    };
    init();
    // eslint-disable-next-line
  }, [firebase, uid]);
  useEffect(() => {
    setFirstName(userData.firstName);
    setLastName(userData.lastName);
    setEmail(userData.email);
  }, [userData]);
  const containerStyle = {
    display: submitted ? "none" : "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  };
  const submittedContainerStyle = {
    display: submitted ? "flex" : "none",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
  };
  // eslint-disable-next-line
  const [textFieldStyleWidth, setTextFieldStyleWidth] = useState(
    windowSize.width < 900 ? windowSize.width / 2 - 40 : 200
  );
  const textFieldStyle = {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 20,
    width: textFieldStyleWidth,
  };
  const textFieldStyleEmail = {
    ...textFieldStyle,
    width: textFieldStyleWidth * 2 + 20,
  };
  const employmentBasisStyle = {
    display: "flex",
    justifyContent: "center",
    width: textFieldStyleWidth * 2 + 20,
    marginTop: 40,
    marginBottom: 10,
  };
  const submitForm = async () => {
    let error = false;
    if (!password) {
      error = true;
      alert("Fill in password");
    }
    if (!firstName) {
      error = true;
      alert("Fill in first name");
    }
    if (!dateOfBirth) {
      error = true;
      alert("Fill in date of birth");
    }
    if (!phone) {
      error = true;
      alert("Fill in phone");
    }
    if (!employmentBasis) {
      error = true;
      alert("Fill in employment asis");
    }
    if (!nextOfKin1Name) {
      error = true;
      alert("Fill in next of kin 1 name");
    }
    if (!nextOfKin1Phone) {
      error = true;
      alert("Fill in next of kin 1 phone");
    }
    if (!taxFileNumber) {
      error = true;
      alert("Fill in tax file number");
    }
    if (!salaryHourlyRate) {
      error = true;
      alert("Fill in salary hourly rate");
    }
    if (!bankName) {
      error = true;
      alert("Fill in bank name");
    }
    if (!bankAccountName) {
      error = true;
      alert("Fill in bank account name");
    }
    if (!BSB) {
      error = true;
      alert("Fill in BSB");
    }
    if (!bankAccountNumber) {
      error = true;
      alert("Fill in bank account number");
    }
    if (!superannuationProvider) {
      error = true;
      alert("Fill in superannuation provider");
    }
    if (!superannuationMemberNumber) {
      error = true;
      alert("Fill in superannuation member number");
    }
    if (!superannuationFundUSI) {
      error = true;
      alert("Fill in superannuation fund USI");
    }
    if (!streetNumber) {
      error = true;
      alert("Fill in street number");
    }
    if (!streetName) {
      error = true;
      alert("Fill in street name");
    }
    if (!postcode) {
      error = true;
      alert("Fill in postcode");
    }
    if (!suburb) {
      error = true;
      alert("Fill in suburb");
    }
    if (error) {
      // alert("Please fill all required fields");
      return;
    }
    setSubmitButtonText(<CircularProgress />);
    const userObject = {
      firstName,
      lastName,
      dateOfBirth,
      email,
      phoneNumber: phone,
      employmentBasis,
      nextOfKin1Name,
      nextOfKin1Phone,
      nextOfKin2Name,
      nextOfKin2Phone,
      preferredName,
      nickname: preferredName,
      jobTitle,
      taxFileNumber,
      salaryHourlyRate,
      bankName,
      bankAccountName,
      BSB,
      bankAccountNumber,
      superannuationProvider,
      superannuationMemberNumber,
      superannuationFundUSI,
      address: {
        streetNumber,
        streetName,
        postcode,
        suburb,
      },
      notificationTomorrowsJob: true,
      notificationNextDayChangesMe: true,
      notificationSubscriptions: [],
      wwccExpiry: "",
      wwccCheckNumber: "",
      userTheme: "automatic",
      currentlyEmployed: true,
      isAdmin: false,
      isSuperAdmin: false,
    };

    await firebase
      .firestore()
      .collection("StaffData")
      .doc(userData.id)
      .update(userObject, { merge: true });
    await updateUserPassword({ uid, password });
    setSubmitted(true);
    await sendEmail({
      emailBody: `<style>
			body {
				font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
					Helvetica, Arial, "Lucida Grande", sans-serif;
			}
		</style>

		<body>
			<p>Hello Jodie,</p>
			<p>${firstName} ${lastName} has completed the Employee Details Form</p>
			<span>Thanks!</span>
			<br>
			<span>ITS Team</span>
		</body>`,
      fromEmail: "production@imagesthatsell.com.au",
      toEmails: "jodie@imagesthatsell.com.au",
      ccEmails: [],
      bccEmails: [],
      replyToEmails: [],
      subject: `${firstName} ${lastName} has completed the Employee Details Form`,
      attachments: [],
    }).then((res) => {
      console.log(res);
    });
    setTimeout(() => {
      window.location.href = "https://staff.imagesthatsell.com.au";
    }, 5000);
  };

  //   const show = false;

  // const [activeStep, setActiveStep] = useState(0);

  // const isStepFailed = (step) => {
  //   // return step === 1;
  // };
  // const steps = [
  //   "Personal Info",
  //   "Next of Kin",
  //   "Bank Details",
  //   "Employment Details",
  //   "Superannuation Details",
  //   "Password",
  // ];

  return (
    <>
      <LoadingFullScreen
        loadingVisible={loading}
        isDarkMode={false}
        darkMode="#f4f5f7"
        lightMode="#f4f5f7"
      />
      {/* <Box sx={{ width: "60%" }} >
        <Stepper activeStep={1}>
          {steps.map((label, index) => {
            const labelProps = {};
            if (isStepFailed(index)) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  Alert message
                </Typography>
              );
              labelProps.error = true;
            }

            return (
              <Step key={label}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box> */}

      {!loading ? (
        //  {show ? (
        <div style={containerStyle}>
          <Logo />
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              marginLeft: 10,
              marginRight: 10,
              fontSize: 14,
            }}
          >
            <span>IMAGES THAT SELL EMPLOYMENT DETAILS FORM</span>
            <span>Please fill out the below details an press "SUBMIT"</span>
            <span>*required</span>
          </div>
          <div>
            <TextField
              variant="outlined"
              label="First Name *"
              value={firstName || ""}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              style={textFieldStyle}
            />
            <TextField
              variant="outlined"
              label="Last Name *"
              value={lastName || ""}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              style={textFieldStyle}
            />
          </div>
          <div>
            <TextField
              variant="outlined"
              label="Job Title"
              value={jobTitle || ""}
              onChange={(e) => {
                setJobTitle(e.target.value);
              }}
              style={textFieldStyle}
            />
            <TextField
              variant="outlined"
              label="Phone Number *"
              value={phone || ""}
              onChange={(e) => {
                let val = e.target.value;
                val.replace(/^04/, "4");
                setPhone(val);
              }}
              style={textFieldStyle}
            />
          </div>
          <div>
            <TextField
              variant="outlined"
              label="Nickname (If Preferred)"
              value={preferredName || ""}
              onChange={(e) => {
                setPreferredName(e.target.value);
              }}
              style={textFieldStyle}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date of Birth *"
                inputFormat="dd/MM/yyyy"
                value={dateOfBirth || null}
                onChange={(e) => {
                  setDateOfBirth(new Date(e).toISOString());
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{
                      ...textFieldStyle,
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div>
            <TextField
              variant="outlined"
              label="Email"
              value={email || ""}
              style={textFieldStyleEmail}
              disabled
            />
          </div>
          <>
            <h4 style={{ marginTop: 30, marginBottom: 10 }}>Address</h4>
            <div>
              <TextField
                variant="outlined"
                label="Street Number *"
                value={streetNumber || ""}
                onChange={(e) => {
                  setStreetNumber(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Street Name *"
                value={streetName || ""}
                onChange={(e) => {
                  setStreetName(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                label="Postcode *"
                value={postcode || ""}
                onChange={(e) => {
                  setPostcode(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Suburb *"
                value={suburb || ""}
                onChange={(e) => {
                  setSuburb(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <div style={employmentBasisStyle}>
              <FormControl>
                <FormLabel>Employment Basis *</FormLabel>
                <RadioGroup
                  row
                  value={employmentBasis || ""}
                  onChange={(e) => {
                    setEmploymentBasis(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="permanent"
                    control={<Radio />}
                    label="Permanent"
                  />
                  <FormControlLabel
                    value="part-time"
                    control={<Radio />}
                    label="Part-Time"
                  />
                  <FormControlLabel
                    value="casual"
                    control={<Radio />}
                    label="Casual"
                  />
                  <FormControlLabel
                    value="freelancer"
                    control={<Radio />}
                    label="Freelancer"
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <h4 style={{ marginTop: 30, marginBottom: 10 }}>
              Next of Kin Details
            </h4>
            <div>
              <TextField
                variant="outlined"
                label="Next of Kin 1 Name *"
                value={nextOfKin1Name || ""}
                onChange={(e) => {
                  setNextOfKin1Name(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Next of Kin 1 Phone *"
                value={nextOfKin1Phone || ""}
                onChange={(e) => {
                  setNextOfKin1Phone(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                label="Next of Kin 2 Name"
                value={nextOfKin2Name || ""}
                onChange={(e) => {
                  setNextOfKin2Name(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Next of Kin 2 Phone"
                value={nextOfKin2Phone || ""}
                onChange={(e) => {
                  setNextOfKin2Phone(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>

            <h4 style={{ marginTop: 30, marginBottom: 10 }}>Tax Details</h4>
            <div>
              <TextField
                variant="outlined"
                label="Tax File Number *"
                value={taxFileNumber || ""}
                onChange={(e) => {
                  setTaxFileNumber(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Salary / Hourly Rate *"
                value={salaryHourlyRate || ""}
                onChange={(e) => {
                  setSalaryHourlyRate(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <h4 style={{ marginTop: 30, marginBottom: 10 }}>Bank Details</h4>
            <div>
              <TextField
                variant="outlined"
                label="Bank Name *"
                value={bankName || ""}
                onChange={(e) => {
                  setBankName(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Account Name *"
                value={bankAccountName || ""}
                onChange={(e) => {
                  setBankAccountName(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                label="BSB *"
                value={BSB || ""}
                onChange={(e) => {
                  setBSB(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Account Number *"
                value={bankAccountNumber || ""}
                onChange={(e) => {
                  setBankAccountNumber(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <h4 style={{ marginTop: 30, marginBottom: 10 }}>
              Superannuation Fund
            </h4>
            <div>
              <TextField
                variant="outlined"
                label="Provider *"
                value={superannuationProvider || ""}
                onChange={(e) => {
                  setSuperannuationProvider(e.target.value);
                }}
                style={textFieldStyle}
              />
              <TextField
                variant="outlined"
                label="Member Number *"
                value={superannuationMemberNumber || ""}
                onChange={(e) => {
                  setSuperannuationMemberNumber(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <div>
              <TextField
                variant="outlined"
                label="Fund USI *"
                value={superannuationFundUSI || ""}
                onChange={(e) => {
                  setSuperannuationFundUSI(e.target.value);
                }}
                style={textFieldStyle}
              />
            </div>
            <div
              style={{
                marginTop: 60,
                marginBottom: 50,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p>Password will be used to log into the ITS Backend</p>
              <TextField
                variant="outlined"
                label="Password *"
                type={showPassword ? "text" : "password"}
                value={password || ""}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                style={{ width: 320 }}
              />
              <Button
                style={{ marginTop: 30, width: 320, height: 50 }}
                variant={"outlined"}
                onClick={submitForm}
              >
                {submitButtonText}
              </Button>
            </div>
          </>
        </div>
      ) : null}
      <div style={submittedContainerStyle}>
        <Logo />
        <p>Thank you for submitting the form!</p>
        <p>You will redirected to the ITS backend in a shortly</p>
      </div>
    </>
  );
};

export default NewStaffFormOld;
const LoadingFullScreen = ({ loadingVisible, lightMode }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: loadingVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 10000,
        backgroundColor: lightMode,
      }}
    >
      <Box sx={loading.box}>
        <CircularProgress style={loading.progress} />
      </Box>
    </div>
  );
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

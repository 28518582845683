import React, { useState } from "react";
// import { addDays, format } from "date-fns";
// import { Calendar as CalendarIcon } from "lucide-react";
// import { DateRange } from "react-day-picker";

// import { Button } from "../components/ui/button.jsx";
import { Calendar } from "../components/ui/calendar";
// import {
//   Popover,
//   PopoverContent,
//   PopoverTrigger,
// } from "../components/ui/popover";

export function DatePickerWithRange({ setDateRange }) {
  const [date, setDate] = useState({
    from: new Date(),
    to: new Date(),
  });

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <Calendar
        initialFocus
        mode="range"
        defaultMonth={date?.from}
        selected={date}
        onSelect={(e) => {
          setDateRange(e);
          setDate(e);
        }}
        numberOfMonths={2}
      />
      {/* <Popover>
        <PopoverTrigger asChild>
          <Button id="date" variant={"outline"}>
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={setDate}
            numberOfMonths={2}
          />
        </PopoverContent>
      </Popover> */}
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Autocomplete from "@mui/material/Autocomplete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { saveAs } from "file-saver";
import Switch from "@mui/material/Switch";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { useAtom } from "jotai";
import { isElectronAtom, themeAtom, isAdminAtom } from "../../-Atoms";

import { v4 as uuidv4 } from "uuid";

import MarkdownNotesEditorClient from "../../ClientDatabase/MarkdownNotesEditorClient";
import ClientTabBreakdownItem from "../ClientTabBreakdownItem";

import "../../App.css";

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

let deleteSupplySizeTimeout;
let deleteContactTimeout;
let deleteTimeout;
let arrayRemove;

const ClientTab = ({
  jobClientData,
  firebase,
  theme,
  soloClose,
  modalWidth,
  modalClientData,
  kanbanView,
}) => {
  const [clientData, setClientData] = useState(jobClientData);
  const [themeA] = useAtom(themeAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [canEdit, setCanEdit] = useState(isAdmin);

  useEffect(() => {
    setCanEdit(isAdmin);
  }, [isAdmin, setCanEdit]);

  const [isDigitalBackground, setIsDigitalBackground] = useState(
    clientData.isDigitalBackground || false
  );

  useEffect(() => {
    let unsub;
    if (modalClientData && modalClientData.id) {
      unsub = firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(modalClientData.id)
        .onSnapshot((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setClientData(data);
        });
    }
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
    // eslint-disable-next-line
  }, []);

  const updateFS = debounce(async (id, field, value) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  }, 800);

  const titleStyle = {
    marginLeft: 10,
    marginTop: 10,
    color: themeA === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const [attachments, setAttachments] = useState([]);
  const [suppliedLinks, setSuppliedLinks] = useState([]);

  useEffect(() => {
    if (clientData.attachments) {
      setAttachments([...clientData.attachments]);
    } else {
      setAttachments([]);
    }

    if (clientData?.suppliedLinks) {
      const reverse = clientData?.suppliedLinks.reverse();
      setSuppliedLinks(reverse);
    }
  }, [clientData]);

  const [imageBreakdown, setImageBreakdown] = useState(
    clientData.imageBreakdown || []
  );
  useEffect(() => {
    setImageBreakdown(clientData.imageBreakdown || []);
  }, [clientData]);

  const addNewBreakdownItem = () => {
    const newitemObj = {
      id: uuidv4(),
      description: "",
      jobTypesFilter: [],
      expectedPerHour: "",
      costPerFile: "",
      existingItem: true,
    };
    imageBreakdown.push(newitemObj);
    setImageBreakdown([...imageBreakdown]);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(clientData.id)
      .update({ imageBreakdown: imageBreakdown }, { merge: true })
      .catch((e) => console.log(e));
  };
  const [showRemoveBreakdownItem, setShowRemoveBreakdownItem] = useState(false);
  const toggleRemoveBreakdownItem = () => {
    setShowRemoveBreakdownItem(true);
    setTimeout(() => {
      setShowRemoveBreakdownItem(false);
    }, 6000);
  };

  return (
    <div
      style={{
        // height: "100vh",
        // overflow: "scroll",
        width: !kanbanView ? "100%" : modalWidth,
        padding: soloClose ? 20 : 0,
        overflowY: soloClose ? "auto" : "",
        height: soloClose ? "100vh" : "",
      }}
    >
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 20 }}
      >
        <p style={{ margin: 0, fontSize: "1.2rem", fontWeight: 800 }}>
          {clientData.brand}
        </p>
      </div>

      <Contacts
        client={clientData}
        updateFS={updateFS}
        firebase={firebase}
        canEdit={canEdit}
        theme={theme}
      />
      <div style={titleStyle}>
        <span>Image Breakdown</span>
        <div>
          <IconButton onClick={toggleRemoveBreakdownItem}>
            <RemoveIcon />
          </IconButton>
          <IconButton onClick={addNewBreakdownItem}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: -10,
          gap: 2,
          paddingTop: 10,
          paddingBottom: 30,
        }}
      >
        {imageBreakdown &&
          imageBreakdown.map((bi, index) => (
            <ClientTabBreakdownItem
              key={bi.id}
              item={bi}
              items={imageBreakdown}
              setItems={setImageBreakdown}
              index={index}
              showRemoveBreakdownItem={showRemoveBreakdownItem}
              firebase={firebase}
              clientData={clientData}
              updateFS={updateFS}
            />
          ))}
      </div>
      <Invoicing
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
        theme={theme}
      />
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <FormGroup row={true} sx={{ gap: 5 }}>
          <FormControlLabel
            control={
              <Switch
                checked={isDigitalBackground}
                onChange={(e) => {
                  setIsDigitalBackground(e.target.checked);
                  firebase
                    .firestore()
                    .collection("ClientDatabase")
                    .doc(clientData.id)
                    .update({ isDigitalBackground: e.target.checked });
                }}
              />
            }
            label={
              isDigitalBackground ? "Digital Background" : "Organic Background"
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={clientData.noRetouching}
                onChange={(e) => {
                  firebase
                    .firestore()
                    .collection("ClientDatabase")
                    .doc(clientData.id)
                    .update({ noRetouching: e.target.checked });
                }}
              />
            }
            label="No Retouching"
          />
        </FormGroup>
      </div>
      <Production
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
        theme={theme}
      />
      <Notes
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
        theme={theme}
        modalWidth={modalWidth}
      />
      <Files
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        attachments={attachments}
        setAttachments={setAttachments}
        dbx={dbx}
        suppliedLinks={suppliedLinks}
        canEdit={canEdit}
        theme={theme}
      />
      <Checklists
        client={clientData}
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
      />
    </div>
  );
};

export default ClientTab;

const Contacts = ({ client, updateFS, firebase, canEdit, theme }) => {
  const [showDeleteContact, setShowDeleteContact] = useState(false);

  const handleDeleteClick = () => {
    if (!canEdit) return;
    if (deleteContactTimeout) {
      clearTimeout(deleteContactTimeout);
      deleteContactTimeout = null;
    } else {
      deleteContactTimeout = setTimeout(() => {
        setShowDeleteContact(false);
        deleteContactTimeout = null;
      }, 15000);
    }
    setShowDeleteContact(!showDeleteContact);
  };

  const handleNewClick = () => {
    if (!canEdit) return;
    client?.contacts?.push({
      name: "",
      title: "",
      email: "",
      phone: "",
      isSupply: false,
      isBilling: false,
    });
    updateFS(client?.id, "contacts", client?.contacts);
  };

  const contactsButtons = [
    <Button key="newContactButton" onClick={handleNewClick} size="small">
      New Contact
    </Button>,
    <Button key="deleteContactButton" onClick={handleDeleteClick} size="small">
      Delete Contact
    </Button>,
  ];

  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
      >
        <ButtonGroup size="large">{contactsButtons}</ButtonGroup>
      </div>

      <div
        style={{
          paddingBottom: "10px",
          borderRadius: "10px",
          maxHeight: 500,
          overflow: "auto",
          display: "flex",
        }}
      >
        {client?.contacts?.map((contact, i) => (
          <Contact
            key={`contact-${i}`}
            contact={contact}
            client={client}
            showDeleteContact={showDeleteContact}
            updateFS={updateFS}
            firebase={firebase}
            small={true}
            canEdit={canEdit}
            theme={theme}
          />
        ))}
      </div>
    </>
  );
};

const Contact = ({
  contact,
  client,
  showDeleteContact,
  updateFS,
  firebase,
  small,
  canEdit,
  theme,
}) => {
  arrayRemove = firebase.firestore.FieldValue.arrayRemove;
  const textFieldStyle = {
    margin: "10px",
    minWidth: "220px",
  };
  const [name, setName] = useState(contact.name);
  const [title, setTitle] = useState(contact.title);
  const [email, setEmail] = useState(contact.email);
  const [phone, setPhone] = useState(contact.phone);
  const [isBilling, setIsBilling] = useState(contact.isBilling);
  const [isSupply, setIsSupply] = useState(contact.isSupply);

  useEffect(() => {
    setName(contact.name);
    setTitle(contact.title);
    setEmail(contact.email);
    setPhone(contact.phone);
    setIsBilling(contact.isBilling);
    setIsSupply(contact.isSupply);
  }, [contact]);

  const handleSupplyChange = () => {
    if (!canEdit) return;
    const tmp = isSupply;
    setIsSupply(!tmp);
    contact.isSupply = !tmp;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleBillingChange = () => {
    if (!canEdit) return;
    const bil = isBilling;
    setIsBilling(!bil);
    contact.isBilling = !bil;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleNameChange = (e) => {
    if (!canEdit) return;
    setName(e.target.value);
    contact.name = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleTitleChange = (e) => {
    if (!canEdit) return;
    setTitle(e.target.value);
    contact.title = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleEmailChange = (e) => {
    if (!canEdit) return;
    setEmail(e.target.value);
    contact.email = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handlePhoneChange = (e) => {
    if (!canEdit) return;
    setPhone(e.target.value);
    contact.phone = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleDeleteContact = () => {
    if (!canEdit) return;
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ contacts: arrayRemove(contact) });
  };
  return (
    <div
      style={{
        background:
          theme === "dark"
            ? "rgba(255, 255, 255, 0.05)"
            : "rgba(0, 0, 0, 0.02)",
        borderRadius: "10px",
        paddingTop: "10px",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxWidth: "240px",
      }}
    >
      {showDeleteContact ? (
        <div
          style={{
            background: "#00000015",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <IconButton onClick={handleDeleteContact}>
            <DeleteIcon
              style={{
                color: "#ff0033",
                width: 60,
                height: 60,
                filter: "drop-shadow(4px 4px 3px #00000060)",
              }}
            />
          </IconButton>
        </div>
      ) : null}
      <TextField
        InputProps={{ disableUnderline: true }}
        variant="filled"
        label="Name"
        value={name}
        onChange={handleNameChange}
        style={textFieldStyle}
        size={small ? "small" : "medium"}
        disabled={!canEdit}
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        variant="filled"
        label="Title"
        value={title}
        onChange={handleTitleChange}
        style={textFieldStyle}
        size={small ? "small" : "medium"}
        disabled={!canEdit}
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        variant="filled"
        label="Email"
        value={email}
        onChange={handleEmailChange}
        style={textFieldStyle}
        size={small ? "small" : "medium"}
        disabled={!canEdit}
      />
      <TextField
        InputProps={{ disableUnderline: true }}
        variant="filled"
        label="Phone"
        value={phone}
        onChange={handlePhoneChange}
        style={textFieldStyle}
        size={small ? "small" : "medium"}
        disabled={!canEdit}
      />
      <div>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            paddingBottom: "10px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isSupply}
                disabled={!canEdit}
                onChange={handleSupplyChange}
              />
            }
            label="Supply"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isBilling}
                disabled={!canEdit}
                onChange={handleBillingChange}
              />
            }
            label="Billing"
          />
        </FormGroup>
      </div>
    </div>
  );
};

const Files = ({
  client,
  firebase,
  updateFS,
  attachments,
  setAttachments,
  dbx,
  suppliedLinks,
  canEdit,
  theme,
}) => {
  // eslint-disable-next-line
  const [filesUploading, setFilesUploading] = useState(false);

  const deleteAttachment = (item, index) => {
    if (!canEdit) return;
    const splice = [...attachments];
    splice.splice(index, 1);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ attachments: splice || "" }, { merge: true });
    setAttachments([...splice]);
  };

  const onDrop = async (e) => {
    if (!canEdit) return;
    // console.log(e[0]);
    // if (!canEdit) return;
    // setStopModalClose(true);
    setFilesUploading(true);
    let newAttachments = [];
    for (let i = 0; i < e.length; i++) {
      const file = e[i];
      if (file.size > 150000000) {
        alert("file must be under 150MB");
      } else {
        let url;
        const res = await dbx
          .filesUpload({
            path: `/-Client Attachments/${client?.id}/${file.name}`,
            autorename: false,
            mode: "add",
            mute: false,
            contents: file,
          })
          .catch((e) => {
            // setStopModalClose(false);
            setFilesUploading(false);
            console.log(e);
          });

        await dbx
          .sharingListSharedLinks({
            path: res.result.path_display,
            direct_only: true,
          })
          .then((data) => {
            url = data.result.links[0].url;
          })
          .catch(async (e) => {
            console.log(e);
            await dbx
              .sharingCreateSharedLinkWithSettings({
                path: res.result.path_display,
                settings: {
                  requested_visibility: "public",
                  audience: "public",
                  access: "viewer",
                },
              })
              .then((data) => {
                url = data.result.url;
              })
              .catch((e) => {
                // setStopModalClose(false);
                setFilesUploading(false);
                console.log(e);
              });
          });
        if (url) newAttachments.push({ fileName: file.name, url: url });
      }
    }
    // console.log(newAttachments);
    if (newAttachments) {
      newAttachments.forEach((a) => {
        attachments.push(a);
      });
      firebase
        .firestore()
        .collection("ClientDatabase")
        .doc(client?.id)
        .update({ attachments: attachments || "" }, { merge: true });
      setAttachments([...attachments, ...newAttachments]);
    }
    setTimeout(() => {
      // setStopModalClose(false);
      setFilesUploading(false);
    }, 500);
  };

  const { getRootProps } = useDropzone({ onDrop });

  return (
    <div
      style={{
        marginTop: 20,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
        }}
        {...getRootProps()}
      >
        <div
          style={{
            marginLeft: 5,
            marginBottom: 10,
            color:
              theme === "dark" ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.6)",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: 400,
            fontSize: "1.1rem",
            lineHeight: "1.4375em",
            letterSpacing: "0.00938em",
          }}
        >
          Attachments
        </div>
        <div
          style={{
            marginLeft: 2,
            width: "95%",
            height: 250,
            background:
              theme === "dark" ? "rgba(255,255,255,0.01)" : "rgba(0,0,0,0.01)",
            border: `1px solid rgba(${
              theme === "dark" ? "255,255,255,0.7" : "25,25,25,0.3"
            })`,
            borderRadius: 5,
            overflowY: "auto",
            position: "relative",
          }}
        >
          <div
            style={{
              background: "rgba(0, 0, 0, 0.05)",
              width: "100%",
              height: "100%",
              display: filesUploading ? "block" : "none",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </div>
          </div>
          {attachments &&
            attachments.map((a, index) => (
              <Attachment
                key={`${a.fileName}-${a.url}-${index}`}
                index={index}
                attachment={a}
                deleteAttachment={deleteAttachment}
                canEdit={canEdit}
              />
            ))}
        </div>
      </div>
      <div
        style={{
          marginTop: 10,
          marginLeft: 5,
          marginBottom: 10,
          color: theme === "dark" ? "rgba(255,255,255,0.8)" : "rgba(0,0,0,0.6)",
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          fontWeight: 400,
          fontSize: "1.1rem",
          lineHeight: "1.4375em",
          letterSpacing: "0.00938em",
        }}
      >
        Supply Links
      </div>
      <div
        style={{
          marginLeft: 2,
          width: "95%",
          height: 250,
          background:
            theme === "dark" ? "rgba(255,255,255,0.01)" : "rgba(0,0,0,0.01)",
          border: `1px solid rgba(${
            theme === "dark" ? "255,255,255,0.7" : "25,25,25,0.3"
          })`,
          borderRadius: 5,
          overflowY: "auto",
          position: "relative",
        }}
      >
        <div
          style={{
            background: "rgba(0, 0, 0, 0.05)",
            width: "100%",
            height: "100%",
            display: filesUploading ? "block" : "none",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress />
          </div>
        </div>
        {suppliedLinks &&
          suppliedLinks.map((s, index) => (
            <div
              style={{
                fontSize: "0.9rem",
              }}
              key={`suppliedLinks-${s.jobNumber}-${index}`}
            >
              <p>
                {s.jobNumber} -{" "}
                <a href={s.url} target="_blank" rel="noreferrer">
                  Link
                </a>
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

const Attachment = ({ attachment, index, deleteAttachment, canEdit }) => {
  return (
    <div
      style={{
        margin: 5,
        padding: 4,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 5,
        borderBottom: "1px solid rgba(25,25,25,0.3)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <a href={attachment.url} rel="noreferrer" target="_blank">
        {attachment.fileName}
      </a>
      <IconButton
        onClick={() => {
          if (!canEdit) return;

          deleteAttachment(attachment, index);
        }}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  );
};

const Invoicing = ({ client, firebase, updateFS, canEdit, theme }) => {
  const [PSF, setPSF] = useState(client?.PSF);
  // console.log(PSF);
  const [paymentTerms, setPaymentTerms] = useState(client?.paymentTerms);
  const [turnAround, setTurnAround] = useState(client?.turnAround);
  // console.log(client);

  //   const [fullDayHours, setFullDayHours] = useState(client?.fullDayRate?.time);
  const [fullDayLunch, setFullDayLunch] = useState(client?.fullDayRate?.lunch);
  const [fullDayImages, setFullDayImages] = useState(
    client?.fullDayRate?.images
  );
  const [fullDayLooks, setFullDayLooks] = useState(client?.fullDayRate?.looks);
  const [fullDayAngles, setFullDayAngles] = useState(
    client?.fullDayRate?.angles
  );

  //   const [halfDayHours, setHalfDayHours] = useState(client?.halfDayRate?.time);
  const [halfDayImages, setHalfDayImages] = useState(
    client?.halfDayRate?.images
  );
  const [halfDayLooks, setHalfDayLooks] = useState(client?.halfDayRate?.looks);
  const [halfDayAngles, setHalfDayAngles] = useState(
    client?.halfDayRate?.angles
  );

  //   const [contentFullDayHours, setContentFullDayHours] = useState(
  //     client?.contentFullDayRate?.time
  //   );
  const [contentFullDayLunch, setContentFullDayLunch] = useState(
    client?.contentFullDayRate?.lunch
  );
  const [contentFullDayImages, setContentFullDayImages] = useState(
    client?.contentFullDayRate?.images
  );
  const [contentFullDayLooks, setContentFullDayLooks] = useState(
    client?.contentFullDayRate?.looks
  );
  const [contentFullDayAngles, setContentFullDayAngles] = useState(
    client?.contentFullDayRate?.angles
  );
  //   const [contentHalfDayHours, setContentHalfDayHours] = useState(
  //     client?.contentHalfDayRate?.time
  //   );
  const [contentHalfDayLooks, setContentHalfDayLooks] = useState(
    client?.contentHalfDayRate?.looks
  );
  const [contentHalfDayAngles, setContentHalfDayAngles] = useState(
    client?.contentHalfDayRate?.angles
  );
  const [contentHalfDayImages, setContentHalfDayImages] = useState(
    client?.contentHalfDayRate?.images
  );

  useEffect(() => {
    setPSF(client?.PSF);
    setPaymentTerms(client?.paymentTerms);
    setTurnAround(client?.turnAround);

    // setFullDayHours(client?.fullDayRate?.time);
    setFullDayLunch(client?.fullDayRate?.lunch);
    setFullDayImages(client?.fullDayRate?.images);
    setFullDayLooks(client?.fullDayRate?.looks);
    setFullDayAngles(client?.fullDayRate?.angles);

    // setHalfDayHours(client?.halfDayRate?.time);
    setHalfDayImages(client?.halfDayRate?.images);
    setHalfDayLooks(client?.halfDayRate?.looks);
    setHalfDayAngles(client?.halfDayRate?.angles);

    // setContentFullDayHours(client?.contentFullDayRate?.time);
    setContentFullDayLunch(client?.contentFullDayRate?.lunch);
    setContentFullDayImages(client?.contentFullDayRate?.images);
    setContentFullDayLooks(client?.contentFullDayRate?.looks);
    setContentFullDayAngles(client?.contentFullDayRate?.angles);

    // setContentHalfDayHours(client?.contentHalfDayRate?.time);
    setContentHalfDayImages(client?.contentHalfDayRate?.images);
    setContentHalfDayLooks(client?.contentHalfDayRate?.looks);
    setContentHalfDayAngles(client?.contentHalfDayRate?.angles);
  }, [client]);

  const handlePSFChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/%+/g, "");
    val = val.replace(/[a-zA-Z]+/g, "");
    if (!val) {
      val = 0;
    }
    setPSF(parseInt(val));
    updateFS(client?.id, "PSF", parseInt(val));
  };
  const handlePaymentTermsChange = (e) => {
    if (!canEdit) return;
    setPaymentTerms(e.target.value);
    updateFS(client?.id, "paymentTerms", e.target.value);
  };
  const handleTurnAroundChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    setTurnAround(val);
    updateFS(client?.id, "turnAround", val);
  };
  //   const handleFullDayHoursChange = (e) => {
  //     let val = e.target.value;
  //     val = val.replace(/[a-zA-Z]+/g, "");
  //     val = parseInt(val);
  //     if (!val) {
  //       val = "";
  //     }
  //     setFullDayHours(val);
  //     firebase
  //       .firestore()
  //       .collection("ClientDatabase")
  //       .doc(client?.id)
  //       .update({ "fullDayRate.time": val }, { merge: true });
  //   };
  const handleFullDayLunchChange = (e) => {
    if (!canEdit) return;
    setFullDayLunch(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "fullDayRate.lunch": e.target.value }, { merge: true });
  };
  const handleFullDayLooksChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setFullDayLooks(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "fullDayRate.looks": e.target.value }, { merge: true });
  };
  const handleFullDayAnglesChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setFullDayAngles(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "fullDayRate.angles": e.target.value }, { merge: true });
  };
  const handleFullDayImagesChange = (e) => {
    if (!canEdit) return;
    setFullDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "fullDayRate.images": e.target.value }, { merge: true });
  };
  //   const handleHalfDayHoursChange = (e) => {
  //     let val = e.target.value;
  //     val = val.replace(/[a-zA-Z]+/g, "");
  //     val = parseInt(val);
  //     if (!val) {
  //       val = "";
  //     }
  //     setHalfDayHours(val);
  //     firebase
  //       .firestore()
  //       .collection("ClientDatabase")
  //       .doc(client?.id)
  //       .update({ "halfDayRate.time": val }, { merge: true });
  //   };
  const handleHalfDayLooksChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setHalfDayLooks(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "halfDayRate.looks": e.target.value }, { merge: true });
  };
  const handleHalfDayAnglesChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setHalfDayAngles(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "halfDayRate.angles": e.target.value }, { merge: true });
  };
  const handleHalfDayImagesChange = (e) => {
    if (!canEdit) return;
    setHalfDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "halfDayRate.images": e.target.value }, { merge: true });
  };
  //   const handleContentFullDayHoursChange = (e) => {
  //     let val = e.target.value;
  //     val = val.replace(/[a-zA-Z]+/g, "");
  //     val = parseInt(val);
  //     if (!val) {
  //       val = "";
  //     }
  //     setContentFullDayHours(val);
  //     firebase
  //       .firestore()
  //       .collection("ClientDatabase")
  //       .doc(client?.id)
  //       .update({ "contentFullDayRate.time": val }, { merge: true });
  //   };
  const handleContentFullDayLunchChange = (e) => {
    if (!canEdit) return;
    console.log(e.target.value);
    setContentFullDayLunch(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "contentFullDayRate.lunch": e.target.value }, { merge: true });
  };
  const handleContentFullDayImagesChange = (e) => {
    if (!canEdit) return;
    setContentFullDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "contentFullDayRate.images": e.target.value }, { merge: true });
  };
  const handleContentFullDayLooksChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setContentFullDayLooks(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "contentFullDayRate.looks": e.target.value }, { merge: true });
  };
  const handleContentFullDayAnglesChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setContentFullDayAngles(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "contentFullDayRate.angles": e.target.value }, { merge: true });
  };
  //   const handleContentHalfDayHoursChange = (e) => {
  //     let val = e.target.value;
  //     val = val.replace(/[a-zA-Z]+/g, "");
  //     val = parseInt(val);
  //     if (!val) {
  //       val = "";
  //     }
  //     setContentHalfDayHours(val);
  //     firebase
  //       .firestore()
  //       .collection("ClientDatabase")
  //       .doc(client?.id)
  //       .update({ "contentHalfDayRate.time": val }, { merge: true });
  //   };
  const handleContentHalfDayLooksChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setContentHalfDayLooks(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "contentHalfDayRate.looks": e.target.value }, { merge: true });
  };
  const handleContentHalfDayAnglesChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setContentHalfDayAngles(val);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "contentHalfDayRate.angles": val }, { merge: true });
  };
  const handleContentHalfDayImagesChange = (e) => {
    if (!canEdit) return;
    setContentHalfDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ "contentHalfDayRate.images": e.target.value }, { merge: true });
  };
  const cardStyle = {
    // background: "#00000005",
    background:
      theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.02)",
    borderRadius: "10px",
    margin: "10px",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  };
  const textFieldStyle = {
    margin: "10px",
    minWidth: "25%",
  };
  const textFieldStyleSml = {
    margin: "10px",
    maxWidth: "25%",
  };
  return (
    <div style={{ overflow: "auto", maxHeight: "calc(100vh - 220px)" }}>
      <div style={cardStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="PSF"
            value={`${PSF}%`}
            onChange={handlePSFChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Payment Terms"
            value={paymentTerms}
            onChange={handlePaymentTermsChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Turn Around"
            value={turnAround}
            onChange={handleTurnAroundChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
        </div>
      </div>
      <div style={cardStyle}>
        <p style={{ margin: 0 }}>Ecomm</p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Lunch"
            value={fullDayLunch}
            onChange={handleFullDayLunchChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Looks"
            value={fullDayLooks}
            onChange={handleFullDayLooksChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Angles"
            value={fullDayAngles}
            onChange={handleFullDayAnglesChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Images"
            value={fullDayImages}
            onChange={handleFullDayImagesChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Half Day Looks"
            value={halfDayLooks}
            onChange={handleHalfDayLooksChange}
            style={textFieldStyle}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Half Day Angles"
            value={halfDayAngles}
            onChange={handleHalfDayAnglesChange}
            style={textFieldStyle}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Half Day Images"
            value={halfDayImages}
            onChange={handleHalfDayImagesChange}
            style={textFieldStyle}
            size="small"
            disabled={!canEdit}
          />
        </div>
      </div>
      <div style={cardStyle}>
        <p style={{ margin: 0 }}>Content</p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Lunch"
            value={contentFullDayLunch}
            onChange={handleContentFullDayLunchChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Looks"
            value={contentFullDayLooks}
            onChange={handleContentFullDayLooksChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Angles"
            value={contentFullDayAngles}
            onChange={handleContentFullDayAnglesChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Full Day Images"
            value={contentFullDayImages}
            onChange={handleContentFullDayImagesChange}
            style={textFieldStyleSml}
            size="small"
            disabled={!canEdit}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Half Day Looks"
            value={contentHalfDayLooks}
            onChange={handleContentHalfDayLooksChange}
            style={textFieldStyle}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Half Day Angles"
            value={contentHalfDayAngles}
            onChange={handleContentHalfDayAnglesChange}
            style={textFieldStyle}
            size="small"
            disabled={!canEdit}
          />
          <TextField
            InputProps={{ disableUnderline: true }}
            variant="filled"
            label="Half Day Images"
            value={contentHalfDayImages}
            onChange={handleContentHalfDayImagesChange}
            style={textFieldStyle}
            size="small"
            disabled={!canEdit}
          />
        </div>
      </div>
    </div>
  );
};

const Production = ({ client, firebase, updateFS, canEdit, theme }) => {
  const [lifeFileSizes, setLifeFileSizes] = useState(client?.fileSizes?.life);
  const [productFileSizes, setProductFileSizes] = useState(
    client?.fileSizes?.product
  );
  const [videoFileSizes, setVideoFileSizes] = useState(
    client?.fileSizes?.video
  );
  //   const [otherFileSize1, setOtherFileSize1] = useState(client?.fileSizes?.other1);
  //   const [otherFileSize2, setOtherFileSize2] = useState(client?.fileSizes?.other2);
  //   const [otherFileSize3, setOtherFileSize3] = useState(client?.fileSizes?.other3);
  //   const [otherFileSize4, setOtherFileSize4] = useState(client?.fileSizes?.other4);

  useEffect(() => {
    setLifeFileSizes(client?.fileSizes?.life);
    setProductFileSizes(client?.fileSizes?.product);
    setVideoFileSizes(client?.fileSizes?.video);
    // setOtherFileSize1(client?.fileSizes?.other1);
    // setOtherFileSize2(client?.fileSizes?.other2);
    // setOtherFileSize3(client?.fileSizes?.other3);
    // setOtherFileSize4(client?.fileSizes?.other4);
  }, [client]);

  const style = {
    overflow: "auto",
    overflowX: "hidden",
    // maxHeight: "calc(100vh - 220px)",
    marginTop: 20,
  };

  return (
    <div style={style} id="FileSizeCategoryContainer">
      <FileSizeCategory
        key="FS-CAT-LIFE"
        kind="Life"
        fileSizes={lifeFileSizes}
        firebase={firebase}
        client={client}
        topMargin={false}
        canEdit={canEdit}
        updateFS={updateFS}
        theme={theme}
      />
      <FileSizeCategory
        key="FS-CAT-PRODUCT"
        kind="Product"
        fileSizes={productFileSizes}
        firebase={firebase}
        client={client}
        topMargin={true}
        canEdit={canEdit}
        updateFS={updateFS}
        theme={theme}
      />
      <FileSizeCategory
        key="FS-CAT-VIDEO"
        kind="Video"
        fileSizes={videoFileSizes}
        firebase={firebase}
        client={client}
        topMargin={true}
        canEdit={canEdit}
        updateFS={updateFS}
        theme={theme}
      />
    </div>
  );
};

const FileSizeCategory = ({
  kind,
  fileSizes,
  firebase,
  client,
  topMargin,
  canEdit,
  updateFS,
  theme,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteSupplySize, setShowDeleteSupplySize] = useState(false);

  const marginTop = 25;

  const style = {
    background:
      theme === "dark" ? "rgba(255, 255, 255, 0.02)" : "rgba(0, 0, 0, 0.02)",
    width: "95%",
    minHeight: "40px",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    borderRadius: "6px",
    position: "relative",
    marginTop: topMargin ? `${marginTop}px` : "0px",
    right: -17,
  };
  const title = {
    paddingLeft: 10,
    cursor: "pointer",
    MozUserSelect: "none",
    WebkitUserSelect: "none",
    msUserSelect: "none",
  };
  const buttonGroupStyle = {
    marginLeft: 10,
    paddingRight: 10,
    // minWidth: "310px",
  };
  const arrowStyle = { paddingLeft: 10, cursor: "pointer" };

  const handleNewSupplySizeClick = () => {
    if (!canEdit) return;
    client.fileSizes[`${kind.toLowerCase()}`].push({
      title: "",
      width: "",
      height: "",
      dpi: "",
      backgroundColour: "",
      fileSize: "",
      fileSizeUnit: "",
      fileFormat: "",
      colourSpace: "",
    });
    updateFS(client.id, "fileSizes", client.fileSizes);
  };

  const handleDeleteSupplySizeClick = () => {
    if (!canEdit) return;
    if (deleteSupplySizeTimeout) {
      clearTimeout(deleteSupplySizeTimeout);
      deleteSupplySizeTimeout = null;
    } else {
      deleteSupplySizeTimeout = setTimeout(() => {
        setShowDeleteSupplySize(false);
        deleteSupplySizeTimeout = null;
      }, 15000);
    }
    setShowDeleteSupplySize(!showDeleteSupplySize);
  };
  const contactsButtons = [
    <Button
      key={`${client.id}-${kind}-NewSupplySize`}
      onClick={handleNewSupplySizeClick}
    >
      New
    </Button>,
    <Button
      key={`${client.id}-${kind}-DeleteSupplySize`}
      onClick={handleDeleteSupplySizeClick}
    >
      Delete
    </Button>,
  ];
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const [joorTimeline, setJoorTimeline] = useState(client.joorTimeline);

  useEffect(() => {
    setJoorTimeline(client.joorTimeline);
  }, [client]);

  const handleJoorTimelineChange = (e) => {
    if (!canEdit) return;
    const j = joorTimeline;
    setJoorTimeline(!j);
    client.joorTimeline = !j;
    updateFS(client.id, "joorTimeline", !j);
  };
  return (
    <div style={style}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          onClick={handleClick}
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {isOpen ? (
            <ArrowDropDownIcon style={arrowStyle} />
          ) : (
            <ArrowRightIcon style={arrowStyle} />
          )}{" "}
          <h3 style={title}>{kind}</h3>
        </div>
        {isOpen ? (
          <div style={buttonGroupStyle}>
            <ButtonGroup size="small" aria-label="contact button group">
              {contactsButtons}
            </ButtonGroup>
          </div>
        ) : (
          <div
            style={{
              paddingRight: "40px",
              fontSize: "1.8em",
              color: theme === "dark" ? "#ffffff" : "#1f1f1f",
            }}
          >
            {fileSizes?.length}
          </div>
        )}
      </div>
      {isOpen ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              minHeight: "40px",
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            {fileSizes &&
              fileSizes.map((fs, index) => (
                <FileSizeContainer
                  key={`FileSizeContainer-${index}`}
                  fs={fs}
                  firebase={firebase}
                  client={client}
                  showDeleteSupplySize={showDeleteSupplySize}
                  kind={kind}
                  updateFS={updateFS}
                  canEdit={canEdit}
                />
              ))}
          </div>
          <div style={{ marginBottom: 10 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={joorTimeline}
                  disabled={!canEdit}
                  onChange={handleJoorTimelineChange}
                />
              }
              label="Client Requires JOOR"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

const FileSizeContainer = ({
  fs,
  firebase,
  client,
  showDeleteSupplySize,
  kind,
  updateFS,
  canEdit,
}) => {
  const [isElectron] = useAtom(isElectronAtom);
  arrayRemove = firebase.firestore.FieldValue.arrayRemove;
  const textFieldStyle = {
    margin: "10px",
    minWidth: "270px",
    maxWidth: "270px",
  };
  const textFieldStyleSml = {
    margin: "10px",
    maxWidth: "125px",
  };
  const cardStyle = {
    background: "#00000006",
    borderRadius: "10px",
    margin: "10px",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",
    position: "relative",
  };
  const fileFormats = ["psd", "jpg", "png", "webp", "tiff", "mp4", "webm"];
  const colourSpaces = ["sRGB", "Adobe 1998", "CMYK"];
  const fileSizeUnits = ["KB", "MB"];

  const fileUploadRef = useRef(null);

  const [title, setTitle] = useState(fs.title);
  const [width, setWidth] = useState(fs.width);
  const [height, setHeight] = useState(fs.height);
  const [dpi, setDpi] = useState(fs.dpi);
  const [fileFormat, setFileFormat] = useState(fs.fileFormat);
  const [removeITSBackground, setRemoveITSBackground] = useState(false);
  const [backgroundColour, setBackgroundColour] = useState(fs.backgroundColour);
  const [colourSpace, setColourSpace] = useState(fs.colourSpace);
  const [fileSize, setFileSize] = useState(fs.fileSize);
  const [fileSizeUnit, setFileSizeUnit] = useState(fs.fileSizeUnit);

  const [fileUploading, setFileUploading] = useState(false);

  useEffect(() => {
    setTitle(fs.title);
    setWidth(fs.width);
    setHeight(fs.height);
    setDpi(fs.dpi);
    setFileFormat(fs.fileFormat);
    setBackgroundColour(fs.backgroundColour);
    setRemoveITSBackground(fs.removeITSBackground);
    setColourSpace(fs.colourSpace);
    setFileSize(fs.fileSize);
    setFileSizeUnit(fs.fileSizeUnit);
  }, [fs]);

  const handleTitleChange = (e) => {
    if (!canEdit) return;
    setTitle(e.target.value);
    fs.title = e.target.value;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleWidthChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setWidth(val);
    fs.width = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleHeightChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setHeight(val);
    fs.height = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleDpiChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setDpi(val);
    fs.dpi = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleFileFormatChange = (e, newValue) => {
    if (!canEdit) return;
    if (!newValue) {
      newValue = "";
    }
    setFileFormat(newValue);
    fs.fileFormat = newValue;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleRemoveITSBackgroundChange = (e) => {
    if (!canEdit) return;
    setRemoveITSBackground(e.target.checked);
    fs.removeITSBackground = e.target.checked;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleBackgroundColourChange = (e) => {
    if (!canEdit) return;
    setBackgroundColour(e.target.value);
    fs.backgroundColour = e.target.value;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleBackgroundColourChangeFileUpload = (url) => {
    if (!canEdit) return;
    setBackgroundColour(url);
    fs.backgroundColour = url;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleColourSpaceChange = (e, newValue) => {
    if (!canEdit) return;
    if (!newValue) {
      newValue = "";
    }
    setColourSpace(newValue);
    fs.colourSpace = newValue;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleFileSizeChange = (e) => {
    if (!canEdit) return;
    let val = parseInt(e.target.value);
    if (!val) {
      val = "";
    }
    setFileSize(val);
    fs.fileSize = val;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleFileSizeUnitChange = (e, newValue) => {
    if (!canEdit) return;
    if (!newValue) {
      newValue = "";
    }
    setFileSizeUnit(newValue);
    fs.fileSizeUnit = newValue;
    updateFS(client.id, "fileSizes", client.fileSizes);
  };
  const handleDeleteSupplySizeClick = () => {
    if (!canEdit) return;
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ [`fileSizes.${kind.toLowerCase()}`]: arrayRemove(fs) });
  };

  return (
    <div style={cardStyle}>
      {showDeleteSupplySize ? (
        <div
          style={{
            background: "#00000015",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <IconButton onClick={handleDeleteSupplySizeClick}>
            <DeleteIcon
              style={{
                color: "#ff0033",
                width: 60,
                height: 60,
                filter: "drop-shadow(4px 4px 3px #00000060)",
              }}
            />
          </IconButton>
        </div>
      ) : null}
      <TextField
        InputProps={{ disableUnderline: true }}
        variant="filled"
        label="Title"
        value={title}
        onChange={handleTitleChange}
        inputProps={{ style: { fontSize: "0.8rem" } }}
        style={textFieldStyle}
        disabled={!canEdit}
      />
      <div>
        <TextField
          InputProps={{ disableUnderline: true }}
          variant="filled"
          label="Width"
          value={width}
          onChange={handleWidthChange}
          style={textFieldStyleSml}
          disabled={!canEdit}
        />
        <TextField
          InputProps={{ disableUnderline: true }}
          variant="filled"
          label="Height"
          value={height}
          onChange={handleHeightChange}
          style={textFieldStyleSml}
          disabled={!canEdit}
        />
        <div style={{ display: "flex" }}>
          {kind === "Video" ? null : (
            <TextField
              InputProps={{ disableUnderline: true }}
              variant="filled"
              label="DPI"
              value={dpi}
              onChange={handleDpiChange}
              style={textFieldStyleSml}
              disabled={!canEdit}
            />
          )}
          <Autocomplete
            value={fileFormat}
            onChange={handleFileFormatChange}
            inputValue={fileFormat}
            onInputChange={handleFileFormatChange}
            options={fileFormats}
            sx={{
              display: "flex",
              margin: "10px",
              maxWidth: "125px",
              minWidth: "125px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                variant="filled"
                label="File Format"
              />
            )}
            disabled={!canEdit}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {kind === "Video" ? null : (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={removeITSBackground}
                        onChange={handleRemoveITSBackgroundChange}
                      />
                    }
                    label="Remove ITS Background"
                  />
                </FormGroup>
              </div>
              <TextField
                disabled={!canEdit}
                variant="filled"
                label="Background"
                value={
                  typeof backgroundColour === "object"
                    ? backgroundColour.fileName
                    : backgroundColour
                }
                onChange={handleBackgroundColourChange}
                style={textFieldStyle}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <>
                      {!fileUploading ? (
                        <IconButton
                          onClick={async () => {
                            if (!canEdit) return;
                            if (fileUploading) return;
                            if (isElectron) {
                              alert("Upload using Chrome or Safari");
                              return;
                            }
                            fileUploadRef.current.value = null;
                            fileUploadRef.current.click();
                          }}
                        >
                          <FileUploadIcon />
                        </IconButton>
                      ) : (
                        <IconButton disabled>
                          <CircularProgress size={24} />
                        </IconButton>
                      )}
                      {typeof backgroundColour === "object" ? (
                        <IconButton
                          onClick={() => {
                            saveAs(
                              backgroundColour.url,
                              backgroundColour.fileName
                            );
                          }}
                        >
                          <FileDownloadIcon />
                        </IconButton>
                      ) : null}
                    </>
                  ),
                }}
              />
            </>
          )}
        </div>
        <div style={{ display: "flex" }}>
          {kind === "Video" ? null : (
            <>
              <Autocomplete
                disabled={!canEdit}
                value={colourSpace}
                onChange={handleColourSpaceChange}
                inputValue={colourSpace}
                onInputChange={handleColourSpaceChange}
                options={colourSpaces}
                sx={{
                  display: "flex",
                  margin: "10px",
                  maxWidth: "270px",
                  minWidth: "270px",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    variant="filled"
                    label="Colour Space"
                  />
                )}
              />
            </>
          )}
        </div>
        <div style={{ display: "flex" }}>
          <TextField
            InputProps={{ disableUnderline: true }}
            disabled={!canEdit}
            variant="filled"
            label="Compress"
            value={fileSize}
            onChange={handleFileSizeChange}
            style={textFieldStyleSml}
          />
          <Autocomplete
            disabled={!canEdit}
            value={fileSizeUnit}
            onChange={handleFileSizeUnitChange}
            inputValue={fileSizeUnit}
            onInputChange={handleFileSizeUnitChange}
            options={fileSizeUnits}
            sx={{
              display: "flex",
              margin: "10px",
              maxWidth: "125px",
              minWidth: "125px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                variant="filled"
                label="File Size Unit"
              />
            )}
          />
        </div>
      </div>
      <input
        ref={fileUploadRef}
        type="file"
        style={{ display: "none" }}
        accept="image/png, image/jpeg"
        onChange={async (e) => {
          setFileUploading(true);
          // await new Promise((r) => setTimeout(r, 1000));
          console.log(e.target.files);
          try {
            const base64 = await getBase64(e.target.files[0]);
            const blob = await fetch(base64)
              .then((res) => res.blob())
              .catch((e) => {
                console.log(e);
              });
            const storageRef = firebase
              .storage()
              .ref()
              .child(
                `/Client Background Plates/${client.brand}/${e.target.files[0].name}`
              );
            storageRef.put(blob).then(async (snapshot) => {
              const url = await storageRef.getDownloadURL();
              handleBackgroundColourChangeFileUpload({
                fileName: e.target.files[0].name,
                url: url,
              });
              setFileUploading(false);
            });
          } catch (e) {
            setFileUploading(false);
          }
        }}
      />
    </div>
  );
};

async function getBase64(file) {
  const promise = new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
  return await promise;
}

const Notes = ({ client, firebase, updateFS, canEdit, theme, modalWidth }) => {
  const [invoicingNotes, setInvoicingNotes] = useState(client?.invoicingNotes);
  const [spreadsheetURL, setSpreadsheetURL] = useState(client?.spreadsheetURL);
  const [createSpreadsheetButtonText, setCreateSpreadsheetButtonText] =
    useState("Create Client Spreadsheet");
  const [shareSpreadsheetWithClientsText, setShareSpreadsheetWithClientsText] =
    useState("Share With Clients");
  const [noClick, setNoClick] = useState(false);

  useEffect(() => {
    setInvoicingNotes(client?.invoicingNotes);
    setSpreadsheetURL(client?.spreadsheetURL);
  }, [client]);

  const style = {
    width: "70%",
    maxWidth: "800px",
    marginBottom: "20px",
    marginTop: "5px",
    borderColor: "#f7f7f7 !important",
  };

  const handleSpreadhseetChange = (e) => {
    if (!canEdit) return;
    setSpreadsheetURL(e.target.value);
    updateFS(client?.id, "spreadsheetURL", e.target.value);
  };

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          //   maxHeight: "calc(100vh - 220px)",
        }}
      >
        <div style={{ marginBottom: "40px" }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 30,
              marginTop: 10,
            }}
          >
            <Button
              variant="filled"
              style={{
                marginBottom: 20,
                width: 260,
                height: 40,
                display: spreadsheetURL ? "none" : "block",
              }}
              size="small"
              onClick={async () => {
                if (!canEdit) return;
                if (!noClick) {
                  setNoClick(true);
                  setCreateSpreadsheetButtonText(
                    <CircularProgress style={{ width: 28, height: 28 }} />
                  );
                  const res = await fetch(
                    "https://us-central1-its-connect-main.cloudfunctions.net/createClientSpreadsheet",
                    {
                      method: "POST",
                      headers: {
                        "content-type": "application/json",
                      },
                      body: JSON.stringify({
                        brand: client?.brand,
                        id: client?.id,
                      }),
                    }
                  )
                    .then((res) => {
                      return res.json();
                    })
                    .catch(() => {
                      alert("an error occurred");
                    });
                  setSpreadsheetURL(res.url);
                  updateFS(client?.id, "spreadsheetURL", res.url);
                  setCreateSpreadsheetButtonText("Create Client Spreadsheet");
                  setNoClick(false);
                }
              }}
            >
              {createSpreadsheetButtonText}
            </Button>
            <ButtonGroup
              variant="filled"
              style={{
                marginBottom: 20,
                display: spreadsheetURL ? "block" : "none",
              }}
            >
              <Button
                style={{ width: 160, height: 30, fontSize: "0.7rem" }}
                onClick={async () => {
                  if (!canEdit) return;
                  if (!noClick) {
                    setNoClick(true);
                    setShareSpreadsheetWithClientsText(
                      <CircularProgress style={{ width: 28, height: 28 }} />
                    );
                    const spreadsheetId =
                      spreadsheetURL.match(/\/d\/(.+)\//)[1];
                    const clientEmails = [];
                    client?.contacts.forEach((c) => {
                      if (c.email) {
                        clientEmails.push(c.email);
                      }
                    });
                    await fetch(
                      "https://us-central1-its-connect-main.cloudfunctions.net/shareSpreadsheetToClients",
                      {
                        method: "POST",
                        headers: {
                          "content-type": "application/json",
                        },
                        body: JSON.stringify({
                          emails: clientEmails,
                          id: spreadsheetId,
                        }),
                      }
                    )
                      .then((res) => {
                        return res.json();
                      })
                      .catch(() => {
                        alert("an error occurred");
                      });
                    setShareSpreadsheetWithClientsText("Share With Clients");
                    setNoClick(false);
                  }
                }}
              >
                {shareSpreadsheetWithClientsText}
              </Button>
              <Button
                style={{ width: 160, height: 30, fontSize: "0.7rem" }}
                onClick={() => {
                  window.open(spreadsheetURL, "_blank");
                }}
              >
                Shootlist
              </Button>
            </ButtonGroup>
            <TextField
              InputProps={{ disableUnderline: true }}
              variant="filled"
              label={"Spreadsheet URL"}
              style={style}
              onChange={handleSpreadhseetChange}
              value={spreadsheetURL}
            />
          </div>

          <div
            style={{
              width: `calc(${modalWidth} - 50px)`,
              display: "flex",
              flexDirection: "column",
              alignItems: "space-between",
            }}
          >
            {/* <div style={{ marginLeft: 30, marginRight: 20 }}>
              <NotesContainerContainer
                jobData={{}}
                clientData={client}
                firebase={firebase}
                theme={theme}
                clientTab={true}
              />
            </div> */}
            <MarkdownNotesEditorClient
              label="Invoicing Notes"
              clientValue={invoicingNotes}
              setClientValue={(e) => {
                if (!canEdit) return;
                setInvoicingNotes(e);
                updateFS(client?.id, "invoicingNotes", e);
              }}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </>
  );
};

// const NotesContainerContainer = ({
//   jobData,
//   clientData,
//   firebase,
//   isMobile,
//   theme,
//   clientTab,
// }) => {
//   const titleContainerStyle = {
//     marginTop: 20,
//     marginLeft: 5,
//     marginBottom: 5,
//     color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
//     fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
//     fontWeight: 400,
//     fontSize: "1rem",
//     lineHeight: "1.4375em",
//     letterSpacing: "0.00938em",
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   };
//   const [sortedNotes, setSortedNotes] = useState([]);

//   useEffect(() => {
//     let tmp = [];

//     jobData?.notes?.forEach((note) => {
//       tmp.push(note);
//     });
//     clientData?.notes?.forEach((note) => {
//       tmp.push(note);
//     });
//     setSortedNotes(sortNotes(tmp));
//     // setSortedNotes(sortNotes([...jobData.notes, ...clientData.notes]));
//   }, [jobData, clientData]);

//   const [newNoteModalOpen, setNewNoteModalOpen] = useState(false);

//   const [newNote, setNewNote] = useState(true);

//   const [newSelectedNotesFilter, setNewSelectedNotesFilter] = useState([]);
//   const [newNoteKind, setNewNoteKind] = useState("Permanent");

//   const [newNotesText, setNewNotesText] = useState("");
//   const [newNotesID, setNewNotesID] = useState(null);

//   const toggleSelectedNotesFilterItem = (item) => {
//     if (newSelectedNotesFilter.indexOf(item) !== -1) {
//       newSelectedNotesFilter.splice(newSelectedNotesFilter.indexOf(item), 1);
//       setNewSelectedNotesFilter([...newSelectedNotesFilter]);
//     } else {
//       newSelectedNotesFilter.push(item);
//       setNewSelectedNotesFilter([...newSelectedNotesFilter]);
//     }
//   };

//   const [newNoteErrorDialogOpen, setNewNoteErrorDialogOpen] = useState(false);
//   const handlenewNoteErrorDialogClose = () => setNewNoteErrorDialogOpen(false);

//   const updateNotes = async () => {
//     let err = false;
//     if (newSelectedNotesFilter.length === 0) err = true;
//     if (!newNoteKind) err = true;
//     if (!newNotesText) err = true;

//     if (err) {
//       setNewNoteErrorDialogOpen(true);
//       return;
//     }
//     const obj = {
//       id: uuidv4(),
//       kind: newNoteKind,
//       tags: newSelectedNotesFilter,
//       text: newNotesText,
//       timestamp: DateTime.now().setZone("Australia/Sydney").toUTC().toISO(),
//     };

//     if (newNoteKind === "Job") {
//       if (!jobData.notes) jobData.notes = [];
//       if (newNote) {
//         jobData.notes.push(obj);
//       } else {
//         jobData.notes.forEach((note, index) => {
//           if (note.id === newNotesID) {
//             jobData.notes[index] = obj;
//           }
//         });
//       }
//       await firebase
//         .firestore()
//         .collection("JobData")
//         .doc(jobData.id)
//         .update({ notes: jobData.notes });
//     } else if (newNoteKind === "Permanent") {
//       if (!clientData.notes) clientData.notes = [];
//       if (newNote) {
//         clientData.notes.push(obj);
//       } else {
//         clientData.notes.forEach((note, index) => {
//           if (note.id === newNotesID) {
//             clientData.notes[index] = obj;
//           }
//         });
//       }
//       await firebase
//         .firestore()
//         .collection("ClientDatabase")
//         .doc(clientData.id)
//         .update({ notes: clientData.notes });
//     }

//     setNewNotesText("");
//     setNewNoteKind("Permanent");
//     setNewSelectedNotesFilter([]);
//     setNewNoteModalOpen(false);
//     setNewNote(true);
//     setNewNotesID(null);
//   };

//   const [jobTypes] = useAtom(jobTypesAtom);

//   const handleFilterChange = (event, newFilter) => {
//     setNewSelectedNotesFilter(newFilter);
//   };

//   const filterButtonStyles = {
//     fontSize: 11,
//     fontWeight: "bold",
//     padding: 0.8,
//     border: `1px solid ${theme === "dark" ? "#3A3A3A" : "#DCDCDC"} !important`,
//     borderRadius: "4px !important",
//   };
//   return (
//     <div>
//       <Dialog
//         open={newNoteErrorDialogOpen}
//         onClose={handlenewNoteErrorDialogClose}
//       >
//         <DialogContent>
//           <DialogContentText>
//             Please ensure there is atleast one tag selected
//           </DialogContentText>
//           <DialogContentText>
//             Please ensure you select Permanent or Job
//           </DialogContentText>
//           <DialogContentText>Please ensure write a note</DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handlenewNoteErrorDialogClose} autoFocus>
//             Okay
//           </Button>
//         </DialogActions>
//       </Dialog>
//       <Slide direction="right" in={newNoteModalOpen} mountOnEnter unmountOnExit>
//         <div
//           style={{
//             background: theme === "dark" ? "#1f1f1f" : "#ffffff",
//             marginTop: 20,
//           }}
//         >
//           <span style={{ marginTop: 20 }}>
//             {newNote ? "New Note" : "Edit Note"}
//           </span>

//           <ToggleButtonGroup
//             value={newSelectedNotesFilter}
//             onChange={handleFilterChange}
//             sx={{
//               marginTop: 2,
//               flexWrap: "wrap",
//               justifyContent: "flex-start",
//               gap: 0.8,
//             }}
//           >
//             <ToggleButton
//               value="Pre-Production"
//               size="small"
//               sx={filterButtonStyles}
//             >
//               Pre-Production
//             </ToggleButton>
//             <ToggleButton
//               value="Photographer"
//               size="small"
//               sx={filterButtonStyles}
//             >
//               Photographer
//             </ToggleButton>
//             <ToggleButton
//               value="Videographer"
//               size="small"
//               sx={filterButtonStyles}
//             >
//               Videographer
//             </ToggleButton>
//             <ToggleButton value="Operator" size="small" sx={filterButtonStyles}>
//               Operator
//             </ToggleButton>
//             <ToggleButton value="Stylist" size="small" sx={filterButtonStyles}>
//               Stylist
//             </ToggleButton>
//             <ToggleButton
//               value="Retoucher"
//               size="small"
//               sx={filterButtonStyles}
//             >
//               Retoucher
//             </ToggleButton>
//           </ToggleButtonGroup>
//           <ToggleButtonGroup
//             value={newSelectedNotesFilter}
//             onChange={handleFilterChange}
//             sx={{
//               marginTop: 3,
//               marginBottom: 3,
//               flexWrap: "wrap",
//               justifyContent: "flex-start",
//               gap: 0.8,
//             }}
//           >
//             {jobTypes &&
//               jobTypes.map((jt) => {
//                 const combined = `${jt.name} (-${jt.nameShort})`;
//                 return (
//                   <ToggleButton
//                     key={jt.id}
//                     value={jt.id}
//                     size="small"
//                     sx={filterButtonStyles}
//                   >
//                     {combined}
//                   </ToggleButton>
//                 );
//               })}
//           </ToggleButtonGroup>

//           <div style={{ display: "block !important" }}>
//             <MDEditor
//               value={newNotesText}
//               onChange={(e) => {
//                 setNewNotesText(e);
//               }}
//               commands={[
//                 commands.bold,
//                 commands.italic,
//                 commands.strikethrough,
//                 commands.hr,
//                 commands.title4,
//                 commands.divider,
//                 commands.link,
//                 commands.quote,
//                 commands.code,
//                 commands.codeBlock,
//                 commands.divider,
//                 commands.unorderedListCommand,
//                 commands.orderedListCommand,
//               ]}
//               extraCommands={[]}
//             />
//           </div>

//           <Button onClick={updateNotes} variant="filled" sx={{ marginTop: 2 }}>
//             {newNote ? "Add New Note" : "Save Note"}
//           </Button>
//         </div>
//       </Slide>
//       <div style={titleContainerStyle}>
//         <span>Notes</span>
//         <IconButton
//           onClick={() => {
//             setNewNoteModalOpen(!newNoteModalOpen);
//             setNewNotesText("");
//             setNewNoteKind(clientTab ? "Permanent" : "");
//             setNewSelectedNotesFilter([]);
//             setNewNote(true);
//             setNewNotesID(null);
//           }}
//           style={{ marginRight: -8 }}
//         >
//           {!newNoteModalOpen ? <AddIcon /> : <RemoveIcon />}
//         </IconButton>
//       </div>
//       <NotesContainer
//         firebase={firebase}
//         jobData={jobData}
//         clientData={clientData}
//         sortedNotes={sortedNotes}
//         selectedNotesFilter={[]}
//         isMobile={isMobile}
//         modalWidth={"100%"}
//         theme={theme}
//         newNoteModalOpen={newNoteModalOpen}
//         setNewNoteModalOpen={setNewNoteModalOpen}
//         setNewSelectedNotesFilter={setNewSelectedNotesFilter}
//         setNewNoteKind={setNewNoteKind}
//         setNewNotesText={setNewNotesText}
//         setNewNotesID={setNewNotesID}
//         setNewNote={setNewNote}
//         jobTab={true}
//         clientTab={clientTab}
//         shootTab={false}
//       />
//     </div>
//   );
// };

const Checklists = ({ client, firebase, updateFS, canEdit }) => {
  arrayRemove = firebase.firestore.FieldValue.arrayRemove;

  const [generalChecklist, setGeneralChecklist] = useState(
    client?.generalChecklist
  );
  const [lifeChecklist, setLifeChecklist] = useState(client?.lifeChecklist);
  const [productChecklist, setProductChecklist] = useState(
    client?.productChecklist
  );
  const [videoChecklist, setVideoChecklist] = useState(client?.videoChecklist);

  useEffect(() => {
    setGeneralChecklist(client?.generalChecklist);
    setLifeChecklist(client?.lifeChecklist);
    setProductChecklist(client?.productChecklist);
    setVideoChecklist(client?.videoChecklist);
  }, [client]);

  return (
    <div
      style={{
        overflow: "auto",
        // maxHeight: "calc(100vh - 220px)",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <ChecklistContainer
        key={`${client?.id}-generalChecklist`}
        checklist={generalChecklist}
        setChecklist={setGeneralChecklist}
        client={client}
        kind="generalChecklist"
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
      />
      <ChecklistContainer
        key={`${client?.id}-lifeChecklist`}
        checklist={lifeChecklist}
        setChecklist={setLifeChecklist}
        client={client}
        kind="lifeChecklist"
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
      />
      <ChecklistContainer
        key={`${client?.id}-productChecklist`}
        checklist={productChecklist}
        setChecklist={setProductChecklist}
        client={client}
        kind="productChecklist"
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
      />
      <ChecklistContainer
        key={`${client?.id}-videoChecklist`}
        checklist={videoChecklist}
        setChecklist={setVideoChecklist}
        client={client}
        kind="videoChecklist"
        firebase={firebase}
        updateFS={updateFS}
        canEdit={canEdit}
      />
    </div>
  );
};

const ChecklistContainer = ({
  checklist,
  setChecklist,
  client,
  kind,
  firebase,
  canEdit,
}) => {
  const updateFS = debounce(async (firebase, id, field, value) => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  }, 800);

  const [showDelete, setShowDelete] = useState(false);
  const cardStyle = {
    // background: "#00000005",
    borderRadius: "10px",
    padding: "10px",
    margin: "10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    maxHeight: "550px",
    minHeight: "300px",
  };
  const widthStyle = { width: "110px" };
  const handleShowDelete = () => {
    if (!canEdit) return;
    if (deleteTimeout) {
      clearTimeout(deleteTimeout);
      deleteTimeout = null;
    } else {
      deleteTimeout = setTimeout(() => {
        setShowDelete(false);
        deleteTimeout = null;
      }, 15000);
    }
    setShowDelete(!showDelete);
  };
  const handleNewChecklistItem = () => {
    if (!canEdit) return;
    setShowDelete(false);
    checklist.push("");
    setChecklist([...checklist]);
    updateFS(firebase, client?.id, kind, client[kind]);
  };
  const handleDeleteChecklistItem = (item) => {
    if (!canEdit) return;
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client?.id)
      .update({ [kind]: arrayRemove(item) });
  };
  const checklistButtons = [
    <Button
      style={widthStyle}
      onClick={handleNewChecklistItem}
      key={`${client?.id}-${kind}-NewChecklistItem`}
    >
      New Item
    </Button>,
    <Button
      style={widthStyle}
      onClick={handleShowDelete}
      key={`${client?.id}-${kind}-DeleteChecklistItem`}
    >
      Delete Item
    </Button>,
  ];
  return (
    <div style={cardStyle}>
      <h3>{camelToSentence(kind)}</h3>
      <ButtonGroup size="small" style={{ paddingBottom: "10px" }}>
        {checklistButtons}
      </ButtonGroup>
      <List
        style={{
          width: "35vw",
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        {checklist &&
          checklist.map((item, index) => (
            <div
              key={`${client?.id}-${kind}-${index}-Cont`}
              style={{ display: "flex" }}
            >
              {showDelete ? (
                <IconButton
                  onClick={() => {
                    handleDeleteChecklistItem(item);
                  }}
                >
                  <DeleteIcon
                    style={{
                      color: "#ff0033",
                      width: 30,
                      height: 30,
                    }}
                  />
                </IconButton>
              ) : null}
              <CheckListItem
                key={`${client?.id}-${kind}-${index}`}
                index={index}
                item={item}
                client={client}
                kind={kind}
                firebase={firebase}
                updateFS={updateFS}
                canEdit={canEdit}
              />
            </div>
          ))}
      </List>
    </div>
  );
};

const CheckListItem = ({
  index,
  item,
  client,
  kind,
  firebase,
  updateFS,
  canEdit,
}) => {
  const [itemContent, setItemContent] = useState(item);

  useEffect(() => {
    setItemContent(item);
  }, [item]);

  const handleChange = (e) => {
    if (!canEdit) return;
    setItemContent(e.target.value);
    client[kind][index] = e.target.value;
    updateFS(firebase, client?.id, kind, client[kind]);
  };

  return (
    <ListItem>
      <TextField
        InputProps={{ disableUnderline: true }}
        size="small"
        variant="filled"
        label={`Item ${index + 1}`}
        style={{ width: "100%" }}
        inputProps={{
          style: { fontSize: 14 },
        }}
        value={itemContent}
        onChange={handleChange}
      />
    </ListItem>
  );
};

function camelToSentence(str) {
  const result = str.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
}

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

// function sortNotes(notes) {
//   let newNotes = [...notes];
//   newNotes.sort((a, b) => {
//     var keyA = new Date(a.timestamp),
//       keyB = new Date(b.timestamp);
//     if (keyA < keyB) return 1;
//     if (keyA > keyB) return -1;
//     return 0;
//   });

//   let tmp = [];

//   newNotes.forEach((note, index) => {
//     if (note.tags.includes("Pre-Production")) {
//       tmp.push(note);
//       newNotes[index] = { tags: [] };
//     }
//   });
//   newNotes.forEach((note, index) => {
//     if (note.tags.includes("Photographer")) {
//       tmp.push(note);
//       newNotes[index] = { tags: [] };
//     }
//   });
//   newNotes.forEach((note, index) => {
//     if (note.tags.includes("Videographer")) {
//       tmp.push(note);
//       newNotes[index] = { tags: [] };
//     }
//   });
//   newNotes.forEach((note, index) => {
//     if (note.tags.includes("Operator")) {
//       tmp.push(note);
//       newNotes[index] = { tags: [] };
//     }
//   });
//   newNotes.forEach((note, index) => {
//     if (note.tags.includes("Stylist")) {
//       tmp.push(note);
//       newNotes[index] = { tags: [] };
//     }
//   });
//   newNotes.forEach((note, index) => {
//     if (note.tags.includes("Retoucher")) {
//       tmp.push(note);
//       newNotes[index] = { tags: [] };
//     }
//   });

//   return tmp;
// }

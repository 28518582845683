import React, { useState, useEffect } from "react";

import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";

const textFieldStyle = {
  margin: "10px",
  minWidth: "250px",
};
const textFieldStyleSml = {
  margin: "10px",
  maxWidth: "180px",
};

const Invoicing = ({ client, firebase, updateFS, theme }) => {
  const [PSF, setPSF] = useState(client.PSF);
  const [paymentTerms, setPaymentTerms] = useState(client.paymentTerms);
  const [turnAround, setTurnAround] = useState(client.turnAround);

  const [fullDayHours, setFullDayHours] = useState(client.fullDayRate.time);
  const [fullDayLunch, setFullDayLunch] = useState(client.fullDayRate.lunch);
  const [fullDayImages, setFullDayImages] = useState(client.fullDayRate.images);
  const [halfDayHours, setHalfDayHours] = useState(client.halfDayRate.time);
  const [halfDayImages, setHalfDayImages] = useState(client.halfDayRate.images);

  const [contentFullDayHours, setContentFullDayHours] = useState(
    client.contentFullDayRate.time
  );
  const [contentFullDayLunch, setContentFullDayLunch] = useState(
    client.contentFullDayRate.lunch
  );
  const [contentFullDayImages, setContentFullDayImages] = useState(
    client.contentFullDayRate.images
  );
  const [contentHalfDayHours, setContentHalfDayHours] = useState(
    client.contentHalfDayRate.time
  );
  const [contentHalfDayImages, setContentHalfDayImages] = useState(
    client.contentHalfDayRate.images
  );

  useEffect(() => {
    setPSF(client.PSF);
    setPaymentTerms(client.paymentTerms);
    setTurnAround(client.turnAround);

    setFullDayHours(client.fullDayRate.time);
    setFullDayLunch(client.fullDayRate.lunch);
    setFullDayImages(client.fullDayRate.images);
    setHalfDayHours(client.halfDayRate.time);
    setHalfDayImages(client.halfDayRate.images);

    setContentFullDayHours(client.contentFullDayRate.time);
    setContentFullDayLunch(client.contentFullDayRate.lunch);
    setContentFullDayImages(client.contentFullDayRate.images);
    setContentHalfDayHours(client.contentHalfDayRate.time);
    setContentHalfDayImages(client.contentHalfDayRate.images);
  }, [client]);

  const handlePSFChange = (e) => {
    let val = e.target.value;
    val = val.replace(/%+/g, "");
    val = val.replace(/[a-zA-Z]+/g, "");
    if (!val) {
      val = 0;
    }
    setPSF(parseInt(val));
    updateFS(client.id, "PSF", parseInt(val));
  };
  const handlePaymentTermsChange = (e) => {
    setPaymentTerms(e.target.value);
    updateFS(client.id, "paymentTerms", e.target.value);
  };
  const handleTurnAroundChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    setTurnAround(val);
    updateFS(client.id, "turnAround", val);
  };
  const handleFullDayHoursChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setFullDayHours(val);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "fullDayRate.time": val }, { merge: true });
  };
  const handleFullDayLunchChange = (e) => {
    setFullDayLunch(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "fullDayRate.lunch": e.target.value }, { merge: true });
  };
  const handleFullDayImagesChange = (e) => {
    setFullDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "fullDayRate.images": e.target.value }, { merge: true });
  };
  const handleHalfDayHoursChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setHalfDayHours(val);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "halfDayRate.time": val }, { merge: true });
  };
  const handleHalfDayImagesChange = (e) => {
    setHalfDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "halfDayRate.images": e.target.value }, { merge: true });
  };
  const handleContentFullDayHoursChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setContentFullDayHours(val);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "contentFullDayRate.time": val }, { merge: true });
  };
  const handleContentFullDayLunchChange = (e) => {
    setContentFullDayLunch(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "contentFullDayRate.lunch": e.target.value }, { merge: true });
  };
  const handleContentFullDayImagesChange = (e) => {
    setContentFullDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "contentFullDayRate.images": e.target.value }, { merge: true });
  };
  const handleContentHalfDayHoursChange = (e) => {
    let val = e.target.value;
    val = val.replace(/[a-zA-Z]+/g, "");
    val = parseInt(val);
    if (!val) {
      val = "";
    }
    setContentHalfDayHours(val);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "contentHalfDayRate.time": val }, { merge: true });
  };
  const handleContentHalfDayImagesChange = (e) => {
    setContentHalfDayImages(e.target.value);
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ "contentHalfDayRate.images": e.target.value }, { merge: true });
  };

  const cardStyle = {
    background:
      theme === "dark" ? "rgba(255, 255, 255, 0.05)" : "rgba(0, 0, 0, 0.02)",
    borderRadius: "10px",
    margin: "10px",
    paddingTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
  };
  return (
    <div style={{ overflow: "auto", maxHeight: "calc(100vh - 220px)" }}>
      <div style={cardStyle}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
        >
          <Tooltip
            title="Production service fee, the default is 15%"
            enterDelay={500}
          >
            <TextField
              variant="outlined"
              label="Production Service Fee"
              value={`${PSF}%`}
              onChange={handlePSFChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
          <Tooltip title="Payment terms, the default is COD" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Payment Terms"
              value={paymentTerms}
              onChange={handlePaymentTermsChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
        </div>
        <Tooltip
          title="Turn around time in days, the default is 5 days"
          enterDelay={500}
        >
          <TextField
            variant="outlined"
            label="Turn Around (In Days)"
            value={turnAround}
            onChange={handleTurnAroundChange}
            style={textFieldStyleSml}
          />
        </Tooltip>
      </div>
      <div style={cardStyle}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Full day hours" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Full Day Hours"
              value={fullDayHours}
              onChange={handleFullDayHoursChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
          <Tooltip title="Full day lunch" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Full Day Lunch"
              value={fullDayLunch}
              onChange={handleFullDayLunchChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
          <Tooltip title="Full day images" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Full Day Images"
              value={fullDayImages}
              onChange={handleFullDayImagesChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Half day hours" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Half Day Hours"
              value={halfDayHours}
              onChange={handleHalfDayHoursChange}
              style={textFieldStyle}
            />
          </Tooltip>
          <Tooltip title="Half day images" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Half Day Images"
              value={halfDayImages}
              onChange={handleHalfDayImagesChange}
              style={textFieldStyle}
            />
          </Tooltip>
        </div>
      </div>
      <div style={cardStyle}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Content full day hours" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Content Full Day Hours"
              value={contentFullDayHours}
              InputLabelProps={{ style: { fontSize: "0.85rem" } }}
              onChange={handleContentFullDayHoursChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
          <Tooltip title="Content full day lunch" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Content Full Day Lunch"
              value={contentFullDayLunch}
              InputLabelProps={{ style: { fontSize: "0.85rem" } }}
              onChange={handleContentFullDayLunchChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
          <Tooltip title="Content full day images" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Content Full Day Images"
              value={contentFullDayImages}
              InputLabelProps={{ style: { fontSize: "0.85rem" } }}
              onChange={handleContentFullDayImagesChange}
              style={textFieldStyleSml}
            />
          </Tooltip>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Tooltip title="Content half day hours" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Content Half Day Hours"
              value={contentHalfDayHours}
              InputLabelProps={{ style: { fontSize: "0.85rem" } }}
              onChange={handleContentHalfDayHoursChange}
              style={textFieldStyle}
            />
          </Tooltip>
          <Tooltip title="Content half day images" enterDelay={500}>
            <TextField
              variant="outlined"
              label="Content Half Day Images"
              value={contentHalfDayImages}
              InputLabelProps={{ style: { fontSize: "0.85rem" } }}
              onChange={handleContentHalfDayImagesChange}
              style={textFieldStyle}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Invoicing;

import React from "react";

import Contact from "./Contacts/Contact";

const Contacts = ({ client, showDeleteContact, updateFS, firebase, theme }) => {
  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "10px",
        minWidth: "150px",
        maxWidth: "2000px",
        maxHeight: "calc(100vh - 300px)",
        overflow: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {client.contacts.map((contact, i) => (
        <Contact
          key={`contact-${i}`}
          contact={contact}
          client={client}
          showDeleteContact={showDeleteContact}
          updateFS={updateFS}
          firebase={firebase}
          theme={theme}
        />
      ))}
    </div>
  );
};

export default Contacts;

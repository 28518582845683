export function returnBackgroundColour(mode) {
  // const appBarColour = "00987a";
  // const appBarColour = "547AA5";
  // const appBarColour = "034C3C";
  // const appBarColour = "93032E";
  // const appBarColour = "983628";
  // const appBarColour = "449DD1";
  // const appBarColour = "38405F";
  // const appBarColour = "182626";
  // const appBarColour = "426B69";

  //   const appBarColour = "042A2B";
  //   const appBarColour = "605770";
  // return appBarColour;
  // const td = Array.from(document.querySelectorAll(".fc-theme-standard td"));
  // const th = Array.from(document.querySelectorAll(".fc-theme-standard th"));
  // td.forEach((t) => {
  //   t.classList.remove(mode === "dark" ? "lightModeBorder" : "darkModeBorder");
  //   t.classList.add(mode === "dark" ? "darkModeBorder" : "lightModeBorder");
  // });
  // th.forEach((t) => {
  //   t.classList.remove(mode === "dark" ? "lightModeBorder" : "darkModeBorder");
  //   t.classList.add(mode === "dark" ? "darkModeBorder" : "lightModeBorder");
  // });
    return mode === "dark" ? "1f1f1f" : "ffffff";
  // return mode === "dark" ? "182626" : "426B69";
}

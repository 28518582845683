import React, { useState, useEffect } from 'react'

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';


const OutsourcerCostings = ({ firebase }) => {
    const firestore = firebase.firestore();
    const [outsourcerData, setOutsourcerData] = useState([])
    const [jobData, setJobData] = useState([])

    const [outsourcerArray, setOutsourcerArray] = useState([])
    // console.log(outsourcerArray)

    useEffect(() => {
        if (outsourcerArray.length > 0) {
            outsourcerArray.forEach(o => {
                console.log(o.companyName, o.jobs.length)
            })
        }
    }, [outsourcerArray])
    useEffect(() => {
        let arr = [...outsourcerData]
        arr.forEach(a => { a.jobs = [] })
        for (let i = 0; i < jobData.length; i++) {
            if (jobData.length > 0) {
                jobData[i].outsourcers.forEach(job => {
                    if (job.outsourcer) {
                        arr.forEach(a => {
                            if (a.companyName === job.outsourcer) {
                                a.jobs.push(job)
                            }
                        })
                    }
                })
            }
        }
        setOutsourcerArray(arr)
        // eslint-disable-next-line
    }, [jobData, setOutsourcerArray])

    useEffect(() => {
        let reference = firestore
            .collection("JobData")
            .where('year', '==', new Date().getFullYear())
            .orderBy("jobNumber", "desc")
        // .limit(140)

        const unsub = reference.onSnapshot(collection => {
            const initialData = collection.docs.map(d => {
                const data = d.data()
                data.id = d.id
                return data
            })
            setJobData([...initialData])
        })
        return () => {
            unsub()
        }
        // eslint-disable-next-line
    }, [setJobData])

    useEffect(() => {
        let reference = firestore
            .collection("Outsourcers")
            .orderBy("companyName", "asc")

        const unsub = reference.onSnapshot(collection => {
            const initialData = collection.docs.map(outsourcer => {
                const data = outsourcer.data()
                data.id = outsourcer.id
                return data
            })
            setOutsourcerData([...initialData])
        })
        return () => {
            unsub()
        }
        // eslint-disable-next-line
    }, [setOutsourcerData])

    return (
        <div>OutsourcerCostings</div>
    )
}

export default OutsourcerCostings
import React, { useEffect } from 'react'

// import wip1 from './wipgifs/wip1.mp4'
// import wip2 from './wipgifs/wip2.mp4'
// import wip3 from './wipgifs/wip3.mp4'
// import wip4 from './wipgifs/wip4.mp4'
// import wip5 from './wipgifs/wip5.mp4'

// const gifs = [wip1, wip2, wip3, wip4, wip5]
// let gifsEditable = [...gifs]

const WIP = () => {


    // const shuffleArray = array => {
    //     for (let i = array.length - 1; i > 0; i--) {
    //         const j = Math.floor(Math.random() * (i + 1));
    //         const temp = array[i];
    //         array[i] = array[j];
    //         array[j] = temp;
    //     }
    // }

    useEffect(() => {
        // const element = document.getElementById('vid')
        // shuffleArray(gifsEditable)
        // element.src = gifsEditable[0]
        // gifsEditable.shift()
        // element.addEventListener('ended', () => {
        //     if (gifsEditable.length === 0) {
        //         gifsEditable = [...gifs]
        //         shuffleArray(gifsEditable)
        //     }
        //     element.src = gifsEditable[0]
        //     gifsEditable.shift()
        // })
    }, [])
    return (
        <div style={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
            left: `50%`,
        }}>
            <video id="vid" autoPlay muted>
                <source src={''} type="video/mp4"></source>
            </video>
        </div>
    )
}

export default WIP

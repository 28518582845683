import React from "react";

import TextField from "@mui/material/TextField";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const Dates = ({
    titleStyle,
    bookedStart,
    bookedFinish,
    startTime,
    setStartTime,
    updateFS,
    firebase,
    jobData,
    finishTime,
    setFinishTime,
    overtime,
    setOvertime,

}) => {
  return (
    <>
      <div style={titleStyle}>
        <span>Dates</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: -10,
          gap: 15,
          padding: 30,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div style={{ display: "flex", gap: 15 }}>
            <TimePicker
              label="Booked Start"
              value={bookedStart || null}
              onChange={(d) => {}}
              readOnly={true}
              disabled={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 15 },
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: 15 },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  size="small"
                />
              )}
            />
            <TimePicker
              label="Booked Finish"
              value={bookedFinish || null}
              onChange={(d) => {}}
              readOnly={true}
              disabled={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 15 },
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: 15 },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  size="small"
                />
              )}
            />
          </div>
          <div style={{ display: "flex", gap: 15 }}>
            <TimePicker
              label="Start Time"
              value={startTime || null}
              onChange={(d) => {
                if (!d) return;
                if (!d.isValid()) return;
                setStartTime(d.toISOString());
                updateFS(firebase, jobData.id, "startTime", d.toISOString());
              }}
              onInput={(e) => {
                e.preventDefault();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 15 },
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: 15 },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  size="small"
                />
              )}
            />
            <TimePicker
              label="Finish Time"
              value={finishTime || null}
              onChange={(d) => {
                if (!d) return;
                if (!d.isValid()) return;
                setFinishTime(d.toISOString());
                updateFS(firebase, jobData.id, "finishTime", d.toISOString());
              }}
              onInput={(e) => {
                console.log(e);
                e.preventDefault();
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  fullWidth
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 15 },
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: 15 },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  size="small"
                />
              )}
            />
          </div>
        </LocalizationProvider>
        <TextField
          multiline={true}
          label="Overtime (In Hours)"
          value={overtime || ""}
          onChange={(e) => {
            const numericValue = e.target.value.replace(/[^0-9]/g, "");
            setOvertime(numericValue);
            updateFS(firebase, jobData.id, "overtime", numericValue);
          }}
          size="small"
          variant="filled"
          fullWidth
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          InputProps={{ disableUnderline: true }}
        />
      </div>
    </>
  );
};

export default Dates;

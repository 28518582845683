import React, { useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useAtom } from "jotai";
import { isMobileAtom } from "../../../../-Atoms";
import debounce from 'lodash.debounce';

const Staff = ({
  titleStyle,
  additionalOperators,
  textFieldStyle,
  staffData,
  findStaff,
  operator,
  setOperator,
  firebase,
  jobData,
  retoucherError,
  jobTypeFull,
  retoucher,
  setRetoucher,
  retouchingTimeError,
  retouchingTime,
  handleValueChange,
  setRetouchingTime,
}) => {
  const [isMobile] = useAtom(isMobileAtom);

  const updateFirestore = useCallback(
    debounce((field, value) => {
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({ [field]: value }, { merge: true })
        .catch((e) => console.log(e));
    }, 500),
    []
  );

  const handleOperatorChange = (e, value) => {
    const val = value?.id || "";
    setOperator(val);
    updateFirestore("operator", val);
  };

  const handleRetoucherChange = (e, value) => {
    const val = value?.id || "";
    setRetoucher(val);
    updateFirestore("retoucher", val);
  };

  return (
    <>
      <div style={titleStyle}>
        <span>Staff</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          flexWrap: "wrap",
          marginTop: -10,
          marginLeft: 2,
          gap: 2,
          padding: 25,
        }}
      >
        <Autocomplete
          disablePortal
          sx={{ width: additionalOperators.length > 0 ? "48.1%" : "98%" }}
          style={textFieldStyle}
          options={staffData}
          getOptionLabel={(option) => findStaff(option, staffData)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              size="small"
              label="Operator"
              inputProps={{ ...params.inputProps, style: { fontSize: 15 } }}
              InputLabelProps={{
                ...params.InputLabelProps,
                style: { fontSize: 15 },
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
          value={operator || ""}
          onChange={handleOperatorChange}
        />
        {additionalOperators &&
          additionalOperators.map((ao, index) => (
            <TextField
              sx={{ width: isMobile ? "46%" : "48.1%" }}
              key={`${ao}_${index}`}
              variant="filled"
              label="Additional Operator"
              value={findStaff(ao, staffData) || ""}
              style={textFieldStyle}
              size="small"
              inputProps={{ style: { fontSize: 15 } }}
              InputLabelProps={{ style: { fontSize: 15 } }}
              InputProps={{ disableUnderline: true }}
            />
          ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: -22,
          paddingLeft: 25,
          paddingRight: 25,
          marginBottom: 25,
        }}
      >
        <Autocomplete
          disablePortal
          sx={{ width: "48%" }}
          style={textFieldStyle}
          options={staffData}
          getOptionLabel={(option) => findStaff(option, staffData)}
          renderInput={(params) => (
            <TextField
              {...params}
              error={retoucherError}
              variant="filled"
              fullWidth
              inputProps={{ ...params.inputProps, style: { fontSize: 15 } }}
              InputLabelProps={{
                ...params.InputLabelProps,
                style: { fontSize: 15 },
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
              size="small"
              label={
                jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
                  ? "Editor *"
                  : "Retoucher *"
              }
            />
          )}
          value={retoucher || ""}
          onChange={handleRetoucherChange}
        />
        <TextField
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          InputProps={{ disableUnderline: true }}
          sx={{ width: "48%" }}
          size="small"
          variant="filled"
          multiline={true}
          error={retouchingTimeError}
          label={
            jobTypeFull?.includes("(-V)") || jobTypeFull?.includes("(-E)")
              ? "Editing Time *"
              : "Retouching Time"
          }
          value={retouchingTime || ""}
          onChange={(e) => {
            handleValueChange(e, setRetouchingTime, "retouchingTime");
          }}
          style={textFieldStyle}
        />
      </div>
    </>
  );
};

export default Staff;

import React, { useState, useEffect, useCallback, useRef } from "react";
import { createGlobalStyle } from "styled-components";
import { Button, Skeleton, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import IconButton from "@mui/material/IconButton";

import { v4 as uuidv4 } from "uuid";

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

const Attachments = ({
  jobData,
  clientData,
  staffData,
  firebase,
  isDarkMode,
  setStopModalClose,
  setUrlModalUrl,
  setURLModalOpen,
}) => {
  // const [attachments, setAttachments] = useState([]);
  const [jobAttachments, setJobAttachments] = useState([]);
  const [clientAttachments, setClientAttachments] = useState([]);

  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    const tmpJobAttachments = [];
    const tmpClientAttachments = [];
    if (jobData?.attachments) {
      jobData?.attachments.forEach((attachment) => {
        tmpJobAttachments.push(attachment);
      });
    }
    if (clientData?.attachments) {
      clientData?.attachments.forEach((attachment) => {
        tmpClientAttachments.push(attachment);
      });
    }
    setJobAttachments([...tmpJobAttachments]);
    setClientAttachments([...tmpClientAttachments]);
  }, [firebase, jobData, clientData]);

  const GlobalStyles = createGlobalStyle`
  .attachment:hover{
    background: ${
      isDarkMode ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)"
    }
  }
  .editAttachment:hover{
    color: ${isDarkMode ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)"}
  }

  .deleteAttachment:hover{
    color: rgba(255, 50, 50, 1)
  }
  `;
  // color: ${isDarkMode ? "rgba(255, 100, 100, 0.5)" : "rgba(0, 0, 0, 0.5)"}

  const handleAttachment = useCallback(
    async (e) => {
      setIsUploading(true);
      setStopModalClose(true);
      const files = document.getElementById("addAttachment").files;
      let newAttachments = [];
      for (let i = 0; i < files.length; i++) {
        let file = files[i];
        let fileName = file.name;

        const random = uuidv4().slice(0, 5);
        const ext = fileName.match(/(\.[^.]+)$/)[1];
        const fnOrig = fileName.replace(ext, "");
        const newFn = `${fnOrig}_${random}${ext}`;
        fileName = newFn;

        // attachments.forEach((att) => {
        //   console.log(att.fileName);
        //   console.log(fileName === att.fileName);
        //   if (fileName === att.fileName) {
        //     console.log("file name exists");
        //     const random = uuidv4().slice(0, 5);
        //     const ext = fileName.match(/(\.[^.]+)$/)[1];
        //     const fnOrig = fileName.replace(ext, "");
        //     const newFn = `${fnOrig}_${random}${ext}`;
        //     console.log(newFn);
        //     fileName = newFn;
        //   }
        // });

        await new Promise((r) => setTimeout(r, 100));

        if (file.size > 150000000) {
          alert("file must be under 150MB");
        } else {
          let url;
          const res = await dbx
            .filesUpload({
              path: `/-Job Attachments/${jobData.id}/${fileName}`,
              autorename: false,
              mode: "add",
              mute: false,
              contents: file,
            })
            .catch((e) => {
              setStopModalClose(false);
              setIsUploading(false);
              console.log(e);
            });

          await dbx
            .sharingListSharedLinks({
              path: res.result.path_display,
              direct_only: true,
            })
            .then((data) => {
              url = data.result.links[0].url;
            })
            .catch(async (e) => {
              // console.log(e);
              await dbx
                .sharingCreateSharedLinkWithSettings({
                  path: res.result.path_display,
                  settings: {
                    requested_visibility: "public",
                    audience: "public",
                    access: "viewer",
                  },
                })
                .then((data) => {
                  url = data.result.url;
                })
                .catch((e) => {
                  setStopModalClose(false);
                  setIsUploading(false);
                  console.log(e);
                });
            });
          if (url) newAttachments.push({ fileName: fileName, url: url });
        }
      }
      try {
        if (newAttachments) {
          newAttachments.forEach((a) => {
            firebase
              .firestore()
              .collection("JobData")
              .doc(jobData.id)
              .update({
                attachments: firebase.firestore.FieldValue.arrayUnion(a),
              })
              .catch((e) => {
                alert(e);
              });
          });
        }

        document.getElementById("addAttachment").value = null;
        setIsUploading(false);
        setStopModalClose(false);
        newAttachments = [];
      } catch (e) {
        alert(e);
      }
    },
    // eslint-disable-next-line
    [firebase, jobData]
  );

  return (
    <>
      <GlobalStyles />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: 20,
          marginBottom: 60,
        }}
      >
        <div
          style={{
            width: "90%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <span style={{ fontWeight: "bold", marginBottom: 8, fontSize: 15 }}>
            Attachments
          </span>
          <div>
            {clientAttachments.map((attachment, index) => (
              <Attachment
                key={`${attachment.url}_${index}`}
                attachment={attachment}
                isDarkMode={isDarkMode}
                index={index}
                attachments={clientAttachments}
                setAttachments={() => {}}
                firebase={firebase}
                kind="client"
                jobData={jobData}
                setUrlModalUrl={setUrlModalUrl}
                setURLModalOpen={setURLModalOpen}
              />
            ))}
            {jobAttachments.map((attachment, index) => (
              <Attachment
                key={`${attachment.url}_${index}`}
                attachment={attachment}
                isDarkMode={isDarkMode}
                index={index}
                attachments={jobAttachments}
                setAttachments={setJobAttachments}
                firebase={firebase}
                kind="job"
                jobData={jobData}
                setUrlModalUrl={setUrlModalUrl}
                setURLModalOpen={setURLModalOpen}
              />
            ))}
            <div
              style={{
                display: isUploading ? "flex" : "none",
                marginBottom: 6,
                borderRadius: 4,
              }}
            >
              <Skeleton
                width={85}
                height={55}
                variant="rounded"
                sx={{ marginRight: 1 }}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Skeleton width={240} height={25} variant="text" />
                <Skeleton width={120} height={25} variant="text" />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 5 }}>
            <Button
              variant="contained"
              sx={{ width: 180, height: 30, fontSize: 12 }}
              onClick={() => {
                document.getElementById("addAttachment").click();
              }}
            >
              Add Attachment
            </Button>
            <input
              type="file"
              id="addAttachment"
              multiple={true}
              style={{ display: "none" }}
              onChange={handleAttachment}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Attachments;

const Attachment = ({
  attachment,
  isDarkMode,
  index,
  attachments,
  setAttachments,
  firebase,
  kind,
  jobData,
  setUrlModalUrl,
  setURLModalOpen,
}) => {
  const [ext, setExt] = useState("");
  const [fileNameNoExt, setFileNameNoExt] = useState("");
  const [rawURL, setRawURL] = useState("");
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const tmpExt = attachment.fileName.match(/\.([^.]+)$/)[1].toLowerCase();
    setExt(tmpExt);
    setFileNameNoExt(attachment.fileName.replace(`.${tmpExt}`, ""));
    setRawURL(attachment.url.replace("dl=0", "raw=1"));
    if (tmpExt === "jpg" || tmpExt === "png" || tmpExt === "jpeg") {
      setShowImage(true);
    }
  }, [attachment]);

  const editAttachment = () => setEditAttachmentOpen(true);

  const deleteAttachment = () => setDeleteAttachmentOpen(true);

  const editAttachmentAnchorEl = useRef(null);
  const [editAttachmentOpen, setEditAttachmentOpen] = useState(false);

  const updateFileName = () => {
    if (kind !== "job") return;
    const originalFileName = attachments[index].fileName;
    const newFileName = `${fileNameNoExt}.${ext}`;
    attachments[index].fileName = newFileName;

    setAttachments([...attachments]);

    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ attachments: attachments })
      .catch((error) => {
        console.log(error);
      });

    dbx
      .filesMoveV2({
        allow_ownership_transfer: false,
        allow_shared_folder: false,
        autorename: false,
        from_path: `/-Job Attachments/${jobData.id}/${originalFileName}`,
        to_path: `/-Job Attachments/${jobData.id}/${newFileName}`,
      })
      .catch((e) => {
        setEditAttachmentOpen(false);
        console.log(e);
      });

    setEditAttachmentOpen(false);
  };

  const [deleteAttachmentOpen, setDeleteAttachmentOpen] = useState(false);

  const deleteFile = () => {
    if (kind !== "job") return;

    attachments.splice(index, 1);
    setAttachments([...attachments]);

    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ attachments: attachments })
      .catch((error) => {
        console.log(error);
      });

    setDeleteAttachmentOpen(false);
  };

  return (
    <>
      <Menu
        anchorEl={editAttachmentAnchorEl.current}
        open={editAttachmentOpen}
        onClose={() => {
          setEditAttachmentOpen(false);
        }}
        sx={{ borderRadius: 1, marginTop: 1, padding: 0 }}
      >
        <div
          style={{
            width: 300,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ marginTop: 2, marginBottom: 10 }}>
            Edit Attachment
          </span>

          <TextField
            value={fileNameNoExt}
            onChange={(e) => {
              setFileNameNoExt(e.target.value);
            }}
            sx={{ width: 280 }}
          />

          <Button
            onClick={updateFileName}
            sx={{ width: 280, marginTop: 2 }}
            variant="contained"
          >
            Update
          </Button>
        </div>
      </Menu>
      <Menu
        anchorEl={editAttachmentAnchorEl.current}
        open={deleteAttachmentOpen}
        onClose={() => {
          setDeleteAttachmentOpen(false);
        }}
        sx={{ borderRadius: 1, marginTop: 1, padding: 0 }}
      >
        <div
          style={{
            width: 300,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <span style={{ marginTop: 2, marginBottom: 5 }}>
            Delete Attachment
          </span>

          <Button
            onClick={deleteFile}
            sx={{ width: 280, marginTop: 2 }}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </div>
      </Menu>
      <div
        ref={editAttachmentAnchorEl}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 6,
          borderRadius: 4,
          cursor: "pointer",
          alignItems: "center",
        }}
        className="attachment"
        onClick={(e) => {
          // console.log(e.target.className);
          if (e.target.className === "editAttachment") {
            editAttachment();
            return;
          }
          if (e.target.className === "deleteAttachment") {
            deleteAttachment();
            return;
          }
          // console.log("open");
          // return;
          setUrlModalUrl(attachment.url);
          window.ipcRenderer?.send("eval", "setCalendarWindowBounds()");
          setURLModalOpen(true);
          // window.open(attachment.url, "_blank");
        }}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              background: isDarkMode
                ? "rgba(255, 255, 255, 0.1)"
                : "rgba(0, 0, 0, 0.1)",
              width: 85,
              height: 55,
              marginRight: 10,
              borderRadius: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
              fontSize: 16,
              display: "flex",
            }}
          >
            {showImage ? (
              <img
                src={rawURL}
                style={{ width: 85, height: 55, objectFit: "contain" }}
                alt="attachment"
              />
            ) : (
              <span>{ext}</span>
            )}
          </div>
          <div>
            <span
              style={{
                fontSize: 13,
                fontWeight: "bold",
                // display: "flex",
                // justifyContent: "space-between",
              }}
            >
              {attachment.fileName}
            </span>
            {/* <div>
          </div> */}
            <div style={{ display: kind === "job" ? "block" : "none" }}>
              <span
                style={{
                  fontSize: 12,
                  textDecoration: "underline",
                  marginRight: 10,
                }}
                className="editAttachment"
              >
                Edit
              </span>
              <span
                style={{
                  fontSize: 12,
                  textDecoration: "underline",
                  marginRight: 10,
                }}
                className="deleteAttachment"
              >
                Delete
              </span>
            </div>
          </div>
        </div>
        <IconButton
          sx={{ width: 45, height: 45 }}
          onClick={(e) => {
            e.stopPropagation();
            window.open(attachment.url, "_blank");
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      </div>
    </>
  );
};

import React from "react";
import Chip from "@mui/material/Chip";
import { useAtom } from "jotai";
import { themeAtom, labelsAtom, labelColoursAtom } from "../-Atoms";

const LabelChip = ({ label, setLabelMenuOpen, kanbanBoard }) => {
  const [theme] = useAtom(themeAtom);
  const [labels] = useAtom(labelsAtom);
  const [labelColours] = useAtom(labelColoursAtom);

  let labelData = {};
  labels.forEach((l) => {
    if (l.id === label) {
      labelData = l;
    }
  });

  const isHidden = () => {
    if (kanbanBoard && !labelData.color) return true;
    if (kanbanBoard && labelData.kanbanHidden) return true;
    return false;
  };

  return (
    <>
      {isHidden() ? null : (
        <Chip
          label={labelData.name}
          size="small"
          onClick={() => setLabelMenuOpen(true)}
          sx={{
            backgroundColor: labelData.color
              ? labelColours[labelData.color][theme]
              : "rgba(0, 0, 0, 0.4)",
            "&:hover": {
              backgroundColor: kanbanBoard
                ? labelData.color
                  ? labelColours[labelData.color][theme]
                  : "rgba(0, 0, 0, 0.2)"
                : newShade(
                    labelData.color
                      ? labelColours[labelData.color][theme]
                      : "rgba(0, 0, 0, 0.2)",
                    theme === "dark" ? -30 : 30
                  ),
            },
            height: kanbanBoard ? "" : 24,
            fontSize: kanbanBoard ? 11 : 13,
            color: "white",
            fontWeight: "bold",
            borderRadius: 0.6,
            margin: kanbanBoard ? 0 : 0,
            marginRight: kanbanBoard ? 1 : 0.4,
            marginBottom: kanbanBoard ? 1 : 0,
          }}
        />
      )}
    </>
  );
};

export default LabelChip;

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

import React from "react";

import { useAtom } from "jotai";
import { themeAtom } from "../../../-Atoms";
import Contacts from "./Contacts";
import Models from "./Models";
import HMUs from "./HMUs";

const People = ({ jobData, clientData }) => {
  const [theme] = useAtom(themeAtom);

  const titleStyle = {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 0,
    marginTop: 10,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: "bold",
    fontSize: "1.1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  return (
    <div style={{ padding: 10 }}>
      <div style={titleStyle}>
        <span>Client Contacts</span>
      </div>
      <Contacts clientData={clientData} />
      <div style={titleStyle}>
        <span>Models</span>
      </div>
      <Models jobData={jobData} />
      <div style={titleStyle}>
        <span>Hair & Makeup</span>
      </div>
      <HMUs jobData={jobData} />
    </div>
  );
};

export default People;

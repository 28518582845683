import React, { useState, useEffect, useCallback } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import debounce from "lodash.debounce";

const VideoEditingItem = ({
  item,
  items,
  setItems,
  index,
  showRemoveVideoEditingItem,
  firebase,
  jobData,
  staffData,
  findStaff,
}) => {
  const [date, setDate] = useState("");
  const [hours, setHours] = useState("");
  const [staff, setStaff] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    if (date !== item.date) setDate(item.date);
    if (hours !== item.hours) setHours(item.hours);
    if (staff !== item.staff) setStaff(item.staff);
    if (notes !== item.notes) setNotes(item.notes);
  }, [item, date, hours, staff, notes]);

  const updateFirestore = useCallback(
    debounce((updatedItems) => {
      const batch = firebase.firestore().batch();
      const docRef = firebase.firestore().collection("JobData").doc(jobData.id);
      batch.update(docRef, { videoEditingBreakdown: updatedItems });
      batch.commit().catch((e) => console.log(e));
    }, 500),
    []
  );

  const removeVideoEditingItem = () => {
    items.splice(index, 1);
    setItems([...items]);
    updateFirestore(items);
  };

  const handleChange = (field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = { ...item, [field]: value };
    setItems(updatedItems);
    updateFirestore(updatedItems);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 15,
        width: "100%",
        marginBottom: 10,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 15,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 15,
            width: "100%",
          }}
        >
          <Autocomplete
            disablePortal
            sx={{ width: "49%" }}
            options={staffData}
            getOptionLabel={(option) => findStaff(option, staffData)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                fullWidth
                inputProps={{ ...params.inputProps, style: { fontSize: 15 } }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  style: { fontSize: 15 },
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                size="small"
                label="Editor"
              />
            )}
            value={staff || ""}
            onChange={(e, value) => {
              const val = value?.id || "";
              setStaff(val);
              handleChange("staff", val);
            }}
          />
          <TextField
            inputProps={{ style: { fontSize: 15 } }}
            InputLabelProps={{ style: { fontSize: 15 } }}
            label="Hours"
            variant="filled"
            value={hours}
            size="small"
            sx={{ width: "49%" }}
            InputProps={{ disableUnderline: true }}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/[^0-9.]/g, "");
              setHours(numericValue);
              handleChange("hours", numericValue);
            }}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              label="Date"
              value={date || null}
              inputFormat="DD/MM/YYYY"
              onChange={(d) => {
                if (!d || !d.isValid()) return;
                const dateValue = d.toISOString();
                setDate(dateValue);
                handleChange("date", dateValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  sx={{ width: "49%" }}
                  inputProps={{
                    ...params.inputProps,
                    style: { fontSize: 15 },
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    style: { fontSize: 15 },
                  }}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                  size="small"
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <TextField
          inputProps={{ style: { fontSize: 15 } }}
          InputLabelProps={{ style: { fontSize: 15 } }}
          label="Notes"
          variant="filled"
          value={notes}
          size="small"
          fullWidth
          multiline
          InputProps={{ disableUnderline: true }}
          onChange={(e) => {
            const notesValue = e.target.value;
            setNotes(notesValue);
            handleChange("notes", notesValue);
          }}
        />
      </div>
      <IconButton
        sx={{ display: showRemoveVideoEditingItem ? "block" : "none" }}
        onClick={removeVideoEditingItem}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

export default VideoEditingItem;

import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";

const AdditionalStaffContainer = ({
  kind,
  additionalStaff,
  setAdditionalStaff,
  staffData,
  firebase,
  jobData,
  checkIfStaffAssigned,
  events,
  event,
  findStaffAvatar,
  additionalIndex,
}) => {
  return (
    <>
      <div>
        {additionalStaff &&
          additionalStaff.map((p, i) => (
            <AdditionalStaff
              key={`${kind}-${i}`}
              kind={kind}
              staff={p}
              index={i}
              staffData={staffData}
              additionalStaff={additionalStaff}
              setAdditionalStaff={setAdditionalStaff}
              firebase={firebase}
              jobData={jobData}
              checkIfStaffAssigned={checkIfStaffAssigned}
              events={events}
              event={event}
              findStaffAvatar={findStaffAvatar}
              additionalIndex={additionalIndex}
            />
          ))}
      </div>
    </>
  );
};

export default AdditionalStaffContainer;

const AdditionalStaff = ({
  kind,
  staff,
  index,
  staffData,
  additionalStaff,
  setAdditionalStaff,
  firebase,
  jobData,
  checkIfStaffAssigned,
  events,
  event,
  findStaffAvatar,
  additionalIndex,
}) => {
  const removeAdditionalStaff = async (index) => {
    if (additionalIndex === undefined) {
      additionalStaff.splice(index, 1);
      setAdditionalStaff([...additionalStaff]);
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({
          [`additional${capitalizeFirstLetter(kind)}s`]: additionalStaff,
        });
    } else {
      jobData.additionalDates[additionalIndex][
        `additional${capitalizeFirstLetter(kind)}s`
      ].splice(index, 1);
      firebase.firestore().collection("JobData").doc(jobData.id).update({
        additionalDates: jobData.additionalDates,
      });
    }
  };
  const addAdditionalStaff = async (value, index) => {
    if (additionalIndex === undefined) {
      additionalStaff[index] = value;
      setAdditionalStaff([...additionalStaff]);
      firebase
        .firestore()
        .collection("JobData")
        .doc(jobData.id)
        .update({
          [`additional${capitalizeFirstLetter(kind)}s`]: additionalStaff,
        });
    } else {
      jobData.additionalDates[additionalIndex][
        `additional${capitalizeFirstLetter(kind)}s`
      ][index] = value;
      firebase.firestore().collection("JobData").doc(jobData.id).update({
        additionalDates: jobData.additionalDates,
      });
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
        marginBottom: index === additionalStaff.length - 1 ? 25 : 0,
      }}
    >
      <Autocomplete
        disablePortal
        sx={{ marginLeft: 0 }}
        options={[...staffData, "Not Required", "External"]}
        fullWidth
        getOptionLabel={(option) => findStaff(option, staffData)}
        renderOption={(props, option) => (
          <li {...props}>
            <span
              style={{
                color: checkIfStaffAssigned(option, events, event, jobData)
                  ? "#d13c36"
                  : "",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Avatar
                style={{ marginRight: 10 }}
                alt={findStaff(option, staffData)}
                src={findStaffAvatar(option, staffData)}
              />
              {findStaff(option, staffData)}
            </span>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`Additional ${capitalizeFirstLetter(kind, jobData)}`}
            variant="filled"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              startAdornment: (
                <Avatar
                  sx={{ height: 28, width: 28 }}
                  style={{ marginRight: 10 }}
                  alt={staff}
                  src={findStaffAvatar(staff, staffData)}
                />
              ),
            }}
          />
        )}
        value={staff || ""}
        onChange={(e, value) => {
          let val = "";
          if (value?.id) val = value.id;
          if (value === "Not Required") val = "Not Required";
          if (value === "External") val = "External";

          addAdditionalStaff(val, index);
        }}
      />
      <IconButton
        style={{ marginRight: -8, marginLeft: 20 }}
        onClick={() => {
          removeAdditionalStaff(index);
        }}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

const findStaff = (option, staffData) => {
  if (option.firstName) return `${option.firstName} ${option.lastName}`;
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === option) {
      return `${staffData[i].firstName} ${staffData[i].lastName}`;
    }
  }
  return option;
};

function capitalizeFirstLetter(string, jobData) {
  if (jobData?.studio === "Critical Events") return "Staff";
  if (
    jobData?.jobTypeFull?.includes("-V") ||
    jobData?.jobTypeFull?.includes("-E")
  ) {
    return "Videographer";
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

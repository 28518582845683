import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Slide from "@mui/material/Slide";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";

const ModelsContainer = ({
  firebase,
  jobData,
  models,
  setModels,
  canEdit,
  theme,
}) => {
  const [newModelModalOpen, setNewModelModalOpen] = useState(false);

  const [editExistingModelData, setEditExistingModelData] = useState({});
  const [editExistingModelIndex, setEditExistingModelIndex] = useState(0);

  const parseCallFinishTime = (time) => {
    let date;
    try {
      date = new Date(time);
    } catch (e) {
      return "";
    }
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const handleRowClick = (e, model, index) => {
    if (!canEdit) return;
    if (!Array.from(e.target.classList).includes("MuiTableCell-root")) return;
    toggleNewModelModalOpen(model, index);
  };
  const toggleNewModelModalOpen = (model = {}, index = 0) => {
    if (!canEdit) return;
    if (!newModelModalOpen) {
      setEditExistingModelData(model);
      setEditExistingModelIndex(index);
      setNewModelModalOpen(true);
    } else {
      setEditExistingModelData({});
      setEditExistingModelIndex(0);
      setNewModelModalOpen(false);
    }
  };

  const addNewModel = async (newModelObject) => {
    if (!canEdit) return;
    models.push(newModelObject);
    setModels([...models]);
    await firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ models: models }, { merge: true });
    setNewModelModalOpen(false);
  };
  const removeModel = (index) => {
    if (!canEdit) return;
    models.splice(index, 1);
    setModels([...models]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ models: models }, { merge: true });
  };
  const editModel = (model, index) => {
    if (!canEdit) return;
    models[index] = model;
    setModels([...models]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ models: models }, { merge: true });
    toggleNewModelModalOpen();
  };
  const tableCellStyle = {
    paddingTop: 2,
    paddingBottom: 2,
    height: 20,
  };
  const titleContainerStyle = {
    marginLeft: 5,
    marginBottom: 5,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const tableContainerStyle = {
    marginLeft: 2,
    width: "99.5%",
    background: "rgba(0,0,0,0.01)",
    border:
      theme === "dark"
        ? "1px solid rgba(230,230,230,0.3)"
        : "1px solid rgba(25,25,25,0.3)",
    borderRadius: 5,
  };
  return (
    <div style={{ marginTop: 90 }}>
      <Slide
        direction="right"
        in={newModelModalOpen}
        mountOnEnter
        unmountOnExit
      >
        <div style={{ background: theme === "dark" ? "#1f1f1f" : "#ffffff" }}>
          <NewModelModal
            addNewModel={addNewModel}
            modelData={editExistingModelData}
            index={editExistingModelIndex}
            editModel={editModel}
          />
        </div>
      </Slide>
      <div style={titleContainerStyle}>
        <span>Models</span>
        <IconButton
          onClick={toggleNewModelModalOpen}
          style={{ marginRight: -8 }}
        >
          <AddIcon />
        </IconButton>
      </div>
      <div style={tableContainerStyle}>
        <TableContainer style={{ height: 220 }}>
          <Table sx={{ minWidth: 600 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                    borderRadius: "5px 0px 0px 0px",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                  }}
                  align="right"
                >
                  Agency
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                  }}
                  align="right"
                >
                  Call Time
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                  }}
                  align="right"
                >
                  Finish Time
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                    borderRadius: "0px 5px 0px 0px",
                  }}
                  align="right"
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                background:
                  theme === "dark"
                    ? "rgba(255, 255, 255, 0.02)"
                    : "rgba(0, 0, 0, 0.02)",
              }}
            >
              {models &&
                models?.map((model, index) => (
                  <TableRow
                    key={`${model.name}-${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": {
                        background:
                          theme === "dark"
                            ? "rgba(255, 255, 255, 0.02)"
                            : "rgba(0, 0, 0, 0.02)",
                      },
                      height: 45,
                    }}
                    onClick={(e) => {
                      handleRowClick(e, model, index);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={tableCellStyle}
                      component="th"
                      scope="row"
                    >
                      {model.name}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      {model.agency}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      {parseCallFinishTime(model.callTime)}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      {parseCallFinishTime(model.finishTime)}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      <IconButton
                        onClick={() => {
                          removeModel(index);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ModelsContainer;

const NewModelModal = ({ addNewModel, modelData, index, editModel }) => {
  const [newModelName, setNewModelName] = useState(modelData?.name || "");
  const [newModelAgency, setNewModelAgency] = useState(modelData?.agency || "");
  const [newModelCallTime, setNewModelCallTime] = useState(
    modelData?.callTime || null
  );
  const [newModelFinishTime, setNewModelFinishTime] = useState(
    modelData?.finishTime || null
  );
  const [newModelFaceNoFace, setNewModelFaceNoFace] = useState(
    modelData?.faceNoFace || ""
  );
  const [newModelBookedBy, setNewModelBookedBy] = useState(
    modelData?.bookedBy || ""
  );
  const [newModelPaidBy, setNewModelPaidBy] = useState(modelData?.paidBy || "");

  const [newModelNameError, setNewModelNameError] = useState(false);
  const [newModelAgencyError, setNewModelAgencyError] = useState(false);
  const [newModelCallTimeError, setNewModelCallTimeError] = useState(false);
  const [newModelFinishTimeError, setNewModelFinishTimeError] = useState(false);
  const [newModelFaceNoFaceError, setNewModelFaceNoFaceError] = useState(false);
  const [newModelBookedByError, setNewModelBookedByError] = useState(false);
  const [newModelPaidByError, setNewModelPaidByError] = useState(false);

  const MUITheme = useMUITheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down("md"));

  const containerStyle = {
    display: "flex",
    justifyContent: isMobile ? "center" : "left",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 20,
  };
  const textFieldStyle = {
    width: isMobile ? "45%" : 145,
    margin: 5,
  };
  const handleSave = async (
    name,
    agency,
    callTime,
    finishTime,
    faceNoFace,
    bookedBy,
    paidBy
  ) => {
    if (!modelData.name) {
      let error = false;
      if (!name) {
        setNewModelNameError(true);
        error = true;
      }
      if (!agency) {
        setNewModelAgencyError(true);
        error = true;
      }
      if (!callTime) {
        setNewModelCallTimeError(true);
        error = true;
      }
      if (!finishTime) {
        setNewModelFinishTimeError(true);
        error = true;
      }
      if (!faceNoFace) {
        setNewModelFaceNoFaceError(true);
        error = true;
      }
      if (!bookedBy) {
        setNewModelBookedByError(true);
        error = true;
      }
      if (!paidBy) {
        setNewModelPaidByError(true);
        error = true;
      }
      if (error) return;
      await addNewModel({
        name,
        agency,
        callTime,
        finishTime,
        faceNoFace,
        bookedBy,
        paidBy,
      });
    } else {
      await editModel(
        { name, agency, callTime, finishTime, faceNoFace, bookedBy, paidBy },
        index
      );
    }
  };

  return (
    <div style={containerStyle}>
      <TextField
        label="Name"
        variant="outlined"
        size="small"
        style={textFieldStyle}
        error={newModelNameError}
        value={newModelName || ""}
        onChange={(e) => {
          setNewModelName(e.target.value);
          setNewModelNameError(false);
        }}
      />
      <TextField
        label="Agency"
        variant="outlined"
        size="small"
        style={textFieldStyle}
        error={newModelAgencyError}
        value={newModelAgency || ""}
        onChange={(e) => {
          setNewModelAgency(e.target.value);
          setNewModelAgencyError(false);
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="Call Time"
          value={newModelCallTime || null}
          onChange={(e) => {
            try {
              setNewModelCallTime(e.toISOString());
              setNewModelCallTimeError(false);
            } catch (e) {
              setNewModelCallTimeError(true);
            }
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              style={textFieldStyle}
              {...params}
              error={newModelCallTimeError}
            />
          )}
        />
        <TimePicker
          label="Finish Time"
          value={newModelFinishTime || null}
          onChange={(e) => {
            try {
              setNewModelFinishTime(e.toISOString());
              setNewModelFinishTimeError(false);
            } catch (e) {
              setNewModelFinishTimeError(true);
            }
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              style={textFieldStyle}
              {...params}
              error={newModelFinishTimeError}
            />
          )}
        />
      </LocalizationProvider>
      <FormControl size="small" style={textFieldStyle}>
        <InputLabel id="faceNoFaceLabel">Face / No Face</InputLabel>
        <Select
          labelId="faceNoFaceLabel"
          error={newModelFaceNoFaceError}
          value={newModelFaceNoFace}
          label="Face / No Face"
          onChange={(e) => {
            setNewModelFaceNoFace(e.target.value);
            setNewModelFaceNoFaceError(false);
          }}
        >
          <MenuItem value="Face">Face</MenuItem>
          <MenuItem value="No Face">No Face</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" style={textFieldStyle}>
        <InputLabel id="modelBookedByLabel">Booked By</InputLabel>
        <Select
          labelId="modelBookedByLabel"
          error={newModelBookedByError}
          value={newModelBookedBy}
          label="Booked By"
          onChange={(e) => {
            setNewModelBookedBy(e.target.value);
            setNewModelBookedByError(false);
          }}
        >
          <MenuItem value="Paid By Client">Booked By Client</MenuItem>
          <MenuItem value="Paid By ITS">Booked By ITS</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" style={textFieldStyle}>
        <InputLabel id="modelPaidByLabel">Paid By</InputLabel>
        <Select
          labelId="modelPaidByLabel"
          error={newModelPaidByError}
          value={newModelPaidBy}
          label="Paid By"
          onChange={(e) => {
            setNewModelPaidBy(e.target.value);
            setNewModelPaidByError(false);
          }}
        >
          <MenuItem value="Paid By Client">Paid By Client</MenuItem>
          <MenuItem value="Paid By ITS">Paid By ITS</MenuItem>
        </Select>
      </FormControl>
      <Button
        style={textFieldStyle}
        variant="outlined"
        onClick={() => {
          handleSave(
            newModelName,
            newModelAgency,
            newModelCallTime,
            newModelFinishTime,
            newModelFaceNoFace,
            newModelBookedBy,
            newModelPaidBy
          );
        }}
      >
        Save
      </Button>
    </div>
  );
};

import React, { useState } from "react";

import { useAtom } from "jotai";
import {
  themeAtom,
  labelsAtom,
  labelColoursAtom,
  isAdminAtom,
} from "../../../-Atoms";

import Menu from "@mui/material/Menu";
import { Button, IconButton, TextField } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AddIcon from "@mui/icons-material/Add";

import LabelItem from "./LabelItem";
import { v4 as uuidv4 } from "uuid";

const LabelMenu = ({ firebase, jobData, open, setOpen, labelsContainer }) => {
  const [theme] = useAtom(themeAtom);
  const [labels, setLabels] = useAtom(labelsAtom);
  const [labelColours] = useAtom(labelColoursAtom);
  const [isAdmin] = useAtom(isAdminAtom);

  const [labelMenuEditMode, setLabelMenuEditMode] = useState(false);
  const [labelToEdit, setLabelToEdit] = useState({});

  const labelChipColourStyle = {
    width: 45,
    height: 25,
    borderRadius: 3,
    margin: 3,
    cursor: "pointer",
  };

  const itemArrowStyle = {
    fontSize: 27,
    cursor: "pointer",
    "&:hover": {
      color: theme === "dark" ? "rgb(150,180,180)" : "rgb(90,70,70)",
    },
  };

  return (
    <Menu
      anchorEl={labelsContainer.current}
      open={open}
      onClose={() => {
        setOpen(false);
        setLabelMenuEditMode(false);
        setLabelToEdit({});
      }}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      sx={{ borderRadius: 1, marginTop: 1, padding: 0 }}
    >
      <div
        style={{
          width: 330,
          maxHeight: 600,
          padding: 10,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {labelMenuEditMode ? (
          <>
            <IconButton
              size="small"
              sx={{ position: "absolute", left: 5, top: 10 }}
              onClick={() => {
                setLabelMenuEditMode(false);
                setLabelToEdit({});
              }}
            >
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
            <span style={{ marginTop: -6, marginBottom: 12 }}>Edit Label</span>

            <div
              style={{
                fontSize: 13,
                fontWeight: "bold",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                cursor: "pointer",
                borderRadius: 4,
                width: "80%",
                backgroundColor: labelToEdit.color
                  ? labelColours[labelToEdit.color][theme]
                  : "rgba(0, 0, 0, 0.1)",
                marginLeft: 5,
                marginRight: 5,
                marginTop: 20,
                marginBottom: 50,
                padding: 5,
              }}
            >
              {labelToEdit.name}
            </div>
            <TextField
              label="Label"
              value={labelToEdit.name}
              onChange={(e) => {
                let obj = {
                  name: e.target.value,
                  id: labelToEdit.id,
                  color: labelToEdit.color,
                };
                setLabelToEdit(obj);
              }}
              size="small"
              sx={{
                width: "80%",
              }}
              variant="filled"
              InputProps={{ disableUnderline: true }}
            />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.green_light[theme],
                  border:
                    labelToEdit.color === "green_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "green_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.yellow_light[theme],
                  border:
                    labelToEdit.color === "yellow_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "yellow_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.orange_light[theme],
                  border:
                    labelToEdit.color === "orange_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "orange_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.red_light[theme],
                  border:
                    labelToEdit.color === "red_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "red_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.purple_light[theme],
                  border:
                    labelToEdit.color === "purple_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "purple_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.green[theme],
                  border:
                    labelToEdit.color === "green" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "green",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.yellow[theme],
                  border:
                    labelToEdit.color === "yellow" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "yellow",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.orange[theme],
                  border:
                    labelToEdit.color === "orange" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "orange",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.red[theme],
                  border:
                    labelToEdit.color === "red" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "red",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.purple[theme],
                  border:
                    labelToEdit.color === "purple" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "purple",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.green_dark[theme],
                  border:
                    labelToEdit.color === "green_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "green_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.yellow_dark[theme],
                  border:
                    labelToEdit.color === "yellow_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "yellow_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.orange_dark[theme],
                  border:
                    labelToEdit.color === "orange_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "orange_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.red_dark[theme],
                  border:
                    labelToEdit.color === "red_dark" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "red_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.purple_dark[theme],
                  border:
                    labelToEdit.color === "purple_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "purple_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.blue_light[theme],
                  border:
                    labelToEdit.color === "blue_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "blue_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.sky_light[theme],
                  border:
                    labelToEdit.color === "sky_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "sky_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.lime_light[theme],
                  border:
                    labelToEdit.color === "lime_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "lime_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.pink_light[theme],
                  border:
                    labelToEdit.color === "pink_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "pink_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.black_light[theme],
                  border:
                    labelToEdit.color === "black_light"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "black_light",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.blue[theme],
                  border:
                    labelToEdit.color === "blue" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "blue",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.sky[theme],
                  border:
                    labelToEdit.color === "sky" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "sky",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.lime[theme],
                  border:
                    labelToEdit.color === "lime" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "lime",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.pink[theme],
                  border:
                    labelToEdit.color === "pink" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "pink",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.black[theme],
                  border:
                    labelToEdit.color === "black" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "black",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.blue_dark[theme],
                  border:
                    labelToEdit.color === "blue_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "blue_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.sky_dark[theme],
                  border:
                    labelToEdit.color === "sky_dark" ? "2px solid #5E9EF8" : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "sky_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.lime_dark[theme],
                  border:
                    labelToEdit.color === "lime_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "lime_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.pink_dark[theme],
                  border:
                    labelToEdit.color === "pink_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "pink_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
              <div
                style={{
                  ...labelChipColourStyle,
                  backgroundColor: labelColours.black_dark[theme],
                  border:
                    labelToEdit.color === "black_dark"
                      ? "2px solid #5E9EF8"
                      : "",
                }}
                onClick={() => {
                  let obj = {
                    name: labelToEdit.name,
                    id: labelToEdit.id,
                    color: "black_dark",
                    kanbanHidden: labelToEdit.kanbanHidden || false,
                  };
                  setLabelToEdit(obj);
                }}
              ></div>
            </div>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              sx={{ width: "95%", marginTop: 2, marginBottom: 1 }}
              onClick={() => {
                let obj = {
                  name: labelToEdit.name,
                  id: labelToEdit.id,
                  color: null,
                  kanbanHidden: labelToEdit.kanbanHidden || false,
                };
                setLabelToEdit(obj);
              }}
            >
              Remove Colour
            </Button>

            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={labelToEdit.kanbanHidden}
                    onChange={(e, checked) => {
                      let obj = {
                        name: labelToEdit.name,
                        id: labelToEdit.id,
                        color: null,
                        kanbanHidden: checked || false,
                      };
                      setLabelToEdit(obj);
                    }}
                  />
                }
                label="Hide on Kanban Board"
              />
            </FormGroup>

            <div
              style={{
                marginTop: 20,
                width: "90%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                size="small"
                onClick={() => {
                  labels.forEach(async (l, i) => {
                    if (l.id === labelToEdit.id) {
                      labels[i] = labelToEdit;

                      console.log(labels);
                      // return;
                      await firebase
                        .firestore()
                        .collection("AdminCollection")
                        .doc("labels")
                        .update({ labels: [...labels] });
                      // .update({ labels: labels, colours: labelColours });

                      setLabelMenuEditMode(false);
                      setLabelToEdit({});
                    }
                  });
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  labels.forEach(async (l, i) => {
                    if (l.id === labelToEdit.id) {
                      labels.splice(i, 1);
                      await firebase
                        .firestore()
                        .collection("AdminCollection")
                        .doc("labels")
                        .update({ labels: [...labels] });

                      setLabelMenuEditMode(false);
                      setLabelToEdit({});
                    }
                  });
                }}
              >
                Delete
              </Button>
            </div>
          </>
        ) : (
          <>
            <IconButton
              sx={{ position: "absolute", top: 12, right: 6 }}
              onClick={() => {
                labels.push({
                  color: "",
                  id: uuidv4(),
                  name: "NEW LABEL",
                  kanbanHidden: false,
                });
                setLabels([...labels]);
                firebase
                  .firestore()
                  .collection("AdminCollection")
                  .doc("labels")
                  .update({ labels: [...labels] });
              }}
            >
              <AddIcon />
            </IconButton>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  fontSize: 20,
                  marginTop: 0,
                  marginBottom: 25,
                }}
              >
                Labels
              </span>
              {labels &&
                labels.map((label, index) => {
                  const toggleCheck = () => {
                    if (jobData?.idLabels?.includes(label.id)) {
                      jobData?.idLabels.splice(
                        jobData?.idLabels.indexOf(label.id),
                        1
                      );
                    } else {
                      jobData?.idLabels.push(label.id);
                    }
                    firebase
                      .firestore()
                      .collection("JobData")
                      .doc(jobData.id)
                      .update({ idLabels: jobData.idLabels });
                  };
                  if (
                    jobData?.idLabels?.includes(label.id) &&
                    !jobData?.idLabelsPermanent?.includes(label.id)
                  ) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        {isAdmin ? (
                          <div style={{ width: 26 }}>&zwnj;</div>
                        ) : null}
                        <LabelItem
                          key={`labelItemKey_${index}`}
                          index={index}
                          jobData={jobData}
                          toggleCheck={toggleCheck}
                          label={label}
                          setLabelMenuEditMode={setLabelMenuEditMode}
                          setLabelToEdit={setLabelToEdit}
                          //   itemExists={}
                        />
                      </div>
                    );
                  } else return null;
                })}
              {jobData?.idLabels?.length > 0 ? (
                <div
                  style={{
                    marginBottom: 10,
                    height: 10,
                    width: "100%",
                    borderBottom: "1px solid grey",
                  }}
                >
                  &nbsp;
                </div>
              ) : null}
              {labels &&
                labels.map((label, index) => {
                  const toggleCheck = () => {
                    if (jobData?.idLabels?.includes(label.id)) {
                      jobData?.idLabels.splice(
                        jobData?.idLabels.indexOf(label.id),
                        1
                      );
                    } else {
                      jobData?.idLabels.push(label.id);
                    }
                    firebase
                      .firestore()
                      .collection("JobData")
                      .doc(jobData.id)
                      .update({ idLabels: jobData.idLabels });
                  };
                  // if (
                  //   !jobData?.idLabels?.includes(label.id) &&
                  //   !jobData?.idLabelsPermanent?.includes(label.id)
                  // ) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        //   marginBottom: 5,
                      }}
                    >
                      {isAdmin ? (
                        <span
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <ArrowDropUpIcon
                            sx={{ ...itemArrowStyle, marginBottom: -0.5 }}
                            onClick={async () => {
                              const newLabelsArray = shiftItemUp(labels, index);
                              setLabels([...newLabelsArray]);
                              await firebase
                                .firestore()
                                .collection("AdminCollection")
                                .doc("labels")
                                .update({ labels: [...newLabelsArray] });
                            }}
                          />
                          <ArrowDropDownIcon
                            sx={{ ...itemArrowStyle, marginTop: -0.5 }}
                            onClick={async () => {
                              const newLabelsArray = shiftItemDown(
                                labels,
                                index
                              );
                              setLabels([...newLabelsArray]);
                              await firebase
                                .firestore()
                                .collection("AdminCollection")
                                .doc("labels")
                                .update({ labels: [...newLabelsArray] });
                            }}
                          />
                        </span>
                      ) : null}
                      <LabelItem
                        key={`labelItemKey2_${index}`}
                        index={index}
                        jobData={jobData}
                        toggleCheck={toggleCheck}
                        label={label}
                        setLabelMenuEditMode={setLabelMenuEditMode}
                        setLabelToEdit={setLabelToEdit}
                      />
                    </div>
                  );
                  // } else return null;
                })}
            </div>
          </>
        )}
        <div style={{ width: "100%", height: 100 }}>&zwnj;</div>
      </div>
    </Menu>
  );
};

export default LabelMenu;

function shiftItemUp(array, index) {
  if (index === 0) return array;
  const temp = array[index - 1];
  array[index - 1] = array[index];
  array[index] = temp;
  return array;
}
function shiftItemDown(array, index) {
  if (index === array.length - 1) return array;
  const temp = array[index + 1];
  array[index + 1] = array[index];
  array[index] = temp;
  return array;
}

import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const EventTrackingContainer = ({ tracking, staffData, theme }) => {
  const outerStyle = {
    marginLeft: 5,
    marginBottom: 10,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
  };
  const innerStyle = {
    marginLeft: 2,
    width: "99.5%",
    height: 220,
    background:
      theme === "dark" ? "rgba(255,255,255,0.01)" : "rgba(0,0,0,0.01)",
    border:
      theme === "dark"
        ? "1px solid rgba(230,230,230,0.3)"
        : "1px solid rgba(25,25,25,0.3)",
    borderRadius: 5,
    overflowY: "auto",
  };
  return (
    <>
      <div style={{ marginTop: 40 }}>
        <div style={outerStyle}>Event Tracking</div>
        <div style={innerStyle}>
          <TableContainer style={{ height: 220 }}>
            <Table sx={{ minWidth: 600 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                      borderRadius: "5px 0px 0px 0px",
                    }}
                  >
                    Kind
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                    }}
                  >
                    Modified By
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                    }}
                  >
                    Date Modified
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                    }}
                  >
                    New
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                    }}
                  >
                    Previous
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tracking.modified &&
                  tracking.modified.map((m, index) => (
                    <EventTracking
                      key={`modified-${m.at}-${m.kind}-${index}`}
                      modified={m}
                      index={index}
                      staffData={staffData}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      {/* <div style={{ marginTop: 20 }}>
        <div style={outerStyle}>Trello Card Tracking</div>
        <div style={innerStyle}>
          <TableContainer style={{ height: 220 }}>
            <Table sx={{ minWidth: 600 }} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                      borderRadius: "5px 0px 0px 0px",
                    }}
                  >
                    Kind
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                    }}
                  >
                    Modified By
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                    }}
                  >
                    Date Modified
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        theme === "dark"
                          ? "rgba(26,26,26,1)"
                          : "rgba(252,252,252,1)",
                    }}
                  >
                    Card ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tracking.trelloCardModified &&
                  tracking.trelloCardModified.map((tcm, index) => (
                    <TrelloCardTracking
                      key={`trelloCardModified-${tcm.cardID}-${tcm.kind}-${index}`}
                      trelloCardModified={tcm}
                      index={index}
                      staffData={staffData}
                    />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div> */}
    </>
  );
};

export default EventTrackingContainer;
const EventTracking = ({ modified, index, staffData }) => {
  const tableCellStyle = {
    paddingTop: 2,
    paddingBottom: 2,
    height: 20,
  };
  return (
    <TableRow
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        "&:hover": { background: "rgba(0, 0, 0, 0.02)" },
        height: 45,
      }}
    >
      <TableCell style={tableCellStyle} component="th" scope="row">
        {capitalizeFirstLetter(modified.kind)}
      </TableCell>
      <TableCell style={tableCellStyle} component="th" scope="row">
        {findStaffNameById(staffData, modified.by)}
      </TableCell>
      <TableCell style={tableCellStyle} component="th" scope="row">
        {parseDateTime(modified.at)}
      </TableCell>
      <TableCell style={tableCellStyle} component="th" scope="row">
        <div>Start: {formatAMPM(new Date(modified.new.start))}</div>
        <div>End: {formatAMPM(new Date(modified.new.end))}</div>
        <div>All Day: {modified.new.allDay.toString()}</div>
      </TableCell>
      <TableCell style={tableCellStyle} component="th" scope="row">
        <div>Start: {formatAMPM(new Date(modified.prev.start))}</div>
        <div>End: {formatAMPM(new Date(modified.prev.end))}</div>
        <div>All Day: {modified.prev.allDay.toString()}</div>
      </TableCell>
    </TableRow>
  );
};
// const TrelloCardTracking = ({ trelloCardModified, index, staffData }) => {
//   const tableCellStyle = {
//     paddingTop: 2,
//     paddingBottom: 2,
//     height: 20,
//   };
//   return (
//     <TableRow
//       sx={{
//         "&:last-child td, &:last-child th": { border: 0 },
//         "&:hover": { background: "rgba(0, 0, 0, 0.02)" },
//         height: 45,
//       }}
//     >
//       <TableCell style={tableCellStyle} component="th" scope="row">
//         {capitalizeFirstLetter(trelloCardModified.kind)}
//       </TableCell>
//       <TableCell style={tableCellStyle} component="th" scope="row">
//         {findStaffNameById(staffData, trelloCardModified.by)}
//       </TableCell>
//       <TableCell style={tableCellStyle} component="th" scope="row">
//         {parseDateTime(trelloCardModified.at)}
//       </TableCell>
//       <TableCell style={tableCellStyle} component="th" scope="row">
//         {trelloCardModified.trelloCardID}
//       </TableCell>
//     </TableRow>
//   );
// };

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const findStaffNameById = (staffData, id) => {
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === id) {
      return `${staffData[i].firstName} ${staffData[i].lastName}`;
    }
  }
};

const parseDateTime = (date) => {
  const d = new Date(date);
  const year = d.getFullYear().toString().substring(2, 4);
  const month = d.getMonth() + 1;
  const day = d.getDate();

  return `${day}-${month}-${year} ${formatAMPM(d)}`;
};

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

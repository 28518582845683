import React, { useState, useEffect } from "react";

import { Box, Button, TextField } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import CircularProgress from "@mui/material/CircularProgress";

import { UpdateMetaThemeColour } from "./Utils/UpdateMetaThemeColour";

import Logo from "./Login/Logo";

import { getAuth, signInWithPhoneNumber } from "firebase/auth";

const countryCodes = require("./countryCodes.json");

// const darkMode = "#1f1f1f";
const darkMode = "#1f1f1f";
const lightMode = "#f4f5f7";

const Login = ({ user, firebase }) => {
  const auth = getAuth();
  const checkNumberRegistered = firebase
    .functions()
    .httpsCallable("checkNumberRegistered");

  UpdateMetaThemeColour({ light: lightMode, dark: darkMode });

  const [isDarkMode, setIsDarkMode] = useState(true);
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        setIsDarkMode(e.matches ? true : false)
      );
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("AU");
  const [verifyingNumber, setVerifyingNumber] = useState(false);
  const [verifyingButtonText, setVerifyingButtonText] = useState("Verify");
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationCodeError, setVerificationCodeError] = useState(false);

  const [verificationCodeButtonText, setVerificationCodeButtonText] =
    useState("Login");
  const [numberVerified, setNumberVerified] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState("");
  const [applicationVerifier, setApplicationVerifier] = useState(null);

  useEffect(() => {
    const av = new firebase.auth.RecaptchaVerifier("login-captcha-container", {
      size: "invisible",
      callback: (response) => {},
    });
    setApplicationVerifier(av);
  }, [setApplicationVerifier, firebase]);

  // STYLES
  const loginBoxStyle = {
    width: "90vw",
    maxWidth: 320,
    height: 450,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0 0 20px rgba(0,0,0,0.5)",
    borderRadius: 15,
    padding: 10,
  };

  const inputStyle = {
    width: "80%",
    boxShadow: "0 0 10px rgba(0,0,0,0.2)",
  };

  const innerStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };
  // STYLES

  // FUNCTIONS
  const handlePhoneChange = (e) => {
    if (phoneError) setPhoneError(false);
    setPhone(e.target.value.replace(/^04/, 4));
  };
  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value);
  };
  const handleVerify = async () => {
    if (verifyingNumber) return;
    if (!phone) {
      setPhoneError(true);
      return;
    }
    // let sanitisedPhone = e.target.value.replace(/^04/, 4)
    setVerifyingNumber(true);
    setVerifyingButtonText(
      <CircularProgress size={24} sx={{ color: "black" }} />
    );

    const cc = countryCodes.find((obj) => obj.code === selectedCountryCode);

    const intPhone = `${cc.dialCode}${phone}`;
    const registered = await checkNumberRegistered({ phoneNumber: intPhone });
    console.log(registered);
    if (!registered) return;

    const confirmRes = await signInWithPhoneNumber(
      auth,
      intPhone,
      applicationVerifier
    ).catch((error) => {
      console.log(error);
      applicationVerifier.render().then(function (widgetId) {
        window.grecaptcha.reset(widgetId);
      });
      alert("Invalid Phone Number, please ensure the number is correct");
      setPhone("");
      setVerifyingNumber(false);
      setVerifyingButtonText("Verify");
    });
    if (!confirmRes) return;
    setConfirmationResult(confirmRes);
    setVerifyingNumber(false);
    setNumberVerified(true);
  };
  const handleLogin = async () => {
    setVerificationCodeButtonText(
      <CircularProgress size={24} sx={{ color: "black" }} />
    );

    confirmationResult
      .confirm(verificationCode)
      .then((result) => {
        setVerificationCodeError(false);
        setVerificationCodeButtonText("Login");
      })
      .catch((error) => {
        setVerificationCodeButtonText("Login");
        setVerificationCodeError(true);
        setVerificationCode("");
        applicationVerifier.render().then(function (widgetId) {
          window.grecaptcha.reset(widgetId);
        });
        console.log(error.code);
        if (error.code === "auth/user-disabled") {
          alert("Your account has been disabled by the administration team.");
        } else {
          alert(
            "Incorrect Verification Code, double check it is correct and try again"
          );
        }
      });
  };

  // FUNCTIONS

  return (
    <>
      <div id="login-captcha-container"></div>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={loginBoxStyle}>
          <Logo isDarkMode={isDarkMode} hasShadow={true} />
          <div style={innerStyle}>
            {!numberVerified ? (
              <>
                <FormControl
                  disabled={verifyingNumber}
                  variant="filled"
                  sx={{ ...inputStyle, margin: 0.5 }}
                  size="small"
                >
                  <InputLabel id="countryCodeSelectorLabel">
                    Country Code
                  </InputLabel>
                  <Select
                    labelId="countryCodeSelectorLabel"
                    value={selectedCountryCode}
                    label="Country Code"
                    onChange={(e) => {
                      setSelectedCountryCode(e.target.value);
                    }}
                    disableUnderline
                  >
                    {countryCodes &&
                      countryCodes.map((cc, index) => (
                        <MenuItem
                          key={`${cc.code}${index}`}
                          value={cc.code}
                        >{`${cc.emoji} ${cc.name} (${cc.dialCode})`}</MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <TextField
                  label="Phone Number"
                  value={phone}
                  onChange={handlePhoneChange}
                  sx={{ ...inputStyle, margin: 0.5 }}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  error={phoneError}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerify}
                  sx={{ ...inputStyle, margin: 3 }}
                >
                  {verifyingButtonText}
                </Button>

                <Button
                  // variant="contained"
                  color="primary"
                  onClick={() => {
                    window.location.href = "loginold";
                  }}
                  sx={{ ...inputStyle }}
                >
                  Old Login
                </Button>
              </>
            ) : (
              <>
                <TextField
                  label="Verification Code"
                  value={verificationCode}
                  onChange={handleVerificationCodeChange}
                  sx={{ ...inputStyle, margin: 0.5 }}
                  variant="filled"
                  InputProps={{ disableUnderline: true }}
                  size="small"
                  error={verificationCodeError}
                />

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLogin}
                  sx={{ ...inputStyle, margin: 3 }}
                >
                  {verificationCodeButtonText}
                </Button>
              </>
            )}
          </div>
        </div>
      </Box>
    </>
  );
};

export default Login;

import React, { useRef, useState, useEffect, useCallback } from "react";

import {
  motion,
  useMotionValue,
  useSpring,
  useTransform,
  useAnimate,
} from "framer-motion";

import { createGlobalStyle } from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import { useAtom } from "jotai";
import {
  themeAtom,
  isAdminAtom,
  isMobileBrowserAtom,
  exportDatesModalOpenAtom,
  isDockHiddenAtom,
  eventDataModalOpenAtom,
  emailSelectorDialogOpenAtom,
  isShootTabAtom,
  isElectronAtom,
} from "./-Atoms";

import { subscribe, unsubscribe } from "./GlobalFunctions/customEvent";

import { saveAs } from "file-saver";

// import { useMediaQuery } from "@mui/material";

const GlobalStyles = createGlobalStyle`
.toolTip {
  opacity: 0;
  transition: opacity 0.3s ease;
}
.visible {
  opacity: 1;
}
.iconSizeSmall {
  font-size: 25px;
  transition: font-size 0.2s ease;
}
.iconSizeLarge {
  font-size: 40px;
}
`;

const Dock = ({ restricted, firebase }) => {
  const [theme] = useAtom(themeAtom);
  const [isElectron] = useAtom(isElectronAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);
  const [eventDataModalOpen] = useAtom(eventDataModalOpenAtom);
  const [isShootTab] = useAtom(isShootTabAtom);
  const [isDockHidden, setIsDockHidden] = useAtom(isDockHiddenAtom);
  const [, setExportDatesModalOpen] = useAtom(exportDatesModalOpenAtom);
  const [, setEmailSelectorDialogOpen] = useAtom(emailSelectorDialogOpenAtom);

  const [scopeHide, animateHide] = useAnimate();
  const [scopeShow, animateShow] = useAnimate();

  const location = useLocation();

  const pages = [
    {
      name: "Calendar",
      route: location.pathname.includes("autologin")
        ? "/calendar/autologin"
        : "/calendar",
      icon: "calendar_month",
      restricted: true,
      admin: false,
      electronShow: true,
    },
    {
      name: "Kanban",
      route: location.pathname.includes("autologin")
        ? "/kanban/autologin"
        : "/kanban",
      icon: "view_kanban",
      restricted: true,
      admin: false,
      electronShow: true,
    },
    {
      name: "Video Schedule",
      route: "/videoschedule",
      icon: "play_circle",
      restricted: false,
      admin: false,
      electronShow: true,
    },
    {
      name: "Clients",
      route: "/clients",
      icon: "contacts_icon",
      restricted: false,
      admin: true,
      electronShow: true,
    },
    {
      name: "Staff",
      route: "/staff",
      icon: "person_icon",
      restricted: false,
      admin: isElectron ? true : false,
      electronShow: false,
    },
    {
      name: "Outsourcers",
      route: "/outsourcers",
      icon: "public_con",
      restricted: false,
      admin: true,
      electronShow: true,
    },
  ];

  const hideDock = useCallback(
    (manualHide = false) => {
      animateHide(
        scopeHide.current,
        { y: 150 },
        { type: "spring", damping: 13, stiffness: 200 }
      );
      animateShow(
        scopeShow.current,
        { y: 0 },
        { type: "spring", damping: 13, stiffness: 200 }
      );
      // if (manualHide === true) {
      setIsDockHidden(true);
      // }
    },
    [animateHide, animateShow, scopeHide, scopeShow, setIsDockHidden]
  );

  const showDock = useCallback(() => {
    animateHide(
      scopeHide.current,
      { y: 0 },
      { type: "spring", damping: 13, stiffness: 200 }
    );
    animateShow(
      scopeShow.current,
      { y: 150 },
      { type: "spring", damping: 13, stiffness: 200 }
    );
    setIsDockHidden(false);
  }, [animateHide, animateShow, scopeHide, scopeShow, setIsDockHidden]);

  useEffect(() => {
    subscribe("showDock", showDock);
    subscribe("hideDock", hideDock);

    return () => {
      unsubscribe("showDock", showDock);
      unsubscribe("hideDock", hideDock);
    };
  }, [showDock, hideDock]);

  let mouseX = useMotionValue(Infinity);
  return (
    <>
      <GlobalStyles />
      <div
        style={{
          position: "fixed",
          bottom: isMobileBrowser ? 20 : 8,
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 9999990,
        }}
      >
        <motion.div
          ref={scopeShow}
          style={{
            cursor: "pointer",
            background:
              theme === "dark"
                ? "rgba(60, 60, 60, 0.9)"
                : "rgba(200, 200, 200, 0.9)",
            width: 100,
            height: 22,
            borderRadius: 25,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          initial={{ y: 150 }}
          onClick={() => {
            showDock();
          }}
        >
          <span className="material-icons">expand_less</span>
        </motion.div>
      </div>

      <div
        style={{
          position: "fixed",
          left: "50%",
          transform: "translateX(-50%)",
          bottom: isMobileBrowser ? 20 : 10,
          zIndex: 999999,
          pointerEvents: isDockHidden ? "none" : "all",
        }}
      >
        <motion.div
          ref={scopeHide}
          onMouseMove={(e) => mouseX.set(e.pageX)}
          onMouseLeave={() => mouseX.set(Infinity)}
          className={`mx-auto flex ${
            isMobileBrowser ? "h-14 gap-1.5" : "h-16 gap-4"
          } items-end px-3.5 pb-2.5`}
          style={{
            backgroundColor:
              theme === "dark"
                ? "rgba(255, 255, 255, 0.1)"
                : "rgba(10, 10, 10, 0.1)",
            backdropFilter: "blur(8px)",
            WebkitBackdropFilter: "blur(8px)",
            borderRadius: 50,
          }}
        >
          {pages &&
            pages.map((page, index) =>
              shouldReturnPage(page, isAdmin, restricted, isElectron) ? (
                <AppIcon mouseX={mouseX} key={index} page={page} />
              ) : null
            )}
          <div
            style={{
              height: "80%",
              borderRight: `2px solid ${
                theme === "dark"
                  ? "rgba(30, 30, 30, 0.8)"
                  : "rgba(240, 240, 240, 0.8)"
              }`,
              maskImage:
                "linear-gradient(0deg, transparent, rgb(255, 255, 255) 16px, rgb(255, 255, 255) calc(100% - 16px), transparent)",
            }}
          >
            &zwnj;
          </div>
          {shouldShowExportDates(location, isAdmin) && !eventDataModalOpen ? (
            <AppIconButton
              mouseX={mouseX}
              button={{
                name: `Export Dates`,
                admin: true,
                function: () => {
                  setExportDatesModalOpen(true);
                  hideDock();
                },
                icon: "ios_share",
              }}
            />
          ) : null}
          {shouldShowExportDates(location, isAdmin) && !eventDataModalOpen ? (
            <AppIconButton
              mouseX={mouseX}
              button={{
                name: `Export Contacts CSV`,
                admin: true,
                function: async () => {
                  const contactsRef = firebase
                    .firestore()
                    .collection("ClientDatabase");
                  const querySnapshot = await contactsRef.get();
                  console.log(querySnapshot.docs);
                  let contactsArray = [];

                  querySnapshot.docs.forEach((doc) => {
                    const contacts = doc.data().contacts;
                    contacts &&
                      contacts.forEach((contact) => {
                        contactsArray.push({
                          email: contact.email.toLowerCase(),
                          name: contact.name,
                        });
                      });
                  });

                  const uniqueContacts = [];
                  const emailsSet = new Set();

                  contactsArray.forEach((contact) => {
                    if (!emailsSet.has(contact.email)) {
                      emailsSet.add(contact.email);
                      uniqueContacts.push(contact);
                    }
                  });

                  const csvContent = uniqueContacts
                    .map((contact) => `${contact.email},${contact.name}`)
                    .join("\n");

                  const csvHeaders = "Email,Name\n";
                  const csvData = csvHeaders + csvContent;

                  const blob = new Blob([csvData], {
                    type: "text/csv;charset=utf-8;",
                  });
                  saveAs(blob, "contacts.csv");
                },
                icon: "ios_share",
              }}
            />
          ) : null}
          {isShootTab ? (
            <AppIconButton
              mouseX={mouseX}
              button={{
                name: `Emails`,
                admin: true,
                function: () => {
                  setEmailSelectorDialogOpen(true);
                  hideDock();
                },
                icon: "mail",
              }}
            />
          ) : null}

          <AppIconButton
            mouseX={mouseX}
            button={{
              name: "Hide",
              icon: "expand_more",
              function: () => {
                hideDock(true);
              },
            }}
          />
        </motion.div>
      </div>
    </>
  );
};
// page.admin && isAdmin ? (
//   <AppIcon mouseX={mouseX} key={index} page={page} />
// ) : !page.admin &&
//   returnRestricted(restricted, page.restricted) ? (
//   <AppIcon mouseX={mouseX} key={index} page={page} />
// ) : null
// )}
export default Dock;

function shouldShowExportDates(location, isAdmin) {
  if (!isAdmin) return false;
  if (location.pathname.includes("calendar")) return true;
  if (location.pathname === "/") return true;

  return false;
}

function shouldReturnPage(page, isAdmin, restricted, isElectron) {
  if (isElectronAtom) return page.electronShow;

  if (isAdmin) return true;
  if (restricted) {
    if (page.restricted) return true;
  } else {
    if (!page.admin) {
      return true;
    }
  }
  return false;
}

function AppIconButton({ mouseX, button }) {
  const [theme] = useAtom(themeAtom);
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);

  const [growValues, setGrowValues] = useState([45, 70, 45]);
  useEffect(() => {
    isMobileBrowser ? setGrowValues([40, 40, 40]) : setGrowValues([45, 70, 45]);
  }, [isMobileBrowser]);

  let ref = useRef(null);

  let distance = useTransform(mouseX, (val) => {
    let bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };

    return val - bounds.x - bounds.width / 2;
  });

  let widthSync = useTransform(distance, [-150, 0, 150], growValues);
  let width = useSpring(widthSync, { mass: 0.1, stiffness: 150, damping: 12 });

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          position: "absolute",
          top: -52,
          backgroundColor:
            theme === "dark"
              ? "rgba(30, 30, 30, 0.8)"
              : "rgba(240, 240, 240, 0.8)",
          borderRadius: 8,
          padding: 4,
          whiteSpace: "nowrap",
        }}
        className={`toolTip ${isHovered ? "visible" : ""}`}
      >
        {button.name}
      </div>
      <motion.div
        ref={ref}
        style={{
          width,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            theme === "dark"
              ? "rgba(40, 40, 40, 0.95)"
              : "rgba(200, 200, 200, 0.95)",
          containerType: "inline-size",
        }}
        className="aspect-square w-10 rounded-full"
        onClick={() => button.function()}
      >
        <span
          style={{
            fontSize: "60cqw",
            width: "60cqw",
            color: theme === "dark" ? "#e5e5e5" : "#383838",
          }}
          className="material-icons"
        >
          {button.icon}
        </span>
      </motion.div>
    </div>
  );
}

function AppIcon({ mouseX, page }) {
  const [theme] = useAtom(themeAtom);
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);

  const location = useLocation();
  const history = useHistory();

  const [growValues, setGrowValues] = useState([45, 70, 45]);
  useEffect(() => {
    isMobileBrowser ? setGrowValues([40, 40, 40]) : setGrowValues([45, 70, 45]);
  }, [isMobileBrowser]);

  const [ref, animateIcon] = useAnimate();

  // let ref = useRef(null);

  let distance = useTransform(mouseX, (val) => {
    let bounds = ref.current?.getBoundingClientRect() ?? { x: 0, width: 0 };

    return val - bounds.x - bounds.width / 2;
  });

  let widthSync = useTransform(distance, [-150, 0, 150], growValues);
  let width = useSpring(widthSync, { mass: 0.1, stiffness: 150, damping: 12 });

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        style={{
          position: "absolute",
          top: -52,
          backgroundColor:
            theme === "dark"
              ? "rgba(30, 30, 30, 0.8)"
              : "rgba(240, 240, 240, 0.8)",
          borderRadius: 8,
          padding: 4,
          whiteSpace: "nowrap",
        }}
        className={`toolTip ${isHovered ? "visible" : ""}`}
      >
        {page.name}
      </div>
      <motion.div
        ref={ref}
        initial={{ y: 0 }}
        style={{
          width,
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            theme === "dark"
              ? "rgba(40, 40, 40, 0.95)"
              : "rgba(200, 200, 200, 0.95)",
          containerType: "inline-size",
        }}
        className="aspect-square w-10 rounded-full"
        onClick={() => {
          const ani = {
            type: "spring",
            damping: 15,
            stiffness: 600,
            restSpeed: 200,
            restDelta: 20,
          };
          animateIcon([[ref.current, { y: -15 }, ani]]);
          setTimeout(() => {
            history.push(page.route);
          }, 150);
          setTimeout(() => {
            animateIcon([[ref.current, { y: 0 }, ani]]);
          }, 400);
        }}
      >
        <span
          style={{
            fontSize: "60cqw",
            width: "60cqw",
            color: theme === "dark" ? "#e5e5e5" : "#383838",
          }}
          className="material-icons"
        >
          {page.icon}
        </span>
      </motion.div>
      <div
        style={{
          position: "absolute",
          bottom: 3,
          backgroundColor: theme === "dark" ? "#e5e5e5" : "#383838",
          borderRadius: 25,
          width: 3.5,
          height: 3.5,
          display: shouldShowPageDot(location.pathname, page.route)
            ? "block"
            : "none",
        }}
      ></div>
    </div>
  );
}
function shouldShowPageDot(pathName, route) {
  if (pathName.includes(route)) return true;
  if (pathName === "/" && route.includes("calendar")) return true;
  return false;
}

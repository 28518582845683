import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 10,
};

const Models = ({ jobData }) => {
  const [models, setModels] = useState(jobData.models);

  useEffect(() => {
    setModels(jobData.models);
  }, [jobData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 20,
          marginTop: 10,
          justifyContent: "center",
        }}
      >
        {models?.map((m, i) => (
          <Model key={`model-${i}`} model={m} />
        ))}
      </div>
    </div>
  );
};

export default Models;

const Model = ({ model }) => {
  const [name, setName] = useState(model.name);
  const [agency, setAgency] = useState(model.agency);
  const [callTime, setCallTime] = useState(new Date(model.callTime));
  const [finishTime, setFinishTime] = useState(new Date(model.finishTime));
  const [faceNoFace, setFaceNoFace] = useState(model.faceNoFace);
  const [bookedBy, setBookedBy] = useState(model.bookedBy);
  const [paidBy, setPaidBy] = useState(model.paidBy);

  useEffect(() => {
    setName(model.name);
    setAgency(model.agency);
    setCallTime(new Date(model.callTime));
    setFinishTime(new Date(model.finishTime));
    setFaceNoFace(model.faceNoFace);
    setBookedBy(model.bookedBy);
    setPaidBy(model.paidBy);
  }, [model]);

  return (
    <div style={cardStyle}>
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Name"
        value={name}
        inputProps={{ readOnly: true }}
      />
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Agency"
        value={agency}
        inputProps={{ readOnly: true }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label="Call Time"
          value={callTime || null}
          onChange={(d) => {}}
          readOnly={true}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              size="small"
            />
          )}
        />
        <TimePicker
          label="Finish Time"
          value={finishTime || null}
          onChange={(d) => {}}
          readOnly={true}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              size="small"
            />
          )}
        />
      </LocalizationProvider>

      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Face / No Face"
        value={faceNoFace}
        inputProps={{ readOnly: true }}
      />
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Booked By"
        value={bookedBy}
        inputProps={{ readOnly: true }}
      />
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Paid By"
        value={paidBy}
        inputProps={{ readOnly: true }}
      />
    </div>
  );
};

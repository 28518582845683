import { jsx as _jsx } from "react/jsx-runtime";
import "./icon.css";

var MinimizeIcon = function MinimizeIcon() {
  return _jsx("svg", Object.assign({
    className: "avx-traffic-light-icon",
    width: "8",
    height: "2",
    viewBox: "0 0 8 2",
    xmlns: "http://www.w3.org/2000/svg",
    "data-testid": "minimize-icon"
  }, {
    children: _jsx("path", {
      fillRule: "evenodd",
      clipRule: "evenodd",
      d: "M8 2L0 2L0 0L8 0V2Z"
    }, void 0)
  }), void 0);
};

export default MinimizeIcon;
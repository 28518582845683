import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";


import { useHistory } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import CssBaseline from "@mui/material/CssBaseline";

import MenuIcon from "@mui/material/Menu";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import { use100vh } from "react-div-100vh";

import "../App.css";

import Drawer from "@mui/material/Drawer";

import Button from "@mui/material/Button";

import StaffListItem from "./StaffListItem";
import StaffCard from "./StaffCard";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { useAtom } from "jotai";
import { isIdleAtom, currentStaffDataAtom } from "../-Atoms";

const shortUUID = require("short-uuid");

const appBarHeight = 64;
const listWidth = 300;
const listPadding = 10;

const Staff = ({ firebase, user, theme }) => {
  const [isIdle] = useAtom(isIdleAtom);
  const [currentStaffData] = useAtom(currentStaffDataAtom);
  const [open, setOpen] = useState(false);
  const [newStaffModalOpen, setNewStaffModalOpen] = useState(false);

  const [hideNotEmployed, setHideNotEmployed] = useState(true);
  // function hideNotEmployedDefault(){
  //   if(isAdmin(user)){
  //     return false
  //   }
  // }
  const sendEmail = firebase.functions().httpsCallable("sendEmail");
  const createNewUser = firebase.functions().httpsCallable("createNewUser");

  const [newStaffModalFirstName, setNewStaffModalFirstName] = useState("");
  const [newStaffModalLastName, setNewStaffModalLastName] = useState("");
  const [newStaffModalEmail, setNewStaffModalEmail] = useState("");

  const [newStaffModalFirstNameError, setNewStaffModalFirstNameError] =
    useState(false);
  const [newStaffModalLastNameError, setNewStaffModalLastNameError] =
    useState(false);
  const [newStaffModalEmailError, setNewStaffModalEmailError] = useState(false);

  const h = use100vh() - 80;
  const windowSize = useWindowSize();

  const history = useHistory();
  const [selectedStaff, setSelectedStaff] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setSelectedStaff(currentStaffData.id);
    }, 100);
  }, [currentStaffData, setSelectedStaff]);

  // const [count, setCount] = useState(0);

  const [staffData, setStaffData] = useState([]);

  // useEffect(() => {
  //   if (count < 1) {
  //     if (staffData.length !== 0) {
  //       setCount(1);
  //       staffData.forEach((s) => {
  //         // if (s.email === user.email)
  //         if (s.uid === user.uid) setSelectedStaff(s.id);
  //       });
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [staffData]);

  useEffect(() => {
    let unsubscribe = firebase
      .firestore()
      .collection("StaffData")
      .orderBy("firstName", "asc")
      .onSnapshot((collection) => {
        const sd = collection.docs.map((staff) => {
          const data = staff.data();
          data.id = staff.id;

          if (hideNotEmployed) {
            if (!data.currentlyEmployed) {
              return false;
            }
          }

          return data;
        });
        const arr = [];
        sd.forEach((s) => {
          if (s) {
            arr.push(s);
          }
        });
        setStaffData(arr);
      });
    if (isIdle) {
      unsubscribe();
    }
    return () => unsubscribe();
  }, [firebase, setStaffData, hideNotEmployed, isIdle]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (selectedStaff) {
      params.append("selected", selectedStaff);
    } else {
      params.delete("selected");
    }
    history.push({ search: params.toString() });
  }, [selectedStaff, history]);

  const menuIconContainer = {
    position: "absolute",
    top: 66,
    left: 10,
    zIndex: 500,
  };
  const newStaffModalTextFieldStyle = {
    width: 280,
    margin: 5,
  };
  const newStaffModalBackgroundStyle = {
    display: newStaffModalOpen ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 2000,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };
  const [addStaffButtonText, setAddStaffButtonText] = useState("Add Staff");
  const addNewStaff = async () => {
    setNewStaffModalFirstNameError(false);
    setNewStaffModalLastNameError(false);
    setNewStaffModalEmailError(false);
    let error = false;
    let externalStaff = false;
    if (!newStaffModalFirstName) {
      setNewStaffModalFirstNameError(true);
      error = true;
    }
    if (!newStaffModalLastName) {
      setNewStaffModalLastNameError(true);
      error = true;
    }
    if (!newStaffModalEmail) {
      setNewStaffModalEmailError(true);
      error = true;
    }

    if (!error) {
      setAddStaffButtonText(<CircularProgress />);
      const tmpPass = shortUUID.generate();
      let UID = "";
      if (!externalStaff) {
        const newUserData = {
          displayName: parseDisplayName(
            newStaffModalFirstName,
            newStaffModalLastName
          ),
          email: newStaffModalEmail,
          password: tmpPass,
          firstName: newStaffModalFirstName,
          lastName: newStaffModalLastName,
        };
        const user = await createNewUser(newUserData);
        UID = user.data.uid;
      }

      firebase
        .firestore()
        .collection("StaffData")
        .add({
          uid: UID,
          BSB: "",
          address: {
            postcode: "",
            streetName: "",
            streetNumber: "",
            suburb: "",
          },
          avatar: "No Avatar",
          bankAccountName: "",
          bankAccountNumber: "",
          bankName: "",
          currentlyEmployed: true,
          extrenalStaff: externalStaff,
          dateOfBirth: "",
          email: newStaffModalEmail,
          employmentBasis: "",
          firstName: newStaffModalFirstName,
          isUtility: false,
          lastName: newStaffModalLastName,
          preferredName: "",
          nextOfKin1Name: "",
          nextOfKin1Phone: "",
          nextOfKin2Name: "",
          nextOfKin2Phone: "",
          nickname: "",
          phoneNumber: "",
          salaryHourlyRate: "",
          superannuationFundUSI: "",
          superannuationMemberNumber: "",
          superannuationProvider: "",
          taxFileNumber: "",
          credentials: [],
        });

      if (!externalStaff) {
        sendEmail({
          emailBody: `<style>
                    body {
                        font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
                            Helvetica, Arial, "Lucida Grande", sans-serif;
                    }
                </style>

                <body>
                    <p>Hello ${newStaffModalFirstName}!</p>
                    <p>Please go to the link below and fill out the employment details for Images That Sell</p>
                    <p>
                        <a href="https://staff.imagesthatsell.com.au/staff/newstaff">Click Here</a>
                    </p>
                    <span>Thanks!</span>
                    <br>
                    <span>ITS Team</span>
                </body>`,
          fromEmail: "production@imagesthatsell.com.au",
          toEmails: newStaffModalEmail,
          ccEmails: [],
          bccEmails: [],
          replyToEmails: [],
          subject: "New ITS Account",
          attachments: [],
        }).then((res) => {
          console.log(res);
        });
      }

      closeNewStaffModal({ target: { id: "newStaffModalBackground" } });
      setAddStaffButtonText("Add Staff");
    }
  };
  const closeNewStaffModal = (e) => {
    if (e.target.id === "newStaffModalBackground") {
      setNewStaffModalOpen(false);
      setNewStaffModalFirstName("");
      setNewStaffModalLastName("");
      setNewStaffModalEmail("");
      setNewStaffModalFirstNameError(false);
      setNewStaffModalLastNameError(false);
      setNewStaffModalEmailError(false);
    }
  };
  return (
    <>
      <CssBaseline />
      <div
        style={{
          paddingLeft: `${listPadding}px`,
          height: h,
          display: "flex",
          overflow: "hidden",
        }}
      >
        {isAdmin(user) ? (
          <Fab
            color="primary"
            size="large"
            style={{
              position: "absolute",
              zIndex: 1000,
              bottom: 30,
              right: 30,
            }}
            onClick={() => {
              setNewStaffModalOpen(true);
            }}
          >
            <AddIcon />
          </Fab>
        ) : null}

        <div style={menuIconContainer}>
          <IconButton
            onClick={() => {
              setOpen(!open);
            }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
        </div>
        <Drawer
          sx={{
            width: `calc(${listWidth}px - 15px)`,
            // width: listWidth,
            "& .MuiDrawer-paper": {
              height:
                windowSize.width > 900 ? `calc(100% - ${appBarHeight}px)` : "",
              top: windowSize.width > 900 ? `${appBarHeight}px` : "",
              overflow: "hidden",
            },
          }}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          anhcor="left"
          variant={windowSize.width > 900 ? "permanent" : "temporary"}
          classes={{ paper: { width: listWidth } }}
        >
          <List
            style={{
              width: `${listWidth}px`,
              maxHeight: "100%",
              // overflow: "auto",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            {isAdmin(user) ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={hideNotEmployed}
                        onChange={(e) => {
                          setHideNotEmployed(e.target.checked);
                        }}
                      />
                    }
                    label="Hide Not Employed"
                  />
                </FormGroup>
              </div>
            ) : null}
            {staffData &&
              staffData.map((staff) => (
                <StaffListItem
                  key={staff.id}
                  staff={staff}
                  selectedStaff={selectedStaff}
                  setSelectedStaff={setSelectedStaff}
                  setOpen={setOpen}
                  user={user}
                  theme={theme}
                />
              ))}
          </List>
        </Drawer>
        <div style={{ overflow: "auto" }}>
          {staffData ? (
            <StaffCard
              firebase={firebase}
              selectedStaff={selectedStaff}
              staffData={staffData}
              windowSize={windowSize}
              listWidth={listWidth}
              user={user}
            />
          ) : null}
        </div>

        <div
          id="newStaffModalBackground"
          style={newStaffModalBackgroundStyle}
          onClick={closeNewStaffModal}
        >
          <div
            style={{
              display: newStaffModalOpen ? "flex" : "none",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              zIndex: 2001,
              width: 300,
              height: 365,
              borderRadius: 10,
              background: theme === "dark" ? "#1f1f1f" : "#ffffff",
            }}
          >
            <TextField
              label="First Name *"
              variant="outlined"
              style={newStaffModalTextFieldStyle}
              onChange={(e) => {
                setNewStaffModalFirstName(e.target.value);
              }}
              value={newStaffModalFirstName || ""}
              error={newStaffModalFirstNameError}
            />
            <TextField
              label="Last Name *"
              variant="outlined"
              style={newStaffModalTextFieldStyle}
              onChange={(e) => {
                setNewStaffModalLastName(e.target.value);
              }}
              value={newStaffModalLastName || ""}
              error={newStaffModalLastNameError}
            />
            {/* <span
            style={{
              marginTop: 15,
              marginBottom: 5,
              marginLeft: 10,
              marginRight: 10,
              color: "rgb(125, 125, 125)",
            }}
          >
            If no email is provided, the staff member will be made an "external"
            staff and will not be given access to the back end
          </span> */}
            <TextField
              label="Email *"
              variant="outlined"
              style={newStaffModalTextFieldStyle}
              onChange={(e) => {
                setNewStaffModalEmail(e.target.value);
              }}
              value={newStaffModalEmail || ""}
              error={newStaffModalEmailError}
            />
            <Button
              variant="outlined"
              style={{ marginTop: 20, width: 150 }}
              onClick={addNewStaff}
            >
              {addStaffButtonText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Staff;

const isAdmin = (user) => {
  if (user?.staffData?.isAdmin === true) {
    return true;
  }
  if (user?.staffData?.isSuperAdmin === true) {
    return true;
  }
  return false;
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

function parseDisplayName(firstName, lastName) {
  let name = firstName;
  if (lastName) {
    name = `${firstName} ${lastName}`;
  }
  return name;
}

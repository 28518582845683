import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";

import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Modal from "@mui/material/Modal";

import { use100vh } from "react-div-100vh";

import "./App.css";

import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";


import Contacts from "./ClientDatabase/Contacts";
import Invoicing from "./ClientDatabase/Invoicing";
import Production from "./ClientDatabase/Production";
import Options from "./ClientDatabase/Options";
import Notes from "./ClientDatabase/Notes";
import Checklists from "./ClientDatabase/Checklists";
import Files from "./ClientDatabase/Files";

const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

let firebaseX;
let deleteContactTimeout;

const listWidth = 240;
const listPadding = 10;
const ClientDatabase = ({ firebase, theme }) => {
  const h = use100vh() - 80;

  firebaseX = firebase;
  // const clientDataQuery = firebase
  //   .firestore()
  //   .collection("ClientDatabase")
  //   .orderBy("brand", "asc");
  // const [clientDataRaw2] = useCollectionData(clientDataQuery, {
  //   idField: "id",
  // });
  const [clientDataRaw, setClientDataRaw] = useState([]);
  const [clientData, setClientData] = useState([]);

  useEffect(() => {
    let unsub;
    unsub = firebase
      .firestore()
      .collection("ClientDatabase")
      .orderBy("brand", "asc")
      .onSnapshot((collection) => {
        const cd = collection.docs.map((client) => {
          const raw = client.data();
          const data = { ...raw };
          data.id = client.id;
          return data;
        });
        setClientDataRaw([...cd]);
        setClientData([...cd]);
      });
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
  }, [firebase]);

  const [selectedClient, setSelectedClient] = useState(null);

  useEffect(() => {
    clientDataRaw ? setClientData([...clientDataRaw]) : console.log();
  }, [clientDataRaw]);

  useEffect(() => {
    if (!selectedClient) {
      clientData.length > 0
        ? setSelectedClient(clientData[0].brand)
        : console.log();
    } else {
      clientData.forEach((c) => {
        for (let i = 0; i < clientData.length; i++) {
          let c = clientData[i];
          if (c.brand === selectedClient) {
            break;
          }
          if (i === clientData.length) {
            setSelectedClient(clientData[0].brand);
            break;
          }
        }
      });
    }
  }, [clientData, selectedClient]);

  const [selectedClientData, setSelectedClientData] = useState({});

  useEffect(() => {
    setSelectedClientData(parseSelect(selectedClient, clientData));
  }, [selectedClient, clientData, setSelectedClientData]);

  const [suppliedLinks, setSuppliedLinks] = useState([]);
  useEffect(() => {
    if (selectedClientData?.suppliedLinks) {
      const reverse = selectedClientData?.suppliedLinks.reverse();
      setSuppliedLinks(reverse);
    }
  }, [selectedClientData]);
  function parseSelect(s, c) {
    if (!s) {
      return null;
    }
    let select;
    for (let i = 0; i < c.length; i++) {
      if (s === c[i].brand) {
        select = c[i];
        break;
      }
    }
    return select;
  }
  return (
    <div
      style={{
        paddingLeft: `${listPadding}px`,
        height: h,
        display: "flex",
        overflow: "hidden",
      }}
    >
      <div>
        <Search
          clientData={clientData}
          setClientData={setClientData}
          clientDataRaw={clientDataRaw}
        />
        <List
          style={{
            width: `${listWidth}px`,
            maxHeight: "calc(100% - 80px)",
            overflow: "auto",
            marginRight: "10px",
          }}
        >
          {clientData &&
            clientData.map((client) => (
              <ClientListItem
                key={`${client.brand} - ClientListItem`}
                client={client}
                setSelectedClient={setSelectedClient}
                selectedClient={selectedClient}
                theme={theme}
              />
            ))}
        </List>
      </div>
      <div>
        {clientData ? (
          <ClientCardContainer
            selectedClient={selectedClient}
            clientData={clientData}
            firebase={firebase}
            suppliedLinks={suppliedLinks}
            theme={theme}
          />
        ) : null}
      </div>
    </div>
  );
};
export default ClientDatabase;

const Search = ({ clientData, setClientData, clientDataRaw }) => {
  const [inputValue, setInputValue] = useState("");
  const [searchIcon, setSearchIcon] = useState(false);
  const handleInput = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const regex = new RegExp(String.raw`^${value.toLowerCase()}`);

    if (value.length > 0) {
      setSearchIcon(true);
      const tmpArr = [];
      clientDataRaw.forEach((client) => {
        if (client.brand.toLowerCase().match(regex)) {
          tmpArr.push(client);
        }
      });
      setClientData([...tmpArr]);
    } else {
      setSearchIcon(false);
      handleClear();
    }
  };
  const handleClear = () => {
    setInputValue("");
    setSearchIcon(false);
    setClientData([...clientDataRaw]);
  };

  return (
    <div
      style={{
        padding: "10px",
        maxWidth: "calc(100% - 30px)",
      }}
    >
      <TextField
        label="Search"
        onChange={handleInput}
        value={inputValue}
        inputProps={{ spellCheck: "false" }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              style={{
                marginRight: "0px",
              }}
              onClick={searchIcon ? handleClear : null}
            >
              <IconButton>
                {!searchIcon ? <SearchIcon /> : <ClearIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};
const ClientCard = ({ client, firebase, suppliedLinks, theme }) => {
  // const [value, setValue] = useState(0);
  const [value, setValue] = useState(3);
  // const [value, setValue] = useState(0);
  const [showDeleteContact, setShowDeleteContact] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeleteClick = () => {
    if (deleteContactTimeout) {
      clearTimeout(deleteContactTimeout);
      deleteContactTimeout = null;
    } else {
      deleteContactTimeout = setTimeout(() => {
        setShowDeleteContact(false);
        deleteContactTimeout = null;
      }, 15000);
    }
    setShowDeleteContact(!showDeleteContact);
  };
  const handleNewClick = () => {
    client.contacts.push({
      name: "",
      title: "",
      email: "",
      phone: "",
      isSupply: false,
      isBilling: false,
    });
    updateFSNow(client.id, "contacts", client.contacts);
  };
  const contactsButtons = [
    <Button key="newContactButton" onClick={handleNewClick}>
      New Contact
    </Button>,
    <Button key="deleteContactButton" onClick={handleDeleteClick}>
      Delete Contact
    </Button>,
  ];
  const tabStyle = { minWidth: "50px" };

  const [attachments, setAttachments] = useState([]);
  // console.log(client);
  useEffect(() => {
    if (client.attachments) {
      setAttachments([...client.attachments]);
    } else {
      setAttachments([]);
    }
  }, [client]);
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          background: theme === "dark" ? "#1f1f1f" : "rgb(250, 250, 250)",
          zIndex: "10",
          width: "60%",
        }}
      >
        <h2>{client.brand}</h2>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            variant={"scrollable"}
            scrollButtons={"on"}
            value={value}
            onChange={handleChange}
            aria-label="Client Items"
          >
            <Tab label="Contacts" style={tabStyle} />
            <Tab label="Files" style={tabStyle} />
            <Tab label="Invoicing" style={tabStyle} />
            <Tab label="Production" style={tabStyle} />
            <Tab label="Options" style={tabStyle} />
            <Tab label="Notes" style={tabStyle} />
            <Tab label="Checklists" style={tabStyle} />
          </Tabs>
        </div>
      </div>
      <div style={{ margin: "10px", height: "calc(100vh - 103px)" }}>
        <div style={{ width: "100%", height: "120px" }}></div>
        <TabPanel value={value} index={0}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: "15px",
            }}
          >
            <ButtonGroup size="large" aria-label="contact button group">
              {contactsButtons}
            </ButtonGroup>
          </div>
          <Contacts
            key={`${client.brand}-Contacts`}
            client={client}
            showDeleteContact={showDeleteContact}
            updateFS={updateFS}
            firebase={firebase}
            theme={theme}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Files
            key={`${client.brand}-Files`}
            client={client}
            firebase={firebase}
            updateFS={updateFS}
            attachments={attachments}
            setAttachments={setAttachments}
            dbx={dbx}
            suppliedLinks={suppliedLinks}
            theme={theme}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Invoicing
            key={`${client.brand}-Invoicing`}
            client={client}
            firebase={firebase}
            updateFS={updateFS}
            theme={theme}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Production
            key={`${client.brand}-Production`}
            client={client}
            firebase={firebase}
            theme={theme}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Options
            key={`${client.brand}-Options`}
            client={client}
            updateFSNow={updateFSNow}
            theme={theme}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Notes
            key={`${client.brand}-Notes`}
            client={client}
            firebase={firebase}
            theme={theme}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <Checklists
            key={`${client.brand}-Checklists`}
            client={client}
            firebase={firebase}
            theme={theme}
          />
        </TabPanel>
      </div>
    </div>
  );
};
const ClientCardContainer = ({
  selectedClient,
  clientData,
  firebase,
  suppliedLinks,
  theme,
}) => {
  const client = parseSelect(selectedClient, clientData);
  function parseSelect(s, c) {
    if (!s) {
      return null;
    }
    let select;
    for (let i = 0; i < c.length; i++) {
      if (s === c[i].brand) {
        select = c[i];
        break;
      }
    }
    return select;
  }

  const [newClientModal, setNewClientModal] = useState(false);
  const newClientModalClose = () => {
    setNewClient("");
    setNewClientModal(false);
  };
  const [newClient, setNewClient] = useState("");

  const [newClientButtonDisplay, setNewClientButtonDisplay] = useState("block");
  const [newClientNameDisplay, setNewClientNameDisplay] = useState("none");

  const newClientModalBody = (
    <div
      style={{
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        position: "absolute",
        width: "300px",
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
        // boxShadow: theme2.shadows[5],
        padding: "20px",
        borderRadius: "5px",
        outline: "none",
      }}
    >
      <div
        style={{
          padding: "5px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <TextField
          label="New Client Name"
          onChange={(e) => {
            setNewClient(e.target.value);
          }}
          value={newClient}
          variant="outlined"
          style={{ margin: "10px", width: "100%" }}
        />
        <span style={{ display: newClientNameDisplay, margin: 10 }}>
          Checking Client Name...
        </span>
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: "10px", display: newClientButtonDisplay }}
          onClick={async () => {
            setNewClientButtonDisplay("none");
            setNewClientNameDisplay("block");
            const res = await fetch(
              "https://us-central1-its-connect-main.cloudfunctions.net/checkIfClientNameIsAvailable",
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  clientName: newClient,
                }),
              }
            ).then((response) => {
              // return response;
              return response.json();
            });

            if (res.exists) {
              alert("client with this name already exists");
              setNewClientButtonDisplay("block");
              setNewClientNameDisplay("none");
              return;
            }
            const brandArray = [];
            const brandLowerCaseArray = [];
            for (let i = 1; i < newClient.length + 1; i++) {
              brandArray.push(newClient.substring(0, i));
            }
            for (let i = 1; i < newClient.toLowerCase().length + 1; i++) {
              brandLowerCaseArray.push(newClient.toLowerCase().substring(0, i));
            }

            const clientObj = {
              brand: newClient,
              brandLowerCase: newClient.toLowerCase(),
              brandArray: brandArray,
              brandLowerCaseArray: brandLowerCaseArray,
              turnAround: 5,
              PSF: 15,
              contacts: [],
              paymentTerms: "COD",
              fullDayRate: {
                time: "",
                lunch: "",
                images: "",
              },
              halfDayRate: {
                time: "",
                images: "",
              },
              contentFullDayRate: {
                time: "",
                lunch: "",
                images: "",
              },
              contentHalfDayRate: {
                time: "",
                images: "",
              },
              fileSizes: {
                life: [
                  {
                    backgroundColour: "FFFFFF",
                    colourSpace: "sRGB",
                    dpi: 72,
                    fileFormat: "jpg",
                    fileSize: "",
                    fileSizeUnit: "",
                    height: 1200,
                    title: "1. JPEG - sRGB - WEB USE ONLY",
                    width: 800,
                  },
                  {
                    backgroundColour: "FFFFFF",
                    colourSpace: "Adobe 1998",
                    dpi: 300,
                    fileFormat: "jpg",
                    fileSize: "",
                    fileSizeUnit: "",
                    height: 5000,
                    title: "2. JPEG - ADOBE 98  - PRINT USE ONLY",
                    width: 3333,
                  },
                ],
                product: [
                  {
                    backgroundColour: "FFFFFF",
                    colourSpace: "sRGB",
                    dpi: 72,
                    fileFormat: "jpg",
                    fileSize: "",
                    fileSizeUnit: "",
                    height: 1200,
                    title: "1. JPEG - sRGB - WEB USE ONLY",
                    width: 800,
                  },
                  {
                    backgroundColour: "FFFFFF",
                    colourSpace: "Adobe 1998",
                    dpi: 300,
                    fileFormat: "PSD",
                    fileSize: "",
                    fileSizeUnit: "",
                    height: 5000,
                    title: "2. PSD - ADOBE 98  - PRINT USE ONLY",
                    width: 3333,
                  },
                ],
                video: [],
                other1: "",
                other2: "",
                other3: "",
                other4: "",
              },
              fileNaming: false,
              ghostFront: false,
              ghostBack: false,
              ghost34: false,
              ghostOther: false,
              flatlayFront: false,
              flatlayBack: false,
              flatlayOther: false,
              shoesFront: false,
              shoesRight: false,
              shoesLeft: false,
              showsSole: false,
              shoesAbove: false,
              shoesPair: false,
              shoesOther: false,
              referencesAttached: false,
              referenceLastJob: false,
              clientPresentOnSet: false,
              productFront: false,
              productSide: false,
              productBack: false,
              productDetail: false,
              productReferToLastJob: false,
              treatmentShadow: false,
              treatmentNoShadow: false,
              treatmentBackground: false,
              treatmentOther: false,
              generalNotes: "",
              generalChecklist: [],
              productNotes: "",
              productChecklist: [],
              lifeNotes: "",
              lifeChecklist: [],
              videoNotes: "",
              videoChecklist: [],
              invoicingNotes: "",
            };
            firebase
              .firestore()
              .collection("ClientDatabase")
              .doc()
              .set(clientObj, { merge: true });
            newClientModalClose();
            setNewClientButtonDisplay("block");
            setNewClientNameDisplay("none");
          }}
        >
          Create Client
        </Button>
      </div>
    </div>
  );
  return (
    <>
      <div
        style={{
          width: `calc(100vw - ${listWidth}px - ${listPadding}px - 10px)`,
          background: theme === "dark" ? "#1f1f1f" : "rgb(250 250 250)",
          borderRadius: "15px",
        }}
      >
        {client ? (
          <ClientCard
            client={client}
            firebase={firebase}
            suppliedLinks={suppliedLinks}
            theme={theme}
          />
        ) : (
          <SelectClient />
        )}
      </div>
      <div
        style={{
          position: "fixed",
          right: "40px",
          bottom: "40px",
          zIndex: "100",
        }}
      >
        <Fab
          sx={{ marginRight: 2 }}
          color="primary"
          aria-label="Save CSV"
          onClick={() => {
            const newClientsData = [];
            clientData.forEach((cd) => {
              cd.contacts.forEach((contact) => {
                const split = contact.name.split(" ");
                const fsSplit = contact.name.split(".");
                if (split.length === 2) {
                  newClientsData.push({
                    email: contact.email,
                    "First Name": split[0],
                    "Last Name": split[1],
                  });
                } else if (split.length > 2) {
                  newClientsData.push({
                    email: contact.email,
                    "First Name": contact.name,
                    "Last Name": "",
                  });
                } else if (fsSplit.length === 2) {
                  newClientsData.push({
                    email: contact.email,
                    "First Name": split[0],
                    "Last Name": split[1],
                  });
                } else if (fsSplit.length > 2) {
                  newClientsData.push({
                    email: contact.email,
                    "First Name": contact.name,
                    "Last Name": "",
                  });
                } else {
                  newClientsData.push({
                    email: contact.email,
                    "First Name": contact.name,
                    "Last Name": "",
                  });
                }
              });
            });

            // console.log(newClientsData);

            const CSV = convertToCSV(newClientsData);

            download("Client Database.csv", CSV);

            function download(filename, text) {
              var element = document.createElement("a");
              element.setAttribute(
                "href",
                "data:text/plain;charset=utf-8," + encodeURIComponent(text)
              );
              element.setAttribute("download", filename);

              element.style.display = "none";
              document.body.appendChild(element);

              element.click();

              document.body.removeChild(element);
            }
            // console.log(CSV);

            function convertToCSV(arr) {
              const array = [Object.keys(arr[0])].concat(arr);

              return array
                .map((it) => {
                  return Object.values(it).toString();
                })
                .join("\n");
            }
          }}
        >
          <SaveIcon />
        </Fab>
        <Fab
          color="primary"
          aria-label="Create New Client"
          onClick={() => {
            setNewClientModal(true);
          }}
        >
          <AddIcon />
        </Fab>
        <Modal open={newClientModal} onClose={newClientModalClose}>
          {newClientModalBody}
        </Modal>
      </div>
    </>
  );
};

const ClientListItem = ({
  client,
  setSelectedClient,
  selectedClient,
  theme,
}) => {
  const [PSF, setPSF] = useState(client.PSF);
  const [paymentTerms, setPaymentTerms] = useState(client.paymentTerms);
  const [turnAround, setTurnAround] = useState(client.turnAround);
  useEffect(() => {
    setPSF(client.PSF);
    setPaymentTerms(client.paymentTerms);
    setTurnAround(client.turnAround);
  }, [client]);
  const handleClick = () => {
    setSelectedClient(client.brand);
  };
  return (
    <ListItem
      button
      onClick={handleClick}
      style={{
        borderRadius: "5px",
        background: `${
          selectedClient === client.brand
            ? theme === "dark"
              ? "#383838"
              : "rgb(230 230 230)"
            : ""
        }`,
        paddingRight: "10px",
        borderBottom: "1px solid rgb(180, 180, 180)",
      }}
    >
      <ListItemText
        className="listItemSecondary"
        primary={client.brand}
        secondary={`${paymentTerms}${insertSpacesAndSep(
          6
        )}${PSF}%${insertSpacesAndSep(6)}${turnAround} Days`}
      />
    </ListItem>
  );
};

const SelectClient = () => {
  const [vis, setVis] = useState(false);
  setTimeout(() => {
    setVis(true);
  }, 60000);
  return (
    <div>
      Select a client to view their information
      {vis ? (
        <span style={{ color: "rgb(180 180 180)" }}>
          {" "}
          ...why are you still here? Click a client.
        </span>
      ) : null}
    </div>
  );
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}
const insertSpacesAndSep = (count) => {
  if (!count) {
    return false;
  }
  if (count % 2 !== 0) {
    count = count + 1;
  }
  let str = "\u00A0";
  for (let i = 1; i < count; i++) {
    if (i === count / 2) {
      str = `${str}\u2014`;
    }
    str = `${str}\u00A0`;
  }
  return str;
};
const updateFSNow = debounce(
  async (id, field, value) => {
    firebaseX
      .firestore()
      .collection("ClientDatabase")
      .doc(id)
      .set({ [field]: value }, { merge: true });
  },
  0,
  true
);

const updateFS = debounce(async (id, field, value) => {
  firebaseX
    .firestore()
    .collection("ClientDatabase")
    .doc(id)
    .set({ [field]: value }, { merge: true });
}, 800);
function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function UpdateMetaThemeColour({ light, dark }) {
    light = light.replace('#', '')
    dark = dark.replace('#', '')
    const existingMetas = Array.from(document.querySelectorAll('meta[name=theme-color]'))
    existingMetas.forEach(meta => {
        meta.remove()
        meta.remove()
    })
    let lightMeta = document.createElement('meta')
    lightMeta.name = 'theme-color'
    lightMeta.content = `#${light}`
    lightMeta.media = '(prefers-color-scheme: light)'
    document.querySelector('head').append(lightMeta)
    let darkMeta = document.createElement('meta')
    darkMeta.name = 'theme-color'
    darkMeta.content = `#${dark}`
    darkMeta.media = '(prefers-color-scheme: dark)'
    document.querySelector('head').append(darkMeta)
}
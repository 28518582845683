import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import { Modal, ListItemText, ListItemIcon, ListItem } from "@mui/material";
import { List, Drawer, InputBase, Divider, Dialog } from "@mui/material";
import { FormControlLabel, ToggleButton, Switch } from "@mui/material";
import { ToggleButtonGroup, DialogTitle } from "@mui/material";
import { DialogContent, DialogContentText, Slide } from "@mui/material";
import { Avatar, CircularProgress, useMediaQuery } from "@mui/material";
import { useTheme as useMUITheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import PublicIcon from "@mui/icons-material/Public";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import ContactsIcon from "@mui/icons-material/Contacts";
import WorkIcon from "@mui/icons-material/Work";
import { createGlobalStyle } from "styled-components";
import { UpdateMetaThemeColour } from "./Utils/UpdateMetaThemeColour";
import logosml from "./images/ITS_LOGO_BLACK_SML.svg";
import { returnBackgroundColour } from "./Utils/returnBackgroundColour";
import { ReactFitty } from "react-fitty";
import { styled, Box } from "@mui/system";
import { useHistory, useLocation } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import CachedIcon from "@mui/icons-material/Cached";
// import ContrastIcon from "@mui/icons-material/Contrast";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
// import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ProgressCircle from "./ProgressCircle";

// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
// import CheckroomIcon from "@mui/icons-material/Checkroom";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
// import WifiOffIcon from "@mui/icons-material/WifiOff";
import SearchIcon from "@mui/icons-material/Search";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ParkIcon from "@mui/icons-material/Park";
// import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShootTab from "./Calendar/Event Data Modal Components/ShootTab";
import ClientTab from "./Calendar/Event Data Modal Components/ClientTab";
import EventDataModal from "./Calendar/EventDataModal";
import LabelChipSmall from "./GlobalComponents/LabelChipSmall";
import DueDateChip from "./GlobalComponents/DueDateChip";
import CloseIcon from "@mui/icons-material/Close";

import { getFirestore } from "firebase/firestore";
import { onSnapshot, doc } from "firebase/firestore";

import { use100vh } from "react-div-100vh";
import { debounce } from "lodash";

import { useAtom } from "jotai";
import {
  isIdleAtom,
  themeAtom,
  listsAtom,
  setSearchResultModalIDAtom,
  setSearchResultModalKindAtom,
  currentStaffDataAtom,
  staffDataAtom,
  calendarColoursAtom,
  calendarEventsAtom,
  isMobileBrowserAtom,
  isElectronAtom,
} from "./-Atoms";
import { publish } from "./GlobalFunctions/customEvent";

import DockFunctions from "./GlobalFunctions/DockFunctions";

import QRScanner from "./GlobalComponents/QRScanner";

import TrafficLight from "./macos-traffic-light";

import getJobName from "./GlobalFunctions/getJobName";

const { DateTime } = require("luxon");

const crypto = require("crypto");

const drawerWidth = 220;
const appBarHeight = 64;

const menuItems = [
  {
    text: "Calendar",
    icon: <CalendarTodayIcon />,
    path: "/calendar",
    isRoot: true,
  },
  {
    text: "Kanban",
    icon: <ViewKanbanIcon />,
    path: "/kanban",
    isRoot: true,
  },
  {
    text: "Clients",
    icon: <ContactsIcon />,
    path: "/clients",
    admin: true,
  },
  {
    text: "Staff",
    icon: <PersonIcon />,
    path: "/staff",
  },
  // {
  //   text: "Styling",
  //   icon: <CheckroomIcon />,
  //   path: "/styling",
  // },
  {
    text: "Outsourcers",
    icon: <PublicIcon />,
    path: "/outsourcers",
    admin: true,
  },
  {
    text: "Jobs",
    icon: <WorkIcon />,
    path: "/jobs",
    admin: true,
  },
  // {
  //   text: "Photo Contest",
  //   icon: <ImageIcon />,
  //   path: "/photocontest",
  // },
  {
    text: "Admin Tools",
    icon: <SupervisedUserCircleIcon />,
    path: "/admin",
    admin: true,
  },
];

const Layout = ({
  children,
  user,
  auth,
  firebase,
  userTheme,
  handleThemeChange,
  theme,
}) => {
  const { showDock, hideDock } = DockFunctions();

  UpdateMetaThemeColour({
    light: returnBackgroundColour("light"),
    dark: returnBackgroundColour("dark"),
  });

  const [currentStaffData] = useAtom(currentStaffDataAtom);

  const history = useHistory();
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => {
    auth.signOut();
    handleMenuClose();
  };

  const [notificationSettingsMenuAnchor, setNotificationSettingsMenuAnchor] =
    useState(null);

  const handleNotificationSettingsClick = (event) => {
    setNotificationSettingsMenuAnchor(event.currentTarget);
  };

  const handleNotificationSettingsClose = () => {
    setNotificationSettingsMenuAnchor(null);
  };

  // const isIdle = true;
  const [isIdle] = useAtom(isIdleAtom);

  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const handleSearchModalOpen = () => {
    setSearchModalOpen(true);
    hideDock();
  };
  const handleSearchModalClose = () => {
    setSearchModalOpen(false);
    showDock();
  };

  const [searchResultModalOpen, setSearchResultModalOpen] = useState(false);
  const handleSearchResultModalOpen = () => setSearchResultModalOpen(true);
  const handleSearchResultModalClose = () => setSearchResultModalOpen(false);

  // console.log(setSearchModal);

  useEffect(() => {
    const listener = (e) => {
      if (
        e.code === "KeyK" &&
        (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)
      ) {
        e.preventDefault();
        setSearchModalOpen((prev) => {
          const newValue = !prev;
          if (newValue) {
            hideDock();
          } else {
            showDock();
          }

          return newValue;
        });
      }
    };
    document.addEventListener("keydown", listener, false);
    return () => {
      document.removeEventListener("keydown", listener, false);
    };
  }, [hideDock, showDock]);

  const GlobalStyles = createGlobalStyle`
  .initialSearchButton {
    min-width: 180px;
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px;
    padding-left: 4.8px;
    position: relative;
    color: rgb(176, 184, 196);
    font-size: 0.9rem;
    cursor: pointer;
    transition-property: all;
    transition-duration: 150ms;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: ${
      theme === "dark" ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.1)"
    };
  }
  .initialSearchButton:hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: ${
      theme === "dark" ? "rgba(50, 50, 50, 0.2)" : "rgba(50, 50, 50, 0.1)"
    };
  }
  `;

  const isMobile = useMediaQuery("@media (max-width:780px)");

  const calendarPrevious = () => {
    publish("calendarPrevious");
  };
  const calendarNext = () => {
    publish("calendarNext");
  };

  const [detailsFilledCorrectly, setDetailsFilledCorrectly] = useState(true);
  const [detailsFilled, setDetailsFilled] = useState({});

  useEffect(() => {
    const main = () => {
      if (user?.staffData?.firstName) {
        checkIfDetailsFilledCorrectly(user.staffData);
      }
    };
    main();
  }, [user]);

  const checkIfDetailsFilledCorrectly = (userData) => {
    // console.log(userData);
    let detailsFilledCorrectlyTmp = true;
    let detailsFilledTmp = {
      firstName: true,
      lastName: true,
      phoneNumber: true,
      dateOfBirth: true,
      streetNumber: true,
      streetName: true,
      postcode: true,
      suburb: true,
      employmentBasis: true,
      nextOfKin1Name: true,
      nextOfKin1Phone: true,
      taxFileNumber: true,
      bankName: true,
      BSB: true,
      superannuationProvider: true,
      superannuationMemberNumber: true,
      superannuationFundUSI: true,
    };
    if (!userData.firstName) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.firstName = false;
    }
    if (!userData.lastName) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.lastName = false;
    }
    if (!userData.phoneNumber) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.phoneNumber = false;
    }
    if (!userData.dateOfBirth) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.dateOfBirth = false;
    }
    if (!userData.address?.streetNumber) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.streetNumber = false;
    }
    if (!userData.address?.streetName) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.streetName = false;
    }
    if (!userData.address?.postcode) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.postcode = false;
    }
    if (!userData.address?.suburb) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.suburb = false;
    }
    if (!userData.employmentBasis) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.employmentBasis = false;
    }
    if (!userData.nextOfKin1Name) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.nextOfKin1Name = false;
    }
    if (!userData.nextOfKin1Phone) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.nextOfKin1Phone = false;
    }
    if (!userData.taxFileNumber) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.taxFileNumber = false;
    }
    if (!userData.bankName) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.bankName = false;
    }
    if (!userData.BSB) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.BSB = false;
    }
    if (!userData.superannuationProvider) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.superannuationProvider = false;
    }
    if (!userData.superannuationMemberNumber) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.superannuationMemberNumber = false;
    }
    if (!userData.superannuationFundUSI) {
      detailsFilledCorrectlyTmp = false;
      detailsFilledTmp.superannuationFundUSI = false;
    }
    const checkAgain = localStorage.getItem("checkDetailedFilledAgain");
    let checkAgainDate = DateTime.fromISO(
      checkAgain
        ? checkAgain
        : DateTime.now().setZone("Australia/Sydney").startOf("day")
    );
    const now = DateTime.now().setZone("Australia/Sydney");
    if (now > checkAgainDate) {
      // console.lo;
      setDetailsFilledCorrectly(detailsFilledCorrectlyTmp);
    } else {
      setDetailsFilledCorrectly(true);
    }
    setDetailsFilled(detailsFilledTmp);
  };

  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const handleQrDialogClose = () => {
    setQrDialogOpen(false);
    showDock();
  };
  // const generateLoginToken = firebase
  //   .functions()
  //   .httpsCallable("generateLoginToken");
  const [isElectron] = useAtom(isElectronAtom);

  return (
    <>
      <GlobalStyles />
      <FillOutDetailsModal
        firebase={firebase}
        detailsFilledCorrectly={detailsFilledCorrectly}
        setDetailsFilledCorrectly={setDetailsFilledCorrectly}
        detailsFilled={detailsFilled}
        theme={theme}
        user={user}
      />
      <SearchResultModal
        firebase={firebase}
        searchResultModalOpen={searchResultModalOpen}
        setSearchResultModalOpen={setSearchResultModalOpen}
        handleSearchResultModalClose={handleSearchResultModalClose}
      />
      <SearchModal
        firebase={firebase}
        searchModalOpen={searchModalOpen}
        handleSearchModalClose={handleSearchModalClose}
        handleSearchResultModalOpen={handleSearchResultModalOpen}
      />
      <QRScannerDialog
        firebase={firebase}
        open={qrDialogOpen}
        handleClose={handleQrDialogClose}
        // generateLoginToken={generateLoginToken}
      />

      <AppBar
        elevation={0}
        style={{
          position: "fixed",
          backgroundColor: `#${returnBackgroundColour(theme)}`,
          transition: "margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
          minHeight: `${appBarHeight}px`,
          borderBottom: `1px solid ${
            theme === "dark" ? "rgb(40, 40, 40)" : "rgb(240, 240, 240)"
          }`,
        }}
        position="static"
        sx={{ zIndex: 200 }}
      >
        <Toolbar
          sx={{
            zIndex: 99,
            "-webkit-app-region": searchResultModalOpen ? "no-drag" : "drag",
            WebkitAppRegion: searchResultModalOpen ? "no-drag" : "drag",
          }}
        >
          {isElectron ? (
            <div style={{ position: "absolute", top: 10, left: 10 }}>
              <TrafficLight
                onClose={() => {
                  window.ipcRenderer.send("customCloseWindow");
                }}
                onMinimize={() => {
                  window.ipcRenderer.send("customMinimizeWindow");
                }}
                onMaximize={() => {
                  window.ipcRenderer.send("customMaximizeWindow");
                }}
              />
            </div>
          ) : null}
          <ButtonGroup
            variant="outlined"
            sx={{
              WebkitAppRegion: "no-drag",
              display: isMobile
                ? window.innerWidth < 320
                  ? "none"
                  : "block"
                : "none",
              marginLeft: isElectron ? 7 : 1,
            }}
          >
            <Button
              sx={{
                WebkitAppRegion: "no-drag",
                borderColor:
                  theme === "dark"
                    ? "rgba(210, 210, 210, 0.2)"
                    : "rgba(40, 40, 40, 0.2)",
              }}
              onClick={calendarPrevious}
            >
              <NavigateBeforeIcon
                sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
              />
            </Button>
            <Button
              sx={{
                WebkitAppRegion: "no-drag",
                borderColor:
                  theme === "dark"
                    ? "rgba(210, 210, 210, 0.2)"
                    : "rgba(40, 40, 40, 0.2)",
              }}
              onClick={calendarNext}
            >
              <NavigateNextIcon
                sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
              />
            </Button>
          </ButtonGroup>
          <div
            style={{
              display: isMobile ? "none" : "flex",
              // display: isMobile ? "none" : "block",
              position: "absolute",
              right: user ? (isIdle ? 125 : isElectron ? 125 : 100) : 10,
              // right: user ? (isIdle ? 140 : isElectron ? 125 : 100) : 10,
              paddingRight: 25,
            }}
          >
            <button
              className="initialSearchButton"
              onClick={handleSearchModalOpen}
              style={{
                outline: "none",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  outline: "none",
                  color: theme === "dark" ? "" : "#4d4d4d",
                  WebkitAppRegion: "no-drag",
                }}
              >
                <SearchIcon sx={{ marginRight: 1, marginLeft: 1 }} />
                Search...
              </div>
              <div
                style={{
                  fontSize: "0.75rem",
                  fontWeight: 700,
                  marginLeft: 4,
                  border: "1px solid rgba(0, 0, 0, 0.3)",
                  backgroundColor:
                    theme === "dark"
                      ? "rgba(0, 0, 0, 0.2)"
                      : "rgba(0, 0, 0, 0.1)",
                  padding: 4,
                  borderRadius: 7,
                  color: theme === "dark" ? "#ffffff" : "#4d4d4d",
                }}
              >
                ⌘K
              </div>
            </button>
          </div>
          <div
            style={{ position: "absolute", right: "0", paddingRight: "25px" }}
          >
            {user ? (
              <UserMenu
                user={user}
                firebase={firebase}
                so={signOut}
                a={anchorEl}
                click={handleMenuClick}
                close={handleMenuClose}
                notificationSettingsMenuAnchor={notificationSettingsMenuAnchor}
                handleNotificationSettingsClose={
                  handleNotificationSettingsClose
                }
                handleNotificationSettingsClick={
                  handleNotificationSettingsClick
                }
                userTheme={userTheme}
                handleThemeChange={handleThemeChange}
                theme={theme}
                setQrDialogOpen={setQrDialogOpen}
              />
            ) : (
              <IconButton
                onClick={() => {
                  window.location.href = "/calendar";
                }}
              >
                <LoginIcon
                  sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
                />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {/* <div
        style={{
          WebkitAppRegion: "drag",
          position: "absolute",
          backgroundColor: `#${returnBackgroundColour(theme)}`,
          width: "100vw",
          height: appBarHeight,
          // top: 0,
          // bottom: 0,
          // left: 0,
          // right: 0,
          zIndex: 1,
        }}
      >
        ass
      </div> */}

      <div style={{ display: "flex" }}>
        <Drawer
          sx={{ width: drawerWidth }}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          anhcor="left"
          disableScrollLock={true}
          classes={{ paper: { width: drawerWidth } }}
        >
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={logosml}
                alt="Logo"
                style={{
                  width: "40%",
                  paddingTop: "20px",
                  paddingBottom: "40px",
                }}
              />
            </div>
            <List>
              {menuItems.map((item) =>
                item.admin ? (
                  isAdmin(currentStaffData) ? (
                    <CreateListItem
                      key={item.text}
                      item={item}
                      history={history}
                      location={location}
                      setOpen={setOpen}
                    />
                  ) : null
                ) : (
                  <CreateListItem
                    key={item.text}
                    item={item}
                    history={history}
                    location={location}
                    setOpen={setOpen}
                  />
                )
              )}
            </List>
          </div>
        </Drawer>
        <div
          style={{
            padding: 0,
            marginTop: `${appBarHeight}px`,
          }}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default Layout;

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  // align-items: center;
  justify-content: center;
`;

const FillOutDetailsModal = ({
  firebase,
  detailsFilledCorrectly,
  setDetailsFilledCorrectly,
  detailsFilled,
  theme,
  user,
}) => {
  const modalContainerStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85vw",
    height: "75vh",
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
    boxShadow: `rgba(20, 20, 20, ${
      theme === "dark" ? "0.7" : "0.2"
    }) 0px 4px 20px`,
    border: `1px solid rgb(${
      theme === "dark" ? "50, 50, 50" : "200, 200, 200"
    })`,
    borderRadius: "4px",
    outline: "none",
    overflow: "hidden",
    zIndex: 9999,
    padding: 20,
  };
  const textFieldStyle = { margin: 10 };
  const [detailsFilledDateOfBirth, setDetailsFilledDateOfBirth] =
    useState(null);
  return (
    <>
      <Modal
        open={!detailsFilledCorrectly}
        onClose={() => {
          setDetailsFilledCorrectly(true);
          const checkAgain = DateTime.now()
            .setZone("Australia/Sydney")
            .startOf("day")
            .plus({ days: 2 });
          localStorage.setItem("checkDetailedFilledAgain", checkAgain);
        }}
        sx={{
          "& > .MuiBackdrop-root": {
            backdropFilter: "blur(4px)",
          },
        }}
      >
        <div style={modalContainerStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <span>Some of your details have not been filled out</span>
            <span style={{ marginBottom: 10 }}>
              Please fill out the following information:
            </span>
            {!detailsFilled.firstName ? (
              <TextField
                variant="outlined"
                label="First Name"
                id="detailsFilled_firstName"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.lastName ? (
              <TextField
                variant="outlined"
                label="Last Name"
                id="detailsFilled_lastName"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.phoneNumber ? (
              <TextField
                variant="outlined"
                label="Phone Number"
                id="detailsFilled_phoneNumber"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.dateOfBirth ? (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Date of Birth"
                  inputFormat="dd/MM/yyyy"
                  value={detailsFilledDateOfBirth}
                  onChange={(e) => {
                    setDetailsFilledDateOfBirth(new Date(e));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      fullWidth
                      style={textFieldStyle}
                    />
                  )}
                />
              </LocalizationProvider>
            ) : null}
            {!detailsFilled.streetNumber ? (
              <TextField
                variant="outlined"
                label="Street Number"
                id="detailsFilled_streetNumber"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.streetName ? (
              <TextField
                variant="outlined"
                label="Street Name"
                id="detailsFilled_streetName"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.postcode ? (
              <TextField
                variant="outlined"
                label="Postcode"
                id="detailsFilled_postcode"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.suburb ? (
              <TextField
                variant="outlined"
                label="Suburb"
                id="detailsFilled_suburb"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.nextOfKin1Name ? (
              <TextField
                variant="outlined"
                label="Next Of Kin 1 Name"
                id="detailsFilled_nextOfKin1Name"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.nextOfKin1Phone ? (
              <TextField
                variant="outlined"
                label="Next Of Kin 1 Phone"
                id="detailsFilled_nextOfKin1Phone"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.taxFileNumber ? (
              <TextField
                variant="outlined"
                label="Tax File Number"
                id="detailsFilled_taxFileNumber"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.bankName ? (
              <TextField
                variant="outlined"
                label="Bank Name"
                id="detailsFilled_bankName"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.BSB ? (
              <TextField
                variant="outlined"
                label="BSB"
                id="detailsFilled_BSB"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.superannuationProvider ? (
              <TextField
                variant="outlined"
                label="Superannuation Provider"
                id="detailsFilled_superannuationProvider"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.superannuationMemberNumber ? (
              <TextField
                variant="outlined"
                label="Superannuation Member Number"
                id="detailsFilled_superannuationMemberNumber"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
            {!detailsFilled.superannuationFundUSI ? (
              <TextField
                variant="outlined"
                label="Superannuation Fund USI"
                id="detailsFilled_superannuationFundUSI"
                fullWidth
                style={textFieldStyle}
              />
            ) : null}
          </div>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              bottom: 15,
              left: "calc(85vw / 2 - 34px)",
            }}
            onClick={async () => {
              let tmp = {};
              if (!detailsFilled.firstName) {
                const val = document.getElementById(
                  "detailsFilled_firstName"
                ).value;
                if (val) tmp.firstName = val;
              }
              if (!detailsFilled.lastName) {
                const val = document.getElementById(
                  "detailsFilled_lastName"
                ).value;
                if (val) tmp.lastName = val;
              }
              if (!detailsFilled.phoneNumber) {
                const val = document.getElementById(
                  "detailsFilled_phoneNumber"
                ).value;
                if (val) tmp.phoneNumber = val;
              }
              if (!detailsFilled.dateOfBirth) {
                tmp.dateOfBirth = detailsFilledDateOfBirth;
              }
              if (!detailsFilled.streetNumber) {
                await firebase
                  .firestore()
                  .collection("StaffData")
                  .doc(user.staffData.id)
                  .update(
                    {
                      "address.streetNumber": document.getElementById(
                        "detailsFilled_streetNumber"
                      ).value,
                    },
                    { merge: true }
                  );
              }
              if (!detailsFilled.streetName) {
                await firebase
                  .firestore()
                  .collection("StaffData")
                  .doc(user.staffData.id)
                  .update(
                    {
                      "address.streetName": document.getElementById(
                        "detailsFilled_streetName"
                      ).value,
                    },
                    { merge: true }
                  );
              }
              if (!detailsFilled.postcode) {
                await firebase
                  .firestore()
                  .collection("StaffData")
                  .doc(user.staffData.id)
                  .update(
                    {
                      "address.postcode": document.getElementById(
                        "detailsFilled_postcode"
                      ).value,
                    },
                    { merge: true }
                  );
              }
              if (!detailsFilled.suburb) {
                await firebase
                  .firestore()
                  .collection("StaffData")
                  .doc(user.staffData.id)
                  .update(
                    {
                      "address.suburb": document.getElementById(
                        "detailsFilled_suburb"
                      ).value,
                    },
                    { merge: true }
                  );
              }
              if (!detailsFilled.nextOfKin1Name) {
                const val = document.getElementById(
                  "detailsFilled_nextOfKin1Name"
                ).value;
                if (val) tmp.nextOfKin1Name = val;
              }
              if (!detailsFilled.nextOfKin1Phone) {
                const val = document.getElementById(
                  "detailsFilled_nextOfKin1Phone"
                ).value;
                if (val) tmp.nextOfKin1Phone = val;
              }
              if (!detailsFilled.taxFileNumber) {
                const val = document.getElementById(
                  "detailsFilled_taxFileNumber"
                ).value;
                if (val) tmp.taxFileNumber = val;
              }
              if (!detailsFilled.bankName) {
                const val = document.getElementById(
                  "detailsFilled_bankName"
                ).value;
                if (val) tmp.bankName = val;
              }
              if (!detailsFilled.BSB) {
                const val = document.getElementById("detailsFilled_BSB").value;
                if (val) tmp.BSB = val;
              }
              if (!detailsFilled.superannuationProvider) {
                const val = document.getElementById(
                  "detailsFilled_superannuationProvider"
                ).value;
                if (val) tmp.superannuationProvider = val;
              }
              if (!detailsFilled.superannuationMemberNumber) {
                const val = document.getElementById(
                  "detailsFilled_superannuationMemberNumber"
                ).value;
                if (val) tmp.superannuationMemberNumber = val;
              }
              if (!detailsFilled.superannuationFundUSI) {
                const val = document.getElementById(
                  "detailsFilled_superannuationFundUSI"
                ).value;
                if (val) tmp.superannuationFundUSI = val;
              }
              firebase
                .firestore()
                .collection("StaffData")
                .doc(user.staffData.id)
                .update({ ...tmp });

              setDetailsFilledCorrectly(true);
            }}
          >
            Save
          </Button>
        </div>
      </Modal>
    </>
  );
};

const SearchResultModal = ({
  firebase,
  searchResultModalOpen,
  setSearchResultModalOpen,
}) => {
  const [calendarColours] = useAtom(calendarColoursAtom);
  const [calendarEvents] = useAtom(calendarEventsAtom);

  const [isIdle] = useAtom(isIdleAtom);
  const [theme] = useAtom(themeAtom);
  const [staffData] = useAtom(staffDataAtom);
  const [currentStaffData] = useAtom(currentStaffDataAtom);
  const [searchResultModalID, setSearchResultModalID] = useAtom(
    setSearchResultModalIDAtom
  );
  const [setSearchResultModalKind, setSetSearchResultModalKind] = useAtom(
    setSearchResultModalKindAtom
  );

  // console.log(searchResultModalID);

  const [clientData, setClientData] = useState({});
  const [jobData, setJobData] = useState({});
  // console.log(jobData);
  const [jobClientData, setJobClientData] = useState({});

  const db = getFirestore(firebase.app());

  const location = useLocation();

  useEffect(() => {
    let unsub;
    unsub = firebase
      .firestore()
      .collection("ClientDatabase")
      .orderBy("brand", "asc")
      .onSnapshot((collection) => {
        const cd = collection.docs.map((client) => {
          const data = client.data();
          data.id = client.id;
          return data;
        });
        setClientData(cd);
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
    // eslint-disable-next-line
  }, [firebase, isIdle]);

  useEffect(() => {
    let unsub;
    const main = () => {
      if (!searchResultModalID) return;
      if (setSearchResultModalKind === "job") {
        unsub = onSnapshot(
          doc(db, "JobData", searchResultModalID),
          (doc) => {
            const data = doc.data();
            data.id = searchResultModalID;
            setJobData(data);
          },
          (error) => {
            console.log(error);
          }
        );
      } else {
        unsub = onSnapshot(
          doc(db, "ClientDatabase", searchResultModalID),
          (doc) => {
            const data = doc.data();
            data.id = searchResultModalID;
            setJobData(data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    main();
    // if (isIdle) {
    //   try {
    //     unsub();
    //   } catch (e) {}
    // }
    return () => {
      try {
        unsub();
      } catch (e) {}
    };
  }, [db, searchResultModalID, setJobData, setSearchResultModalKind, isIdle]);

  useEffect(() => {
    if (jobData.client) {
      const unsubscribe = firebase
        .firestore()
        .collection("ClientDatabase")
        .where("brand", "==", jobData.client)
        .onSnapshot((snapshot) => {
          try {
            const data = snapshot.docs[0].data();
            data.id = snapshot.docs[0].id;
            setJobClientData({ ...data });
          } catch (e) {
            console.log(e);
          }
        });
      // if (isIdle) {
      //   unsubscribe();
      // }
      return () => {
        unsubscribe();
      };
    }
  }, [firebase, jobData, isIdle]);

  const MUITheme = useMUITheme();
  const isMobile = useMediaQuery("@media (max-width:780px)");
  const isMidSize = useMediaQuery(MUITheme.breakpoints.down("lg"));

  const modalWidth = isMobile ? "100vw" : "780px";

  const style = (themeMui) => ({
    width: modalWidth,
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
    outline: "none",
  });

  const modal = useRef(null);

  const height = use100vh();

  const windowInner = window.innerWidth;
  const modalInt = parseInt(modalWidth.replace("px", ""));
  const cal = windowInner - modalInt;
  const calDiv2 = cal / 2;

  const closePos = calDiv2 + 10;

  return (
    <>
      {location.pathname.includes("kanban") ||
      setSearchResultModalKind === "client" ? (
        <StyledModal
          open={searchResultModalOpen}
          onClose={() => {
            setSearchResultModalOpen(false);
            setSearchResultModalOpen(false);
            setSearchResultModalID("");
            setSearchResultModalID("");
            setSetSearchResultModalKind("");
            setSetSearchResultModalKind("");
          }}
        >
          <Box sx={style} ref={modal}>
            <IconButton
              sx={{
                position: "absolute",
                top: 6,
                right: `${closePos}px`,
                zIndex: 99999,
              }}
              onClick={() => {
                setSearchResultModalOpen(false);
                setSearchResultModalOpen(false);
                setSearchResultModalID("");
                setSearchResultModalID("");
                setSetSearchResultModalKind("");
                setSetSearchResultModalKind("");
              }}
            >
              <CloseIcon />
            </IconButton>
            {setSearchResultModalKind === "job" ? (
              <ShootTab
                firebase={firebase}
                // jobDataInit={jobData}
                jobData={jobData}
                setJobData={setJobData}
                clientDataInit={jobClientData}
                staffData={staffData}
                theme={theme}
                modalWidth={modalWidth}
                isMobile={isMobile}
                currentUser={currentStaffData}
                setStopModalClose={() => {}}
                kanbanView={true}
                element={modal}
                soloClose={true}
                modalJobData={{}}
                modalClientData={{}}
                layoutView={true}
              />
            ) : (
              <ClientTab
                jobClientData={jobData}
                firebase={firebase}
                theme={theme}
                element={modal}
                soloClose={true}
                layoutView={true}
              />
            )}
          </Box>
        </StyledModal>
      ) : (
        <>
          <Slide
            direction="right"
            in={searchResultModalOpen}
            mountOnEnter
            unmountOnExit
            timeout={50}
          >
            <div
              style={{
                width: isMobile ? "100vw" : isMidSize ? "60vw" : "40vw",
                height: height,
                background: "#ffffff",
                zIndex: 1100,
                position: "absolute",
                top: 0,
              }}
            >
              <EventDataModal
                firebase={firebase}
                staffData={staffData}
                event={{
                  start: new Date(jobData.start),
                  end: new Date(jobData.end),
                  extendedProps: jobData,
                  id: jobData.id,
                }}
                events={calendarEvents}
                eventDataModalOpen={searchResultModalOpen}
                setEventDataModalOpen={setSearchResultModalOpen}
                setEventDataModal={() => {}}
                height={height}
                clientData={clientData}
                calendarColours={calendarColours}
                stopModalClose={false}
                setStopModalClose={() => {}}
                currentUser={currentStaffData}
                confirmationStatusEmailOpen={false}
                setConfirmationStatusEmailOpen={() => {}}
                firstConfirmationStatusEmailOpen={false}
                setFirstConfirmationStatusEmailOpen={() => {}}
                setConfirmationEmailData={() => {}}
                isEventTomorrow={isEventTomorrow}
                theme={theme}
                newCardID={""}
                modalWidth={isMobile ? "100vw" : isMidSize ? "50vw" : "50vw"}
              />
            </div>
          </Slide>
          <div
            style={{
              display: searchResultModalOpen ? "block" : "none",
              height: "100%",
              background: "rgba(0,0,0,0.4)",
              zIndex: 1099,
              position: "absolute",
              filter: "blur(4px)",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            onClick={() => {
              setSearchResultModalOpen(false);
              setSearchResultModalOpen(false);
              setSearchResultModalID("");
              setSearchResultModalID("");
              setSetSearchResultModalKind("");
              setSetSearchResultModalKind("");

              // if (!stopModalClose) setEventDataModalOpen(false);
              // setEventDataModal({});
            }}
          ></div>
        </>
      )}
    </>
  );
};

const SearchModal = ({
  firebase,
  searchModalOpen,
  handleSearchModalClose,
  handleSearchResultModalOpen,
}) => {
  const [theme] = useAtom(themeAtom);
  const [searchValue, setSearchValue] = useState("");
  const [searchReturnValue, setSearchReturnValue] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  const resetSearchWindow = () => {
    setSearchValue("");
    setSearchReturnValue([]);
    setSearchLoading(false);
    handleSearchModalClose();
  };
  const modalContainerStyle = {
    position: "absolute",
    top: 60,
    left: "50%",
    transform: "translate(-50%)",
    width: 700,
    height: 600,
    backgroundColor: theme === "dark" ? "#1f1f1f" : "#ffffff",
    boxShadow: `rgba(20, 20, 20, ${
      theme === "dark" ? "0.7" : "0.2"
    }) 0px 4px 20px`,
    border: `1px solid rgb(${
      theme === "dark" ? "50, 50, 50" : "200, 200, 200"
    })`,
    borderRadius: "clamp(0px, (100vw - 750px) * 9999, 12px)",
    outline: "none",
    overflow: "hidden",
  };
  const modalHeaderStyle = {
    padding: 8,
    height: 70,
    // backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid rgb(${
      theme === "dark" ? "50, 50, 50" : "200, 200, 200"
    })`,
  };
  const modalHeaderLeft = {
    display: "flex",
    alignItems: "center",
  };
  const escStyle = {
    fontSize: "0.75rem",
    fontWeight: 700,
    marginRight: 15,
    border: "1px solid rgba(80, 80, 80, 0.3)",
    backgroundColor: "rgba(80, 80, 80, 0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 25,
    borderRadius: 4,
    cursor: "pointer",
    color: "#858789",
  };
  const loadingSearchStyle = {
    width: "100%",
    height: "100%",
    marginTop: -70,
    display: searchLoading ? "flex" : "none",
    justifyContent: "center",
    alignItems: "center",
  };
  const searchResultsStyle = {
    width: "100%",
    height: "87%",
    marginTop: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  };
  // eslint-disable-next-line
  const searchFirebase = useCallback(
    debounce(async (searchValue) => {
      let resArray = [];
      if (parseInt(searchValue)) {
        const res = await firebase
          .firestore()
          .collection("JobData")
          .where("year", "==", parseInt(`20${searchValue.slice(0, 2)}`))
          .where("jobNumber", "==", parseInt(searchValue))
          .get()
          .catch((err) => {
            console.log(err);
            setSearchReturnValue([]);
            setSearchLoading(false);
          });
        res.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          data.kind = "job";
          resArray.push(data);
        });
      } else {
        const res = await firebase
          .firestore()
          .collection("ClientDatabase")
          .where(
            "brandLowerCaseArray",
            "array-contains",
            searchValue.toLowerCase()
          )
          .get()
          .catch((err) => {
            console.log(err);
            setSearchReturnValue([]);
            setSearchLoading(false);
          });
        res.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          data.kind = "client";
          resArray.push(data);
        });

        const res2 = await firebase
          .firestore()
          .collection("JobData")
          .where(
            "clientLowerCaseArray",
            "array-contains",
            searchValue.toLowerCase()
          )
          .orderBy("start", "desc")
          .get()
          .catch((err) => {
            console.log(err);
            setSearchReturnValue([]);
            setSearchLoading(false);
          });
        res2.docs.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          data.kind = "job";
          if (data.jobNumber) {
            resArray.push(data);
          }
        });
      }
      setSearchReturnValue(resArray);
      setSearchLoading(false);
    }, 1000),
    []
  );
  return (
    <Modal
      open={searchModalOpen}
      onClose={resetSearchWindow}
      sx={{
        "& > .MuiBackdrop-root": {
          backdropFilter: "blur(4px)",
        },
      }}
    >
      <div style={modalContainerStyle}>
        <div style={modalHeaderStyle}>
          <div style={modalHeaderLeft}>
            <SearchIcon sx={{ fontSize: 30, margin: 1.5 }} />
            <InputBase
              autoComplete="off"
              placeholder="Search..."
              sx={{ fontSize: "1.2em", width: 500 }}
              autoFocus
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                if (e.target.value !== "") {
                  setSearchLoading(true);
                  searchFirebase(e.target.value);
                } else {
                  setSearchLoading(false);
                }
              }}
            />
          </div>
          <div onClick={resetSearchWindow} style={escStyle}>
            esc
          </div>
        </div>
        <div style={loadingSearchStyle}>
          <CircularProgress size={80} />
        </div>
        <div style={searchResultsStyle}>
          <div
            style={{
              overflowY: "auto",
              width: "100%",
              display: "grid",
              justifyContent: "center",
            }}
          >
            {searchReturnValue &&
              searchReturnValue.map((res, index) => (
                <SearchResult
                  key={`Search-Result-${index}`}
                  jobData={res}
                  firebase={firebase}
                  resetSearchWindow={resetSearchWindow}
                  handleSearchResultModalOpen={handleSearchResultModalOpen}
                />
              ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const SearchResult = ({
  jobData,
  firebase,
  resetSearchWindow,
  handleSearchResultModalOpen,
}) => {
  const [, setSearchResultModalID] = useAtom(setSearchResultModalIDAtom);
  const [, setSetSearchResultModalKind] = useAtom(setSearchResultModalKindAtom);
  const [theme] = useAtom(themeAtom);
  const [lists] = useAtom(listsAtom);
  const h = jobData?.kind === "client" ? 65 : 85;
  const mainWidth = 640;
  const resultStyle = {
    width: mainWidth,
    height: h,
    border: `1px solid rgb(${
      theme === "dark" ? "50, 50, 50" : "200, 200, 200"
    })`,
    borderRadius: "clamp(0px, (100vw - 750px) * 9999, 12px)",
    backgroundColor:
      theme === "dark" ? "rgba(50, 50, 50, 0.5)" : "rgba(220, 220, 220, 0.5)",
    cursor: "pointer",
    marginBottom: 10,
    paddingLeft: 12,
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  };
  const resultLeftStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginRight: 20,
    width: mainWidth / 3,
  };
  const jobNameStyle = {
    fontWeight: "bold",
    marginBottom: jobData?.kind === "client" ? 0 : 10,
  };
  const jobListStyle = {
    fontSize: "0.7rem",
    backgroundColor: "rgba(30, 60, 80, 0.6)",
    borderRadius: 4,
    padding: 6,
    display: "block",
  };
  const resultRightStyle = {
    height: h,
    fontWeight: "bold",
    width: (mainWidth / 3) * 2 - 80,
    marginLeft: 20,
    display: "flex",
    flexDirection: "column",
  };
  const resultRightTopStyle = {
    height: (h / 3) * 2 - 10,
    width: (mainWidth / 3) * 2 - 80,
    paddingTop: 10,
    display: "flex",
    flexWrap: "wrap",
    overflow: "hidden",
  };
  const resultRightBottomStyle = {
    height: h / 3,
  };

  const findList = (jobData) => {
    let res;
    lists.forEach((list) => {
      list.items.forEach((item) => {
        if (item === jobData.id) {
          res = list.name;
        }
      });
    });

    return res;
  };
  const handleJobClick = () => {
    setSearchResultModalID(jobData.id);
    setSearchResultModalID(jobData.id);
    setSetSearchResultModalKind(jobData.kind);
    setSetSearchResultModalKind(jobData.kind);
    setTimeout(() => {
      resetSearchWindow();
      handleSearchResultModalOpen();
    }, 200);
  };
  return (
    <>
      {jobData?.kind === "job" ? (
        <div style={resultStyle} onClick={handleJobClick}>
          <div style={resultLeftStyle}>
            <ReactFitty maxSize={20} style={jobNameStyle}>
              {getJobName(jobData)}
            </ReactFitty>
            <span style={jobListStyle}>{findList(jobData)}</span>
          </div>
          <Divider orientation="vertical" flexItem sx={{ opacity: 0.4 }} />
          <div style={resultRightStyle}>
            <div style={resultRightTopStyle}>
              {jobData?.idLabels &&
                jobData?.idLabels?.map((label, index) => (
                  <LabelChipSmall
                    key={`${label}-${index}`}
                    label={label}
                    setLabelMenuOpen={() => {}}
                    kanbanBoard={false}
                  />
                ))}
            </div>
            <div style={resultRightBottomStyle}>
              <DueDateChip
                firebase={firebase}
                dueDate={jobData.dueDate}
                complete={jobData.complete}
                id={jobData.id}
                theme={theme}
                canEdit={false}
              />
            </div>
          </div>
        </div>
      ) : jobData?.kind === "client" ? (
        <div style={resultStyle} onClick={handleJobClick}>
          <div style={resultLeftStyle}>
            <ReactFitty maxSize={20} style={jobNameStyle}>
              {jobData.brand}
            </ReactFitty>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
};

function CreateListItem({ item, history, location, setOpen }) {
  return (
    <ListItem
      key={item.text}
      button
      onClick={() => {
        setOpen(false);
        setTimeout(() => {
          history.push(item.path);
        }, 200);
      }}
      className={parseIsActive(location, item)}
    >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.text}></ListItemText>
    </ListItem>
  );
}

const parseIsActive = (location, item) => {
  if (location.pathname === item.path)
    return `background: "rgba(0, 0, 0, 0.2)"`;
  if (location.pathname === "/" && item.path === "/calendar")
    return `background: "rgba(0, 0, 0, 0.2)"`;
  return null;
};

function UserMenu({
  user,
  firebase,
  so,
  a,
  click,
  close,
  notificationSettingsMenuAnchor,
  handleNotificationSettingsClose,
  handleNotificationSettingsClick,
  userTheme,
  handleThemeChange,
  theme,
  setQrDialogOpen,
}) {
  // const isIdle = true;
  const [isIdle] = useAtom(isIdleAtom);
  const [currentStaffData] = useAtom(currentStaffDataAtom);
  const [isMobileBrowser] = useAtom(isMobileBrowserAtom);
  const [isElectron] = useAtom(isElectronAtom);

  const history = useHistory();

  // console.log(currentStaffData);
  const [isDarkMode, setIsDarkMode] = useState(true);

  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) =>
        setIsDarkMode(e.matches ? true : false)
      );
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", () => {});
    };
  }, []);
  const [notificationTomorrowsJob, setNotificationTomorrowsJob] =
    useState(true);
  const [notificationNextDayChangesMe, setNotificationNextDayChangesMe] =
    useState(true);

  const savePushNotificationSubscriptionToUserData = firebase
    .functions()
    .httpsCallable("savePushNotificationSubscriptionToUserData");

  const [
    notificationsNotSupportedDialogOpen,
    setNotificationsNotSupportedDialogOpen,
  ] = useState(false);

  const setupNotifications = async () => {
    if (!isPushNotificationSupported()) {
      setNotificationsNotSupportedDialogOpen(true);
      return;
    }
    setIsRegisteringNotifications(true);
    const permission = await askUserPermission();
    if (permission !== "granted") return;
    registerServiceWorker();
    const serviceWorker = await navigator.serviceWorker.ready;
    const subscriptionRaw = await serviceWorker.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey:
        "BKluKzkYf9wTIVmdWJMy9szdvr-utCUC5XqlAZgLvcTrqDAuGYynQMFZaHYFLozzyxukkGZ6-FxjGYvUxPv2x7w",
    });
    const subscriptionString = JSON.stringify(subscriptionRaw);
    const subscription = JSON.parse(subscriptionString);
    console.log(subscription);
    const success = await savePushNotificationSubscriptionToUserData({
      subscription: subscription,
    });
    setIsRegisteringNotifications(false);
    console.log(success);
  };

  const [notificationRegistered, setNotificationRegistered] = useState(false);
  // console.log(notificationRegistered);

  useEffect(() => {
    // console.log(user?.staffData?.notificationSubscriptions)
    const checkRegistered = async () => {
      if (!currentStaffData?.notificationSubscriptions) return;

      if (!isPushNotificationSupported()) return;
      if (!("serviceWorker" in navigator)) return;

      const serviceWorkers = await navigator.serviceWorker.getRegistrations();

      if (serviceWorkers.length === 0) return;

      const subscription = await getUserSubscription();

      const subscriptionId = createHash(JSON.stringify(subscription));
      currentStaffData.notificationSubscriptions.forEach((sub) => {
        if (sub.id === subscriptionId) {
          setNotificationRegistered(true);
        }
      });
    };
    checkRegistered();
  }, [currentStaffData, setNotificationRegistered]);

  const [isRegisteringNotifications, setIsRegisteringNotifications] =
    useState(false);

  const [themeSettingsMenuAnchor, setThemeSettingsMenuAnchor] = useState(null);

  // const handleThemeSettingsClick = (event) => {
  //   setThemeSettingsMenuAnchor(event.currentTarget);
  // };

  const handleThemeSettingsClose = () => {
    setThemeSettingsMenuAnchor(null);
  };

  const forceReload = firebase.functions().httpsCallable("forceReload");
  const forceReloadNonAdmins = firebase
    .functions()
    .httpsCallable("forceReloadNonAdmins");
  const [forceReloadText, setForceReloadText] = useState("Force Reload");
  const [forceReloadNonAdminsText, setForceReloadNonAdminsText] = useState(
    "Force Reload (Non Admins)"
  );

  const { hideDock } = DockFunctions();
  const [isAlex, setIsAlex] = useState(false);
  useEffect(() => {
    if (currentStaffData.id === "8jXVYoCymxHLQngklbWE") {
      setIsAlex(true);
    } else {
      setIsAlex(false);
    }
  }, [currentStaffData, setIsAlex]);

  const sendLocalNotification = firebase
    .functions()
    .httpsCallable("sendLocalNotification");

  return (
    <div
      style={{ display: "flex", alignItems: "center", color: "red !important" }}
    >
      {/* {isIdle ? <WifiOffIcon sx={{ margin: 1 }} /> : null} */}
      {/* {true ? ( */}
      {isMobileBrowser ? (
        <div
          onClick={() => {
            setQrDialogOpen(true);
            hideDock();
          }}
          style={{
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {/* // <IconButton
        //   onClick={() => {
        //     setQrDialogOpen(true);
        //     hideDock();
        //   }}
        // > */}
          <ProgressCircle
            loginTokenCreated={currentStaffData.loginTokenCreated}
            loginToken={currentStaffData.loginToken}
            theme={theme}
          />

          {/* <QrCodeScannerIcon
            sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
          /> */}
          {/* </IconButton> */}
        </div>
      ) : null}

      {/* <IconButton onClick={handleThemeSettingsClick}>
        <ContrastIcon
          sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
        />
      </IconButton> */}
      {!isElectron ? (
        <IconButton
          onClick={
            notificationRegistered
              ? handleNotificationSettingsClick
              : setupNotifications
          }
          // disabled={notificationRegistered}
        >
          {notificationRegistered ? (
            <NotificationsActiveIcon
              sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
            />
          ) : isRegisteringNotifications ? (
            <CircularProgress size={32} />
          ) : (
            <NotificationsIcon
              sx={{ color: theme === "dark" ? "#ffffff" : "#4d4d4d" }}
            />
          )}
        </IconButton>
      ) : null}
      {isElectron ? (
        <Button variant="contained" size="small" onClick={so}>
          Logout
        </Button>
      ) : null}

      <IconButton onClick={click} style={{ marginRight: -16 }}>
        <Avatar alt={user.displayName} src={user.photoURL} />
      </IconButton>
      <Dialog
        onClose={() => {
          setNotificationsNotSupportedDialogOpen(false);
        }}
        open={notificationsNotSupportedDialogOpen}
      >
        <DialogTitle>Notifications Not Supported 🫠</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you are on an iPhone, ensure you are on iOS 16.4 or later & also
            have the app installed to your homescreen, it wont work in a
            browser.
            <br />
            <br />
            If you are on an Android phone, good luck you are on your own 🤷🏼‍♂️
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Menu
        id="theme-settings-menu"
        anchorEl={themeSettingsMenuAnchor}
        keepMounted
        open={Boolean(themeSettingsMenuAnchor)}
        onClose={handleThemeSettingsClose}
        disableScrollLock={true}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: isDarkMode ? "#f0f0f0" : "#f0f0f0",
            color: isDarkMode ? "#1f1f1f" : "#1f1f1f",
            border: "2px solid rgba(0, 0, 0, 0.1)",
          },
        }}
        PaperProps={{
          style: {
            width: 400,
            background: theme === "dark" ? "#1f1f1f" : "#ebebeb",
          },
        }}
      >
        <div>
          <ToggleButtonGroup
            color="primary"
            value={userTheme}
            exclusive
            onChange={handleThemeChange}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginRight: 2,
              marginLeft: 2,
            }}
          >
            <ToggleButton
              value="light"
              sx={{ width: 132, height: 40 }}
              onClick={handleThemeSettingsClose}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <LightModeIcon style={{ marginRight: 8 }} fontSize="small" />
                <span style={{ fontSize: 10 }}>Light</span>
              </div>
            </ToggleButton>
            <ToggleButton
              value="automatic"
              sx={{ width: 132, height: 40 }}
              onClick={handleThemeSettingsClose}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <SettingsBrightnessIcon
                  style={{ marginRight: 8 }}
                  fontSize="small"
                />
                <span style={{ fontSize: 10 }}>Auto</span>
              </div>
            </ToggleButton>
            <ToggleButton
              value="dark"
              sx={{ width: 132, height: 40 }}
              onClick={handleThemeSettingsClose}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <DarkModeIcon style={{ marginRight: 8 }} fontSize="small" />
                <span style={{ fontSize: 10 }}>Dark</span>
              </div>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Menu>

      <Menu
        id="notification-settings-menu"
        anchorEl={notificationSettingsMenuAnchor}
        keepMounted
        open={Boolean(notificationSettingsMenuAnchor)}
        onClose={handleNotificationSettingsClose}
        disableScrollLock={true}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: isDarkMode ? "#f0f0f0" : "#f0f0f0",
            color: isDarkMode ? "#1f1f1f" : "#1f1f1f",
            border: "2px solid rgba(0, 0, 0, 0.1)",
          },
        }}
        PaperProps={{
          style: {
            width: 280,
            background: theme === "dark" ? "#1f1f1f" : "#ebebeb",
          },
        }}
        // style={{ top: "40px", right: "300px", left: "-10px" }}
      >
        <p
          style={{
            fontWeight: 800,
            textAlign: "center",
            color: theme === "dark" ? "#ffffff" : "#000000",
          }}
        >
          Notification Settings
        </p>
        <MenuItem
          onClick={() => {
            const newVal = !notificationTomorrowsJob;
            setNotificationTomorrowsJob(newVal);
            try {
              firebase
                .firestore()
                .collection("StaffData")
                .doc(user?.staffData?.id)
                .update({ notificationTomorrowsJob: newVal });
            } catch (e) {
              console.log("weird call");
            }
          }}
        >
          <FormControlLabel
            onClick={(event) => {
              event.stopPropagation();
            }}
            control={
              <Switch
                checked={notificationTomorrowsJob}
                onChange={(e) => {
                  setNotificationTomorrowsJob(e.target.checked);
                  try {
                    firebase
                      .firestore()
                      .collection("StaffData")
                      .doc(user?.staffData?.id)
                      .update({ notificationTomorrowsJob: e.target.checked });
                  } catch (e) {
                    console.log("weird call v2");
                  }
                }}
              />
            }
            sx={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
            label="Tomorrow's Job"
          />
        </MenuItem>
        {/* </Tooltip> */}
        <Divider />
        <MenuItem
          onClick={() => {
            const newVal = !notificationNextDayChangesMe;
            setNotificationNextDayChangesMe(newVal);
            firebase
              .firestore()
              .collection("StaffData")
              .doc(user?.staffData?.id)
              .update({ notificationNextDayChangesMe: newVal });
          }}
        >
          <FormControlLabel
            onClick={(event) => {
              event.stopPropagation();
            }}
            control={
              <Switch
                checked={notificationNextDayChangesMe}
                onChange={(e) => {
                  setNotificationNextDayChangesMe(e.target.checked);
                  firebase
                    .firestore()
                    .collection("StaffData")
                    .doc(user?.staffData?.id)
                    .set(
                      { notificationNextDayChangesMe: e.target.checked },
                      { merge: true }
                    );
                }}
              />
            }
            sx={{ color: theme === "dark" ? "#ffffff" : "#000000" }}
            label="Next Day Changes (Me)"
          />
        </MenuItem>
      </Menu>

      <Menu
        id="user-menu"
        anchorEl={a}
        keepMounted
        open={Boolean(a)}
        onClose={close}
        disableScrollLock={true}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: isDarkMode ? "#f0f0f0" : "#f0f0f0",
            color: isDarkMode ? "#1f1f1f" : "#1f1f1f",
            border: "2px solid rgba(0, 0, 0, 0.1)",
          },
        }}
        PaperProps={{
          style: {
            background: theme === "dark" ? "#1f1f1f" : "#ebebeb",
          },
        }}
        // style={{ top: "40px", right: "300px", left: "-10px" }}
      >
        <MenuItem disabled>
          <p
            style={{
              paddingRight: "20px",
              color: theme === "dark" ? "#ffffff" : "#000000",
            }}
          >
            {user.displayName}
          </p>
        </MenuItem>
        <MenuItem disabled>
          <p
            style={{
              paddingRight: "20px",
              color: theme === "dark" ? "#ffffff" : "#000000",
            }}
          >
            {user.email}
          </p>
        </MenuItem>
        {!isElectron ? (
          <>
            <Divider />
            <MenuItem
              onClick={async () => {
                history.push("/staff");
                // window.location.href = "/staff";
              }}
            >
              <AccountCircleIcon
                sx={{
                  marginRight: "20px",
                  color: theme === "dark" ? "#ffffff" : "#000000",
                }}
              />
              <p
                style={{
                  paddingRight: "20px",
                  color: theme === "dark" ? "#ffffff" : "#000000",
                }}
              >
                My Info
              </p>
            </MenuItem>
          </>
        ) : null}
        <Divider />
        <ToggleButtonGroup
          color="primary"
          value={userTheme}
          exclusive
          onChange={handleThemeChange}
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: 1,
          }}
        >
          <ToggleButton
            value="light"
            sx={{ height: 40 }}
            onClick={handleThemeSettingsClose}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <LightModeIcon style={{ marginRight: 8 }} fontSize="small" />
              <span style={{ fontSize: 10 }}>Light</span>
            </div>
          </ToggleButton>
          <ToggleButton
            value="automatic"
            sx={{ height: 40 }}
            onClick={handleThemeSettingsClose}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <SettingsBrightnessIcon
                style={{ marginRight: 8 }}
                fontSize="small"
              />
              <span style={{ fontSize: 10 }}>Auto</span>
            </div>
          </ToggleButton>
          <ToggleButton
            value="dark"
            sx={{ height: 40 }}
            onClick={handleThemeSettingsClose}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <DarkModeIcon style={{ marginRight: 8 }} fontSize="small" />
              <span style={{ fontSize: 10 }}>Dark</span>
            </div>
          </ToggleButton>
        </ToggleButtonGroup>

        {isAlex ? <Divider /> : null}

        {isAlex ? (
          <MenuItem
            onClick={async () => {
              setForceReloadText("Reloading Instances...");
              await forceReload();
              setForceReloadText("Force Reload");
              close();
            }}
            sx={{ marginTop: 1 }}
          >
            <CachedIcon
              sx={{
                marginRight: "20px",
                color: theme === "dark" ? "#ffffff" : "#000000",
              }}
            />
            <p
              style={{
                paddingRight: "20px",
                color: theme === "dark" ? "#ffffff" : "#000000",
              }}
            >
              {forceReloadText}
            </p>
          </MenuItem>
        ) : null}
        {isAlex ? <Divider /> : null}
        {isAlex ? (
          <MenuItem
            onClick={async () => {
              setForceReloadNonAdminsText("Reloading Instances...");
              await forceReloadNonAdmins();
              setForceReloadNonAdminsText("Force Reload (Non Admins)");
              close();
            }}
          >
            <CachedIcon
              sx={{
                marginRight: "20px",
                color: theme === "dark" ? "#ffffff" : "#000000",
              }}
            />
            <p
              style={{
                paddingRight: "20px",
                color: theme === "dark" ? "#ffffff" : "#000000",
              }}
            >
              {forceReloadNonAdminsText}
            </p>
          </MenuItem>
        ) : null}
        {isAlex ? (
          <>
            <Divider />
            <MenuItem
              onClick={async () => {
                await firebase
                  .firestore()
                  .collection("-Force Reload")
                  .doc("mrGrinchPlay")
                  .update({ mrGrinchPlay: true });
                setTimeout(async () => {
                  await firebase
                    .firestore()
                    .collection("-Force Reload")
                    .doc("mrGrinchPlay")
                    .update({ mrGrinchPlay: false });
                  close();
                }, 200);
              }}
            >
              <ParkIcon
                sx={{
                  marginRight: "20px",
                  color: theme === "dark" ? "#ffffff" : "#000000",
                }}
              />
              <p
                style={{
                  paddingRight: "20px",
                  color: theme === "dark" ? "#ffffff" : "#000000",
                }}
              >
                You're A Mean One Mr Grinch
              </p>
            </MenuItem>
          </>
        ) : null}
        {isAlex ? (
          <>
            <Divider />
            <MenuItem
              onClick={async () => {
                sendLocalNotification();
              }}
            >
              <NotificationsActiveIcon
                sx={{
                  marginRight: "20px",
                  color: theme === "dark" ? "#ffffff" : "#000000",
                }}
              />
              <p
                style={{
                  paddingRight: "20px",
                  color: theme === "dark" ? "#ffffff" : "#000000",
                }}
              >
                Test Local Notification
              </p>
            </MenuItem>
          </>
        ) : null}
        <Divider />
        <MenuItem onClick={so}>
          <ExitToAppIcon
            style={{
              paddingRight: "10px",
              color: theme === "dark" ? "#ffffff" : "#000000",
            }}
          />
          <span
            style={{
              color: theme === "dark" ? "#ffffff" : "#000000",
            }}
          >
            Logout
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
}

const isAdmin = (user) => {
  if (user?.isSuperAdmin) return true;
  if (user?.isAdmin) return true;
  if (user?.staffData?.isSuperAdmin) return true;
  if (user?.staffData?.isAdmin) return true;
  return false;
};

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}

function registerServiceWorker() {
  return navigator.serviceWorker.register("/sw.js");
}

function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

function createHash(input) {
  const md5sum = crypto.createHash("md5");
  md5sum.update(Buffer.from(input));
  // console.log(md5sum.digest("hex"));
  return md5sum.digest("hex");
  // console.log(input)
  // console.log(md5Hash.default(input))
  // return md5Hash.default(Buffer.from(input))
}

const isEventTomorrow = (isoString) => {
  const jobTime = DateTime.fromISO(isoString).setZone("Australia/Sydney");
  const tomorrowStart = DateTime.now()
    .setZone("Australia/Sydney")
    .startOf("day")
    .plus({ days: 1 });

  const tomorrowEnd = DateTime.now()
    .setZone("Australia/Sydney")
    .endOf("day")
    .plus({ days: 1 });

  return (
    tomorrowStart.toSeconds() <= jobTime.toSeconds() &&
    jobTime.toSeconds() <= tomorrowEnd.toSeconds()
  );
};

const QRScannerDialog = ({
  firebase,
  open,
  handleClose,
  generateLoginToken,
}) => {
  const containerStyle = {
    width: "80vw",
    maxWidth: 600,
    height: "70vh",
    maxHeight: 600,
    padding: 20,
    gap: 20,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  function onScanSuccess(decodedText, decodedResult) {
    // return;
    // console.log(decodedText);
    // try {
    //   const res = JSON.parse(decodedText);
    //   console.log(res.id);
    //   firebase
    //     .firestore()
    //     .collection("RemoteLogin")
    //     .doc(res.id)
    //     .set({ scanned: true, userID: firebase.auth().currentUser.uid });
    // generateLoginToken({ id: res.id });
    // setTimeout(() => {
    //   handleClose();
    // }, 100);
    // alert(decodedResult);
    // console.log(`Code matched = ${decodedText}`, decodedResult);
    // } catch (e) {
    //   console.log("scan error");
    // }
  }

  function onScanFailure(error) {
    console.warn(`Code scan error = ${error}`);
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <div style={containerStyle}>
        <IconButton
          sx={{ position: "absolute", left: 10, top: 12 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <span style={{ fontWeight: "bold" }}>SCAN LOGIN QR</span>
        <QRScanner
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onScanSuccess}
          qrCodeErrorCallback={onScanFailure}
          firebase={firebase}
          handleClose={handleClose}
        />
      </div>
    </Dialog>
  );
};

import React from "react";

import StaffContainer from "./StaffContainer";

const AdditionalDatesStaff = ({
  ad,
  index,
  firebase,
  jobData,
  checkIfStaffAssigned,
  event,
  events,
  findStaffAvatar,
  addAdditionalStaff,
  canEdit,
  staffData,
  jobType,
  isEventTomorrow,
  client,
  sendAddedNotification,
  sendNotificationToUID,
  findStaff,
  sendRemovedNotification,
  additionalDates,
  theme,
}) => {
  //   console.log(ad);
  return (
    <StaffContainer
      firebase={firebase}
      jobData={jobData}
      checkIfStaffAssigned={checkIfStaffAssigned}
      event={event}
      events={events}
      findStaffAvatar={findStaffAvatar}
      photographer={ad.photographer}
      setPhotographer={() => {}}
      additionalPhotographers={ad.additionalPhotographers}
      setAdditionalPhotographers={() => {}}
      stylist={ad.stylist}
      setStylist={() => {}}
      additionalStylists={ad.additionalStylists}
      setAdditionalStylists={() => {}}
      operator={ad.operator}
      setOperator={() => {}}
      additionalOperators={ad.additionalOperators}
      setAdditionalOperators={() => {}}
      addAdditionalStaff={addAdditionalStaff}
      canEdit={canEdit}
      staffData={staffData}
      jobType={jobType}
      isEventTomorrow={isEventTomorrow}
      client={client}
      sendAddedNotification={sendAddedNotification}
      sendNotificationToUID={sendNotificationToUID}
      findStaff={findStaff}
      sendRemovedNotification={sendRemovedNotification}
      additionalDates={additionalDates}
      theme={theme}
      index={index}
    />
  );
};

export default AdditionalDatesStaff;

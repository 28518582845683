import React from "react";

const Logo = ({ isDarkMode, hasShadow }) => (
  <div style={{ margin: 40 }}>
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="120px"
      viewBox="0 0 600.000000 449.000000"
      preserveAspectRatio="xMidYMid meet"
      style={{
        filter: hasShadow ? "drop-shadow(0 0 10px rgb(0 0 0 / 0.2))" : "",
        // boxShadow: "0 0 60px rgba(0,0,0,1)",
      }}
    >
      <g
        transform="translate(0.000000,449.000000) scale(0.100000,-0.100000)"
        fill={isDarkMode ? "#dbdbdb" : "#242424"}
        stroke="none"
      >
        <path
          d="M3306 4473 c-2 -10 -14 -58 -25 -108 -12 -49 -32 -137 -45 -195 -14
  -58 -39 -166 -56 -240 -17 -74 -42 -182 -56 -240 -13 -58 -26 -115 -29 -127
  l-5 -23 159 0 160 0 11 43 c6 23 33 112 60 198 83 263 162 392 270 443 41 19
  65 21 303 24 143 2 257 -1 254 -5 -3 -5 -31 -116 -62 -247 -30 -132 -67 -285
  -80 -340 -13 -56 -116 -490 -229 -966 -217 -916 -221 -929 -255 -1001 -52
  -110 -102 -129 -337 -129 l-173 0 -10 -37 c-25 -95 -41 -161 -41 -167 0 -3
  335 -6 744 -6 l744 0 21 88 c12 48 24 95 27 104 4 15 -14 17 -203 20 -227 3
  -254 9 -290 65 -31 46 -16 204 33 361 3 10 15 5 42 -16 63 -49 170 -111 322
  -184 389 -187 464 -231 566 -332 124 -124 170 -290 128 -466 -51 -216 -220
  -377 -481 -457 -84 -26 -98 -27 -308 -27 -181 -1 -232 2 -291 17 -223 57 -293
  184 -281 506 3 80 7 152 8 159 3 10 -27 13 -136 13 l-140 0 -21 -103 c-79
  -384 -126 -648 -121 -670 8 -30 31 -41 147 -67 210 -48 551 -81 840 -81 264 1
  439 29 625 101 351 136 553 382 588 717 26 251 -44 472 -202 632 -112 114
  -240 188 -592 343 -222 98 -310 149 -390 227 -90 89 -128 169 -136 288 -22
  349 266 575 732 575 254 -1 387 -60 454 -202 25 -54 26 -63 25 -221 0 -91 -4
  -178 -8 -193 l-6 -27 143 0 143 0 72 318 c40 174 76 334 79 355 9 61 -16 78
  -182 126 -209 60 -434 84 -710 78 -234 -5 -338 -20 -502 -72 -87 -27 -103 -30
  -99 -16 5 15 141 595 187 796 12 50 24 101 28 115 l8 25 234 3 c264 3 295 -3
  348 -63 59 -67 64 -156 26 -395 -14 -85 -28 -176 -32 -203 l-6 -48 158 3 158
  3 52 210 c151 610 175 711 175 723 0 9 -260 12 -1264 12 -1196 0 -1265 -1
  -1270 -17z"
        />
        <path
          d="M2519 3375 c-5 -11 -16 -49 -24 -85 -9 -36 -18 -73 -21 -82 -4 -15
  13 -17 178 -20 147 -2 189 -6 213 -20 71 -38 75 -108 20 -338 -20 -85 -45
  -191 -55 -235 -10 -44 -28 -120 -40 -170 -27 -115 -55 -232 -81 -342 -11 -49
  -26 -115 -34 -148 -8 -33 -87 -368 -175 -745 -174 -741 -182 -770 -215 -845
  -47 -105 -107 -135 -284 -144 l-125 -6 -22 -90 c-13 -50 -23 -93 -23 -97 -1
  -5 291 -8 647 -8 l649 0 24 100 25 100 -106 0 c-123 0 -230 14 -269 35 -31 16
  -61 77 -61 125 0 34 13 96 80 380 28 118 59 253 70 300 11 47 42 182 70 300
  28 118 59 253 70 300 11 47 42 182 70 300 28 118 73 314 101 435 28 121 60
  256 70 300 11 44 28 118 39 165 50 216 81 284 150 325 31 17 55 20 197 23
  l162 4 21 91 c12 51 20 95 18 100 -2 4 -302 8 -667 10 -655 2 -664 2 -672 -18z"
        />
        <path
          d="M1395 2732 c-86 -40 -146 -151 -148 -272 -2 -113 41 -151 170 -149
  120 2 125 4 145 79 20 71 23 77 57 82 14 1 26 9 29 16 6 19 -184 17 -191 -2
  -4 -11 3 -16 26 -18 31 -3 32 -5 29 -42 -5 -56 -17 -74 -57 -82 -53 -10 -101
  3 -126 34 -18 24 -21 36 -17 104 6 107 45 184 108 216 48 24 84 28 126 12 24
  -10 26 -14 21 -50 -5 -36 -3 -40 17 -40 18 0 25 9 35 51 9 35 10 54 3 61 -6 6
  -50 12 -99 15 -72 3 -95 0 -128 -15z"
        />
        <path
          d="M2203 2731 c-25 -12 -48 -32 -57 -50 -36 -68 -12 -115 83 -165 99
  -52 116 -93 62 -147 -25 -25 -37 -29 -84 -29 -63 0 -87 19 -87 69 0 25 -4 32
  -17 29 -19 -3 -30 -35 -32 -84 -1 -39 13 -44 122 -44 97 0 151 24 173 75 31
  75 8 125 -77 165 -77 36 -99 56 -99 91 0 53 74 92 137 72 23 -8 29 -16 31 -46
  2 -29 8 -37 22 -37 13 0 22 11 29 38 6 20 11 43 11 50 0 35 -148 44 -217 13z"
        />
        <path
          d="M80 2725 c0 -10 10 -15 30 -15 26 0 30 -3 30 -29 0 -15 -4 -41 -9
  -57 -5 -16 -22 -82 -37 -147 -26 -114 -40 -141 -70 -130 -6 2 -14 -3 -17 -12
  -6 -13 7 -15 88 -15 71 0 95 3 95 13 0 7 -10 13 -21 14 -39 3 -41 17 -16 124
  14 57 30 129 37 159 15 66 25 80 55 80 13 0 25 7 29 15 4 13 -10 15 -94 15
  -82 0 -100 -3 -100 -15z"
        />
        <path
          d="M317 2733 c-15 -14 -6 -23 22 -23 17 0 32 -3 35 -8 2 -4 -13 -82 -35
  -172 -38 -160 -54 -195 -84 -184 -7 3 -15 -2 -18 -10 -6 -14 6 -16 78 -16 63
  0 85 3 85 13 0 7 -10 13 -21 14 -12 1 -25 6 -30 10 -4 4 12 85 35 178 49 193
  47 193 66 -35 15 -182 15 -180 48 -180 23 0 32 10 66 73 109 202 168 308 171
  305 5 -4 -71 -337 -78 -344 -3 -4 -18 -6 -32 -6 -16 0 -25 -6 -25 -17 0 -14
  17 -15 162 -11 90 3 163 9 163 14 0 5 -8 10 -18 12 -31 4 -32 13 -8 60 l24 44
  82 0 c91 0 95 -3 95 -66 0 -33 -8 -40 -46 -35 -7 1 -14 -6 -18 -14 -5 -14 6
  -15 84 -13 50 2 91 7 93 12 1 5 -6 10 -17 12 -10 1 -23 7 -28 12 -5 5 -18 79
  -28 163 -29 233 -26 221 -63 217 -29 -3 -39 -16 -142 -185 -61 -101 -116 -188
  -121 -193 -15 -15 -74 -16 -83 -1 -6 10 44 267 65 333 3 12 15 18 34 18 15 0
  30 7 34 15 4 13 -8 15 -77 15 l-82 0 -85 -157 c-47 -86 -90 -163 -96 -171 -8
  -11 -14 20 -22 125 -7 76 -12 153 -12 171 l0 32 -83 0 c-46 0 -87 -3 -90 -7z
  m767 -190 l7 -63 -72 0 c-83 0 -83 -8 -6 119 l51 86 7 -40 c4 -22 10 -68 13
  -102z"
        />
        <path
          d="M1720 2725 c0 -10 10 -15 30 -15 23 0 30 -4 30 -20 0 -29 -71 -324
  -81 -334 -4 -5 -19 -10 -31 -11 -13 -1 -24 -8 -26 -16 -2 -12 26 -14 172 -11
  l174 3 11 44 c15 61 14 67 -11 63 -14 -2 -25 -14 -31 -33 -6 -16 -15 -33 -21
  -37 -6 -4 -46 -8 -88 -8 -90 0 -89 -1 -60 105 16 57 19 60 50 63 40 4 65 -14
  57 -39 -5 -15 -1 -19 18 -19 22 0 26 6 36 61 7 34 15 67 18 75 10 28 -35 15
  -49 -13 -11 -24 -20 -28 -61 -31 -47 -3 -48 -3 -41 22 3 14 11 49 18 78 l11
  53 80 0 80 0 3 -37 c3 -30 7 -38 22 -38 14 0 22 11 30 43 6 23 13 48 15 55 3
  9 -36 12 -175 12 -153 0 -180 -2 -180 -15z"
        />
        <path
          d="M715 2248 c-6 -18 -24 -101 -25 -113 0 -6 9 -11 20 -11 15 0 24 11
  32 39 6 21 21 45 32 53 20 14 67 19 78 9 7 -7 -71 -337 -84 -352 -6 -7 -23
  -13 -39 -13 -17 0 -29 -5 -29 -12 0 -17 203 -15 208 1 2 6 -7 11 -20 11 -50 0
  -52 10 -28 116 63 278 52 249 89 252 46 5 63 -13 59 -62 -3 -35 0 -41 16 -41
  16 0 23 13 37 68 l17 67 -179 0 c-135 0 -181 -3 -184 -12z"
        />
        <path
          d="M1097 2253 c-15 -14 -6 -23 22 -23 17 0 32 -3 34 -7 8 -12 -69 -331
  -83 -348 -7 -8 -21 -15 -31 -15 -11 0 -19 -6 -19 -12 0 -17 173 -15 178 1 2 6
  -5 11 -16 11 -37 0 -42 21 -24 101 l17 74 98 3 c53 1 97 -1 97 -5 0 -4 -9 -41
  -20 -82 -19 -74 -30 -91 -62 -91 -10 0 -18 -4 -18 -10 0 -13 177 -13 185 -1 3
  5 -7 11 -22 13 -40 4 -39 27 5 208 l37 155 33 3 c20 2 32 9 32 18 0 11 -19 14
  -95 14 -78 0 -95 -3 -95 -15 0 -9 9 -15 24 -15 14 0 28 -5 31 -10 6 -9 -4 -68
  -22 -132 -4 -16 -17 -18 -100 -18 l-96 0 7 39 c13 83 29 115 60 121 16 3 31
  11 34 18 3 9 -20 12 -90 12 -52 0 -98 -3 -101 -7z"
        />
        <path
          d="M1717 2208 c-18 -28 -70 -115 -117 -192 -49 -80 -95 -145 -108 -151
  -44 -19 -23 -30 53 -27 41 2 75 8 75 13 0 5 -6 9 -14 9 -25 0 -29 20 -11 61
  l18 39 88 0 87 0 7 -41 c8 -45 -2 -59 -42 -59 -13 0 -23 -4 -23 -10 0 -6 37
  -10 90 -10 53 0 90 4 90 10 0 6 -7 10 -15 10 -26 0 -42 43 -54 145 -7 55 -17
  135 -23 178 -10 76 -11 77 -40 77 -24 0 -34 -9 -61 -52z m57 -85 c4 -43 10
  -88 13 -100 5 -22 3 -23 -70 -23 l-76 0 46 78 c26 42 51 87 57 99 6 13 14 23
  17 23 3 0 9 -35 13 -77z"
        />
        <path
          d="M1956 2248 c-8 -24 -26 -107 -26 -115 0 -5 9 -9 20 -9 15 0 24 11 32
  39 14 46 41 67 86 67 30 0 34 -2 29 -22 -3 -13 -20 -84 -37 -158 -38 -168 -46
  -184 -88 -188 -18 -2 -32 -9 -32 -15 0 -16 203 -13 208 2 2 6 -7 11 -20 11
  -53 1 -53 6 -13 176 20 88 39 167 41 177 7 21 66 24 84 3 6 -8 10 -32 8 -53
  -2 -32 0 -38 17 -38 16 0 23 13 37 65 9 36 17 66 18 68 0 1 -81 2 -180 2 -136
  0 -182 -3 -184 -12z"
        />
        <path
          d="M885 1781 c-72 -18 -105 -54 -105 -116 0 -49 27 -79 107 -119 65 -32
  89 -64 76 -103 -14 -42 -57 -68 -112 -68 -59 0 -81 17 -81 61 0 27 -4 34 -20
  34 -14 0 -21 -9 -25 -37 -4 -21 -9 -46 -11 -55 -4 -12 2 -19 18 -22 158 -32
  259 -4 286 82 12 37 12 46 -5 79 -14 30 -33 46 -88 74 -38 20 -75 46 -82 59
  -24 48 27 103 96 103 51 0 71 -17 71 -59 0 -26 4 -34 18 -34 14 0 21 13 30 51
  7 29 10 54 8 57 -18 17 -130 25 -181 13z"
        />
        <path
          d="M1127 1773 c-14 -13 -6 -23 18 -23 36 0 40 -20 21 -98 -8 -37 -25
  -107 -37 -157 -23 -101 -31 -115 -65 -115 -15 0 -24 -6 -24 -15 0 -13 26 -15
  174 -15 l174 0 5 29 c3 16 9 40 13 55 5 22 3 26 -15 26 -16 0 -25 -9 -33 -31
  -13 -38 -41 -49 -124 -49 -75 0 -74 0 -48 106 l17 65 46 -3 c44 -3 46 -4 43
  -31 -3 -25 0 -28 20 -25 18 2 25 13 34 53 6 28 14 60 18 73 6 19 4 22 -18 22
  -19 0 -26 -5 -26 -20 0 -10 -9 -24 -19 -30 -23 -12 -78 -14 -84 -2 -3 4 2 40
  11 80 l15 72 84 0 83 0 0 -35 c0 -28 4 -35 19 -35 19 0 29 21 44 88 l5 22
  -172 0 c-95 0 -176 -3 -179 -7z"
        />
        <path
          d="M1516 1765 c-4 -11 2 -15 24 -15 16 0 31 -4 35 -10 3 -5 -4 -49 -15
  -97 -12 -48 -28 -117 -36 -153 -22 -96 -30 -110 -59 -110 -16 0 -25 -6 -25
  -15 0 -13 24 -15 159 -15 l159 0 11 48 c6 26 13 57 17 70 5 19 3 22 -20 22
  -22 0 -27 -6 -37 -46 -9 -33 -18 -48 -36 -55 -26 -10 -133 -13 -133 -3 0 8 48
  213 68 294 16 62 19 65 50 68 20 2 32 9 32 18 0 21 -185 21 -194 -1z"
        />
        <path
          d="M1870 1765 c0 -9 9 -15 25 -15 14 0 28 -6 31 -13 5 -13 -59 -293 -76
  -334 -6 -15 -18 -23 -35 -23 -16 0 -25 -6 -25 -15 0 -13 24 -15 159 -15 l159
  0 16 65 c20 80 20 75 -8 75 -19 0 -26 -8 -36 -46 -9 -32 -20 -48 -37 -55 -23
  -8 -133 -13 -133 -5 0 28 84 352 93 358 7 4 22 8 35 8 13 0 22 6 22 15 0 12
  -17 15 -95 15 -78 0 -95 -3 -95 -15z"
        />
      </g>
    </svg>
  </div>
);

export default Logo;

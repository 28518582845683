import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

const KanbanJobNumber = ({ newPostJobJobNumber, setNewPostJobJobNumber }) => {
  const [assignJobNumberText, setAssignJobNumberText] =
    useState("Assign Job Number");

  const [assignJobNumberDisabled, setAssignJobNumberDisabled] = useState(false);

  useEffect(() => {
    if (newPostJobJobNumber) {
      setAssignJobNumberDisabled(true);
    } else {
      setAssignJobNumberDisabled(false);
    }
  }, [newPostJobJobNumber, setAssignJobNumberDisabled]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        marginTop: 15,
      }}
    >
      <TextField
        fullWidth
        label="Job Number"
        value={newPostJobJobNumber || ""}
        error={newPostJobJobNumber ? false : true}
        onChange={(e) => {
          setNewPostJobJobNumber(parseInt(e.target.value));
        }}
        variant="outlined"
        sx={{ marginRight: 2, width: 268 }}
      />
      <Button
        variant="outlined"
        style={{ height: 50, width: 268 }}
        sx={{
          marginLeft: 2,
          padding: 0,
          fontSize: "0.9em",
        }}
        disabled={assignJobNumberDisabled}
        onClick={async () => {
          const jobYear = new Date().getFullYear();
          setAssignJobNumberText(<CircularProgress size={35} />);
          const res = await fetch(
            "https://us-central1-its-connect-main.cloudfunctions.net/getLatestJobNumber",
            {
              method: "POST",
              mode: "cors",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ year: jobYear }),
            }
          ).then(function (res) {
            return res.json();
          });
          setNewPostJobJobNumber(parseInt(res.jobNumber));
          setAssignJobNumberText("Assign Job Number");
        }}
      >
        {assignJobNumberText}
      </Button>
    </div>
  );
};

export default KanbanJobNumber;

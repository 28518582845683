import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import CircleIcon from "@mui/icons-material/Circle";

// import { useAtom } from "jotai";
// import { themeAtom } from "../-Atoms";

const MobileMenuContainer = ({
  mobileMenuOpen,
  setMobileMenuOpen,
  isMobile,
  queryFilter,
  setQueryFilter,
  clientData,
  staffData,
  studios,
  calendarColours,
  currentUser,
  calendarRef,
  setShowWeekends,
  showWeekends,
  postProductionButtonClick,
  setExportDatesModalOpen,
}) => {
  const buttonStyleBigger = {
    width: 170,
    fontSize: 13,
    margin: 4,
  };
  const buttonStyleBig = {
    width: 120,
    fontSize: 14,
    margin: 4,
  };
  const buttonStyleSmall = {
    width: 100,
    fontSize: 12,
    margin: 4,
  };
  const resetFilters = () => {
    setMobileMenuOpen(false);
    if (currentUser?.id) {
      if (currentUser.isUtility) {
        setQueryFilter(null);
        return;
      }
      if (currentUser.isAdmin) {
        setQueryFilter(null);
        return;
      }
      if (currentUser.isSuperAdmin) {
        setQueryFilter(null);
        return;
      }
      setQueryFilter({ staff: [currentUser] });
      return;
    }
    setQueryFilter(null);
  };

  const clearFilters = () => {
    setQueryFilter(null);
    setMobileMenuOpen(false);
  };
  return (
    <div
      style={{
        margin: isMobile ? 20 : 40,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div style={{ position: "absolute", top: 20, left: 20 }}>
        <IconButton
          onClick={() => {
            setMobileMenuOpen(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <div style={{ marginTop: 70 }}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            style={buttonStyleBig}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().today();
              setMobileMenuOpen(false);
            }}
          >
            Today
          </Button>
          <Button
            style={buttonStyleBig}
            variant="contained"
            onClick={() => {
              setShowWeekends(!showWeekends);
              setMobileMenuOpen(false);
            }}
          >
            Weekends
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: 10,
          }}
        >
          <Button
            style={buttonStyleSmall}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().changeView("dayGridMonth");
              setMobileMenuOpen(false);
            }}
          >
            Month
          </Button>
          <Button
            style={buttonStyleSmall}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().changeView("timeGridWeek");
              setMobileMenuOpen(false);
            }}
          >
            Week
          </Button>
          <Button
            style={buttonStyleSmall}
            variant="contained"
            onClick={() => {
              calendarRef.current.getApi().changeView("timeGridDay");
              setMobileMenuOpen(false);
            }}
          >
            Day
          </Button>
        </div>
        {currentUser.isAdmin ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: 20,
            }}
          >
            <Button
              style={buttonStyleBigger}
              variant="contained"
              onClick={() => {
                setMobileMenuOpen(false);
                setExportDatesModalOpen(true);
              }}
            >
              Export Dates
            </Button>
            <Button
              style={buttonStyleBigger}
              variant="contained"
              onClick={() => {
                setMobileMenuOpen(false);
                postProductionButtonClick();
              }}
            >
              Post Production
            </Button>
          </div>
        ) : null}
        <div
          style={{
            marginTop: 30,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <StaffSelector
            staffData={staffData}
            staff={queryFilter?.staff || ""}
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
          />
          {/* <CalendarSelector
            studios={studios}
            studio={queryFilter?.studio || ""}
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
            calendarColours={calendarColours}
          />
          <ClientSelector
            clientData={clientData}
            client={queryFilter?.client || ""}
            queryFilter={queryFilter}
            setQueryFilter={setQueryFilter}
          /> */}
          <Button
            style={{ margin: 15 }}
            variant="contained"
            onClick={resetFilters}
          >
            Filter My Jobs
          </Button>
          <Button
            style={{ margin: 10, marginTop: 10 }}
            variant="contained"
            onClick={clearFilters}
          >
            Remove All Filters
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileMenuContainer;

const StaffSelector = ({ staffData, staff, queryFilter, setQueryFilter }) => {
  const employedStaff = staffData.filter((staff) => staff.currentlyEmployed);
  return (
    <Autocomplete
      disablePortal
      sx={{ width: "100%", margin: 1 }}
      size="small"
      options={employedStaff}
      getOptionLabel={(option) => findStaff(option, employedStaff)}
      renderInput={(params) => <TextField {...params} label="Staff Filter" />}
      value={findStaff(staff[0], staffData) || ""}
      onChange={(e, value) => {
        setQueryFilter({ ...queryFilter, staff: [value] });
      }}
    />
  );
};

const findStaff = (option, staffData) => {
  if (!option) return "";
  if (option.firstName) return `${option.firstName} ${option.lastName}`;
  for (let i = 0; i < staffData.length; i++) {
    if (staffData[i].id === option) {
      return `${staffData[i].firstName} ${staffData[i].lastName}`;
    }
  }
  return option;
};

// const CalendarSelector = ({
//   studio,
//   queryFilter,
//   setQueryFilter,
//   firebase,
//   jobData,
//   studios,
//   calendarColours,
// }) => {
//   const [theme] = useAtom(themeAtom);

//   return (
//     <FormControl size="small" sx={{ width: "100%", margin: 1 }}>
//       <InputLabel id="studio-calendar">Studio Filter</InputLabel>
//       <Select
//         labelId="studio-calendar-select"
//         value={studio || ""}
//         label="Studio Filter"
//         onChange={(e) => {
//           setQueryFilter({ ...queryFilter, studio: e.target.value });
//         }}
//       >
//         <MenuItem key={"All Studios"} value={"All Studios"}>
//           All Studios
//         </MenuItem>
//         {studios.map((s) => (
//           <MenuItem key={s} value={s}>
//             <span style={{ display: "flex", aliognItems: "center" }}>
//               <CircleIcon
//                 style={{
//                   fill:
//                     theme === "dark"
//                       ? calendarColours[s].darkBackground.replace(
//                           /(#(?:[\da-fA-F]{6}|[\da-fA-F]{3}))[\da-fA-F]{2}/,
//                           "$1"
//                         ) || "#000000"
//                       : calendarColours[s].background || "#000000",
//                   marginRight: 10,
//                 }}
//               />
//               {s}
//             </span>
//           </MenuItem>
//         ))}
//       </Select>
//     </FormControl>
//   );
// };

// const ClientSelector = ({
//   clientData,
//   client,
//   queryFilter,
//   setQueryFilter,
// }) => {
//   return (
//     <Autocomplete
//       disablePortal
//       sx={{ width: "100%", margin: 1 }}
//       size="small"
//       options={clientData.map((c) => c.brand)}
//       renderInput={(params) => <TextField {...params} label="Client Filter" />}
//       value={client || ""}
//       onChange={(e) => {
//         let value = "";
//         if (e.target.innerText) value = e.target.innerText;
//         setQueryFilter({ ...queryFilter, client: value });
//       }}
//     />
//   );
// };

import React, { useState, useEffect } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Chip from "@mui/material/Chip";

import { useAtom } from "jotai";
import { labelColoursAtom } from "../-Atoms";

const { DateTime } = require("luxon");

const DueDateChip = ({
  firebase,
  dueDate,
  complete,
  expressDueDate,
  expressComplete,
  id,
  theme,
  canEdit,
  flexAlign,
  cardBackgroundColour,
}) => {
  const [labelColours] = useAtom(labelColoursAtom);
  const [shouldShowNormal, setShouldShowNormal] = useState(true);

  const same = DateTime.fromISO(expressDueDate)
    .setZone("Australia/Sydney")
    .startOf("day")
    .equals(
      DateTime.fromISO(dueDate).setZone("Australia/Sydney").startOf("day")
    );

  useEffect(() => {
    if (expressDueDate) {
      setShouldShowNormal(!same);
    }
  }, [expressDueDate, dueDate, same]);

  return (
    <div
      style={{ display: "flex", alignItems: flexAlign ? flexAlign : "center" }}
    >
      {expressDueDate ? (
        <span style={{ marginRight: same ? 0 : 8 }}>
          <TheChip
            dueDate={expressDueDate}
            complete={expressComplete}
            labelColours={labelColours}
            firebase={firebase}
            theme={theme}
            id={id}
            canEdit={canEdit}
            express={true}
            expressDueDate={expressDueDate}
            dueDateActual={dueDate}
            cardBackgroundColour={cardBackgroundColour}
          />
        </span>
      ) : null}
      {shouldShowNormal ? (
        <TheChip
          dueDate={dueDate}
          complete={complete}
          labelColours={labelColours}
          firebase={firebase}
          theme={theme}
          id={id}
          canEdit={canEdit}
          express={false}
          expressDueDate={expressDueDate}
          dueDateActual={dueDate}
          cardBackgroundColour={cardBackgroundColour}
        />
      ) : null}
    </div>
  );
};

export default DueDateChip;

const TheChip = ({
  dueDate,
  complete,
  labelColours,
  firebase,
  theme,
  id,
  canEdit,
  express,
  expressDueDate,
  dueDateActual,
  cardBackgroundColour,
}) => {
  const [DueDateIcon, setDueDateIcon] = useState(
    complete ? <CheckBoxIcon /> : <AccessTimeIcon />
  );

  const changeIcon = (complete) => {
    if (complete) {
      setDueDateIcon(<CheckBoxIcon />);
    } else {
      setDueDateIcon(<CheckBoxOutlineBlankIcon />);
    }
  };

  const same = DateTime.fromISO(expressDueDate)
    .setZone("Australia/Sydney")
    .startOf("day")
    .equals(
      DateTime.fromISO(dueDateActual).setZone("Australia/Sydney").startOf("day")
    );

  return (
    <Chip
      icon={DueDateIcon}
      label={
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 11,
          }}
        >
          {DateTime.fromISO(dueDate)
            .setZone("Australia/Sydney")
            .toFormat("dd LLL")}
        </span>
      }
      sx={{
        marginTop: 0.6,
        cursor: canEdit ? "pointer" : "default",
        backgroundColor: getDueDateColour(
          dueDate,
          complete,
          theme,
          labelColours,
          express,
          cardBackgroundColour
        ),
        padding: 0,
        borderRadius: 0.8,
        // color: express && complete ? "#f5f5f5" : "",
        border: express ? `2px solid ${labelColours["pink_dark"][theme]}` : "",
        "&:hover": {
          backgroundColor: canEdit
            ? newShade(
                getDueDateColour(
                  dueDate,
                  complete,
                  theme,
                  labelColours,
                  express,
                  cardBackgroundColour
                ),
                theme === "dark" ? -30 : 30
              )
            : getDueDateColour(
                dueDate,
                complete,
                theme,
                labelColours,
                express,
                cardBackgroundColour
              ),
        },
      }}
      onClick={() => {
        if (canEdit) {
          complete = !complete;
          changeIcon(complete);

          if (express) {
            firebase
              .firestore()
              .collection("JobData")
              .doc(id)
              .update({ expressComplete: complete });

            if (same) {
              firebase
                .firestore()
                .collection("JobData")
                .doc(id)
                .update({ complete: complete });
            }
          } else {
            firebase
              .firestore()
              .collection("JobData")
              .doc(id)
              .update({ complete: complete });
            if (expressDueDate) {
              if (same) {
                firebase
                  .firestore()
                  .collection("JobData")
                  .doc(id)
                  .update({ expressComplete: complete });
              }
            }
          }
        }
      }}
      onMouseEnter={() => {
        changeIcon(complete);
      }}
      onMouseLeave={() => {
        setDueDateIcon(complete ? <CheckBoxIcon /> : <AccessTimeIcon />);
      }}
      size="small"
    />
  );
};

const getDueDateColour = (
  dueDate,
  complete,
  theme,
  labelColours,
  express,
  cardBackgroundColour
) => {
  if (complete)
    return express
      ? labelColours["pink_dark"][theme]
      : labelColours["lime"][theme];

  if (
    DateTime.fromISO(dueDate)
      .setZone("Australia/Sydney")
      .startOf("day")
      .equals(DateTime.now().setZone("Australia/Sydney").startOf("day"))
  )
    return labelColours["yellow"][theme];
  if (
    DateTime.fromISO(dueDate).setZone("Australia/Sydney").startOf("day") <=
    DateTime.now().setZone("Australia/Sydney").startOf("day")
  )
    return labelColours["red"][theme];
  // return theme === "dark" ? "#EA3323" : "#f24130";

  return cardBackgroundColour
    ? cardBackgroundColour
    : theme === "dark"
    ? "#272727"
    : "#d6d6d6";
};

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    let toReturn = (g | (b << 8) | (r << 16)).toString(16);
    if (toReturn.length === 5) {
      toReturn = `0${toReturn}`;
    }
    return `#${toReturn}`;
  } else {
    return hexColor;
  }
};

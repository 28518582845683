import React, { useState } from "react";

import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import Slide from "@mui/material/Slide";

import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";

const HMUContainer = ({ firebase, jobData, hmus, setHmus, canEdit, theme }) => {
  const [newHmuModalOpen, setNewHmuModalOpen] = useState(false);

  const [editExistingHmuData, setEditExistingHmuData] = useState({});
  const [editExistingHmuIndex, setEditExistingHmuIndex] = useState(0);

  const parseCallFinishTime = (time) => {
    let date;
    try {
      date = new Date(time);
    } catch (e) {
      return "";
    }
    return date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const handleRowClick = (e, hmu, index) => {
    if (!canEdit) return;
    if (!Array.from(e.target.classList).includes("MuiTableCell-root")) return;
    toggleNewHmuModalOpen(hmu, index);
  };
  const toggleNewHmuModalOpen = (hmu = {}, index = 0) => {
    if (!canEdit) return;
    if (!newHmuModalOpen) {
      setEditExistingHmuData(hmu);
      setEditExistingHmuIndex(index);
      setNewHmuModalOpen(true);
    } else {
      setEditExistingHmuData({});
      setEditExistingHmuIndex(0);
      setNewHmuModalOpen(false);
    }
  };

  const addNewHmu = async (newHmuObject) => {
    if (!canEdit) return;
    hmus.push(newHmuObject);
    setHmus([...hmus]);
    await firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ hmu: hmus }, { merge: true });
    setNewHmuModalOpen(false);
  };
  const removeHmu = (index) => {
    if (!canEdit) return;
    hmus.splice(index, 1);
    setHmus([...hmus]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ hmu: hmus }, { merge: true });
  };
  const editHmu = (hmu, index) => {
    if (!canEdit) return;
    hmus[index] = hmu;
    setHmus([...hmus]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ hmu: hmus }, { merge: true });
    toggleNewHmuModalOpen();
  };
  const tableCellStyle = {
    paddingTop: 2,
    paddingBottom: 2,
    height: 20,
  };
  const titleContainerStyle = {
    marginLeft: 5,
    marginBottom: 5,
    color: theme === "dark" ? "rgba(255,255,255,0.6)" : "rgba(0,0,0,0.6)",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    letterSpacing: "0.00938em",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const tableContainerStyle = {
    marginLeft: 2,
    width: "99.5%",
    background: "rgba(0,0,0,0.01)",
    border:
      theme === "dark"
        ? "1px solid rgba(230,230,230,0.3)"
        : "1px solid rgba(25,25,25,0.3)",
    borderRadius: 5,
  };
  return (
    <div style={{ marginTop: 40 }}>
      <Slide direction="right" in={newHmuModalOpen} mountOnEnter unmountOnExit>
        <div style={{ background: theme === "dark" ? "#1f1f1f" : "#ffffff" }}>
          <NewHmuModal
            addNewHmu={addNewHmu}
            hmuData={editExistingHmuData}
            index={editExistingHmuIndex}
            editHmu={editHmu}
          />
        </div>
      </Slide>
      <div style={titleContainerStyle}>
        <span>Hair &amp; Makeup Artists</span>
        <IconButton onClick={toggleNewHmuModalOpen} style={{ marginRight: -8 }}>
          <AddIcon />
        </IconButton>
      </div>
      <div style={tableContainerStyle}>
        <TableContainer style={{ height: 220 }}>
          <Table sx={{ minWidth: 600 }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                    borderRadius: "5px 0px 0px 0px",
                  }}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                  }}
                  align="right"
                >
                  Agency
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                  }}
                  align="right"
                >
                  Call Time
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",
                  }}
                  align="right"
                >
                  Finish Time
                </TableCell>
                <TableCell
                  style={{
                    background:
                      theme === "dark"
                        ? "rgba(26,26,26,1)"
                        : "rgba(252,252,252,1)",

                    borderRadius: "0px 5px 0px 0px",
                  }}
                  align="right"
                >
                  Remove
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                background:
                  theme === "dark"
                    ? "rgba(255, 255, 255, 0.02)"
                    : "rgba(0, 0, 0, 0.02)",
              }}
            >
              {hmus &&
                hmus?.map((hmu, index) => (
                  <TableRow
                    key={`${hmu.name}-${index}`}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:hover": {
                        background:
                          theme === "dark"
                            ? "rgba(255, 255, 255, 0.02)"
                            : "rgba(0, 0, 0, 0.02)",
                      },
                      height: 45,
                    }}
                    onClick={(e) => {
                      handleRowClick(e, hmu, index);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell
                      style={tableCellStyle}
                      component="th"
                      scope="row"
                    >
                      {hmu.name}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      {hmu.agency}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      {parseCallFinishTime(hmu.callTime)}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      {parseCallFinishTime(hmu.finishTime)}
                    </TableCell>
                    <TableCell style={tableCellStyle} align="right">
                      <IconButton
                        onClick={() => {
                          removeHmu(index);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default HMUContainer;

const NewHmuModal = ({ addNewHmu, hmuData, index, editHmu }) => {
  const [newHmuName, setNewHmuName] = useState(hmuData?.name || "");
  const [newHmuAgency, setNewHmuAgency] = useState(hmuData?.agency || "");
  const [newHmuCallTime, setNewHmuCallTime] = useState(
    hmuData?.callTime || null
  );
  const [newHmuFinishTime, setNewHmuFinishTime] = useState(
    hmuData?.finishTime || null
  );
  const [newHmuBookedBy, setNewHmuBookedBy] = useState(hmuData?.bookedBy || "");
  const [newHmuPaidBy, setNewHmuPaidBy] = useState(hmuData?.paidBy || "");

  const [newHmuNameError, setNewHmuNameError] = useState(false);
  const [newHmuAgencyError, setNewHmuAgencyError] = useState(false);
  const [newHmuCallTimeError, setNewHmuCallTimeError] = useState(false);
  const [newHmuFinishTimeError, setNewHmuFinishTimeError] = useState(false);
  const [newHmuBookedByError, setNewHmuBookedByError] = useState(false);
  const [newHmuPaidByError, setNewHmuPaidByError] = useState(false);

  const MUITheme = useMUITheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down("md"));

  const containerStyle = {
    display: "flex",
    justifyContent: isMobile ? "center" : "left",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 20,
  };
  const textFieldStyle = {
    width: isMobile ? "45%" : 145,
    margin: 5,
  };
  const handleSave = async (
    name,
    agency,
    callTime,
    finishTime,
    bookedBy,
    paidBy
  ) => {
    if (!hmuData.name) {
      let error = false;
      if (!name) {
        setNewHmuNameError(true);
        error = true;
      }
      if (!agency) {
        setNewHmuAgencyError(true);
        error = true;
      }
      if (!callTime) {
        setNewHmuCallTimeError(true);
        error = true;
      }
      if (!finishTime) {
        setNewHmuFinishTimeError(true);
        error = true;
      }
      if (!bookedBy) {
        setNewHmuBookedByError(true);
        error = true;
      }
      if (!paidBy) {
        setNewHmuPaidByError(true);
        error = true;
      }
      if (error) return;
      await addNewHmu({ name, agency, callTime, finishTime, bookedBy, paidBy });
    } else {
      await editHmu(
        { name, agency, callTime, finishTime, bookedBy, paidBy },
        index
      );
    }
  };

  return (
    <div style={containerStyle}>
      <TextField
        label="Name"
        variant="outlined"
        size="small"
        style={textFieldStyle}
        error={newHmuNameError}
        value={newHmuName || ""}
        onChange={(e) => {
          setNewHmuName(e.target.value);
          setNewHmuNameError(false);
        }}
      />
      <TextField
        label="Agency"
        variant="outlined"
        size="small"
        style={textFieldStyle}
        error={newHmuAgencyError}
        value={newHmuAgency || ""}
        onChange={(e) => {
          setNewHmuAgency(e.target.value);
          setNewHmuAgencyError(false);
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label="Call Time"
          value={newHmuCallTime || null}
          onChange={(e) => {
            try {
              setNewHmuCallTime(e.toISOString());
              setNewHmuCallTimeError(false);
            } catch (e) {
              setNewHmuCallTimeError(true);
            }
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              style={textFieldStyle}
              {...params}
              error={newHmuCallTimeError}
            />
          )}
        />
        <TimePicker
          label="Finish Time"
          value={newHmuFinishTime || null}
          onChange={(e) => {
            try {
              setNewHmuFinishTime(e.toISOString());
              setNewHmuFinishTimeError(false);
            } catch (e) {
              setNewHmuFinishTimeError(true);
            }
          }}
          renderInput={(params) => (
            <TextField
              size="small"
              style={textFieldStyle}
              {...params}
              error={newHmuFinishTimeError}
            />
          )}
        />
      </LocalizationProvider>
      <FormControl size="small" style={textFieldStyle}>
        <InputLabel id="hmuBookedByLabel">Booked By</InputLabel>
        <Select
          labelId="hmuBookedByLabel"
          error={newHmuBookedByError}
          value={newHmuBookedBy}
          label="Booked By"
          onChange={(e) => {
            setNewHmuBookedBy(e.target.value);
            setNewHmuBookedByError(false);
          }}
        >
          <MenuItem value="Paid By Client">Booked By Client</MenuItem>
          <MenuItem value="Paid By ITS">Booked By ITS</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" style={textFieldStyle}>
        <InputLabel id="hmuPaidByLabel">Paid By</InputLabel>
        <Select
          labelId="hmuPaidByLabel"
          error={newHmuPaidByError}
          value={newHmuPaidBy}
          label="Paid By"
          onChange={(e) => {
            setNewHmuPaidBy(e.target.value);
            setNewHmuPaidByError(false);
          }}
        >
          <MenuItem value="Paid By Client">Paid By Client</MenuItem>
          <MenuItem value="Paid By ITS">Paid By ITS</MenuItem>
        </Select>
      </FormControl>
      <Button
        style={textFieldStyle}
        variant="outlined"
        onClick={() => {
          handleSave(
            newHmuName,
            newHmuAgency,
            newHmuCallTime,
            newHmuFinishTime,
            newHmuBookedBy,
            newHmuPaidBy
          );
        }}
      >
        Save
      </Button>
    </div>
  );
};

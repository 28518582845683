import React, { useState, useEffect } from "react";
import StaffAvatar from "./StaffAvatar";
// import LoadingFullScreen from "../GlobalComponents/LoadingFullScreen";
// import { useTheme as useMUITheme } from "@mui/material/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";

const StaffIcons = ({
  firebase,
  isDarkMode,
  auth,
  darkMode,
  lightMode,
  ipAddress,
}) => {
  const [staffData, setStaffData] = useState([]);
  // eslint-disable-next-line
  const [loadingVisible, setLoadingVisible] = useState(true);

  useEffect(() => {
    const init = async () => {
      const sd = await firebase
        .firestore()
        .collection("StaffData")
        .orderBy("firstName", "asc")
        .get();
      const dataToReturn = [];
      sd.forEach((s) => {
        const userQueryData = s.data();

        dataToReturn.push({
          firstName: userQueryData.firstName,
          lastName: userQueryData.lastName,
          avatar: userQueryData.avatar,
          email: userQueryData.email,
          isUtility: userQueryData.isUtility,
          uid: userQueryData.uid,
          currentlyEmployed: userQueryData.currentlyEmployed,
        });
      });
      try {
        setStaffData([...dataToReturn]);
      } catch (e) {}
      setTimeout(() => {
        setLoadingVisible(false);
      }, 400);
    };
    init();
  }, [firebase]);

  // const MUITheme = useMUITheme();
  // const isMobile = useMediaQuery(MUITheme.breakpoints.down("md"));

  const staffIconsContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    maxWidth: "85vw",
  };
  // const staffIconsContainerStyleUtility = {
  //   display: "flex",
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  //   justifyContent: isMobile ? "space-evenly" : "center",
  //   maxWidth: "85vw",
  //   marginBottom: 40,
  // };
  return (
    <>
      {/* <LoadingFullScreen
        loadingVisible={loadingVisible}
        isDarkMode={isDarkMode}
        darkMode={darkMode}
        lightMode={lightMode}
      /> */}

      <div style={staffIconsContainerStyle}>
        {staffData.map((staff, index) => {
          return staff.currentlyEmployed && !staff.isUtility ? (
            <StaffAvatar
              key={`StaffAvatar-${index}`}
              staff={staff}
              firebase={firebase}
              isDarkMode={isDarkMode}
              auth={auth}
              darkMode={darkMode}
              lightMode={lightMode}
              setLoadingVisible={setLoadingVisible}
              autoLogin={false}
              ipAddress={ipAddress}
            />
          ) : null;
        })}
      </div>
    </>
  );
};

export default StaffIcons;

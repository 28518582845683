import React, { useState, useEffect } from "react";

import { Box, Button } from "@mui/material";

import { UpdateMetaThemeColour } from "./Utils/UpdateMetaThemeColour";

import Logo from "./Login/Logo";

import { getAuth, signInWithCustomToken } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "@mui/material/CircularProgress";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";

import TrafficLight from "./macos-traffic-light";

import { motion, AnimatePresence } from "framer-motion";

import CodeInput from "./CodeInput";

import { useAtom } from "jotai";
import { themeAtom } from "./-Atoms";

const qr = require("qr-image");

const darkMode = "#1f1f1f";
const lightMode = "#f4f5f7";

const LoginElectron = ({ user, firebase }) => {
  const [theme] = useAtom(themeAtom);
  const auth = getAuth();
  const [scanned, setScanned] = useState(false);
  const [uuid] = useState(uuidv4());

  UpdateMetaThemeColour({ light: lightMode, dark: darkMode });

  const [isDarkMode] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  const getBluetoothDeviceNames = firebase
    .functions()
    .httpsCallable("getBluetoothDeviceNames");
  const getBluetoothDeviceIDFromName = firebase
    .functions()
    .httpsCallable("getBluetoothDeviceIDFromName");

  // STYLES
  const loginBoxStyle = {
    position: "relative",
    width: "90vw",
    maxWidth: 580,
    height: 680,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 0 20px rgba(0,0,0,0.5)",
    borderRadius: 15,
    padding: 10,
  };

  const innerStyle = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };

  const QRStyle = {
    display: scanned ? "none" : "block",
    width: "88%",
    padding: "10px 20px 20px 20px",
    fill: isDarkMode ? "#dbdbdb" : "#242424",
  };
  const QRTitleStyle = {
    display: scanned ? "none" : "block",
    fontWeight: "bold",
    fontSize: "1.1rem",
  };
  // STYLES

  const createDoc = async () => {
    // console.log("create", uuid);
    await firebase
      .firestore()
      .collection("RemoteLogin")
      .doc(uuid)
      .set({ scanned: scanned, userID: "" }, { merge: true });
    const QRCode = qr.imageSync(JSON.stringify({ id: uuid }), {
      type: "svg",
    });
    try {
      document.querySelector("#QRCode").innerHTML = QRCode;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    createDoc();
    let timeout = setTimeout(async () => {
      await firebase.firestore().collection("RemoteLogin").doc(uuid).delete();
      // window.ipcRenderer.send(
      //   "eval",
      //   "calendarWindow.close(); setTimeout(()=>{createNewCalendarWindow(true);},400);"
      // );
      window.ipcRenderer.send("eval", "calendarWindow.close();");
      // }, 10000);
    }, 300000);
    // }, 2400000);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("RemoteLogin")
      .doc(uuid)
      .onSnapshot((doc) => {
        const data = doc.data();
        if (!data) return;
        if (data.scanned) setScanned(true);
        if (data.userID) {
          const token = window.ipcRenderer.sendSync(
            "eval",
            `admin
          .auth()
          .createCustomToken("${data.userID}")
          .then((customToken) => {
            event.returnValue = customToken
          })
          .catch((error) => {
            event.returnValue = "error"

            console.log("-----------------------------------------");
            console.log("Error creating custom token:", error);
            console.log("-----------------------------------------");
            return error;
          });
        `
          );

          signInWithCustomToken(auth, token)
            .then((userCredential) => {
              firebase.firestore().collection("RemoteLogin").doc(uuid).delete();
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);
            });
        }
      });
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  });

  useEffect(() => {
    const handleBeforeUnload = async (event) => {
      console.log("beforeUnload");
      await firebase.firestore().collection("RemoteLogin").doc(uuid).delete();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line
  }, []);

  const [isLoadingBluetooth, setIsLoadingBluetooth] = useState(false);
  const [foundWatch, setFoundWatch] = useState(false);
  const [deviceName, setDeviceName] = useState("");
  const [deviceID, setDeviceID] = useState("");

  // useEffect(() => {
  //   const rssi = -100;
  //   // let timeout;
  //   let interval;
  //   const main = async () => {
  //     const res = await getBluetoothDeviceNames();

  //     window.ipcRenderer.send("scanForAppleWatch", {
  //       deviceNames: res.data,
  //       rssi: rssi,
  //     });

  //     interval = setInterval(() => {
  //       window.ipcRenderer.send("scanForAppleWatch", {
  //         deviceNames: res.data,
  //         rssi: rssi,
  //       });
  //     }, 5000);

  //     window.ipcRenderer.on("scanForAppleWatchReturn", (event, arg) => {
  //       console.log(arg);
  //       if (arg.deviceName) {
  //         setDeviceName(arg.deviceName);
  //         setDeviceID(arg.deviceID);
  //         setFoundWatch(true);
  //         // clearTimeout(timeout);
  //         // window.ipcRenderer.removeAllListeners("scanForAppleWatchReturn");
  //         // if (window.location.href.includes("autologin")) {
  //         // timeout = setTimeout(() => {
  //         //   console.log(timeout);
  //         //   window.ipcRenderer.send("scanForAppleWatch", {
  //         //     deviceNames: res.data,
  //         //     rssi: rssi,
  //         //   });
  //         // }, 1000);
  //         // }
  //       } else {
  //         setFoundWatch(false);
  //         setDeviceName("");
  //         setDeviceID("");
  //       }
  //     });
  //     // setDeviceNames(res.data);
  //     // setupWatchScan(res.data, interval);
  //   };
  //   main();
  //   return () => {
  //     // console.log(timeout);
  //     // clearTimeout(timeout);
  //     clearInterval(interval);
  //     window.ipcRenderer.removeAllListeners("scanForAppleWatchReturn");
  //   };
  // }, []);

  function setupWatchScan(deviceNames, interval) {
    // const rssi = -100;
    // window.ipcRenderer.on("scanForAppleWatchReturn", (event, arg) => {
    //   console.log(arg);
    //   if (arg.deviceName) {
    //     setDeviceName(arg.deviceName);
    //     setDeviceID(arg.deviceID);
    //     setFoundWatch(true);
    //     clearInterval(interval);
    //     window.ipcRenderer.removeAllListeners("scanForAppleWatchReturn");
    //     // console.log(window.location.href);
    //     if (window.location.href.includes("autologin")) {
    //       setTimeout(() => {
    //         setupWatchScan(deviceNames, interval);
    //       }, 6000);
    //     }
    //   } else {
    //     setFoundWatch(false);
    //     setDeviceName("");
    //     setDeviceID("");
    //   }
    // });
    // window.ipcRenderer.send("scanForAppleWatch", { deviceNames, rssi });
    // interval = setInterval(() => {
    //   window.ipcRenderer.send("scanForAppleWatch", { deviceNames, rssi });
    // }, 5000);
  }

  const [qrVisible, setQrVisible] = useState(false);
  return (
    <>
      <div id="login-captcha-container"></div>
      <div style={{ position: "absolute", top: 10, left: 10 }}>
        <TrafficLight
          onClose={() => {
            window.ipcRenderer.send("customCloseWindow");
          }}
          onMinimize={() => {
            window.ipcRenderer.send("customMinimizeWindow");
          }}
          onMaximize={() => {
            window.ipcRenderer.send("customMaximizeWindow");
          }}
        />
      </div>
      <AnimatePresence>
        {foundWatch && (
          <motion.div
            initial={{
              x: -1000,
            }}
            animate={{ x: 150 }}
            exit={{
              x: -1000,
              transitionEnd: {
                display: "none",
              },
            }}
            style={{
              display: "flex",
              position: "absolute",
              top: "40%",
              width: 220,
              height: 280,
              background: "rgba(255,255,255,1)",
              borderRadius: 16,
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <img
              src="/applewatch.png"
              alt="watch.png"
              style={{ width: 160, objectFit: "contain" }}
            />
            <Button
              variant="contained"
              sx={{
                marginBottom: 1,
                background: "rgba(0,0,0,0.2)",
                "&:hover": {
                  background: "rgba(0,0,0,0.3)",
                },
                fontSize: 12,
                width: 180,
                height: 40,
              }}
              onClick={async () => {
                setIsLoadingBluetooth(true);
                const res = await getBluetoothDeviceIDFromName(deviceID);
                console.log(res.data);
                if (!res.data) return;

                signInWithCustomToken(auth, res.data)
                  .then((userCredential) => {
                    console.log("login");
                    firebase
                      .firestore()
                      .collection("RemoteLogin")
                      .doc(uuid)
                      .delete();
                  })
                  .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorCode);
                    console.log(errorMessage);
                    setIsLoadingBluetooth(false);
                  });
              }}
            >
              {!isLoadingBluetooth ? (
                deviceName
              ) : (
                <CircularProgress size={28} sx={{ color: "rgb(80, 80, 80)" }} />
              )}
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={loginBoxStyle}>
          <div style={{ position: "absolute", top: 0 }}>
            <Logo isDarkMode={isDarkMode} hasShadow={true} />
          </div>
          <div style={innerStyle}>
            <div style={{ display: qrVisible ? "none" : "block" }}>
              <span style={{ ...QRTitleStyle, marginBottom: 10 }}>
                Enter code found in the ITS Staff app{" "}
              </span>
              <CodeInput firebase={firebase} theme={theme} />
            </div>
            <div
              style={{
                marginTop: 80,
                display: qrVisible ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={QRTitleStyle}>
                Scan using the QR Scanner in the ITS Staff app{" "}
                <QrCodeScannerIcon
                  sx={{
                    color: isDarkMode ? "#dbdbdb" : "#242424",
                    marginLeft: 1,
                  }}
                />
              </span>
              <div style={QRStyle} id="QRCode"></div>
            </div>
            <CircularProgress
              size={250}
              sx={{
                display: scanned ? "block" : "none",
                color: isDarkMode ? "#dbdbdb" : "#242424",
                margin: 10,
              }}
            />
            <div style={{ display: "flex", gap: 10, marginTop: 30 }}>
              <Button
                variant="outlined"
                sx={{
                  display: qrVisible ? "none" : "block",
                  width: 140,
                }}
                onClick={() => {
                  setQrVisible(true);
                }}
              >
                QR Login
              </Button>
              <Button
                variant="outlined"
                sx={{
                  display: qrVisible ? "block" : "none",
                  width: 140,
                }}
                onClick={() => {
                  setQrVisible(false);
                }}
              >
                Code Login
              </Button>
              <Button
                variant="outlined"
                sx={{
                  width: 140,
                }}
                onClick={() => {
                  window.location.href = "/calendar";
                }}
              >
                Phone Login
              </Button>
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

export default LoginElectron;

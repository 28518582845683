import React, { useState, useEffect } from "react";
import Rating from "@mui/material/Rating";

//Alex 8jXVYoCymxHLQngklbWE
//Luke 5plhq9VTSzQvcncNElel

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme as useMUITheme } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// const Dropbox = require("dropbox").Dropbox;
// const dbx = new Dropbox({
//   accessToken:
//     "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
// });
import { useAtom } from "jotai";
import { isIdleAtom } from "./-Atoms";

const images = {
  a1: "https://www.dropbox.com/s/km6i3nvfqhpxec9/_MS_0086.jpg?raw=1",
  a2: "https://www.dropbox.com/s/qvsin97hkwgdy9l/_MS_0109.jpg?raw=1",
  a3: "https://www.dropbox.com/s/ws9803doi9jc45i/_MS_0289.jpg?raw=1",
  a4: "https://www.dropbox.com/s/0377d05xakd32d2/_MS_9581.jpg?raw=1",
  a5: "https://www.dropbox.com/s/1tdvy23pllbulzr/_MS_9629.jpg?raw=1",
  a6: "https://www.dropbox.com/s/mbb7b3vo0avxgf1/_MS_9660.jpg?raw=1",
  a7: "https://www.dropbox.com/s/gh462djdqlwtbws/_MS_9680.jpg?raw=1",
  a8: "https://www.dropbox.com/s/6ggag7mczyqp1jl/_MS_9709.jpg?raw=1",
  a9: "https://www.dropbox.com/s/we1h2mhfx3cw6ql/_MS_9914.jpg?raw=1",
  a10: "https://www.dropbox.com/s/n8itr2uljqtqm8k/_MS_9998.jpg?raw=1",
  l1: "https://www.dropbox.com/s/2sd7r1kouscrei7/Sequence%201-339.JPG?raw=1",
  l2: "https://www.dropbox.com/s/veneqjd8yd6iv0r/Sequence%201-340.JPG?raw=1",
  l3: "https://www.dropbox.com/s/ht4jf5sswuq73rm/Sequence%201-341.JPG?raw=1",
  l4: "https://www.dropbox.com/s/x245jlj6vpwn681/Sequence%201-342.JPG?raw=1",
  l5: "https://www.dropbox.com/s/w5pol04csfoz8d6/Sequence%201-344.JPG?raw=1",
  l6: "https://www.dropbox.com/s/xgokix1f6tbk0qp/Sequence%201-346.JPG?raw=1",
  l7: "https://www.dropbox.com/s/ijcubao9ch7tm26/Sequence%201-347.JPG?raw=1",
  l8: "https://www.dropbox.com/s/azv3k7g682qte69/Sequence%201-348.JPG?raw=1",
  l9: "https://www.dropbox.com/s/eu3iwacjqo2p41s/Sequence%201-349.JPG?raw=1",
  l10: "https://www.dropbox.com/s/f1ww2jus2to3rok/Sequence%201-350.JPG?raw=1",
};

const PhotoContest = ({ firebase, user }) => {
  const [isIdle] = useAtom(isIdleAtom);

  const MUITheme = useMUITheme();
  const isMobile = useMediaQuery(MUITheme.breakpoints.down("md"));

  const [showResults, setShowResults] = useState(false);

  const [shuffledImages, setShuffledImages] = useState([]);

  const [finishedLoading, setFinishedLoading] = useState(false);

  const [staffData, setStaffData] = useState([]);
  // console.log(staffData);

  useEffect(() => {
    let unsub = firebase
      .firestore()
      .collection("StaffData")
      // .orderBy("firstName", "asc")
      .onSnapshot((collection) => {
        const sd = collection.docs.map((staff) => {
          const staffDataRaw = staff.data();
          const data = {};
          data.photoContest = staffDataRaw.photoContest;
          data.name = `${staffDataRaw.firstName} ${staffDataRaw.lastName}`;
          return data;
        });
        setStaffData([...sd]);
      });
    // if (isIdle) {
    //   unsub();
    // }
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [firebase, user, isIdle]);

  const [alexTotals, setAlexTotals] = useState(0);
  const [lukeTotals, setLukeTotals] = useState(0);

  const [imageTotals, setImageTotals] = useState({
    a1: 0,
    a2: 0,
    a3: 0,
    a4: 0,
    a5: 0,
    a6: 0,
    a7: 0,
    a8: 0,
    a9: 0,
    a10: 0,
    l1: 0,
    l2: 0,
    l3: 0,
    l4: 0,
    l5: 0,
    l6: 0,
    l7: 0,
    l8: 0,
    l9: 0,
    l10: 0,
  });

  // console.log(imageTotals);
  useEffect(() => {
    const imageTotalsKeys = Object.keys(imageTotals);
    imageTotalsKeys.forEach((key) => {
      imageTotals[key] = 0;
    });
    setImageTotals(imageTotals);
    let at = 0;
    let lt = 0;
    staffData.forEach((sd) => {
      if (sd.photoContest) {
        const keys = Object.keys(sd.photoContest);
        keys.forEach((key) => {
          imageTotals[key] = imageTotals[key] + sd.photoContest[key];

          if (key.includes("a")) {
            at = at + sd.photoContest[key];
          } else if (key.includes("l")) {
            lt = lt + sd.photoContest[key];
          }
        });
      }
    });
    setAlexTotals(at);
    setLukeTotals(lt);
    setImageTotals(imageTotals);
  }, [staffData, imageTotals]);

  const [photoContestVotes, setPhotoContestVotes] = useState(
    user?.staffData?.photoContest || {}
  );

  useEffect(() => {
    setPhotoContestVotes(user?.staffData?.photoContest);
  }, [user]);

  useEffect(() => {
    setTimeout(() => {
      setFinishedLoading(true);
    }, 3000);
  }, [setFinishedLoading]);

  useEffect(() => {
    const keys = Object.keys(images);
    shuffle(keys);
    shuffle(keys);
    shuffle(keys);
    shuffle(keys);
    setShuffledImages(keys);
  }, [setShuffledImages]);

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }
  const loading = {
    background: "#f3f3f3",
    zIndex: 1000,
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    // alignItems: "center",
    marginTop: 180,
  };

  return (
    <>
      {!finishedLoading ? (
        <div style={loading}>
          <CircularProgress size={150} />
        </div>
      ) : null}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={showResults}
                onChange={async (e) => {
                  setShowResults(e.target.checked);
                }}
              />
            }
            label="Show Results"
          />
        </FormGroup>
      </div>
      {showResults ? (
        <div style={{}}>
          <div
            style={{
              margin: 10,
              fontSize: "1.8rem",
              fontWeight: 800,
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <p>Alex: {alexTotals}</p>
            <p>Luke: {lukeTotals}</p>
          </div>
        </div>
      ) : null}

      <div
        style={{
          margin: 20,
          width: "calc(100vw - 40px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {shuffledImages &&
          shuffledImages.map((item) => (
            <Image
              isMobile={isMobile}
              imageSrc={images[item]}
              imageName={item}
              key={item}
              firebase={firebase}
              user={user}
              photoContestVotes={photoContestVotes}
              showResults={showResults}
              imageTotal={imageTotals[item]}
            />
          ))}
      </div>
    </>
  );
};

export default PhotoContest;

const Image = ({
  isMobile,
  imageSrc,
  imageName,
  firebase,
  user,
  photoContestVotes,
  showResults,
  imageTotal,
}) => {
  const [value, setValue] = useState(0);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    if (photoContestVotes) {
      setValue(photoContestVotes[imageName]);
    }
  }, [photoContestVotes, imageName]);

  const imageStyle = {
    maxWidth: isMobile ? "85vw" : isLandscape ? "61vw" : "30vw",
    margin: 10,
  };
  const imageContainer = {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  };
  const [contestant, setContestant] = useState("");
  useEffect(() => {
    if (imageName.includes("a")) {
      setContestant("Alex");
    } else if (imageName.includes("l")) {
      setContestant("Luke");
    }
  }, [imageName]);

  return (
    <div style={imageContainer}>
      {showResults ? (
        <p
          style={{
            marginBottom: -10,
            marginTop: 20,
            fontSize: "1.5rem",
            fontWeight: 800,
          }}
        >
          {contestant}
        </p>
      ) : null}
      <img
        id={`${imageName}-img`}
        style={imageStyle}
        src={imageSrc}
        onLoad={() => {
          const img = document.getElementById(`${imageName}-img`);
          if (img.height < img.width) setIsLandscape(true);
        }}
        alt="it was saying i needed to put something here"
      />
      {showResults ? (
        <p
          style={{
            marginBottom: 5,
            marginTop: 5,
            fontSize: "1.2rem",
            fontWeight: 800,
          }}
        >
          Image Points: {imageTotal}
        </p>
      ) : null}
      <Rating
        name="simple-controlled"
        value={value}
        size="large"
        sx={{ fontSize: "3.5rem" }}
        precision={0.5}
        onChange={(event, newValue) => {
          setValue(newValue);
          firebase
            .firestore()
            .collection("StaffData")
            .doc(user.staffData.id)
            .set({ photoContest: { [imageName]: newValue } }, { merge: true });
        }}
      />
    </div>
  );
};

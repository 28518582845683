import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingFullScreen = ({
  loadingVisible,
  isDarkMode,
  darkMode,
  lightMode,
}) => {
  const loadingContainerStyle = {
    display: loadingVisible ? "block" : "none",
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 10000,
    backgroundColor: isDarkMode ? darkMode : lightMode,
  };

  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };

  return (
    <div style={loadingContainerStyle}>
      <Box sx={loading.box}>
        <CircularProgress sx={loading.progress} />
      </Box>
    </div>
  );
};

export default LoadingFullScreen;

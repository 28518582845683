import React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const LoadingFullScreen = ({ loadingVisible, loadingMessage, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  const loadingMessageStyle = {
    display: loadingMessage ? "block" : "none",
    marginTop: 40,
  };
  return (
    <div
      style={{
        display: loadingVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <CircularProgress style={loading.progress} />
        <p style={loadingMessageStyle}>{loadingMessage}</p>
      </Box>
    </div>
  );
};

export default LoadingFullScreen;

import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';


const RemoteSession = () => {
    const { sessionid } = useParams()
    useEffect(() => {
        const main = async () => {
            console.log(sessionid)
            const res = await fetch('https://us-central1-its-connect-main.cloudfunctions.net/getSessionURL',
                {
                    method: 'POST',
                    body: sessionid
                })
                .then(response => { return response.json(); })
            console.log(res)
            // let x = btoa(params.url)
            // x = encodeURIComponent(x)
            // console.log(x)
            const base64URL = btoa(res.url)
            const encodedURL = encodeURIComponent(base64URL)
            if (!res.error) {
                window.location = `http://www.imagesthatsell.com.au/remote/index.html?url=${encodedURL}`
            }
            //https://localhost:3000/session/99c6bee66b959f5812f51e3f918c14f2
        }
        main()
    }, [sessionid])
    return (
        <div>
            {/* <iframe src="http://61.69.92.210:4013" title="Images That Sell"></iframe> */}
            {/* <html>
                <frameset cols="*">
                    <frame src="alexmorrisseysmith.com" />

                </frameset>
            </html> */}
        </div>
    )
}
// https://www.google.com/search?q=%http://61.69.92.210:4013&btnI=Im+Feeling+Lucky


export default RemoteSession

import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

const KanbanClientSelector = ({
  clientData,
  newPostJobClient,
  setNewPostJobClient,
  newPostJobDueDate,
  setNewPostJobDueDate,
}) => {
  return (
    <>
      <Autocomplete
        disablePortal
        fullWidth={true}
        sx={{ marginTop: 2 }}
        options={clientData.map((c) => c.brand)}
        renderInput={(params) => (
          <TextField
            {...params}
            error={newPostJobClient ? false : true}
            label="Client"
          />
        )}
        value={newPostJobClient || ""}
        onChange={(e) => {
          let value = "";
          if (e.target.innerText) value = e.target.innerText;

          if (value === "Add New Client...") {
            console.log("new client");
            // value = "Morrissey Media";
            // return;
          }

          setNewPostJobClient(value);

          let selectedClientData;
          for (let i = 0; i < clientData.length; i++) {
            if (clientData[i].brand === value) {
              selectedClientData = clientData[i];
              break;
            }
          }
          let daysToAdd = 5;
          if (selectedClientData) {
            if (selectedClientData.turnAround) {
              daysToAdd = selectedClientData.turnAround;
            }
          }

          const dd = addBusinessDays(new Date(), daysToAdd);
          setNewPostJobDueDate(dd.toISOString());
        }}
      />
    </>
  );
};

export default KanbanClientSelector;

function addBusinessDays(date, daysToAdd) {
  const year = date.getFullYear();
  let weekday = date.getDay();
  let addDays = weekday >= 3 ? 2 : 0;
  date.setDate(date.getDate() + parseInt(addDays) + parseInt(daysToAdd));
  if (date.getDay() === 0 || date.getDay() === 6) {
    date.setDate(date.getDate() + 2);
  }
  date.setYear(year);
  return date;
}

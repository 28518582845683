import React, { useState, useEffect } from "react";

import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { Checkbox, FormControlLabel } from "@mui/material";

import { useAtom } from "jotai";
import { themeAtom } from "../-Atoms";

const OutsourcerTable = ({ firebase }) => {
  const firestore = firebase.firestore();

  const [outsourcerData, setOutsourcerData] = useState([]);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    let reference = firestore
      .collection("Outsourcers")
      .orderBy("companyName", "asc");

    const unsub = reference.onSnapshot((collection) => {
      const initialData = collection.docs.map((outsourcer) => {
        const data = outsourcer.data();
        data.id = outsourcer.id;
        return data;
      });
      setOutsourcerData([...initialData]);
    });
    return () => {
      unsub();
    };
    // eslint-disable-next-line
  }, [setOutsourcerData]);

  useEffect(() => {
    setModalData(modalData);
    // eslint-disable-next-line
  }, [outsourcerData]);
  return (
    <div style={{ width: "100vw" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <Fab
                  style={{}}
                  color="primary"
                  size="small"
                  onClick={() => {
                    firebase.firestore().collection("Outsourcers").add(
                      {
                        companyName: "",
                        contactName: "",
                        mainEmail: "",
                        ccEmails: [],
                      },
                      { merge: true }
                    );
                  }}
                >
                  <AddIcon />
                </Fab>
              </TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Contact Name</TableCell>
              <TableCell>Main Email</TableCell>
              <TableCell>CC Emails</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outsourcerData.map((outsourcer) => (
              <OutsourcerRow
                key={`OutsourcerRow${outsourcer.id}`}
                firebase={firebase}
                outsourcer={outsourcer}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OutsourcerTable;

const returnCCs = (outsourcer) => {
  let res;
  outsourcer.forEach((o) => {
    if (!res) {
      res = o;
    } else {
      res = `${res}, ${o}`;
    }
  });
  return res;
};

const OutsourcerRow = ({ firebase, outsourcer }) => {
  const [theoutsourcer, settheoutsourcer] = useState(outsourcer);

  useEffect(() => {
    settheoutsourcer(outsourcer);
  }, [outsourcer]);

  const [showModal, setShowModal] = useState(false);
  return (
    <TableRow
      key={`OutsourcerRowTableRow${outsourcer.id}`}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell className="editSVG" component="th" scope="row">
        <div
          onClick={(e) => {
            if (e.target.id === "editOutsourcerModal") {
              setShowModal(false);
            } else {
              setShowModal(true);
            }
          }}
        >
          <EditSVG />
          <OutsourcerModal
            firebase={firebase}
            outsourcer={theoutsourcer}
            showModal={showModal}
            setShowModal={setShowModal}
          />
        </div>
      </TableCell>
      <TableCell>{outsourcer.companyName}</TableCell>
      <TableCell>{outsourcer.contactName}</TableCell>
      <TableCell>{outsourcer.mainEmail}</TableCell>
      <TableCell>
        {outsourcer.ccEmails.length > 1
          ? returnCCs(outsourcer.ccEmails)
          : outsourcer.ccEmails}
      </TableCell>
    </TableRow>
  );
};

const updateOutsourcerData = debounce((firebase, id, field, value) => {
  firebase
    .firestore()
    .collection("Outsourcers")
    .doc(id)
    .update({ [field]: value }, { merge: true });
}, 400);

const CCEmail = ({ email, updateCCs, id }) => {
  const [ccEmail, setCcEmail] = useState(email);

  return (
    <TextField
      key={email}
      ccemail="true"
      data-outsourcerid={id}
      style={{ width: 300, marginBottom: 20 }}
      label="CC Email"
      size="small"
      value={ccEmail || ""}
      onChange={(e) => {
        setCcEmail(e.target.value);
        updateCCs();
      }}
    />
  );
};

const OutsourcerModal = ({ firebase, outsourcer, showModal }) => {
  const [companyName, setCompanyName] = useState(outsourcer.companyName);
  const [contactName, setContactName] = useState(outsourcer.contactName);
  const [mainEmail, setMainEmail] = useState(outsourcer.mainEmail);
  const [ccEmails, setCcEmails] = useState(outsourcer.ccEmails);
  const [isPrimary, setIsPrimary] = useState(outsourcer.isPrimary || false);

  const updateCCs = () => {
    const theEmailsRaw = Array.from(document.querySelectorAll("[ccemail]"));
    let theEmails = [];
    let theActualEmails = [];
    theEmailsRaw.forEach((e) => {
      if (e.dataset.outsourcerid === outsourcer.id) {
        theEmails.push(e);
      }
    });
    theEmails.forEach((e) => {
      let email = Array.from(e.getElementsByTagName("input"))[0].value;
      theActualEmails.push(email);
    });
    updateOutsourcerData(firebase, outsourcer.id, "ccEmails", theActualEmails);
  };

  const [theme] = useAtom(themeAtom);

  return (
    <div
      id="editOutsourcerModal"
      className="modalOverlay"
      style={{ display: showModal ? "flex" : "none" }}
    >
      <div
        id="modalContent"
        className="modalContent"
        style={{ backgroundColor: theme === "dark" ? "#1e1e1e" : "#ededed" }}
      >
        <br />
        <h3 className="center">Edit Outsourcer</h3>

        <br />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isPrimary}
                onChange={(e) => {
                  setIsPrimary(e.target.checked);
                  updateOutsourcerData(
                    firebase,
                    outsourcer.id,
                    "isPrimary",
                    e.target.checked
                  );
                }}
              />
            }
            label="Is Primary Outsourcer"
          />
          <br />
          <TextField
            style={{ width: 300, marginBottom: 20 }}
            label="Company Name"
            value={companyName || ""}
            onChange={(e) => {
              setCompanyName(e.target.value);
              updateOutsourcerData(
                firebase,
                outsourcer.id,
                "companyName",
                e.target.value
              );
            }}
          />
          <TextField
            style={{ width: 300, marginBottom: 20 }}
            label="Contact Name"
            size="small"
            value={contactName || ""}
            onChange={(e) => {
              setContactName(e.target.value);
              updateOutsourcerData(
                firebase,
                outsourcer.id,
                "contactName",
                e.target.value
              );
            }}
          />
          <TextField
            style={{ width: 300, marginBottom: 20 }}
            label="Main Email"
            size="small"
            value={mainEmail || ""}
            onChange={(e) => {
              setMainEmail(e.target.value);
              updateOutsourcerData(
                firebase,
                outsourcer.id,
                "mainEmail",
                e.target.value
              );
            }}
          />
          {ccEmails?.map((email) => (
            <CCEmail
              key={`${outsourcer.id}-${email}`}
              email={email}
              updateCCs={updateCCs}
              id={outsourcer.id}
            />
          ))}

          <Fab
            style={{ marginBottom: 60 }}
            color="primary"
            size="small"
            onClick={() => {
              outsourcer.ccEmails.push("");
              setCcEmails(outsourcer.ccEmails);
              firebase
                .firestore()
                .collection("Outsourcers")
                .doc(outsourcer.id)
                .update({ ccEmails: outsourcer.ccEmails }, { merge: true });
            }}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    </div>
  );
};

function debounce(func, timeout) {
  let timer;
  return (...args) => {
    const next = () => func(...args);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(next, timeout > 0 ? timeout : 300);
  };
}

function EditSVG() {
  return (
    <svg
      enableBackground="new 0 0 64 64"
      version="1.1"
      viewBox="0 0 64 64"
      xmlSpace="preserve"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="none"
        stroke="#333"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      >
        <path d="m24.3 49.6-12.7 3.9c-0.7 0.2-1.4-0.4-1.2-1.2l3.9-12.7 24.5-24.5 10 10-24.5 24.5z" />
        <rect
          transform="matrix(.7071 .7071 -.7071 .7071 27.729 -23.567)"
          x="35.3"
          y="19.6"
          width="14.1"
          height="4.3"
        />
        <path d="m24.3 49.6 0.3-4.3c0-0.6-0.4-1-1-1l-3.2 0.2c-0.6 0-1-0.4-1-1l0.2-3.2c0-0.6-0.4-1-1-1l-4.3 0.3 21.5-21.5 10 10-21.5 21.5z" />
        <line x1="40.9" x2="19.9" y1="23.1" y2="44.1" />
        <line x1="16.3" x2="35.9" y1="37.8" y2="18.2" />
        <line x1="38.4" x2="19.4" y1="20.6" y2="39.6" />
        <line x1="45.8" x2="26.2" y1="28.1" y2="47.7" />
        <line x1="24.4" x2="43.4" y1="44.6" y2="25.6" />
        <line x1="13" x2="18" y1="46.4" y2="51.4" />
        <path d="m51.5 12.5c-2.8-2.8-7.2-2.8-10 0l-2.7 2.7 10 10 2.7-2.7c2.7-2.8 2.7-7.2 0-10z" />
      </g>
    </svg>
  );
}

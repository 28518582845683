import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 10,
};

const HMUs = ({ jobData }) => {
  const [hmu, setHmu] = useState(jobData.hmu);

  useEffect(() => {
    setHmu(jobData.hmu);
  }, [jobData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 20,
          marginTop: 10,
          justifyContent: "center",
        }}
      >
        {hmu?.map((h, i) => (
          <HMU key={`model-${i}`} hmu={h} />
        ))}
      </div>
    </div>
  );
};

export default HMUs;

const HMU = ({ hmu }) => {
  const [name, setName] = useState(hmu.name);
  const [agency, setAgency] = useState(hmu.agency);
  const [callTime, setCallTime] = useState(new Date(hmu.callTime));
  const [finishTime, setFinishTime] = useState(new Date(hmu.finishTime));
  const [bookedBy, setBookedBy] = useState(hmu.bookedBy);
  const [paidBy, setPaidBy] = useState(hmu.paidBy);

  useEffect(() => {
    setName(hmu.name);
    setAgency(hmu.agency);
    setCallTime(new Date(hmu.callTime));
    setFinishTime(new Date(hmu.finishTime));
    setBookedBy(hmu.bookedBy);
    setPaidBy(hmu.paidBy);
  }, [hmu]);

  return (
    <div style={cardStyle}>
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Name"
        value={name}
        inputProps={{ readOnly: true }}
      />
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Agency"
        value={agency}
        inputProps={{ readOnly: true }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label="Call Time"
          value={callTime || null}
          onChange={(d) => {}}
          readOnly={true}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              size="small"
            />
          )}
        />
        <TimePicker
          label="Finish Time"
          value={finishTime || null}
          onChange={(d) => {}}
          readOnly={true}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              InputProps={{ disableUnderline: true }}
              size="small"
            />
          )}
        />
      </LocalizationProvider>
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Booked By"
        value={bookedBy}
        inputProps={{ readOnly: true }}
      />
      <TextField
        size="small"
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Paid By"
        value={paidBy}
        inputProps={{ readOnly: true }}
      />
    </div>
  );
};

import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { v4 as uuidv4 } from "uuid";

const ClientSelector = ({
  clientData,
  client,
  setClient,
  updateJobName,
  firebase,
  jobData,
  jobNumber,
  multipleDays,
  jobType,
  canEdit,
  setDueDate,
}) => {
  return (
    <>
      <Autocomplete
        disablePortal
        sx={{ marginTop: 2 }}
        options={clientData.map((c) => c.brand)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            InputProps={{ ...params.InputProps, disableUnderline: true }}
            error={client ? false : true}
            label="Client"
          />
        )}
        value={client || ""}
        onChange={(e) => {
          if (!canEdit) return;
          let value = "";
          if (e.target.innerText) value = e.target.innerText;

          if (value === "Add New Client...") {
            console.log("new client");
            // value = "Morrissey Media";
            // return;
          }

          setClient(value);
          let selectedClientData;
          for (let i = 0; i < clientData.length; i++) {
            if (clientData[i].brand === value) {
              selectedClientData = clientData[i];
              break;
            }
          }
          let daysToAdd = 5;
          if (selectedClientData) {
            if (selectedClientData.turnAround) {
              daysToAdd = selectedClientData.turnAround;
            }
          }

          const dd = addBusinessDays(
            new Date(jobData.bookedStart || new Date()),
            daysToAdd
          );
          setDueDate(dd.toISOString());

          let noRetouching = false;

          if (selectedClientData?.noRetouching) {
            noRetouching = selectedClientData.noRetouching;
          }

          // console.log(e.target);
          if (value) {
            addChecklists(jobData.id, value, firebase, jobData);
          }

          updateJobName(jobNumber, value, multipleDays, jobType);

          const clientArray = [];
          const clientLowerCaseArray = [];
          for (let i = 1; i < value.length + 1; i++) {
            clientArray.push(value.substring(0, i));
          }
          for (let i = 1; i < value.toLowerCase().length + 1; i++) {
            clientLowerCaseArray.push(value.toLowerCase().substring(0, i));
          }

          firebase.firestore().collection("JobData").doc(jobData.id).update(
            {
              client: value,
              clientArray: clientArray,
              clientLowerCaseArray: clientLowerCaseArray,
              dueDate: dd.toISOString(),
              noRetouching: noRetouching,
            },
            { merge: true }
          );
        }}
      />
    </>
  );
};

export default ClientSelector;

async function addChecklists(id, client, firebase, jobData) {
  const clientDataRaw = await firebase
    .firestore()
    .collection("ClientDatabase")
    .where("brand", "==", client)
    .get();

  const clientData = clientDataRaw.docs[0].data();

  let generalChecklist = false;
  let lifeChecklist = false;
  let productChecklist = false;
  let videoChecklist = false;
  jobData?.checklists?.forEach((cl, i) => {
    if (cl.name === "General Checklist") {
      jobData?.checklists.splice(i, 1);
      generalChecklist = true;
    }
    if (cl.name === "Life Checklist") {
      jobData?.checklists.splice(i, 1);
      lifeChecklist = true;
    }
    if (cl.name === "Product Checklist") {
      jobData?.checklists.splice(i, 1);
      productChecklist = true;
    }
    if (cl.name === "Video Checklist") {
      jobData?.checklists.splice(i, 1);
      videoChecklist = true;
    }
  });

  if (!generalChecklist) {
    if (clientData?.generalChecklist?.length > 0) {
      let generalChecklist = {
        name: "General Checklist",
        id: uuidv4(),
        checklistItems: clientData.generalChecklist.map((ci) => {
          return {
            id: uuidv4(),
            title: ci,
            checked: false,
          };
        }),
      };

      jobData?.checklists.push(generalChecklist);
    }
  }
  if (!lifeChecklist) {
    if (clientData?.lifeChecklist?.length > 0) {
      let lifeChecklist = {
        name: "Life Checklist",
        id: uuidv4(),
        checklistItems: clientData.lifeChecklist.map((ci) => {
          return {
            id: uuidv4(),
            title: ci,
            checked: false,
          };
        }),
      };
      jobData?.checklists.push(lifeChecklist);
    }
  }
  if (!productChecklist) {
    if (clientData?.productChecklist?.length > 0) {
      let productChecklist = {
        name: "Product Checklist",
        id: uuidv4(),
        checklistItems: clientData.productChecklist.map((ci) => {
          return {
            id: uuidv4(),
            title: ci,
            checked: false,
          };
        }),
      };
      jobData?.checklists.push(productChecklist);
    }
  }
  if (!videoChecklist) {
    if (clientData?.videoChecklist?.length > 0) {
      let videoChecklist = {
        name: "Video Checklist",
        id: uuidv4(),
        checklistItems: clientData.videoChecklist.map((ci) => {
          return {
            id: uuidv4(),
            title: ci,
            checked: false,
          };
        }),
      };
      jobData?.checklists.push(videoChecklist);
    }
  }
  firebase
    .firestore()
    .collection("JobData")
    .doc(jobData.id)
    .update({ checklists: jobData?.checklists }, { merge: true })
    .catch((e) => console.log(e));
}

function addBusinessDays(date, daysToAdd) {
  const year = date.getFullYear();
  let weekday = date.getDay();
  let addDays = weekday >= 3 ? 2 : 0;
  date.setDate(date.getDate() + parseInt(addDays) + parseInt(daysToAdd));
  if (date.getDay() === 0 || date.getDay() === 6) {
    date.setDate(date.getDate() + 2);
  }
  date.setYear(year);
  return date;
}

import React, { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";

const BackgroundRollItem = ({
  item,
  items,
  setItems,
  index,
  showRemoveBackgroundRollItem,
  updateInProgress,
}) => {
  const [quantity, setQuantity] = useState(item.quantity || "");
  const [itemName, setItemName] = useState(item.itemName || "");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (quantity !== item.quantity) setQuantity(item.quantity);
    if (itemName !== item.itemName) setItemName(item.itemName);
  }, [item, quantity, itemName]);

  const handleChange = (field, value, setValue) => {
    setValue(value);
    setIsTyping(true);
    const updatedItems = items.map((item, idx) =>
      idx === index ? { ...item, [field]: value } : item
    );
    setItems(updatedItems);
  };

  const handleQuantityChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "");
    handleChange("quantity", newValue, setQuantity);
  };

  const handleItemNameChange = (e) => {
    const newValue = e.target.value;
    handleChange("itemName", newValue, setItemName);
  };

  const removeBackgroundRollItem = () => {
    const updatedItems = items.filter((_, idx) => idx !== index);
    setItems(updatedItems);
  };

  useEffect(() => {
    if (!updateInProgress) {
      setIsTyping(false);
    }
  }, [updateInProgress]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 15,
        width: "100%",
      }}
    >
      <TextField
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        label="Quantity"
        variant="filled"
        value={quantity}
        size="small"
        sx={{ width: "49%" }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (isTyping || updateInProgress) ? (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null,
        }}
        onChange={handleQuantityChange}
      />
      <TextField
        inputProps={{ style: { fontSize: 15 } }}
        InputLabelProps={{ style: { fontSize: 15 } }}
        label="Colour Name"
        variant="filled"
        value={itemName}
        size="small"
        sx={{ width: "49%" }}
        InputProps={{
          disableUnderline: true,
          endAdornment: (isTyping || updateInProgress) ? (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ) : null,
        }}
        onChange={handleItemNameChange}
      />
      <IconButton
        sx={{ display: showRemoveBackgroundRollItem ? "block" : "none" }}
        onClick={removeBackgroundRollItem}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

export default BackgroundRollItem;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import cls from 'classnames';
import CloseIcon from './CloseIcon';
import MaximizeIcon from './MaximizeIcon';
import MinimizeIcon from './MinimizeIcon';
import { useTrafficLight } from './useTrafficLight';
import "./style.css";

var TrafficLight = function TrafficLight(_ref) {
  var onClose = _ref.onClose,
      onMinimize = _ref.onMinimize,
      onMaximize = _ref.onMaximize,
      _ref$minimizable = _ref.minimizable,
      minimizable = _ref$minimizable === void 0 ? true : _ref$minimizable,
      _ref$maximizable = _ref.maximizable,
      maximizable = _ref$maximizable === void 0 ? true : _ref$maximizable,
      disableMaximize = _ref.disableMaximize;

  var _useTrafficLight = useTrafficLight(),
      hover = _useTrafficLight.hover,
      isFocus = _useTrafficLight.isFocus,
      hoverOff = _useTrafficLight.hoverOff,
      hoverOn = _useTrafficLight.hoverOn; // 当没有 hover 且没 focus 时为 blur 状态


  var isBlur = !hover && !isFocus;
  return _jsxs("div", Object.assign({
    className: "avx-traffic-light-container",
    onMouseEnter: hoverOn,
    onMouseLeave: hoverOff,
    "data-testid": "container"
  }, {
    children: [_jsx("div", Object.assign({
      onClick: onClose,
      className: cls({
        'avx-traffic-light-close': true,
        'avx-traffic-light-blur': isBlur
      }),
      "data-testid": "onClose"
    }, {
      children: hover ? _jsx(CloseIcon, {}, void 0) : null
    }), void 0), minimizable ? _jsx("div", Object.assign({
      onClick: onMinimize,
      className: cls({
        'avx-traffic-light-minus': true,
        'avx-traffic-light-blur': isBlur
      }),
      "data-testid": "onMinimize"
    }, {
      children: hover ? _jsx(MinimizeIcon, {}, void 0) : null
    }), void 0) : null, maximizable ? _jsx("div", Object.assign({
      onClick: function onClick(e) {
        if (disableMaximize) return;
        onMaximize === null || onMaximize === void 0 ? void 0 : onMaximize(e);
      },
      className: cls({
        'avx-traffic-light-max': true,
        'avx-traffic-light-blur': isBlur,
        'avx-traffic-light-disable': disableMaximize
      }),
      "data-testid": "onMaximize"
    }, {
      children: !disableMaximize && hover ? _jsx(MaximizeIcon, {}, void 0) : null
    }), void 0) : null]
  }), void 0);
};

export default TrafficLight;
import React, { useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

const textFieldStyle = {
  margin: "10px",
  minWidth: "220px",
};

let arrayRemove;

const Contact = ({
  contact,
  client,
  showDeleteContact,
  updateFS,
  firebase,
  small,
  theme,
}) => {
  arrayRemove = firebase.firestore.FieldValue.arrayRemove;

  const [name, setName] = useState(contact.name);
  const [title, setTitle] = useState(contact.title);
  const [email, setEmail] = useState(contact.email);
  const [phone, setPhone] = useState(contact.phone);
  const [isBilling, setIsBilling] = useState(contact.isBilling);
  const [isSupply, setIsSupply] = useState(contact.isSupply);

  useEffect(() => {
    setName(contact.name);
    setTitle(contact.title);
    setEmail(contact.email);
    setPhone(contact.phone);
    setIsBilling(contact.isBilling);
    setIsSupply(contact.isSupply);
  }, [contact]);

  const handleSupplyChange = () => {
    const tmp = isSupply;
    setIsSupply(!tmp);
    contact.isSupply = !tmp;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleBillingChange = () => {
    const bil = isBilling;
    setIsBilling(!bil);
    contact.isBilling = !bil;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
    contact.name = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    contact.title = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    contact.email = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    contact.phone = e.target.value;
    updateFS(client.id, "contacts", client.contacts);
  };
  const handleDeleteContact = () => {
    firebase
      .firestore()
      .collection("ClientDatabase")
      .doc(client.id)
      .update({ contacts: arrayRemove(contact) });
  };
  return (
    <div
      style={{
        background: "#00000005",
        borderRadius: "10px",
        paddingTop: "10px",
        margin: "10px",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        maxWidth: "240px",
      }}
    >
      {showDeleteContact ? (
        <div
          style={{
            background: "#00000015",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "100",
            position: "absolute",
            borderRadius: "10px",
            top: "0px",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        >
          <IconButton onClick={handleDeleteContact}>
            <DeleteIcon
              style={{
                color: "#ff0033",
                width: 60,
                height: 60,
                filter: "drop-shadow(4px 4px 3px #00000060)",
              }}
            />
          </IconButton>
        </div>
      ) : null}
      <Tooltip title="Contact name" enterDelay={500}>
        <TextField
          variant="outlined"
          label="Name"
          value={name}
          onChange={handleNameChange}
          style={textFieldStyle}
          size={small ? "small" : "medium"}
        />
      </Tooltip>
      <Tooltip title="Contact title" enterDelay={500}>
        <TextField
          variant="outlined"
          label="Title"
          value={title}
          onChange={handleTitleChange}
          style={textFieldStyle}
          size={small ? "small" : "medium"}
        />
      </Tooltip>
      <Tooltip title="Contact email" enterDelay={500}>
        <TextField
          variant="outlined"
          label="Email"
          value={email}
          onChange={handleEmailChange}
          style={textFieldStyle}
          size={small ? "small" : "medium"}
        />
      </Tooltip>
      <Tooltip title="Contact phone number" enterDelay={500}>
        <TextField
          variant="outlined"
          label="Phone"
          value={phone}
          onChange={handlePhoneChange}
          style={textFieldStyle}
          size={small ? "small" : "medium"}
        />
      </Tooltip>
      <div>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            paddingBottom: "10px",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox checked={isSupply} onChange={handleSupplyChange} />
            }
            label="Supply"
          />
          <FormControlLabel
            control={
              <Checkbox checked={isBilling} onChange={handleBillingChange} />
            }
            label="Billing"
          />
        </FormGroup>
      </div>
    </div>
  );
};

export default Contact;

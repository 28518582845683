import React, { useState, useEffect, useRef } from "react";

import "./emailModal.css";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import IconButton from "@mui/material/IconButton";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Fab from "@mui/material/Fab";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";

import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// import DanteEditor from "dante3";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

import getJobName from "../GlobalFunctions/getJobName";

// import returnTeamITSsig from "./returnTeamITSsig";

const fetch = require("isomorphic-fetch");
const Dropbox = require("dropbox").Dropbox;
const dbx = new Dropbox({
  fetch: fetch,
  accessToken:
    "b-TxMhg7wmcAAAAAABiOl_5JiyeefA0elglApZxmxg3Uc9n7uXMt65Um5ajpJjuC",
});

// console.log(dbx)
const queryString = require("query-string");

const quillSize = Quill.import("attributors/style/size");
quillSize.whitelist = [
  "14px",
  "12px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
  "30px",
];
Quill.register(quillSize, true);

const EmailModal = ({ firebase, theme }) => {
  const query = window.location.search;
  const [emailKind, setEmailKind] = useState("");
  const [error, setError] = useState(false);
  const [errorReason, setErrorReason] = useState(
    "I'm not sure what went wrong here..."
  );
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  const [replyToEmails, setReplyToEmails] = useState([]);
  const [ccVisible, setCcVisible] = useState(false);
  const [bccVisible, setBccVisible] = useState(false);
  const [subject, setSubject] = useState("");
  // eslint-disable-next-line
  const [emailBody, setEmailBody] = useState("<p>i am groot</p>");
  // eslint-disable-next-line
  const [attachments, setAttachments] = useState([]);
  const [fromEmail, setFromEmail] = useState("");
  const [dropboxUrl, setDropboxUrl] = useState("");
  const [loadingVisible, setLoadingVisible] = useState(true);
  const [successVisible, setSuccessVisible] = useState(false);
  const [errorVisible, setErrorVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [codContent, setCodContent] = useState("");

  const [capturePilotContent, setCapturePilotContent] = useState("");

  const [supplyCustomText1, setSupplyCustomText1] = useState("");
  const [supplyCustomText2, setSupplyCustomText2] = useState("");
  const [supplyCustomText3, setSupplyCustomText3] = useState("");

  const [storyboardsContent, setStoryboardsContent] = useState("");
  // console.log(storyboardsContent)

  const [trelloCardID, setTrelloCardID] = useState("");
  const [jobNumber, setJobNumber] = useState("");
  const [sessionid, setSessionid] = useState("");
  const [sessionPassword, setSessionPassword] = useState("");
  const [jobData, setjobData] = useState({});
  const [clientData, setClientData] = useState({});
  const [suppliedLinks, setSuppliedLinks] = useState(
    clientData.suppliedLinks || []
  );
  // console.log(clientData.id)
  useEffect(() => {
    setSuppliedLinks(clientData.suppliedLinks || []);
  }, [clientData]);

  // useEffect(() => {
  //     if (!suppliedLinks.includes(suppliedLinks)) { setSuppliedLinks([...suppliedLinks, { jobNumber: jobData.jobNumber, url: dropboxUrl }]) }
  // }, [dropboxUrl])

  const sendEmail = firebase.functions().httpsCallable("sendEmail");

  useEffect(() => {
    document.title = subject;
  }, [subject]);

  useEffect(() => {
    if (trelloCardID) {
      fetch(
        "https://us-central1-its-connect-main.cloudfunctions.net/returnJobDataClientDataFromTrelloID",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ trelloCardID: trelloCardID }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then(async (data) => {
          let emails = [];
          data.clientData.contacts.forEach((i) => {
            if (i.isSupply) {
              emails.push(i);
            }
          });
          setToEmails([...emails]);
          setSubject(getJobName(data.jobData));
          setjobData(data.jobData);
          setClientData(data.clientData);
          if (emailKind === "supply") {
            const path = `/${data.jobData.client}/${data.jobData.jobNumber}-${data.jobData.client}${data.jobData.jobTypeShort}`;
            setDropboxUrl(await getDropboxLink(path));
          }
          if (emailKind === "overFileAllocation") {
            setSubject(`${getJobName(data.jobData)} - Over File Allocation`);
            const basePath = `/${data.jobData.client}/${
              data.jobData.jobNumber
            }-${data.jobData.client}${data.jobData.jobTypeShort}${
              data.jobData.multipleDays ? `/${data.jobData.multipleDays}` : ``
            }`;
            const storyboardsUrl = await getDropboxLink(
              `${basePath}/Storyboards`
            );
            const storyboardSelectsUrl = await getDropboxLink(
              `${basePath}/Storyboard Selects`
            );
            const quickproofsUrl = await getDropboxLink(
              `${basePath}/Quickproofs`
            );
            const selectsUrl = await getDropboxFileRequest(
              `${basePath}/Selects`,
              `${data.jobData.jobNumber}-${data.jobData.client}${
                data.jobData.multipleDays ? `-${data.jobData.multipleDays}` : ``
              }${data.jobData.jobTypeShort}`
            );
            setStoryboardsContent(`
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                            ${getGreeting()},
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                It was great to have you in the studio yesterday!
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                              We wanted to touch base with you in regards to the amount of selects from your shoot.
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                             ITS allows for 240 files to be selected and retouched within the package rate and currently I have ${
                               data.jobData.imagesProcessedNumbers
                             } images selected from your shoot.
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                              We are always happy to complete more, however we do charge an additional $14.50++ per file over the 240.
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                          <span style="color: rgb(0, 0, 0); font-size: 14px;">
                           Please let us know if you are happy to move ahead with the current selects, or if you would like to condense your selection please see the steps below:
                          </span>
                        </p>

                        <p><br></p>

                        <p><span style="font-size: 14px;">- Storyboards from the shoot:&nbsp;</span><a
                                href="${storyboardsUrl}" rel="noopener noreferrer"
                                target="_blank"
                                style="font-size: 14px;">Click Here</a></p>
                        <p><br></p>
                        <p><span style="color: rgb(0, 0, 0); font-size: 14px;">- Storyboard Selects (Individual files from the Storyboard):&nbsp;</span><a
                                href="${storyboardSelectsUrl}" rel="noopener noreferrer"
                                target="_blank"
                                style="font-size: 14px;">Click Here</a></p>
                        <p><br></p>
                        <p><span style="color: rgb(0, 0, 0); font-size: 14px;">- Quickproofs (Low Res) of all files:&nbsp;</span><a

                                href="${quickproofsUrl}" rel="noopener noreferrer"
                                target="_blank"
                                style="font-size: 14px;">Click Here</a></p>

                        <p><br></p>

                        <p><u style="font-size: 14px;">STEP 1:</u><span style="font-size: 14px;">&nbsp;Review your Storyboards and confirm if
                                you are happy with the images selects on the day.</span></p>
                        <p><span style="font-size: 14px;">If you are happy with the selected images,</span></p>
                        <p><span style="font-size: 14px;">Please send an email to production@imagesthatsell.com.au confirming the Storyboard
                                images.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">If you want to remove or reselect the images, Please continue to Step 2</span></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 2:</u><span style="font-size: 14px;">&nbsp;Download the Storyboard Selects & Quickproof (Low Res) files
                                from the above link to a folder on your desktop.</span></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 3:</u><span style="font-size: 14px;">&nbsp;Create a selects folder - Drag and drop
                                your selects from the Storyboard Selects / Quickproofs folders to your selects folder.</span></p>
                        <p><span style="font-size: 14px;">Confirm the amount of selects you have ordered is within your daily file
                                allocation.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;"><u>Please Note:</u> The files that are uploaded should be the entire job, and will be the only ones put through post production.</span></p>
                        <p><br></p>
                          <p><u style="font-size: 14px;">STEP 4:</u><span style="font-size: 14px;">&nbsp;Open this link:&nbsp;</span><a
                                href="${selectsUrl}" rel="noopener noreferrer" target="_blank"
                                style="font-size: 14px;">Click Here</a></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 5:</u><span style="font-size: 14px;">&nbsp;Click the
                                “Add Files” button &amp; navigate to your selected images</span></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 6:</u><span style="font-size: 14px;">&nbsp;Click “Open” &amp; wait for your images
                                to upload</span></p>
                        <p><br></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">Once your images have uploaded, Please send us an email letting us know the files have
                                been uploaded &amp; how many there are so the retouching process can begin.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">If your selects are sent through before midday, your due date will be 5 business days
                                from the day we receive your selects,</span></p>
                        <p><span style="font-size: 14px;">otherwise it will be 6 business days.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">production@imagesthatsell.com.au will confirm the receipt of your selects and will
                                provide the due date via email.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">If you have any questions, please don't hesitate to email
                                production@imagesthatsell.com.au</span></p>
                        <p><br><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                Thanks,
                            </span>
                        </p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                Team ITS!
                            </span>
                        </p>
                        `);
            setAttachments([
              {
                filename: "Team ITS.gif",
                path: "https://www.dropbox.com/s/opufie5u7hh12z8/Team%20ITS.gif?raw=1",
              },
            ]);
          }

          // if (emailKind === "cod") {
          //   setCodContent(`<p class="graf graf--p">${getGreeting()},</p>
          //           <p class="graf graf--p"></p>
          //           <p class="graf graf--p">Your latest job is complete - ${subject} -</p>
          //           <p class="graf graf--p"></p>
          //           <p class="graf graf--p"><span style="color: #000000">Accounts will supply you with your invoice shortly.</span></p>
          //           <p class="graf graf--p"></p>
          //           <p class="graf graf--p"><span style="color: #000000">For immediate file supply please email production@imagesthatsell.com.au remittance.</span></p>
          //           <p class="graf graf--p"></p>
          //           <p class="graf graf--p"><span style="color: #000000">Thank you very much for your business.</span></p>
          //           <p class="graf graf--p"><span style="color: #000000">Team ITS!</span></p>
          //           `);
          //   setAttachments([
          //     {
          //       filename: "Team ITS.gif",
          //       path: "https://www.dropbox.com/s/opufie5u7hh12z8/Team%20ITS.gif?raw=1",
          //     },
          //   ]);
          // }
          if (emailKind === "storyboards") {
            setCcEmails([{ email: "jobs@imagesthatsell.com.au" }]);

            const path = `/${data.jobData.client}/${data.jobData.jobNumber}-${
              data.jobData.client
            }${data.jobData.jobTypeShort}${
              data.jobData.multipleDays ? `/${data.jobData.multipleDays}` : ``
            }/Storyboards`;
            const url = await getDropboxLink(path);
            setStoryboardsContent(`
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                            ${getGreeting()},
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                It was great to have you in the studio today!
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                Here is the direct link to the Storyboards from the shoot:
                            </span>
                        </p>
                        <a href="${url}" rel="noopener noreferrer"
                        target="_blank" style="font-size: 14px;">
                        ${url}
                        </a>
                        <p><br></p>
                        <p>
                        <span style="color: rgb(0, 0, 0); font-size: 14px;">
                            Please double check every file you want has been selected and is on the Storyboard.<br>
                            If you wish to reselect files or put the job on hold please inform <a href = "mailto: production@imagesthatsell.com.au">production@imagesthatsell.com.au</a><br>
                            as soon as possible otherwise we will be starting post production the day after the shoot.
                        </span>
                        </p>
                        <p><br><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                Thanks,
                            </span>
                        </p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                Team ITS!
                            </span>
                        </p>
                        `);
            setAttachments([
              {
                filename: "Team ITS.gif",
                path: "https://www.dropbox.com/s/opufie5u7hh12z8/Team%20ITS.gif?raw=1",
              },
            ]);
          }
          if (emailKind === "storyboardsQuickproofs") {
            setCcEmails([{ email: "jobs@imagesthatsell.com.au" }]);

            const storyboardsPath = `/${data.jobData.client}/${
              data.jobData.jobNumber
            }-${data.jobData.client}${data.jobData.jobTypeShort}${
              data.jobData.multipleDays ? `/${data.jobData.multipleDays}` : ``
            }/Storyboards`;
            const storyboardsUrl = await getDropboxLink(storyboardsPath);
            const quickproofsPath = `/${data.jobData.client}/${
              data.jobData.jobNumber
            }-${data.jobData.client}${data.jobData.jobTypeShort}${
              data.jobData.multipleDays ? `/${data.jobData.multipleDays}` : ``
            }/Quickproofs`;
            const quickproofsUrl = await getDropboxLink(quickproofsPath);
            setStoryboardsContent(`
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                            ${getGreeting()},
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                It was great to have you in the studio today!
                            </span>
                        </p>
                        <p><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                            Here are the direct links from the shoot:
                            </span>
                        
                        <br>
                        Storyboards:<a href="${storyboardsUrl}" rel="noopener noreferrer"
                            target="_blank" style="font-size: 14px;">
                            ${storyboardsUrl}
                        </a>
                        <br>
                        Quickproofs: <a href="${quickproofsUrl}" rel="noopener noreferrer"
                            target="_blank" style="font-size: 14px;">
                            ${quickproofsUrl}
                        </a>
                        </p>
                        <p><br></p>
                        <p>
                        <span style="color: rgb(0, 0, 0); font-size: 14px;">
                            Please double check every file you want has been selected and is on the Storyboard.<br>
                            If you wish to reselect files or put the job on hold please inform <a href = "mailto: production@imagesthatsell.com.au">production@imagesthatsell.com.au</a><br>
                            as soon as possible otherwise we will be starting post production the day after the shoot.
                        </span>
                        </p>
                        <p><br><br></p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                Thanks,
                            </span>
                        </p>
                        <p>
                            <span style="color: rgb(0, 0, 0); font-size: 14px;">
                                Team ITS!
                            </span>
                        </p>
                        `);
            setAttachments([
              {
                filename: "Team ITS.gif",
                path: "https://www.dropbox.com/s/opufie5u7hh12z8/Team%20ITS.gif?raw=1",
              },
            ]);
          }
          if (emailKind === "storyboardsQuickproofsSelects") {
            setCcEmails([{ email: "jobs@imagesthatsell.com.au" }]);

            const basePath = `/${data.jobData.client}/${
              data.jobData.jobNumber
            }-${data.jobData.client}${data.jobData.jobTypeShort}${
              data.jobData.multipleDays ? `/${data.jobData.multipleDays}` : ``
            }`;
            const storyboardsUrl = await getDropboxLink(
              `${basePath}/Storyboards`
            );
            const quickproofsUrl = await getDropboxLink(
              `${basePath}/Quickproofs`
            );
            const selectsUrl = await getDropboxFileRequest(
              `${basePath}/Selects`,
              `${data.jobData.jobNumber}-${data.jobData.client}${
                data.jobData.multipleDays ? `-${data.jobData.multipleDays}` : ``
              }${data.jobData.jobTypeShort}`
            );
            setStoryboardsContent(`
                        <p><span style="font-size: 14px;">${getGreeting()},</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">It was great having you in the studio today!</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">I have outlined the steps below to selecting &amp; supplying your selects to Images
                                That Sell.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">Here are the links from your shoot:</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">- Storyboards from the shoot:&nbsp;</span><a
                                href="${storyboardsUrl}" rel="noopener noreferrer"
                                target="_blank"
                                style="font-size: 14px;">Click Here</a></p>
                        <p><br></p>
                        <p><span style="color: rgb(0, 0, 0); font-size: 14px;">- Quickproofs (Low Res) of all files:&nbsp;</span><a
                                href="${quickproofsUrl}" rel="noopener noreferrer"
                                target="_blank"
                                style="font-size: 14px;">Click Here</a></p>
                        <p><br></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEPS TO ORDER YOUR FILES</u></p>
                        <p><br></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 1:</u><span style="font-size: 14px;">&nbsp;Review your Storyboards and confirm if
                                you are happy with the images selects on the day.</span></p>
                        <p><span style="font-size: 14px;">If you are happy with the selected images,</span></p>
                        <p><span style="font-size: 14px;">Please send an email to production@imagesthatsell.com.au confirming the Storyboard
                                images.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">If you want to reselect the images, Please continue to Step 2</span></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 2:</u><span style="font-size: 14px;">&nbsp;Download the Quickproof (Low Res) files
                                from the above link to a folder on your desktop.</span></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 3:</u><span style="font-size: 14px;">&nbsp;Create a selects folder - Drag and drop
                                your selects from the downloaded folder to your selects folder.</span></p>
                        <p><span style="font-size: 14px;">Confirm the amount of selects you have ordered is within your daily file
                                allocation.</span></p>
                        <p><span style="font-size: 14px;">Please note there is a fee for additional images retouched images.</span></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 4:</u><span style="font-size: 14px;">&nbsp;Open this link:&nbsp;</span><a
                                href="${selectsUrl}" rel="noopener noreferrer" target="_blank"
                                style="font-size: 14px;">Click Here</a></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 5:</u><span style="font-size: 14px;">&nbsp;If you are signed into Dropbox, Click the
                              “Add Files” button &amp; navigate to your selected images</span></p>
                        <p><br></p>
                        <p><u style="font-size: 14px;">STEP 6:</u><span style="font-size: 14px;">&nbsp;Click “Open” &amp; wait for your images
                                to upload</span></p>
                        <p><br></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">Once your images have uploaded, Please send us an email letting us know the files have
                                been uploaded &amp; how many there are so the retouching process can begin.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">If your selects are sent through before midday, your due date will be 5 business days
                                from the day we receive your selects,</span></p>
                        <p><span style="font-size: 14px;">otherwise it will be 6 business days.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">production@imagesthatsell.com.au will confirm the receipt of your selects and will
                                provide the due date via email.</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">If you have any questions, please don't hesitate to email
                                production@imagesthatsell.com.au</span></p>
                        <p><br></p>
                        <p><span style="font-size: 14px;">Team ITS!</span></p>
                        <p><br></p>`);
            setAttachments([
              {
                filename: "Team ITS.gif",
                path: "https://www.dropbox.com/s/opufie5u7hh12z8/Team%20ITS.gif?raw=1",
              },
            ]);
          }
          setLoadingVisible(false);
        });
    }
    if (emailKind === "capturePilot") {
      fetch(
        "https://us-central1-its-connect-main.cloudfunctions.net/returnJobDataClientData",
        {
          method: "POST",
          mode: "cors",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ jobNumber: jobNumber }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then(async (data) => {
          let emails = [];
          data.clientData.contacts.forEach((i) => {
            if (i.isSupply) {
              emails.push(i);
            }
          });
          setToEmails([...emails]);
          setSubject(getJobName(data.jobData));
          setjobData(data.jobData);
          setClientData(data.clientData);
          setLoadingVisible(false);
          setCapturePilotContent(
            `<p>${getGreeting()},</p><p><br></p><p><span style="color: rgb(0, 0, 0);">Here are the details for the shoot today:</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">URL: </span><a href="http://www.imagesthatsell.com.au/remote/index.html?sessionid=${sessionid}" rel="noopener noreferrer" target="_blank">http://www.imagesthatsell.com.au/remote/index.html?sessionid=${sessionid}</a></p><p><span style="color: rgb(0, 0, 0);">Password: </span>${sessionPassword}</p><p><br></p><p><span style="color: rgb(0, 0, 0);">Navigate to the above URL &amp; enter the password.</span></p><p><span style="color: rgb(0, 0, 0);">This should allow you to connect to your shoot</span></p><p><span style="color: rgb(0, 0, 0);">If you have any problems connecting please let us know!</span></p><p><br></p><p><span style="color: rgb(0, 0, 0);">Thanks,</span></p><p><span style="color: rgb(0, 0, 0);">Team ITS</span></p>`
          );
          setAttachments([
            {
              filename: "Team ITS.gif",
              path: "https://www.dropbox.com/s/opufie5u7hh12z8/Team%20ITS.gif?raw=1",
            },
          ]);
        });
    }
    // eslint-disable-next-line
  }, [
    trelloCardID,
    emailKind,
    jobNumber,
    sessionPassword,
    sessionid,
    setCodContent,
    setCapturePilotContent,
  ]);

  useEffect(() => {
    const parsed = queryString.parse(query);
    if (!parsed.kind) {
      setError(true);
      setErrorReason(
        "Unable to get kind of email to be created... did you click me from Trello?"
      );
    }
    // console.log(parsed)
    if (parsed.kind === "outsourcer") {
    }
    if (parsed.trelloCardName) {
      setSubject(parsed.trelloCardName);
      document.title = parsed.trelloCardName;
    }
    if (parsed.kind === "supply") {
      setEmailKind("supply");
      setFromEmail("production@imagesthatsell.com.au");
      setCcEmails([{ email: "accounts@imagesthatsell.com.au" }]);
      setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
    } else if (parsed.kind === "cod") {
      setEmailKind("cod");
      setFromEmail("production@imagesthatsell.com.au");
      setCcEmails([{ email: "accounts@imagesthatsell.com.au" }]);
      setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
    } else if (parsed.kind === "overFileAllocation") {
      setEmailKind("overFileAllocation");
      setSubject(`${parsed.trelloCardName} - Over File Allocation`);
      setFromEmail("production@imagesthatsell.com.au");
      setCcEmails([{ email: "accounts@imagesthatsell.com.au" }]);
      setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
    } else if (parsed.kind.includes("storyboards")) {
      setEmailKind(parsed.kind);
      setFromEmail("production@imagesthatsell.com.au");
      setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
    } else if (parsed.kind === "capturePilot") {
      setEmailKind(parsed.kind);
      setFromEmail("production@imagesthatsell.com.au");
      setReplyToEmails([{ email: "production@imagesthatsell.com.au" }]);
      setJobNumber(parsed.jobNumber);
      setSessionid(parsed.sessionid);
      setSessionPassword(parsed.sessionPassword);
    }
    if (parsed.trelloCardID) {
      setTrelloCardID(parsed.trelloCardID);
    }
  }, [query]);

  const emailContainerMainStyle = {
    width: "100vw",
    zIndex: 100,
  };
  const emailContainerStyle = {
    width: "100%",
    margin: 15,
    marginTop: 20,
    position: "relative",
  };
  const toggleCC = () => setCcVisible(!ccVisible);
  const toggleBCC = () => setBccVisible(!bccVisible);
  const getEmailsFromClientObjectArray = (arr) => {
    let tmpArr = [];
    arr.forEach((c) => {
      if (c.email) {
        tmpArr.push(c.email);
      }
    });
    return tmpArr;
  };

  return (
    <>
      {error ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItem: "center",
            flexDirection: "column",
            padding: "50px",
          }}
        >
          <p>An error occurred</p>
          <p>{errorReason}</p>
        </div>
      ) : (
        <>
          <LoadingFullScreen loadingVisible={loadingVisible} theme={theme} />
          <SuccessFullScreen successVisible={successVisible} theme={theme} />
          <ErrorFullScreen
            errorVisible={errorVisible}
            errorMessage={errorMessage}
            theme={theme}
          />
          <div
            style={{
              background: theme === "dark" ? "#1f1f1f" : "white",
              height: "100%",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <div style={emailContainerMainStyle}>
              <div style={emailContainerStyle}>
                <EmailField
                  emails={toEmails}
                  setEmails={setToEmails}
                  kind={"To"}
                  visible={true}
                  marginRight={20}
                />
                <Divider />
                <EmailField
                  emails={ccEmails}
                  setEmails={setCcEmails}
                  kind={"Cc"}
                  visible={ccVisible}
                  marginRight={0}
                />
                <Divider style={{ display: ccVisible ? "flex" : "none" }} />
                <EmailField
                  emails={bccEmails}
                  setEmails={setBccEmails}
                  kind={"Bcc"}
                  visible={bccVisible}
                  marginRight={0}
                />
                <Divider style={{ display: bccVisible ? "flex" : "none" }} />
                <div
                  style={{
                    width: "100%",
                    marginTop: 5,
                    marginBottom: 5,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p
                      style={{
                        marginTop: 5,
                        marginBottom: 5,
                        marginRight: 10,
                        fontSize: "0.8em",
                      }}
                    >
                      Subject:
                    </p>
                    <TextField
                      size="small"
                      variant="standard"
                      inputProps={{
                        sx: {
                          marginTop: "5px",
                          height: 20,
                          // width: 245,
                          width: "60vw",

                          fontSize: "14px",
                        },
                      }}
                      InputProps={{ disableUnderline: true }}
                      value={subject || ""}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSubject(e.target.value);
                      }}
                    />
                  </div>
                  <ButtonGroup
                    size="small"
                    style={{ height: 24, marginRight: 25 }}
                  >
                    <Button
                      variant={ccVisible ? "contained" : "outlined"}
                      onClick={toggleCC}
                    >
                      CC
                    </Button>
                    <Button
                      variant={bccVisible ? "contained" : "outlined"}
                      onClick={toggleBCC}
                    >
                      BCC
                    </Button>
                  </ButtonGroup>
                </div>
                <Divider />
                <EmailField
                  emails={replyToEmails}
                  setEmails={setReplyToEmails}
                  kind={"Reply To"}
                  visible={true}
                  marginRight={0}
                />
                <Divider />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p
                    style={{
                      marginTop: 5,
                      marginBottom: 5,
                      marginRight: 10,
                      fontSize: "0.8em",
                    }}
                  >
                    From:
                  </p>
                  <TextField
                    size="small"
                    variant="standard"
                    inputProps={{
                      sx: {
                        marginTop: "5px",
                        height: 20,
                        width: 320,
                        fontSize: "14px",
                      },
                    }}
                    InputProps={{ disableUnderline: true }}
                    value={fromEmail || ""}
                    onChange={(e) => {
                      setFromEmail(e.target.value);
                    }}
                  />
                </div>
                <Divider />
              </div>
            </div>
            <Fab
              color="primary"
              style={{
                width: "50px",
                height: "50px",
                zIndex: 200,
                position: "absolute",
                bottom: 14,
                right: 14,
              }}
              onClick={() => {
                setLoadingVisible(true);

                sendEmail({
                  emailBody: parseEmailBody({
                    emailKind,
                    subject,
                    dropboxUrl,
                    supplyCustomText1,
                    supplyCustomText2,
                    supplyCustomText3,
                    codContent,
                    capturePilotContent,
                    storyboardsContent,
                  }),
                  fromEmail,
                  toEmails: getEmailsFromClientObjectArray(toEmails),
                  ccEmails: getEmailsFromClientObjectArray(ccEmails),
                  bccEmails: getEmailsFromClientObjectArray(bccEmails),
                  replyToEmails: getEmailsFromClientObjectArray(replyToEmails),
                  subject,
                  attachments,
                }).then((res) => {
                  console.log(res);
                  if (res.data.error) {
                    setErrorMessage(res.data.errortext);
                    setErrorVisible(true);
                  } else {
                    setSuccessVisible(true);
                    if (emailKind === "cod") {
                      addLabelToCard(
                        jobData.trelloCardID,
                        "5f0e8644f6ee37295e76981e"
                      );
                    } else if (emailKind === "supply") {
                      if (suppliedLinks.length === 0) {
                        setSuppliedLinks([
                          { jobNumber: jobData.jobNumber, url: dropboxUrl },
                        ]);
                        const arr = [
                          { jobNumber: jobData.jobNumber, url: dropboxUrl },
                        ];
                        firebase
                          .firestore()
                          .collection("ClientDatabase")
                          .doc(clientData.id)
                          .update({ suppliedLinks: arr }, { merge: true })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        let contains = false;
                        suppliedLinks.forEach((sl) => {
                          if (
                            sl.jobNumber === jobData.jobNumber &&
                            sl.url === dropboxUrl
                          ) {
                            contains = true;
                          }
                        });
                        console.log(jobData);
                        if (!contains) {
                          setSuppliedLinks([
                            ...suppliedLinks,
                            {
                              jobNumber: jobData.jobNumber,
                              url: dropboxUrl,
                              id: jobData.id,
                              jobTypeFull: jobData.jobTypeFull,
                            },
                          ]);
                          const arr = [
                            ...suppliedLinks,
                            {
                              jobNumber: jobData.jobNumber,
                              url: dropboxUrl,
                              id: jobData.id,
                              jobTypeFull: jobData.jobTypeFull,
                            },
                          ];
                          firebase
                            .firestore()
                            .collection("ClientDatabase")
                            .doc(clientData.id)
                            .update({ suppliedLinks: arr }, { merge: true })
                            .catch((error) => {
                              console.log(error);
                            });
                        }
                      }
                    }
                    setTimeout(() => {
                      // window.close("", "_parent", "");
                    }, 4000);
                  }
                  setLoadingVisible(false);
                });
              }}
            >
              <SendIcon
                sx={{ marginLeft: "4px", width: "30px", height: "30px" }}
              />
            </Fab>
            <div
              style={{
                width: "100%",
                height: "76vh",
                overflow: "auto",
                marginTop: 0,
                position: "relative",
              }}
            >
              <div
                style={
                  {
                    // overflow: 'auto',
                  }
                }
              >
                {subject ? (
                  emailKind !== "cod" ? (
                    <EmailBody
                      kind={emailKind}
                      subject={subject}
                      dropboxUrl={dropboxUrl}
                      setSupplyCustomText1={setSupplyCustomText1}
                      setSupplyCustomText2={setSupplyCustomText2}
                      setSupplyCustomText3={setSupplyCustomText3}
                      capturePilotContent={capturePilotContent}
                      setCapturePilotContent={setCapturePilotContent}
                      codContent={codContent}
                      setCodContent={setCodContent}
                      storyboardsContent={storyboardsContent}
                      setStoryboardsContent={setStoryboardsContent}
                    />
                  ) : emailKind === "cod" ? (
                    <EmailBody
                      kind={emailKind}
                      subject={subject}
                      dropboxUrl={dropboxUrl}
                      setSupplyCustomText1={setSupplyCustomText1}
                      setSupplyCustomText2={setSupplyCustomText2}
                      setSupplyCustomText3={setSupplyCustomText3}
                      capturePilotContent={capturePilotContent}
                      setCapturePilotContent={setCapturePilotContent}
                      codContent={codContent}
                      setCodContent={setCodContent}
                      storyboardsContent={storyboardsContent}
                      setStoryboardsContent={setStoryboardsContent}
                    />
                  ) : emailKind === "overFileAllocation" ? (
                    <EmailBody
                      kind={emailKind}
                      subject={subject}
                      dropboxUrl={dropboxUrl}
                      setSupplyCustomText1={setSupplyCustomText1}
                      setSupplyCustomText2={setSupplyCustomText2}
                      setSupplyCustomText3={setSupplyCustomText3}
                      capturePilotContent={capturePilotContent}
                      setCapturePilotContent={setCapturePilotContent}
                      codContent={codContent}
                      setCodContent={setCodContent}
                      storyboardsContent={storyboardsContent}
                      setStoryboardsContent={setStoryboardsContent}
                    />
                  ) : capturePilotContent ? (
                    <EmailBody
                      kind={"capturePilot"}
                      subject={subject}
                      dropboxUrl={dropboxUrl}
                      setSupplyCustomText1={setSupplyCustomText1}
                      setSupplyCustomText2={setSupplyCustomText2}
                      setSupplyCustomText3={setSupplyCustomText3}
                      capturePilotContent={capturePilotContent}
                      setCapturePilotContent={setCapturePilotContent}
                      codContent={codContent}
                      setCodContent={setCodContent}
                      storyboardsContent={storyboardsContent}
                      setStoryboardsContent={setStoryboardsContent}
                    />
                  ) : null
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
// overFileAllocation
export default EmailModal;

const LoadingFullScreen = ({ loadingVisible, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: loadingVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <CircularProgress style={loading.progress} />
      </Box>
    </div>
  );
};
const SuccessFullScreen = ({ successVisible, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: successVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <CheckCircleOutlineIcon color="success" style={loading.progress} />

        <span style={{ marginTop: 50 }}>Email Sent!</span>
      </Box>
    </div>
  );
};

const ErrorFullScreen = ({ errorVisible, errorMessage, theme }) => {
  const loading = {
    box: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
    },
    progress: {
      width: 90,
      height: 90,
    },
  };
  return (
    <div
      style={{
        display: errorVisible ? "block" : "none",
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999,
        backgroundColor: theme === "dark" ? "#1f1f1f" : "#f4f5f7",
      }}
    >
      <Box sx={loading.box}>
        <ErrorOutlineIcon color="error" style={loading.progress} />

        <span style={{ marginTop: 40 }}>{errorMessage}</span>
        <Button
          onClick={() => window.location.reload()}
          style={{ marginTop: 30 }}
          variant="outlined"
        >
          Retry
        </Button>
      </Box>
    </div>
  );
};

async function getDropboxFileRequest(path, title) {
  await dbx
    .filesCreateFolderV2({
      path: path,
      autorename: false,
    })
    .catch((error) => {
      if (error.error.error_summary.includes("path/conflict/folder")) {
        console.log(
          "technically im an error, but im an expected error so its all g bro"
        );
        console.log(
          "all im saying is, there was already a folder with that name, all good"
        );
      } else console.error(JSON.stringify(error));
    });
  let existingRequestURL;
  const existingRequests = await dbx.fileRequestsListV2().catch((error) => {
    console.log(error);
    return { error: true, errorText: "Could not connect to Dropbox..." };
  });
  existingRequests.result.file_requests.forEach((req) => {
    if (req.is_open) {
      if (req.title === title) {
        existingRequestURL = req.url;
      }
    }
  });
  if (existingRequestURL) return existingRequestURL;
  const res = await dbx
    .fileRequestsCreate({
      title: title,
      destination: path,
      open: true,
    })
    .catch(function (error) {
      console.error(JSON.stringify(error));
      return { error: true, errorText: error.error.error_summary };
    });
  console.log(res);
  return res.result.url;
}

async function getDropboxLink(path) {
  const existingLinkRes = await dbx
    .sharingListSharedLinks({
      path: path,
      direct_only: true,
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      return {
        error: true,
        errorText: error.error.error_summary,
        result: { links: [] },
      };
    });
  if (!existingLinkRes) {
    return { error: true, errorText: "Could not connect to Dropbox..." };
  }

  if (existingLinkRes.error) {
    if (existingLinkRes.errorText.includes("path/not_found")) {
      console.log("path not found");
      const createFolderRes = await dbx
        .filesCreateFolderV2({
          path: path,
          autorename: false,
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          return { error: true, errorText: error.error.error_summary };
        });
      if (createFolderRes.error) {
        return createFolderRes;
      }
    } else return existingLinkRes;
  }

  if (existingLinkRes.result.links.length === 0) {
    const createLinkRes = await dbx
      .sharingCreateSharedLinkWithSettings({
        path: path,
        settings: {
          requested_visibility: "public",
          audience: "public",
          access: "viewer",
        },
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        return { error: true, errorText: error.error.error_summary };
      });
    return createLinkRes.result.url;
  } else {
    return existingLinkRes.result.links[0].url;
  }
}

const EmailBody = ({
  kind,
  subject,
  dropboxUrl,
  setSupplyCustomText1,
  setSupplyCustomText2,
  setSupplyCustomText3,
  codContent,
  setCodContent,
  capturePilotContent,
  setCapturePilotContent,
  storyboardsContent,
  setStoryboardsContent,
}) => {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [
        {
          size: [
            "14px",
            "12px",
            "16px",
            "18px",
            "20px",
            "22px",
            "24px",
            "26px",
            "28px",
            "30px",
          ],
        },
      ],
      [{ font: [] }],
      [{ align: [] }],
      ["link"],
      ["clean"],
    ],
  };

  return (
    <>
      {kind === "supply" ? (
        <>
          <table
            className="main-wrapper"
            border="0"
            cellPadding="0"
            cellSpacing="0"
            width="620"
            align="center"
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              tableLayout: "fixed",
              borderTop: "1px solid #e9e9e9",
              borderRight: "1px solid #e9e9e9",
              borderBottom: "1px solid #e9e9e9",
              borderLeft: "1px solid #e9e9e9",
            }}
          >
            <tbody>
              <tr style={{ lineHeight: "20px" }}>
                <td>&zwnj;&nbsp;</td>
              </tr>

              <tr align="center">
                <td>
                  <a
                    href="https://www.imagesthatsell.com.au"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{ width: "90px" }}
                      alt="Logo"
                      src="https://www.imagesthatsell.com.au/ITS_LOGO_BLACK%20BLACK.jpg"
                    />
                  </a>
                </td>
              </tr>
              <tr style={{ lineHeight: "30px" }}>
                <td>&zwnj;&nbsp;</td>
              </tr>
              <tr align="center" style={{ lineHeight: "40px" }}>
                <td>
                  <p>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Black-Custom, Helvetica, sans-serif",
                        fontSize: "32px",
                      }}
                    >
                      THANK YOU SO MUCH
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Black-Custom, Helvetica, sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      WE REALLY APPRECIATE YOUR BUSINESS
                    </span>
                  </p>
                </td>
              </tr>
              <tr align="center" style={{ lineHeight: "40px" }}>
                <td>
                  <p
                    style={{
                      lineHeight: "23px",
                      border: "1px cornflowerblue",
                      borderStyle: "dashed",
                    }}
                  >
                    <span
                      onInput={(e) => {
                        setSupplyCustomText1(e.target.textContent);
                      }}
                      contentEditable=""
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      &zwnj;&zwnj;&zwnj;&zwnj;&zwnj;
                    </span>
                  </p>
                </td>
              </tr>
              <tr align="center" style={{ lineHeight: "40px" }}>
                <td>
                  <span>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      Your latest job is complete:
                    </span>

                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      {subject}
                    </span>
                    <p
                      style={{
                        lineHeight: "23px",
                        border: "1px cornflowerblue",
                        borderStyle: "dashed",
                      }}
                    >
                      <span
                        onInput={(e) => {
                          setSupplyCustomText2(e.target.textContent);
                        }}
                        contentEditable=""
                        style={{
                          fontFamily:
                            "Avenir-Light-Custom, Helvetica, sans-serif",
                          fontSize: "18px",
                          display: "block",
                        }}
                      >
                        &zwnj;&zwnj;&zwnj;&zwnj;&zwnj;
                      </span>
                    </p>
                    <span style={{ lineHeight: "23px" }}>
                      <span
                        style={{
                          fontFamily:
                            "Avenir-Light-Custom, Helvetica, sans-serif",
                          fontSize: "18px",
                          display: "block",
                        }}
                      >
                        The files are currently uploading to Dropbox and
                      </span>
                      <span
                        style={{
                          fontFamily:
                            "Avenir-Light-Custom, Helvetica, sans-serif",
                          fontSize: "18px",
                          display: "block",
                        }}
                      >
                        should be available for download within the next hour.
                      </span>
                      <span
                        style={{
                          fontFamily:
                            "Avenir-Light-Custom, Helvetica, sans-serif",
                          fontSize: "18px",
                          display: "block",
                        }}
                      >
                        Please find the direct link below:
                      </span>
                    </span>
                  </span>
                </td>
              </tr>
              <tr align="center" style={{ lineHeight: "40px" }}>
                <td>
                  <p
                    style={{
                      lineHeight: "23px",
                      border: "1px cornflowerblue",
                      borderStyle: "dashed",
                    }}
                  >
                    <span
                      onInput={(e) => {
                        setSupplyCustomText3(e.target.textContent);
                      }}
                      contentEditable=""
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      &zwnj;&zwnj;&zwnj;&zwnj;&zwnj;
                    </span>
                  </p>
                  &zwnj;&nbsp;
                </td>
              </tr>
              <tr align="center" style={{ lineHeight: "40px" }}>
                <td>
                  <a
                    href={dropboxUrl}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Heavy-Custom, Helvetica, sans-serif",
                        display: "block",
                        fontSize: "15px",
                        backgroundColor: "black",
                        color: "white",
                        border: "none",
                        height: "35px",
                        width: "250px",
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      CLICK HERE
                    </span>
                  </a>
                </td>
              </tr>
              <tr style={{ lineHeight: "50px" }}>
                <td>&zwnj;&nbsp;</td>
              </tr>
              <tr align="center" style={{ lineHeight: "30px" }}>
                <td>
                  <span style={{ lineHeight: "23px" }}>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      Let us know if you have any trouble locating the files or
                      if
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      you have any questions at all.
                    </span>
                  </span>
                  <br />
                  <span style={{ lineHeight: "23px" }}>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      Thank you so much,
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: "18px",
                        display: "block",
                      }}
                    >
                      Team ITS
                    </span>
                  </span>
                </td>
              </tr>
              <tr style={{ lineHeight: "20px" }}>
                <td>&zwnj;&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </>
      ) : kind === "cod" ? (
        <>
          <table
            class="main-wrapper"
            border="0"
            cellpadding="0"
            cellspacing="0"
            width="620"
            align="center"
            style={{
              paddingLeft: 20,
              paddingRight: 20,
              tableLayout: "fixed",
              borderTop: "1px solid #e9e9e9",
              borderRight: "1px solid #e9e9e9",
              borderBottom: "1px solid #e9e9e9",
              borderLeft: "1px solid #e9e9e9",
            }}
          >
            <tr style={{ lineHight: 20 }}>
              <td>&zwnj;&nbsp;</td>
            </tr>

            <tr align="center">
              <td>
                <a
                  href="https://www.imagesthatsell.com.au"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    style={{ width: 90 }}
                    src="https://www.imagesthatsell.com.au/ITS_LOGO_BLACK%20BLACK.jpg"
                    alt="logo"
                  />
                </a>
              </td>
            </tr>
            <tr style={{ lineHight: 30 }}>
              <td>&zwnj;&nbsp;</td>
            </tr>
            <tr align="center" style={{ lineHight: 40 }}>
              <td>
                <p>
                  <span
                    style={{
                      fontFamily: "Avenir-Black-Custom, Helvetica, sans-serif",
                      fontSize: 32,
                    }}
                  >
                    YOUR FILES ARE READY!
                  </span>
                </p>
              </td>
            </tr>
            <tr align="center" style={{ lineHeight: 1 }}>
              <td>
                <p
                  style={{
                    lineHeight: "23px",
                    border: "1px cornflowerblue",
                    borderStyle: "dashed",
                  }}
                >
                  <span
                    onInput={(e) => {
                      setSupplyCustomText1(e.target.textContent);
                    }}
                    contentEditable=""
                    style={{
                      fontFamily: "Avenir-Light-Custom, Helvetica, sans-serif",
                      fontSize: "18px",
                      display: "block",
                    }}
                  >
                    &zwnj;&zwnj;&zwnj;&zwnj;&zwnj;
                  </span>
                </p>
                &zwnj;&nbsp;
              </td>
            </tr>
            <tr align="center" style={{ lineHight: 40 }}>
              <td>
                <p>
                  <span
                    style={{
                      fontFamily: "Avenir-Light-Custom, Helvetica, sans-serif",
                      fontSize: 18,
                      display: "block",
                    }}
                  >
                    Your latest job is complete:
                  </span>
                  <span
                    style={{
                      fontFamily: "Avenir-Heavy-Custom, Helvetica, sans-serif",
                      fontSize: 18,
                      display: "block",
                      lineHeight: 4,
                    }}
                  >
                    {subject}
                  </span>
                </p>
              </td>
            </tr>
            <tr align="center" style={{ lineHeight: 0 }}>
              <td>
                <p
                  style={{
                    lineHeight: "23px",
                    border: "1px cornflowerblue",
                    borderStyle: "dashed",
                  }}
                >
                  <span
                    onInput={(e) => {
                      setSupplyCustomText2(e.target.textContent);
                    }}
                    contentEditable=""
                    style={{
                      fontFamily: "Avenir-Light-Custom, Helvetica, sans-serif",
                      fontSize: "18px",
                      display: "block",
                    }}
                  >
                    &zwnj;&zwnj;&zwnj;&zwnj;&zwnj;
                  </span>
                </p>
                &zwnj;&nbsp;
              </td>
            </tr>
            <tr align="center" style={{ lineHeight: 10 }}>
              <td>
                <p>
                  <span
                    style={{ lineHeight: 3, marginTop: 20, background: "red" }}
                  >
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: 18,
                        display: "block",
                      }}
                    >
                      Accounts will supply you with your invoice shortly.
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: 18,
                        display: "block",
                      }}
                    >
                      For immediate file supply please{" "}
                      <span style={{ fontWeight: 800 }}>email a remittace</span>{" "}
                      to
                    </span>
                    <span
                      style={{
                        fontFamily:
                          "Avenir-Light-Custom, Helvetica, sans-serif",
                        fontSize: 18,
                        display: "block",
                      }}
                    >
                      <a
                        style={{ color: "black", fontWeight: 800 }}
                        href="mailto: production@imagesthatsell.com.au"
                      >
                        production@imagesthatsell.com.au
                      </a>
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr align="center" style={{ lineHeight: 2 }}>
              <td>
                <p
                  style={{
                    lineHeight: "23px",
                    border: "1px cornflowerblue",
                    borderStyle: "dashed",
                  }}
                >
                  <span
                    onInput={(e) => {
                      setSupplyCustomText3(e.target.textContent);
                    }}
                    contentEditable=""
                    style={{
                      fontFamily: "Avenir-Light-Custom, Helvetica, sans-serif",
                      fontSize: "18px",
                      display: "block",
                    }}
                  >
                    &zwnj;&zwnj;&zwnj;&zwnj;&zwnj;
                  </span>
                </p>
                &zwnj;&nbsp;
              </td>
            </tr>
            <tr align="center" style={{ lineHight: 30 }}>
              <td>
                <span style={{ lineHight: 23 }}>
                  <span
                    style={{
                      fontFamily: "Avenir-Light-Custom, Helvetica, sans-serif",
                      fontSize: 18,
                      display: "block",
                    }}
                  >
                    Thanks so much!
                  </span>
                  <p></p>
                  <span
                    style={{
                      fontFamily: "Avenir-Light-Custom, Helvetica, sans-serif",
                      fontSize: 18,
                      display: "block",
                    }}
                  >
                    ITS Team
                  </span>
                </span>
              </td>
            </tr>
            <tr style={{ lineHight: 20 }}>
              <td>&zwnj;&nbsp;</td>
            </tr>
          </table>
          {/* <div
            style={{
              // width: '100vw',
              // height: '100vh',
              display: "flex",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <div style={{ width: "95vw" }}>
              <DanteEditor
                content={codContent}
                onUpdate={(e) => {
                  console.log(e);
                  setCodContent(e.getHTML());
                }}
              />
              <br />
              <img alt="logo" src={returnTeamITSsig()}></img>
            </div>
          </div> */}
        </>
      ) : kind === "capturePilot" ? (
        <>
          <div style={{ marginBottom: 0 }}>
            <ReactQuill
              modules={modules}
              style={{ height: "70vh" }}
              theme="snow"
              value={capturePilotContent}
              onChange={setCapturePilotContent}
            />
          </div>
        </>
      ) : kind.includes("storyboards") ? (
        <>
          <div style={{ marginBottom: 0 }}>
            <ReactQuill
              modules={modules}
              style={{ height: "70vh" }}
              theme="snow"
              value={storyboardsContent}
              onChange={setStoryboardsContent}
            />
          </div>
        </>
      ) : kind.includes("overFileAllocation") ? (
        <>
          <div style={{ marginBottom: 0 }}>
            <ReactQuill
              modules={modules}
              style={{ height: "70vh" }}
              theme="snow"
              value={storyboardsContent}
              onChange={setStoryboardsContent}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

const EmailField = ({ emails, setEmails, kind, visible, marginRight }) => {
  const [newEmail, setNewEmail] = useState("");
  const [newEmailVisible, setNewEmailVisible] = useState(false);
  const [newEmailError, setNewEmailError] = useState(false);
  const [newEmailButtonVisible, setNewEmailButtonVisible] = useState(true);

  const newEmailRef = useRef(newEmail);
  newEmailRef.current = newEmail;

  const handleDeleteEmailChip = (index) => {
    const tmpClients = [...emails];
    tmpClients.splice(index, 1);
    setEmails([...tmpClients]);
  };
  const handleNewEmailClick = () => {
    setNewEmailButtonVisible(false);
    setNewEmailVisible(true);
    setTimeout(() => {
      if (!newEmailRef.current) {
        setNewEmailButtonVisible(true);
        setNewEmailVisible(false);
        setNewEmailError(false);
      }
    }, 10000);
  };

  const confirmNewEmail = () => {
    if (!newEmailError) {
      setEmails([...emails, { email: newEmail }]);
      setNewEmailVisible(false);
      setNewEmailButtonVisible(true);
      setNewEmail("");
    }
  };

  const emailsContainerStyle = {
    minHeight: 10,
    display: visible ? "flex" : "none",
    marginBottom: 2,
    marginTop: 2,
    marginRight: marginRight,
    alignItems: "center",
  };
  return (
    <div style={emailsContainerStyle}>
      <p
        style={{
          marginTop: 8,
          marginBottom: 8,
          marginRight: 10,
          fontSize: "0.8em",
        }}
      >
        {kind}:
      </p>
      <div style={{ marginTop: 0 }}>
        <Stack direction="row" spacing={1}>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            {emails &&
              emails.map((toEmail, i) => {
                if (toEmail.email) {
                  return (
                    <Tooltip
                      key={`EmailChipToolTip-${i}`}
                      title={toEmail.email}
                    >
                      <Chip
                        style={{
                          margin: 2,
                          fontSize: "0.6em",
                        }}
                        size="small"
                        key={`EmailChip-${i}`}
                        label={toEmail.name || toEmail.email}
                        onDelete={() => {
                          handleDeleteEmailChip(i);
                        }}
                      />
                    </Tooltip>
                  );
                } else return null;
              })}
            <TextField
              style={{
                marginLeft: 4,
                marginTop: 3,
                marginBottom: 2,
                display: newEmailVisible ? "block" : "none",
              }}
              size="small"
              inputRef={(input) => {
                if (input) {
                  newEmailVisible && input.focus();
                }
              }}
              inputProps={{ sx: { height: 10, width: 190, fontSize: "11px" } }}
              error={newEmailError}
              value={newEmail || ""}
              onChange={(e) => {
                setNewEmail(e.target.value);
                // eslint-disable-next-line
                if (
                  e.target.value.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
                ) {
                  setNewEmailError(false);
                } else setNewEmailError(true);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  confirmNewEmail();
                }
              }}
            />
            <IconButton
              sx={{ width: 26, height: 26 }}
              style={{ display: newEmailButtonVisible ? "flex" : "none" }}
              onClick={handleNewEmailClick}
            >
              <AddCircleIcon color="primary" sx={{ width: 20 }} />
            </IconButton>
          </div>
        </Stack>
      </div>
    </div>
  );
};

const parseEmailBody = (data) => {
  if (data.emailKind === "supply") {
    return `<!DOCTYPE html>
    <html lang="en">
    
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>ITS File Supply</title>
        <style>
            @font-face {
                font-family: 'Avenir-Black-Custom';
                src: url('https://www.imagesthatsell.com.au/email-fonts/Avenir-Black.woff2') format('woff2'),
                    url('https://www.imagesthatsell.com.au/email-fonts/Avenir-Black.ttf') format('truetype');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
    
            @font-face {
                font-family: 'Avenir-Heavy-Custom';
                src: url('https://www.imagesthatsell.com.au/email-fonts/Avenir-Heavy.woff2') format('woff2'),
                    url('https://www.imagesthatsell.com.au/email-fonts/Avenir-Heavy.ttf') format('truetype');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
    
            @font-face {
                font-family: 'Avenir-Light-Custom';
                src: url('https://www.imagesthatsell.com.au/email-fonts/Avenir-Light.woff2') format('woff2'),
                    url('https://www.imagesthatsell.com.au/email-fonts/Avenir-Light.ttf') format('truetype');
                font-weight: normal;
                font-style: normal;
                font-display: swap;
            }
        </style>
    </head>
    
    <body style="color: #343434; font-weight: 300; font-size: 16px; line-height: 25px; margin:0;padding:20px">
        <div style="display: none; max-height: 0px; overflow: hidden;">
            Your files are complete and ready for download.
        </div>
    
        <!-- Insert &zwnj;&nbsp; hack after hidden preview text -->
        <div style="display: none; max-height: 0px; overflow: hidden;">
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
            &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
        </div>
    
    
        <table class="main-wrapper" border="0" cellpadding="0" cellspacing="0" width="620" align="center" style="
            padding-left:20px;
            padding-right:20px;
            table-layout: fixed;
            border-top: 1px solid #e9e9e9;
            border-right: 1px solid #e9e9e9;
            border-bottom: 1px solid #e9e9e9;
            border-left: 1px solid #e9e9e9;">
    
            <tr style="line-height: 20px;">
                <td>
                    &zwnj;&nbsp;
                </td>
            </tr>
    
            <tr align="center">
                <td>
                    <a href="https://www.imagesthatsell.com.au" target="_blank">
                        <img style="width:90px" src="https://www.imagesthatsell.com.au/ITS_LOGO_BLACK%20BLACK.jpg">
                    </a>
                </td>
            </tr>
            <tr style="line-height: 30px;">
                <td>
                    &zwnj;&nbsp;
                </td>
            </tr>
            <tr align="center" style="line-height: 40px;">
                <td>
                    <p>
                        <span style="font-family: Avenir-Black-Custom, Helvetica, sans-serif; font-size: 32px;">
                            THANK YOU SO MUCH
                        </span>
                        <br>
                        <span style="font-family: Avenir-Black-Custom, Helvetica, sans-serif; font-size: 16px;">
                            WE REALLY APPRECIATE YOUR BUSINESS
                        </span>
                    </p>
                </td>
            </tr>
            <tr align="center" style="line-height: 40px;">
                <td>
                    &zwnj;&nbsp;
                    <p style="line-height: 23px;">
                        <span 
                            style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px;display: block;">
                            ${data.supplyCustomText1}
                        </span>
                    </p>
                </td>
            </tr>
            <tr align="center" style="line-height: 40px;">
                <td>
                    <p>
                        <span
                            style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px;display: block;">
                            Your latest job is complete:
                        </span>
    
                        <span
                            style="font-family: Avenir-Heavy-Custom, Helvetica, sans-serif; font-size: 18px;display: block;">
                            ${data.subject}
                        </span>
                        <p style="line-height: 23px;">
                            <span 
                                style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px;display: block;">
                                ${data.supplyCustomText2}
                            </span>
                        </p>
                        <span style="line-height: 23px;">
                            <span
                                style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px; display: block;">
                                The files are currently uploading to Dropbox and
                            </span>
                            <span
                                style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px; display: block;">
                                should be available for download within the next hour.
                            </span>
                            <span
                                style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px; display: block;">
                                Please find the direct link below:
                            </span>
                        </span>
                    </p>
                </td>
            </tr>
            <tr align="center" style="line-height: 40px;">
                <td>
                    <p style="line-height: 23px;">
                        <span 
                            style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px;display: block;">
                            ${data.supplyCustomText3}
                        </span>
                    </p>
                    &zwnj;&nbsp;
                </td>
            </tr>
            <tr align="center" style="line-height: 40px;">
                <td>
                    <a href="${data.dropboxUrl}" target="_blank"
                        style="text-decoration:none;">
                        <span style="font-family: Avenir-Heavy-Custom, Helvetica, sans-serif;
                    display: block;
                    font-size: 15px;
                    background-color: black;
                    color: white;
                    border: none;
                    height: 35px;
                    width: 250px;
                    cursor: pointer;
                    text-decoration:none;">
                            CLICK HERE
                        </span>
                    </a>
                </td>
            </tr>
            <tr style="line-height: 50px;">
                <td>
                    &zwnj;&nbsp;
                </td>
            </tr>
            <tr align="center" style="line-height: 30px;">
                <td>
                    <span style="line-height: 23px;">
                        <span
                            style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px; display: block;">
                            Let us know if you have any trouble locating the files or if
                        </span>
                        <span
                            style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px; display: block;">
                            you have any questions at all.
                        </span>
                    </span>
                    <br>
                    <span style="line-height: 23px;">
                        <span
                            style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px; display: block;">
                            Thank you so much,
                        </span>
                        <span
                            style="font-family: Avenir-Light-Custom, Helvetica, sans-serif; font-size: 18px; display: block;">
                            Team ITS
                        </span>
                    </span>
                </td>
            </tr>
            <tr style="line-height: 20px;">
                <td>
                    &zwnj;&nbsp;
                </td>
            </tr>
        </table>
    </body>
    
    </html>`;
  } else if (data.emailKind === "cod") {
    // let content = data.codContent;
    // let f = `<!DOCTYPE html>
    //     <html lang="en">
    //         <head>
    //             <meta charset="UTF-8">
    //             <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
    //             <meta name="viewport" content="width=device-width, initial-scale=1.0">
    //             <title>ITS COD</title>
    //             <style>
    //                 .graf {
    //                     margin-top:0 !important;
    //                     line-height: 20px;
    //                 }
    //             </style>
    //         </head>
    //         <body>
    //         <div
    //         style="font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px;">
    //
    //                 ${content}
    //                 </div>
    //         </body>
    //     </html>`;
    // return f;

    return `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>ITS COD</title>
        <style>
          @font-face {
            font-family: "Avenir-Black-Custom";
            src: url("https://www.imagesthatsell.com.au/email-fonts/Avenir-Black.woff2")
                format("woff2"),
              url("https://www.imagesthatsell.com.au/email-fonts/Avenir-Black.ttf")
                format("truetype");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
    
          @font-face {
            font-family: "Avenir-Heavy-Custom";
            src: url("https://www.imagesthatsell.com.au/email-fonts/Avenir-Heavy.woff2")
                format("woff2"),
              url("https://www.imagesthatsell.com.au/email-fonts/Avenir-Heavy.ttf")
                format("truetype");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
    
          @font-face {
            font-family: "Avenir-Light-Custom";
            src: url("https://www.imagesthatsell.com.au/email-fonts/Avenir-Light.woff2")
                format("woff2"),
              url("https://www.imagesthatsell.com.au/email-fonts/Avenir-Light.ttf")
                format("truetype");
            font-weight: normal;
            font-style: normal;
            font-display: swap;
          }
        </style>
      </head>
    
      <body
        style="
          color: #343434;
          font-weight: 300;
          font-size: 16px;
          line-height: 25px;
          margin: 0;
          padding: 20px;
        "
      >
        <div style="display: none; max-height: 0px; overflow: hidden">
          Please send through remittance for immediate supply.
        </div>
    
        <!-- Insert &zwnj;&nbsp; hack after hidden preview text -->
        <div style="display: none; max-height: 0px; overflow: hidden">
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
          &nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;&nbsp;&zwnj;
        </div>
    
        <table
          class="main-wrapper"
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="620"
          align="center"
          style="
            padding-left: 20px;
            padding-right: 20px;
            table-layout: fixed;
            border-top: 1px solid #e9e9e9;
            border-right: 1px solid #e9e9e9;
            border-bottom: 1px solid #e9e9e9;
            border-left: 1px solid #e9e9e9;
          "
        >
          <tr style="line-height: 20px">
            <td>&zwnj;&nbsp;</td>
          </tr>
    
          <tr align="center">
            <td>
              <a href="https://www.imagesthatsell.com.au" target="_blank">
                <img
                  style="width: 90px"
                  src="https://www.imagesthatsell.com.au/ITS_LOGO_BLACK%20BLACK.jpg"
                />
              </a>
            </td>
          </tr>
          <tr style="line-height: 30px">
            <td>&zwnj;&nbsp;</td>
          </tr>
          <tr align="center" style="line-height: 40px">
            <td>
              <p>
                <span
                  style="
                    font-family: Avenir-Black-Custom, Helvetica, sans-serif;
                    font-size: 32px;
                  "
                >
                  YOUR FILES ARE READY!
                </span>
              </p>
            </td>
          </tr>
          <tr align="center" style="line-height: 0px">
            <td>
              &zwnj;&nbsp;
              <p style="line-height: 23px">
                <span
                  style="
                    font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                    font-size: 18px;
                    display: block;
                  "
                >
                  ${data.supplyCustomText1}
                </span>
              </p>
            </td>
          </tr>
          <tr align="center" style="line-height: 40px">
            <td>
              <p>
                <span
                  style="
                    font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                    font-size: 18px;
                    display: block;
                  "
                >
                  Your latest job is complete:
                </span>
                <span
                  style="
                    font-family: Avenir-Heavy-Custom, Helvetica, sans-serif;
                    font-size: 18px;
                    display: block;
                  "
                >
                  ${data.subject}
                </span>
              </p>
            </td>
          </tr>
          <tr align="center" style="line-height: 0px">
            <td>
              &zwnj;&nbsp;
              <p style="line-height: 23px">
                <span
                  style="
                    font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                    font-size: 18px;
                    display: block;
                  "
                >
                  ${data.supplyCustomText2}
                </span>
              </p>
            </td>
          </tr>
          <tr align="center" style="line-height: 40px">
            <td>
              <p>
                <span style="line-height: 40px; margin-top: 20">
                  <span
                    style="
                      font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                      font-size: 18px;
                      display: block;
                    "
                  >
                    Accounts will supply you with your invoice shortly.
                  </span>
                  <span
                    style="
                      font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                      font-size: 18px;
                      display: block;
                    "
                  >
                    For immediate file supply please <span style="font-weight:800">email a remittace</span> to
                  </span>
                  <span
                    style="
                      font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                      font-size: 18px;
                      display: block;
                    "
                  >
                    <a
                      style="color: black; font-weight: 800"
                      href="mailto: production@imagesthatsell.com.au"
                      >production@imagesthatsell.com.au</a
                    >
                  </span>
                </span>
              </p>
            </td>
          </tr>
          <tr align="center" style="line-height: 30px">
            <td>
              <p style="line-height: 23px">
                <span
                  style="
                    font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                    font-size: 18px;
                    display: block;
                  "
                >
                  ${data.supplyCustomText3}
                </span>
              </p>
              &zwnj;&nbsp;
            </td>
          </tr>
          <tr align="center" style="line-height: 30px">
            <td>
              <span style="line-height: 23px">
                <span
                  style="
                    font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                    font-size: 18px;
                    display: block;
                  "
                >
                  Thanks so much!
                </span>
                <p></p>
                <span
                  style="
                    font-family: Avenir-Light-Custom, Helvetica, sans-serif;
                    font-size: 18px;
                    display: block;
                  "
                >
                  ITS Team
                </span>
              </span>
            </td>
          </tr>
          <tr style="line-height: 20px">
            <td>&zwnj;&nbsp;</td>
          </tr>
        </table>
      </body>
    </html>
    `;
  } else if (data.emailKind === "capturePilot") {
    let content = data.capturePilotContent;
    // capturePilotContent
    let f = `<!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>ITS COD</title>
                <style>
                    .graf {
                        margin-top:0 !important;
                        line-height: 20px;
                    }
                </style>
            </head>
            <body>
            <div
            style="font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px;">
                    ${content}
                    </div>
            </body>
        </html>`;
    return f;
  } else if (data.emailKind.includes("storyboards")) {
    let content = `<!DOCTYPE html>
        <html lang="en">
            <head>
                <meta charset="UTF-8">
                <meta http-equiv="Content-Type" content="text/html charset=UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>ITS Storyboards</title>
                <style>
                    p {
                        margin:0;
                        padding:0;
                    }
                </style>
            </head>
            <body>
            <div
            style="font-family: 'HelveticaNeue-Light', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif; font-size: 14px;">
                    ${data.storyboardsContent}
            </div>
            <br>
            </body>
        </html>`;
    return content;
  }
};

const addLabelToCard = async (cardId, labelId) => {
  const key = "8991105779d1e51b2d00fb7d0e53fdf4";
  const token =
    "bd2df1f9229a34b63c8a5a5f59d75f3529f13475b8d0e27b5e3065e6252a987a";
  const res = await fetch(
    `https://api.trello.com/1/cards/${cardId}/idLabels?key=${key}&token=${token}`,
    {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        value: labelId,
      }),
    }
  ).then((res) => {
    return res.json();
  });
  return res;
};

function getGreeting() {
  var d = new Date(),
    e = new Date(d);
  var msSinceMidnight = e - d.setHours(0, 0, 0, 0);
  var sSinceMidnight = msSinceMidnight / 1000;
  if (sSinceMidnight < 42600) {
    return "Good Morning";
  } else if (sSinceMidnight > 42600 && sSinceMidnight < 43800) {
    return "Hi";
  } else if (sSinceMidnight > 43800) {
    return "Good Afternoon";
  }
}

import React from "react";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import { useAtom } from "jotai";
import { isDebouncingAtom, isAdminAtom } from "../../../-Atoms";

const HeaderTabs = ({
  tabState,
  handleTabStateChange,
  theme,
  modalWidth,
  idList,
}) => {
  const [isDebouncing] = useAtom(isDebouncingAtom);
  const [isAdmin] = useAtom(isAdminAtom);

  const tabStyle = {
    minWidth: "70px",
    maxWidth: "120px",
    fontSize: "0.75em",
    padding: "12px",
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        background: theme === "dark" ? "#1f1f1f" : "#ffffff",
      }}
      className="stickytabsShoot"
    >
      <Tabs
        variant={"scrollable"}
        scrollButtons={"auto"}
        selectionFollowsFocus={true}
        value={tabState}
        onChange={handleTabStateChange}
        style={{ marginBottom: "20px" }}
      >
        <Tab
          label="Shoot Info"
          value="ShootInfo"
          sx={tabStyle}
          disabled={isDebouncing}
        />
        <Tab
          label="Invoicing"
          value="Invoicing"
          sx={tabStyle}
          disabled={isDebouncing}
        />
        <Tab
          label="People"
          value="People"
          sx={tabStyle}
          disabled={isDebouncing}
        />
        <Tab
          label="Image Specs"
          value="JobInfo"
          sx={tabStyle}
          disabled={isDebouncing}
        />
        {idList !== "5f081763cd97556d03075c86" ? (
          <Tab
            label="Outsourcers"
            value="Outsourcers"
            sx={tabStyle}
            disabled={isDebouncing}
          />
        ) : isAdmin ? (
          <Tab
            label="Outsourcers"
            value="Outsourcers"
            sx={tabStyle}
            disabled={isDebouncing}
          />
        ) : null}
      </Tabs>
    </div>
  );
};
export default HeaderTabs;

export async function setExpressLabel(firebase, jobId, checked) {
  const expressID = "5f0e8630fb83455e28da9908";
  const jobDataRaw = await firebase
    .firestore()
    .collection("JobData")
    .doc(jobId)
    .get();
  const jobData = jobDataRaw.data();

  if (checked) {
    if (jobData.idLabels.indexOf(expressID) === -1) {
      jobData.idLabels.push(expressID);
    }
  } else {
    if (jobData.idLabels.indexOf(expressID) !== -1) {
      jobData.idLabels.splice(jobData.idLabels.indexOf(expressID), 1);
    }
  }
  const noDupes = [...new Set(jobData.idLabels)];
  jobData.idLabels = noDupes;

  firebase
    .firestore()
    .collection("JobData")
    .doc(jobId)
    .update({ idLabels: jobData.idLabels });
}

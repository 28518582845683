import React, { useState } from 'react';
import './App.css'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import OutsourcerTable from './Outsourcers/OutsourcerTable';
import OutsourcerTimeline from './Outsourcers/OutsourcerTimeline'
import OutsourcerCostings from './Outsourcers/OutsourcerCostings';

function Outsourcers({ firebase }) {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Timeline" />
                <Tab label="Outsourcers" />
                <Tab label="Costings" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <OutsourcerTimeline firebase={firebase} />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <OutsourcerTable firebase={firebase} />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <OutsourcerCostings firebase={firebase} />
            </TabPanel>
        </>
    )
}
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                children
            )}
        </div>
    );
}

export default Outsourcers;

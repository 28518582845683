import React, { useState, useCallback, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { v4 as uuidv4 } from "uuid";
import BackgroundRollItem from "./BackgroundRollItem";
import debounce from "lodash.debounce";

const BackgroundRolls = ({
  titleStyle,
  backgroundRolls,
  setBackgroundRolls,
  firebase,
  jobData,
}) => {
  const [queuedUpdates, setQueuedUpdates] = useState({});
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const debouncedUpdateFS = useCallback(
    debounce((updates) => {
      setUpdateInProgress(true);
      const batch = firebase.firestore().batch();
      const docRef = firebase.firestore().collection("JobData").doc(jobData.id);
      batch.update(docRef, updates);
      batch.commit()
        .then(() => setUpdateInProgress(false))
        .catch((e) => {
          console.log(e);
          setUpdateInProgress(false);
        });
    }, 500),
    [firebase, jobData.id]
  );

  useEffect(() => {
    if (Object.keys(queuedUpdates).length > 0) {
      debouncedUpdateFS(queuedUpdates);
      setQueuedUpdates({});
    }
  }, [queuedUpdates, debouncedUpdateFS]);

  const addBackgroundRollItem = () => {
    if (!Array.isArray(backgroundRolls)) backgroundRolls = [];
    const newitemObj = {
      id: uuidv4(),
      quantity: "",
      itemName: "",
    };
    const updatedBackgroundRolls = [...backgroundRolls, newitemObj];
    setBackgroundRolls(updatedBackgroundRolls);
    setQueuedUpdates((prevUpdates) => ({
      ...prevUpdates,
      backgroundRolls: updatedBackgroundRolls,
    }));
  };

  const [showRemoveBackgroundRollItem, setShowRemoveBackgroundRollItem] =
    useState(false);
  const toggleShowRemoveBackgroundRollItem = () => {
    setShowRemoveBackgroundRollItem(true);
    setTimeout(() => {
      setShowRemoveBackgroundRollItem(false);
    }, 6000);
  };

  const handleUpdate = (updatedRolls) => {
    setBackgroundRolls(updatedRolls);
    setQueuedUpdates((prevUpdates) => ({
      ...prevUpdates,
      backgroundRolls: updatedRolls,
    }));
  };

  return (
    <>
      <div style={titleStyle}>
        <span>Background Rolls</span>
        <div>
          <IconButton onClick={toggleShowRemoveBackgroundRollItem}>
            <RemoveIcon />
          </IconButton>
          <IconButton onClick={addBackgroundRollItem}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          gap: 15,
          paddingBottom: 25,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        {backgroundRolls &&
          Array.isArray(backgroundRolls) &&
          backgroundRolls.map((bri, index) => (
            <BackgroundRollItem
              key={bri.id}
              item={bri}
              items={backgroundRolls}
              setItems={handleUpdate}
              index={index}
              showRemoveBackgroundRollItem={showRemoveBackgroundRollItem}
              updateInProgress={updateInProgress}
            />
          ))}
      </div>
    </>
  );
};

export default BackgroundRolls;

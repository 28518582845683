import React from "react";

import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "@mui/material/TextField";

import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const AdditionalDates = ({
  firebase,
  jobData,
  additionalDates,
  setAdditionalDates,
}) => {
  return (
    <div style={{ marginBottom: 10 }}>
      {additionalDates &&
        additionalDates.map((additionalDate, index) => (
          <AdditionalDate
            key={`${additionalDate.start}-${index}`}
            index={index}
            start={additionalDate.start}
            end={additionalDate.end}
            additionalDates={additionalDates}
            setAdditionalDates={setAdditionalDates}
            jobData={jobData}
            firebase={firebase}
          />
        ))}
    </div>
  );
};

export default AdditionalDates;

const AdditionalDate = ({
  index,
  start,
  end,
  additionalDates,
  setAdditionalDates,
  jobData,
  firebase,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 15,
        marginTop: 15,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          label="Additional Start Date"
          value={start || null}
          inputFormat="dd/MM/yyyy hh:mm a"
          onChange={(e) => {
            try {
              additionalDates[index].start = new Date(e).toISOString();
              updateAdditionalDates(
                firebase,
                additionalDates,
                setAdditionalDates,
                jobData
              );
            } catch (e) {
              console.log(e);
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
        <DateTimePicker
          label="Additional End Date"
          value={end || null}
          inputFormat="dd/MM/yyyy hh:mm a"
          onChange={(e) => {
            try {
              additionalDates[index].end = new Date(e).toISOString();
              updateAdditionalDates(
                firebase,
                additionalDates,
                setAdditionalDates,
                jobData
              );
            } catch (e) {
              console.log(e);
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
      <IconButton
        onClick={() => {
          additionalDates.splice(index, 1);
          updateAdditionalDates(
            firebase,
            additionalDates,
            setAdditionalDates,
            jobData
          );
        }}
      >
        <RemoveIcon />
      </IconButton>
    </div>
  );
};

const updateAdditionalDates = (
  firebase,
  additionalDates,
  setAdditionalDates,
  jobData
) => {
  setAdditionalDates([...additionalDates]);
  firebase
    .firestore()
    .collection("JobData")
    .doc(jobData.id)
    .update({ additionalDates });
};

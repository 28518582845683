import React, { useState, useEffect } from "react";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

import { useAtom } from "jotai";
import { themeAtom } from "../../../-Atoms";

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  gap: 10,
};

const Contacts = ({ clientData }) => {
  const [contacts, setContacts] = useState(clientData.contacts);

  useEffect(() => {
    setContacts(clientData.contacts);
  }, [clientData]);
  const emailSupplyContacts = () => {
    let emailLink = "mailto:";
    clientData.contacts.forEach((contact) => {
      if (contact.isSupply) {
        if (contact.email) {
          emailLink = `${emailLink}${contact.email},`;
        }
      }
    });
    window.open(emailLink);
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Button
        variant="contained"
        size="small"
        sx={{ width: 300, margin: 1 }}
        onClick={emailSupplyContacts}
      >
        Email Supply Contacts
      </Button>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 20,
          marginTop: 10,
          justifyContent: "center",
        }}
      >
        {contacts?.map((c, i) => (
          <Contact key={`contact-${i}`} contact={c} />
        ))}
      </div>
    </div>
  );
};

export default Contacts;

const Contact = ({ contact }) => {
  const [theme] = useAtom(themeAtom);

  const [name, setName] = useState(contact.name);
  const [title, setTitle] = useState(contact.title);
  const [email, setEmail] = useState(contact.email);
  const [phone, setPhone] = useState(contact.phone);
  const [isBilling, setIsBilling] = useState(contact.isBilling);
  const [isSupply, setIsSupply] = useState(contact.isSupply);

  useEffect(() => {
    setName(contact.name);
    setTitle(contact.title);
    setEmail(contact.email);
    setPhone(contact.phone);
    setIsBilling(contact.isBilling);
    setIsSupply(contact.isSupply);
  }, [contact]);

  //   const handleEmailClick = () => {
  //     window.location.href = `mailto:${email}`;
  //   };
  return (
    <div style={cardStyle}>
      <TextField
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Name"
        value={name}
        size="small"
        inputProps={{ readOnly: true }}
      />
      <TextField
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Title"
        value={title}
        size="small"
        inputProps={{ readOnly: true }}
      />
      <TextField
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Email"
        value={email}
        size="small"
        inputProps={{ readOnly: true }}
      />
      <TextField
        variant="filled"
        InputProps={{ disableUnderline: true }}
        label="Phone"
        value={phone}
        size="small"
        inputProps={{ readOnly: true }}
      />
      <div>
        <FormGroup
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingBottom: "10px",
          }}
        >
          <FormControlLabel
            control={<Checkbox checked={isSupply} />}
            label="Supply"
            style={{
              color:
                theme === "dark" ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
            }}
          />
          <FormControlLabel
            control={<Checkbox checked={isBilling} />}
            label="Billing"
            style={{
              color:
                theme === "dark" ? "rgb(192, 194, 195)" : "rgba(0, 0, 0, 0.6)",
            }}
          />
        </FormGroup>
      </div>
    </div>
  );
};

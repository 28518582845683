import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { setExpressLabel } from "../../GlobalFunctions/setExpressLabel";

const DueDateExpressContainer = ({
  isMobile,
  dueDate,
  setDueDate,
  firebase,
  jobData,
  express,
  setExpress,
  expressDueDate,
  setExpressDueDate,
  canEdit,
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        flexDirection: isMobile ? "column" : "row",
        marginTop: 0,
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label="Due Date"
          inputFormat="dd/MM/yyyy"
          value={dueDate || null}
          onChange={(e) => {
            try {
              if (!canEdit) return;
              e.setHours(17);
              e.setMinutes(30);
              setDueDate(e.toISOString());
              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update({ dueDate: e.toISOString() }, { merge: true });
            } catch (e) {
              setDueDate(null);
              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update({ dueDate: null }, { merge: true });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              InputProps={{ ...params.InputProps, disableUnderline: true }}
              error={dueDate ? false : true}
              sx={{ marginTop: 2, width: isMobile ? "100%" : "56%" }}
            />
          )}
        />
      </LocalizationProvider>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormGroup
          style={{
            width: isMobile ? "100%" : "80%",
            display: "flex",
            alignItems: "center",
          }}
          sx={{ marginTop: 2 }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={express || false}
                onChange={(e) => {
                  if (!canEdit) return;
                  setExpressLabel(firebase, jobData.id, e.target.checked);
                  setExpress(e.target.checked);
                  firebase
                    .firestore()
                    .collection("JobData")
                    .doc(jobData.id)
                    .update({ express: e.target.checked }, { merge: true });
                }}
              />
            }
            label="Express"
          />
        </FormGroup>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Express Due Date"
            value={expressDueDate || null}
            inputFormat="dd/MM/yyyy hh:mm"
            onChange={(e) => {
              if (!canEdit) return;
              let date = null;
              try {
                date = e.toISOString();
              } catch (e) {
                console.log(e);
              }
              setExpressDueDate(date);
              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update(
                  { expressDueDate: date || null },

                  { merge: true }
                )
                .catch((error) => {
                  console.log(error);
                });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                InputProps={{ ...params.InputProps, disableUnderline: true }}
                fullWidth
                sx={{ marginTop: 2 }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default DueDateExpressContainer;

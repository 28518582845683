import React from "react";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useAtom } from "jotai";
import { themeAtom } from "../../-Atoms";

const PrefixSelector = ({
  isMobile,
  canEdit,
  firebase,
  jobData,
  eventPrefix,
  eventPrefixCustom,
  setEventPrefix,
  setEventPrefixCustom,
}) => {
  const [theme] = useAtom(themeAtom);
  // const [first, setfirst] = useState(second)
  //   console.log(eventPrefix);

  const updateFS = debounce(async (value) => {
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ eventPrefixCustom: value }, { merge: true });
  }, 2000);

  const filterButtonStyles = {
    fontWeight: "bold",
    padding: 0.8,
    border: `1px solid ${theme === "dark" ? "#3A3A3A" : "#DCDCDC"} !important`,
    borderRadius: "4px !important",
    width: 70,
  };

  const handleChange = (e) => {
    if (!canEdit) return;
    let val = e.target.value;
    if (e.target.value !== "Custom") {
      setEventPrefixCustom("");
    }
    if (e.target.value === "clear") val = "";

    setEventPrefix(val);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ eventPrefix: val }, { merge: true });
  };

  return (
    <div style={{ marginTop: 30, marginBottom: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 10,
        }}
      >
        <ToggleButtonGroup
          value={eventPrefix || ""}
          onChange={handleChange}
          sx={{
            flexWrap: "wrap",
            justifyContent: "center",
            gap: 0.8,
          }}
        >
          <ToggleButton value="AM" size="small" sx={filterButtonStyles}>
            AM
          </ToggleButton>
          <ToggleButton value="PM" size="small" sx={filterButtonStyles}>
            PM
          </ToggleButton>
          <ToggleButton value="Custom" size="small" sx={filterButtonStyles}>
            Custom
          </ToggleButton>
          <ToggleButton value="clear" size="small" sx={filterButtonStyles}>
            Clear
          </ToggleButton>
        </ToggleButtonGroup>
        {eventPrefix === "Custom" ? (
          <TextField
            variant="filled"
            InputProps={{ disableUnderline: true }}
            size="small"
            sx={{ width: 120 }}
            value={eventPrefixCustom || ""}
            onChange={(e) => {
              setEventPrefixCustom(e.target.value);
              updateFS(e.target.value);
            }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default PrefixSelector;

function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

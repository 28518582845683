import React, { useState, useEffect } from "react";

import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { v4 as uuidv4 } from "uuid";

import VideoEditingItem from "./VideoEditingItem";

import { useAtom } from "jotai";
import { isDebouncingAtom } from "../../../../-Atoms";

const VideoEditingBreakdown = ({
  titleStyle,
  videoEditingBreakdown,
  setVideoEditingBreakdown,
  firebase,
  jobData,
  staffData,
  updateFS,
  findStaff,
}) => {
  const [isDebouncing] = useAtom(isDebouncingAtom);
  const [hoursTotal, setHoursTotal] = useState(0.0);

  useEffect(() => {
    let total = 0.0;
    videoEditingBreakdown &&
      videoEditingBreakdown.forEach((veb) => {
        if (veb.hours) {
          total = parseFloat(total) + parseFloat(veb.hours);
        }
      });
    // console.log(total)
    setHoursTotal(total.toFixed(1));
  }, [videoEditingBreakdown]);

  const addVideoEditingItem = () => {
    if (!Array.isArray(videoEditingBreakdown)) videoEditingBreakdown = [];
    const newitemObj = {
      id: uuidv4(),
      date: "",
      hours: "",
      staff: "",
      notes: "",
    };
    videoEditingBreakdown.push(newitemObj);
    setVideoEditingBreakdown([...videoEditingBreakdown]);
    firebase
      .firestore()
      .collection("JobData")
      .doc(jobData.id)
      .update({ videoEditingBreakdown: videoEditingBreakdown }, { merge: true })
      .catch((e) => console.log(e));
  };

  const [showRemoveVideoEditingItem, setShowRemoveVideoEditingItem] =
    useState(false);
  const toggleShowVideoEditingItem = () => {
    setShowRemoveVideoEditingItem(true);
    setTimeout(() => {
      setShowRemoveVideoEditingItem(false);
    }, 6000);
  };
  return (
    <>
      <div style={titleStyle}>
        <span>Video Editing - {hoursTotal} Hours</span>
        <div>
          <IconButton
            onClick={toggleShowVideoEditingItem}
            disabled={isDebouncing}
          >
            <RemoveIcon />
          </IconButton>
          <IconButton onClick={addVideoEditingItem} disabled={isDebouncing}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: "column",
          gap: 15,
          paddingBottom: 25,
          paddingTop: 10,
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        {videoEditingBreakdown &&
          Array.isArray(videoEditingBreakdown) &&
          videoEditingBreakdown.map((vei, index) => (
            <VideoEditingItem
              key={vei.id}
              item={vei}
              items={videoEditingBreakdown}
              setItems={setVideoEditingBreakdown}
              index={index}
              showRemoveVideoEditingItem={showRemoveVideoEditingItem}
              firebase={firebase}
              jobData={jobData}
              staffData={staffData}
              updateFS={updateFS}
              findStaff={findStaff}
            />
          ))}
      </div>
    </>
  );
};

export default VideoEditingBreakdown;

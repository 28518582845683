import React, { useState, useEffect } from "react";

import Modal from "@mui/material/Modal";
import { debounce } from "lodash";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import MDEditor from "@uiw/react-md-editor";
import ChecklistIcon from "@mui/icons-material/Checklist";

import ConfettiExplosion from "react-confetti-explosion";

const OperatorChecklistModal = ({
  open,
  close,
  jobData,
  firebase,
  t,
  isDarkMode,
}) => {
  const modalContainer = {
    position: "absolute",
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    width: "92%",
    maxWidth: 700,
    height: "65vh",
    outline: "none",
  };
  const modalBody = {
    backgroundColor: isDarkMode ? "#1f1f1f" : "#f4f5f7",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "center",
    height: "100%",
    borderRadius: "10px",
    padding: 20,
  };

  return (
    <Modal
      open={open}
      onClose={close}
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,0.4)" } }}
    >
      {
        <div style={modalContainer}>
          <div style={modalBody}>
            <Checklist
              firebase={firebase}
              jobData={jobData}
              isDarkMode={isDarkMode}
              checklist={jobData.operatorChecklist}
            />
          </div>
        </div>
      }
    </Modal>
  );
};

export default OperatorChecklistModal;

// firebase.firestore().collection('JobData').doc(id).update({ [field]: value }, { merge: true });

const Checklist = ({ firebase, jobData, isDarkMode, checklist }) => {
  const [isExploding, setIsExploding] = useState(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <ChecklistIcon
            fontSize="large"
            sx={{
              color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
              marginLeft: "17px",
              marginRight: "12px",
            }}
            onContextMenu={(e) => {
              e.preventDefault();
              const allTrue = checklist.checklistItems.every(
                (item) => item.checked === true
              );
              const allFalse = checklist.checklistItems.every(
                (item) => item.checked === false
              );

              if (allTrue) {
                checklist.checklistItems.forEach((ci) => {
                  ci.checked = false;
                });
              } else if (allFalse) {
                checklist.checklistItems.forEach((ci) => {
                  ci.checked = true;
                });
              } else {
                checklist.checklistItems.forEach((ci) => {
                  ci.checked = true;
                });
              }

              firebase
                .firestore()
                .collection("JobData")
                .doc(jobData.id)
                .update({ operatorChecklist: checklist });

              setIsExploding(true);
              setTimeout(() => {
                setIsExploding(false);
              }, 3000);
            }}
          />
          <div
            style={{
              fontSize: "1.6rem",
              fontWeight: 800,
              color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
              marginBottom: 10,
            }}
          >
            {checklist.name}
          </div>
        </div>
        {/* <div
          style={{
            color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
            fontWeight: "bold",
            marginRight: 30,
            userSelect: "none",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
          }}
        >
          {checklist?.checklistItems?.length}
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 17,
          marginRight: 30,
        }}
      >
        {/* <span
          style={{
            color: isDarkMode ? "#c1c2c4" : "#1f1f1f",
            fontWeight: 800,
            fontSize: "0.7rem",
          }}
        >
          {Math.trunc(percentage)}%
        </span> */}
        {/* <div style={{ width: "90%" }}>
          <LinearProgress variant="determinate" value={percentage} />
        </div> */}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {isExploding && (
          <ConfettiExplosion
            particleCount={140}
            particleSize={15}
            height={"120vh"}
            duration={2200}
            zIndex={9999}
          />
        )}
      </div>
      <div>
        <List sx={{ width: "100%", paddingTop: "3px", paddingBottom: "3px" }}>
          {checklist.checklistItems &&
            checklist.checklistItems.map((ci, index) => (
              <ChecklistItem
                key={`${ci.title}_${index}_CheckListItem`}
                firebase={firebase}
                jobData={jobData}
                checklist={checklist}
                isDarkMode={isDarkMode}
                index={index}
                ci={ci}
                setIsExploding={setIsExploding}
              />
            ))}
        </List>
      </div>
      <div style={{ marginTop: 40 }}> </div>
    </div>
  );
};

const updateChecklists = debounce(async (firebase, jobData, checklist) => {
  firebase
    .firestore()
    .collection("JobData")
    .doc(jobData.id)
    .update({ operatorChecklist: checklist });
}, 1000);

const ChecklistItem = ({
  isDarkMode,
  firebase,
  jobData,
  checklist,
  index,
  ci,
  setIsExploding,
}) => {
  const ListItemTextID = `${ci.title}_${index}_CheckListItem_Title`;
  const CheckboxID = `${ci.title}_${index}_CheckListItem_Checkbox`;
  const TextAreaID = `${ci.title}_${index}_CheckListItem_TextArea`;

  const [title, setTitle] = useState(ci.title);
  const [checked, setChecked] = useState(ci.checked);
  const editMode = false;
  // const [editMode, setEditMode] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const toggleCheck = async (e) => {
    const newChecked = !checked;
    setChecked(newChecked);
    ci.checked = newChecked;

    let totalChecked = 0;
    checklist.checklistItems.forEach((c) => {
      if (c.checked) totalChecked = totalChecked + 1;
    });
    if (totalChecked === checklist.checklistItems.length) {
      setIsExploding(true);
      setTimeout(() => {
        setIsExploding(false);
      }, 3000);
    }

    updateChecklists(firebase, jobData, checklist);
  };

  useEffect(() => {
    setTitle(ci.title);
    setChecked(ci.checked);
  }, [ci]);

  return (
    <>
      <ListItem
        disablePadding
        className="checklistItem"
        style={{
          webkitTouchCallout: "none",
          webkitUserSelect: "none",
          khtmlUserSelect: "none",
          MozUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "90%",
            marginLeft: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={checked}
                onClick={toggleCheck}
                disableRipple
                id={CheckboxID}
                size="small"
              />
            </ListItemIcon>
            {editMode ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: 5,
                  marginLeft: -25,
                }}
              >
                <textarea
                  id={TextAreaID}
                  onKeyPress={(event) => {
                    if (event.key === "Enter" && !event.shiftKey) {
                      return false;
                    }
                  }}
                  value={title}
                  autoFocus
                  onFocus={(e) => {
                    e.target.setSelectionRange(
                      e.target.value.length,
                      e.target.value.length
                    );
                  }}
                  style={{
                    width: "100%",
                    resize: "none",
                    outline: "none",
                    borderRadius: 5,
                    border: "none",
                    padding: 6,
                    minHeight: 80,
                    color: isDarkMode ? "#ffffff" : "#000000",
                    background: isDarkMode ? "#1c1c1c" : "#ffffff",
                  }}
                  onChange={handleTitleChange}
                ></textarea>
              </div>
            ) : (
              <div
                onClick={() => {
                  toggleCheck();
                }}
                id={ListItemTextID}
                style={{
                  width: "90%",
                  marginLeft: -25,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <MDEditor.Markdown
                  source={`${ci.checked ? "~~" : ""}${title}${
                    ci.checked ? "~~" : ""
                  }`}
                  style={{
                    background: "transparent",
                    width: "90%",
                    paddingTop: 5,
                    paddingBottom: 5,
                    color: ci.checked ? "#808080" : "",
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </ListItem>
    </>
  );
};

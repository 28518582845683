import React from "react";
import TextField from "@mui/material/TextField";

const KanbanSpecialRequirementsContainer = ({ value, setValue, label }) => {
  return (
    <TextField
      label={label}
      variant="outlined"
      multiline
      minRows={4}
      maxRows={4}
      value={value || ""}
      sx={{ marginTop: 1 }}
      style={{
        width: "100%",
      }}
      onChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};

export default KanbanSpecialRequirementsContainer;

import React, { useState } from "react";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAtom } from "jotai";
import {
  themeAtom,
  labelsAtom,
  labelColoursAtom,
  isAdminAtom,
} from "../-Atoms";

const PermanentLabelChip = ({ label, kanbanBoard, jobData, firebase }) => {
  const [theme] = useAtom(themeAtom);
  const [labels] = useAtom(labelsAtom);
  const [labelColours] = useAtom(labelColoursAtom);
  const [isAdmin] = useAtom(isAdminAtom);

  let labelData = {};
  labels.forEach((l) => {
    if (l.id === label) {
      labelData = l;
    }
  });
  const [contextMenu, setContextMenu] = useState(null);

  const handleContextMenu = (event) => {
    event.preventDefault();
    if (contextMenu) {
      setContextMenu(null);
    } else {
      setContextMenu({
        mouseX: event.clientX + 2,
        mouseY: event.clientY - 6,
      });
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const isHidden = () => {
    if (kanbanBoard && !labelData.color) return true;
    if (kanbanBoard && labelData.kanbanHidden) return true;
    return false;
  };

  return (
    <>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        <MenuItem
          onClick={() => {
            jobData?.idLabelsPermanent.splice(
              jobData?.idLabelsPermanent.indexOf(label),
              1
            );
            firebase
              .firestore()
              .collection("JobData")
              .doc(jobData.id)
              .update({ idLabelsPermanent: jobData.idLabelsPermanent });
            handleClose();
          }}
        >
          Delete Permanent Label
        </MenuItem>
      </Menu>
      {isHidden() ? null : (
        <Chip
          label={labelData.name}
          size="small"
          onClick={() => {}}
          onContextMenu={isAdmin ? handleContextMenu : noop}
          sx={{
            backgroundColor: labelData.color
              ? labelColours[labelData.color][theme]
              : "rgba(0, 0, 0, 0.4)",
            "&:hover": {
              backgroundColor: kanbanBoard
                ? labelData.color
                  ? labelColours[labelData.color][theme]
                  : "rgba(0, 0, 0, 0.2)"
                : newShade(
                    labelData.color
                      ? labelColours[labelData.color][theme]
                      : "rgba(0, 0, 0, 0.2)",
                    theme === "dark" ? -30 : 30
                  ),
            },
            height: kanbanBoard ? "" : 24,
            fontSize: kanbanBoard ? 11 : 13,
            color: "white",
            fontWeight: "bold",
            borderRadius: 0.6,
            margin: kanbanBoard ? 0 : 0,
            marginRight: kanbanBoard ? 1 : 0.4,
            marginBottom: kanbanBoard ? 1 : 0,
          }}
        />
      )}
    </>
  );
};

export default PermanentLabelChip;

const noop = () => {};

const newShade = (hexColor, magnitude) => {
  hexColor = hexColor.replace(`#`, ``);
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16);
    let r = (decimalColor >> 16) + magnitude;
    r > 255 && (r = 255);
    r < 0 && (r = 0);
    let g = (decimalColor & 0x0000ff) + magnitude;
    g > 255 && (g = 255);
    g < 0 && (g = 0);
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
    b > 255 && (b = 255);
    b < 0 && (b = 0);
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
  } else {
    return hexColor;
  }
};

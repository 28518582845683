import * as ZXing from "html5-qrcode/third_party/zxing-js.umd"

export function performMonkeyPatches() {
	console.log("Monkey patching")
	
	// Monkey-patch based on https://github.com/zxing-js/library/pull/420/commits/7644e279df9fd2e754e044c25f450576d2878e45
	const oldFunc = ZXing.HTMLCanvasElementLuminanceSource.toGrayscaleBuffer;
	let inverterToggle = true;
	ZXing.HTMLCanvasElementLuminanceSource.toGrayscaleBuffer = function(imagebuffer, width, height) {
		const grayscaleBuffer = oldFunc(imagebuffer, width, height);
		const len = grayscaleBuffer.length;
		inverterToggle = !inverterToggle;
		if(inverterToggle) {
			for (let i = 0; i < len; i++) {
				grayscaleBuffer[i] = 0xFF - grayscaleBuffer[i];
			}
		}
		return grayscaleBuffer;
	};
}